import React from 'react';

const ActionDelete = ({ fill = "#000000", width = 25, height = 25 }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1804 6.10425L15.0748 3.8931C14.5267 2.79677 13.4062 2.10425 12.1804 2.10425C10.9547 2.10425 9.83416 2.79677 9.28599 3.8931L8.18042 6.10425M4.18042 6.10425L20.1804 6.10425M10.1804 11.1042L10.1804 16.1042M14.1804 11.1042L14.1804 16.1042M6.18042 6.10425L18.1804 6.10425L18.1804 15.1042C18.1804 16.968 18.1804 17.8999 17.8759 18.635C17.47 19.6151 16.6913 20.3938 15.7112 20.7998C14.9761 21.1042 14.0442 21.1042 12.1804 21.1042C10.3167 21.1042 9.38477 21.1042 8.64969 20.7998C7.66957 20.3938 6.89088 19.6151 6.4849 18.635C6.18042 17.8999 6.18042 16.968 6.18042 15.1042L6.18042 6.10425Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
);

export default ActionDelete;
