import React from 'react';

const VCPromptPreviousBtnVC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 11 20">
            <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.89 18.818a44.226 44.226 0 0 1-8.403-7.93 1.16 1.16 0 0 1 0-1.473 44.226 44.226 0 0 1 8.402-7.93" />
        </svg>

    );
};

export default VCPromptPreviousBtnVC;