import { Box, Typography } from "@mui/material";
import DeleteIcon from "components/Icons/Delete.svg";
import RefreshIcon from "components/Icons/Refresh.svg";
import { useState } from "react";
import AdminPortalActionIcon from "components/Icons/AdminPortalAction.svg";
import { isActionButtonEnabled } from "utils/teamsActionOnUserPermissions";


const ToolBar = ({ user, userData, selected, fetchCompanyData, openDeleteModal, handleOpenActionOnUserModal, companyInformation, myPrivateUsers, myTeamsInformation }) => {
    const [isLoading, setIsLoading] = useState(false); // State for loading indication


    console.log("yser data ***: ", userData);
    const handleRefresh = async () => {
        setIsLoading(true); // Set loading state to true
        await fetchCompanyData(); // Call the function to fetch data
        setIsLoading(false); // Set loading state to false after fetching
    };

    const isDeleteDisabled = (selected?.includes(user?.sub) || selected?.length < 1);

    const isActionDisabled = !isActionButtonEnabled(
        selected, // Map selected IDs to row objects
        user,
        companyInformation,
        myPrivateUsers,
        myTeamsInformation
      ); 

    return(
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#F5F7FF",
                    },
                    }}
                    // onMouseEnter={() => setIsRefreshHovered(true)}
                    // onMouseLeave={() => setIsRefreshHovered(false)}
                    onClick={handleRefresh}
                >
                    {/* Commenting out the hover effect conditional rendering */}
                    {/* {isRefreshHovered === false ? ( */}
                    <>
                        <RefreshIcon color="enabled" height={22} width={22} />
                        <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#2D2D2D",
                        }}
                        >
                        Refresh
                        </Typography>
                    </>
                    {/* ) : ( */}
                    {/* <>
                        <RefreshIcon color="hovered" />
                        <Typography
                        sx={{
                            background:
                            "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                            backgroundOrigin: "border-box",
                            backgroundClip: "text",
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "transparent",
                        }}
                        >
                        Refresh
                        </Typography>
                    </> */}
                    {/* )} */}
                </Box>
                {(user.role === 'SUPERADMINS' || user.role === 'OWNER') && (
                <>
                    <Box
                        sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "8px",
                        borderRadius: "8px",
                        transition: "all 0.3s",
                        cursor: !isDeleteDisabled ? "pointer" : "not-allowed",
                        "&:hover": {
                            backgroundColor: "#F5F7FF",
                        },
                        }}
                        onClick={() => {
                            if (!isDeleteDisabled) {
                              openDeleteModal(); // Trigger the delete modal
                            }
                        }}
                    >
                        <DeleteIcon color={isDeleteDisabled ? "disabled" : "enabled"} height={22} width={22}/>
                        <Typography
                            sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: isDeleteDisabled ? "#B6B6B6" : "#2D2D2D",
                            }}
                        >
                            Delete
                        </Typography>
                    </Box>
                    </>
                )}
                {((userData?.accessTo === 'platform') || (userData?.accessTo === 'virtual_coach' && user?.role === 'OWNER')) && (
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    cursor: isActionDisabled ? "not-allowed" : "pointer",
                    "&:hover": {
                        backgroundColor: "#F5F7FF",
                      },
                    }}

                    onClick={(!isActionDisabled) ? handleOpenActionOnUserModal : null}
                >
                    <>
                    <AdminPortalActionIcon fill={isActionDisabled ? "#B6B6B6" : "#000000"} />
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: isActionDisabled ? "#B6B6B6" : "#000000",
                        }}
                    >
                        Actions
                    </Typography>
                    </>
                </Box>
                )}
            </Box>
        </>
    );
}

export default ToolBar;
