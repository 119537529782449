import { Box, Button, Typography } from "@mui/material";
import vector from "assets/Vector2.png";
import LockIcon from "components/Icons/LockIcon.svg";
import { useNavigate } from "react-router";

const PersonalTierUpgrade = () => {
    const navigate = useNavigate();
    return(
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "50%",
            left: {xs: "50%", lg: "60%", xl:"58%"},
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: "840px",
              maxWidth: {
                xs: "calc(100% - 32px)",
                md: "calc(100% - 40px)",
              },
              padding: "56px",
              background: "linear-gradient(45deg, #070707 0%,  #666666 100%)",
              borderRadius: "40px",
              display: "flex",
              alignItems: {
                xs: "flex-start",
                md: "center",
              },
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
              gap: {
                xs: "40px",
                md: "0",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  xl: "50%",
                },
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "300",
                  fontSize: "32px",
                  fontFamily: "Montserrat",
                }}
              >
                Upgrade your subscription today!
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  lineHeight: "1.5",
                  letterSpacing: "0.3px",
                }}
              >
                By upgrading, you can create and manage teams and members. While gaining valuable insights over communication and actions.
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  xl: "50%",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-end",
                },
                zIndex: "1",
              }}
            >
                <a
                    href="https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                    textDecoration: "none",
                    }}
                >
                    <Button
                        onClick={() => navigate("/platform/coach")}
                        sx={{
                        width: "250px",
                        paddingX: "20px",
                        paddingY: "16px",
                        background: "white",
                        border: "1px solid white",
                        borderRadius: "52px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        transition: "all 0.3s",
                        "&:hover": {
                          background: "black",
                          "& svg path": { // Target the path element within the SVG
                              fill: "white", // Change the fill color to white on hover
                          },
                          "& *": {
                              color: "white",
                          },
                      },
                        }}
                    >
                        <Typography
                        sx={{
                            fontFamily: "Lexend",
                            color: "#262626",
                            fontSize: "10px",
                            fontWeight: "700",
                            lineHeight: "1.5",
                            letterSpacing: "2px",
                            textTransform: "uppercase",
                        }}
                        >
                          Upgrade Subscription
                        </Typography>
                        <LockIcon />
                    </Button>
                </a>
            </Box>
            <Box
              component="img"
              src={vector}
              alt="vector"
              width={{ xs: "395px" }}
              sx={{
                position: "absolute",
                marginLeft: {md: "390px"},
                zIndex: "0",
                display: {xs:"none", md:"inline"}
              }}
            />
          </Box>
        </Box>
    )
}

export default PersonalTierUpgrade;