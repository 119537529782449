import React from "react";

function DeleteIcon({ color, width = "25", height = "25" }) {
  const getColor = () => {
    switch (color) {
      case "enabled":
        return "#2D2D2D"; // Enabled color
      case "hovered":
        return "black"; // Hovered gradient
      case "disabled":
        return "#B6B6B6"; // Disabled color
      default:
        return "#B0B7BD"; // Default color
    }
  };

  const commonPath = (
    <path
      d="M16.1526 6.10645L15.047 3.8953C14.4989 2.79897 13.3783 2.10645 12.1526 2.10645C10.9269 2.10645 9.80633 2.79897 9.25816 3.8953L8.15259 6.10645M4.15259 6.10645L20.1526 6.10645M10.1526 11.1064L10.1526 16.1064M14.1526 11.1064L14.1526 16.1064M6.15259 6.10645L18.1526 6.10645L18.1526 15.1064C18.1526 16.9702 18.1526 17.9021 17.8481 18.6372C17.4421 19.6173 16.6634 20.396 15.6833 20.802C14.9482 21.1064 14.0164 21.1064 12.1526 21.1064C10.2888 21.1064 9.35694 21.1064 8.62185 20.802C7.64174 20.396 6.86305 19.6173 6.45707 18.6372C6.15259 17.9021 6.15259 16.9702 6.15259 15.1064L6.15259 6.10645Z"
      stroke={getColor()}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="hoveredGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#242F8C", stopOpacity: 1 }} />
          <stop offset="40%" style={{ stopColor: "#03093A", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "#D8659B", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      {commonPath}
    </svg>
  );
}

export default DeleteIcon;
