import React from "react";
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import avatar from "assets/avatar.png";

const ProfilePicture = ({ src, size = 100, addBorder = false, borderWidth = 4, fallback = avatar, onError }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent", // Outer border background if enabled
        borderRadius: "50%", // Circular container
        width: `${size}px`, // Outer container size
        height: `${size}px`,
        borderWidth: addBorder ? "medium" : "",
        borderColor: addBorder ? "#0076BA" : ""
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white", // Inner container background
          borderRadius: "50%", // Circular container
          width: `calc(${size}px - ${2 * borderWidth}px)`, // Adjust for border
          height: `calc(${size}px - ${2 * borderWidth}px)`,
          overflow: "hidden", // Prevent overflow
        }}
      >
        <LazyLoadImage
          alt="Profile Picture"
          src={src || fallback}
          effect="blur"
          style={{
            borderRadius: "50%", // Ensure the image stays circular
            objectFit: "cover", // Maintain aspect ratio and fill container
            width: `calc(${size}px - ${2 * borderWidth}px)`, // Adjust for border
            height: `calc(${size}px - ${2 * borderWidth}px)`,
          }}
          onError={onError || (() => console.error("Error loading profile picture."))}
        />
      </Box>
    </Box>
  );
};

export default ProfilePicture;
