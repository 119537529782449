import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { Check, ErrorOutline } from "@mui/icons-material";
import { useIncrementClientCount } from "hooks/useIncrementClientCount";
import { useDecrementClientCount } from "hooks/useDecrementClientCount";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { useAuthenticator } from "@aws-amplify/ui-react";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import axios from "axios";

import { generateClient } from "aws-amplify/data";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const ClientCards = ({
  user,
  userData,
  clientData,
  clientStatusArray,
  setLoading,
  fetchClientData,
  avatar,
}) => {
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [selectedDeleteClient, setSelectedDeleteClient] = useState({});
  const navigate = useNavigate();
  const [menuShow, setMenuShow] = useState([]);
  const { checkClientInviteAllowance, loadingCounter, error } =
    useCheckClientInviteAllowance();
  const { decrementClientCount, loadingDecrement, errorDecrement } =
    useDecrementClientCount();
  const { incrementClientCount, loadingIncrease, errorIncrease } =
    useIncrementClientCount();

  const deleteClientModalContent = () => {
    return (
      <Modal open={openDeleteClientModal} onClose={closeDeleteClientModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="#160042" fontWeight="400">
              Are you sure you want to delete {selectedDeleteClient.full_name}?
            </Typography>
            <Box display="flex" gap="8px" alignItems="center">
              <ErrorOutline
                sx={{
                  color: "#CC5F0A",
                }}
              />
              <Typography color="#6B7094" fontWeight="400">
                All of their data will be erased and cannot be retrieved.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeDeleteClientModal} label={"No"} />
            <ConfirmButton onClick={handleDeleteClient} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleDeleteClient = async () => {
    if (!user?.sub) {
      console.error("User ID is undefined or null.");
      toast.error("User authentication is required.");
      return;
    }

    try {
      setLoading(true);
      setOpenDeleteClientModal(false);

      const client_id = selectedDeleteClient?.user_id;

      // Wrap client_id in an array since the backend expects an array of IDs
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-company-members`,
        {
          userIds: [client_id], // Pass client_id as an array
          companyId: userData?.companyInformation?.company_id,
          user_id: user?.sub,
        }
      );

      // Decrement client count in DB after successful client deletion
      const { deletedCount } = response.data; // API should return the count of deleted users

      // Decrement the seat count based on the number of deleted users
      for (let i = 0; i < deletedCount; i++) {
        await resourceClient.mutations.decrementCoacheeSeatCount({
          user_id: userData?.companyInformation?.owner?.user_id,
        });
      }

      fetchClientData(); // Refresh client data assuming client list display needs to be updated
      setLoading(false);

      toast(`Member deleted successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
    } catch (err) {
      console.error("Error deleting member", err.message);
      toast.error("Failed to update seat count.");
      toast.error(err.message);
      setLoading(false);
    }
  };

  const closeDeleteClientModal = () => {
    setOpenDeleteClientModal(false);
    setSelectedDeleteClient({});
  };

  const handleDisableClient = async (client_id) => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/disable_client_test`,
        {
          client_id: client_id,
          coach_id: user?.sub,
        }
      );
      const { clientCount } = await decrementClientCount(user?.sub);
      fetchClientData();
      toast(`Client disabled successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleEnableClient = async (client_id) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/enable_client_test`,
        {
          client_id: client_id,
          coach_id: user?.sub,
        }
      );
      const { clientTierAllowance, clientCount } = await incrementClientCount(
        user?.sub
      );
      fetchClientData();
      toast(`Client enabled successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      setLoading(false);
    } catch (err) {
      console.log("Error in enable client", err.message);
      toast.error("Failed to update client count.");
      setLoading(false);
    }
  };

  const handleResendInvite = async (client_id, client_name) => {
    try {
      if (!userData?.companyInformation) {
        throw new Error("Company information is not available");
      }
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/resend-invite`,
        {
          invited_user_id: client_id,
          user_id: user?.sub,
          company_id: userData?.companyInformation?.company_id,
          company_name: userData?.companyInformation?.company_name || "",
        }
      );

      toast(`Invite has been resent to ${client_name}`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });

      setLoading(false);
    } catch (err) {
      console.error("Error in resend invite", err.message);
      toast.error(`Error resending invitation to ${client_name}`);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        paddingRight: "10px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "8px", // Custom scrollbar width
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgb(207, 207, 207)", // Custom scrollbar thumb color
          borderRadius: "4px", // Rounded scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgb(170, 170, 170)", // Thumb color on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // Custom scrollbar track
        },
      }}
    >
      {deleteClientModalContent()}
      {clientData.map((row, index) => (
        <Box
          key={index} // Add a unique key prop here
          sx={{
            display: "flex",
            alignItems: {
              xs: "center",
              lg: "center",
            },
            position: "relative",
            gap: {
              xs: "8px",
              xl: "24px",
            },
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            padding: "16px 8px",
            bgcolor: "white",
            borderRadius: "40px",
            cursor: "pointer",
          }}
          data-cy="client-list-item"
          onClick={() =>
            navigate(`/platform/coach/coachee-overview/${row.user_id}`)
          }
        >
          {(user?.role === "SUPERADMINS" || user?.role === "OWNER") &&
            user?.sub !== row.user_id &&
            row.user_id !== userData?.companyInformation?.owner.user_id && (
              <Box
                sx={{
                  color: "#C4C4C4",
                  position: "absolute",
                  width: "30px",
                  height: "30px",
                  top: "24px",
                  right: "24px",
                  cursor: "pointer",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  const newStates = [...menuShow];
                  newStates[index] = !newStates[index];
                  setMenuShow(newStates);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color: "#C4C4C4",
                    fontSize: "32px",
                  }}
                />
              </Box>
            )}
          {menuShow[index] && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                paddingY: "6px",
                top: "70px",
                right: "24px",
                width: "10%",
                borderRadius: "12pt",
                background: "#fff",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                zIndex: "999",
              }}
            >
              {/* {row.client_status !== "withdrew" && (
                row.client_status === "disabled" ? (
                  <Button
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "1.4",
                      padding: "8px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: "200",
                      "&:hover": {
                        background: "#F5F5F5",
                        cursor: "pointer",
                      },
                      "&:disabled": {
                        color: "#d3d3d3",
                        cursor: "not-allowed",
                      },
                    }}
                    disabled={row.client_status === "accepted" || row.client_status === "pending"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEnableClient(row.user_id);
                    }}
                  >
                    Enable
                  </Button>
                ) : (
                  <Button
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "1.4",
                      padding: "8px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: "200",
                      "&:hover": {
                        background: "#F5F5F5",
                        cursor: "pointer",
                      },
                      "&:disabled": {
                        color: "#d3d3d3",
                        cursor: "not-allowed",
                      },
                    }}
                    disabled={row.status === "pending"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDisableClient(row.user_id);
                    }}
                  >
                    Disable
                  </Button>
                )
              )} */}
              {row.client_status !== "accepted" &&
                row.client_status !== "disabled" && ( // Invite Pending status check
                  <Button
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "1.4",
                      padding: "8px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: "200",
                      "&:hover": {
                        background: "#F5F5F5",
                        cursor: "pointer",
                      },
                      "&:disabled": {
                        color: "#d3d3d3",
                        cursor: "not-allowed",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleResendInvite(row.user_id, row.full_name);
                    }}
                  >
                    Resend Invite
                  </Button>
                )}
              <Button
                sx={{
                  color: "black",
                  fontSize: "14px",
                  lineHeight: "1.4",
                  padding: "8px",
                  textAlign: "center",
                  textTransform: "none",
                  fontWeight: "200",
                  "&:hover": {
                    background: "#F5F5F5",
                    cursor: "pointer",
                  },
                  "&:disabled": {
                    color: "#d3d3d3",
                    cursor: "not-allowed",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDeleteClient(row);
                  const newStates = [...menuShow];
                  newStates[index] = false;
                  setMenuShow(newStates);
                  setOpenDeleteClientModal(true);
                }}
              >
                Delete
              </Button>
            </Box>
          )}
          <Box
            sx={{
              background: "#0076BA",
              padding: "3px",
              borderRadius: "50%",
              width: {
                xs: "60px",
                lg: "80px",
              },
              height: {
                xs: "60px",
                lg: "80px",
              },
            }}
          >
            <Box
              sx={{
                background: "white",
                padding: "4px",
                borderRadius: "50%",
                width: {
                  xs: "54px",
                  lg: "74px",
                },
                height: {
                  xs: "54px",
                  lg: "74px",
                },
              }}
            >
              <Avatar
                sx={{
                  width: {
                    xs: "46px",
                    lg: "66px",
                  },
                  height: {
                    xs: "46px",
                    lg: "66px",
                  },
                }}
                src={row.avatar ?? avatar}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              minWidth: "fit-content",
            }}
          >
            {/* {row.new_insights > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "700",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    color: "#499A0A",
                  }}
                >
                  NEW INSIGHTS AVAILABLE
                </Typography>
              </Box>
            )} */}
            {row.client_status !== "accepted" && (
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "700",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    textTransform: "uppercase",
                    color:
                      row.status === "accepted"
                        ? "#499A0A"
                        : row.client_status === "disabled" ||
                          row.client_status === "expired"
                        ? "#FF6A55"
                        : "#F69607",
                  }}
                >
                  {row.client_status === "pending"
                    ? "Invite Pending"
                    : row.client_status === "accepted"
                    ? "Active Status"
                    : row.client_status === "disabled"
                    ? "Disabled Status"
                    : row.client_status === "expired"
                    ? "Invite Expired"
                    : row.client_status === "withdrew"
                    ? "Withdrew"
                    : ""}
                </Typography>
              </Box>
            )}
            <Typography
              sx={{
                ...headingStyle,
                fontWeight: "400",
              }}
              data-cy="client-overview-button"
            >
              {row.full_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              marginRight: {
                lg: "60px",
                xl: "100px",
              },
              marginLeft: {
                lg: "auto",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                paddingRight: "20px",
                borderRight: "1px solid #D4D4D4",
                width: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: "300",
                  color: "black",
                  lineHeight: 1,
                }}
              >
                {row.session_count <= 0 || !row.session_count
                  ? "0"
                  : row.session_count}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "1.2",
                  color: "black",
                }}
                data-cy="client-overview-button"
              >
                Sessions
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                paddingLeft: "20px",
                alignItems: "center",
                width: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: "300",
                  color: "black",
                  lineHeight: 1,
                }}
              >
                {row.actions_count <= 0 || !row.actions_count
                  ? "0"
                  : row.actions_count}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "1.2",
                  color: "black",
                }}
                data-cy="client-overview-button"
              >
                Actions
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ClientCards;

const headingStyle = {
  color: "black",
  fontWeight: "600",
  fontSize: "28px",
  fontFamily: "Montserrat",
  lineHeight: "120%",
};
