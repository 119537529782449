import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, useFormik } from "formik";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { API, Auth, Storage } from "aws-amplify";
import GlobalSidebar from "components/GlobalSidebar/GlobalSidebar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "components/GlobalHeader/GlobalHeader";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import CpPaymentSuccess from "scenes/Stripe/CoachingPlatformPaymentSuccess";

const CpPlatformPaymentSuccessPage = () => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  return (
    <>
      <Header hideLogo={false} hasRightSidebar={false} />
      {/* <Box
        sx={{
          display: "flex",
        }}
      >
        <GlobalSidebar
          typeOfNav="virtualcoach"
          typeOfBottomNav="genericLastItems"
        /> */}

      {/* <GlobalPageComponent> */}
      <CpPaymentSuccess />
      {/* </GlobalPageComponent> */}
      {/* </Box> */}
    </>
  );
};

export default CpPlatformPaymentSuccessPage;
