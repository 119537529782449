import { Check, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Modal, Select, MenuItem, Typography } from "@mui/material";
import { useState } from "react"; // Import useState for managing selected client
import { generateClient } from "aws-amplify/data";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { toast } from "react-toastify";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const AssignAssessmentToTeamModal = ({ user, userData, open, assessment, fetchData, setOpenAssignToTeam, teams }) => {
  // State to hold the selected team
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log("teams: ", teams)

  // Check if the user is a superadmin or admin or owner
  const isOwner = user?.role === 'OWNER';
  const isSuperAdmin = user?.role === 'SUPERADMINS';
  const isAdmin = user?.role === 'ADMINS';
  
  // Access team information


  // Function to handle team selection from the dropdown (for both superadmins and admins)
  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value); // Assign full team object
  };

  const handleClose = () => {
    setOpenAssignToTeam(false);
    setSelectedTeam(null);
  };

  // Function to create the assessment and send the selected team user IDs
  const assignAssessment = async () => {
    const team = selectedTeam; // Use selected team for both superadmins and admins

    if (!team) {
      console.log("No team selected");
      return;
    }

    console.log("team selected: ", team)

    // Create a Set to automatically remove duplicate user IDs
    const userIdSet = new Set(
      team.members
        .map(member => member.user_id)
        .filter(id => id !== user?.sub)  // Exclude the current user's ID
    );

    // Convert the Set back to an array
    const userIds = Array.from(userIdSet);

    try {
      setLoading(true);

      // Loop through each user ID and create an assessment
      for (const userId of userIds) {
        const { data, errors } = await resourceClient.models.assessments.create({
          user_id: userId,  // Use user ID from the team
          test_type: assessment,  // Assessment type passed in props
          coach_id: user?.sub,
          assigned: true,
          team_id: team.team_id  // Use selected or admin’s team id
        });

        if (errors) {
          console.error("Errors from GraphQL create assessment: ", errors);
        } else {
          console.log("Response from GraphQL create assessment: ", data);
        }
      }

      toast(`Assessment assigned successfully to the team.`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });

      fetchData();  // Fetch updated data after assignments
    } catch (error) {
      console.error("Error creating assessments: ", error);
    } finally {
      setLoading(false);
      handleClose();  // Close modal after processing
    }
  };

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: 'black' }} />
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          boxShadow: 24,
          padding: {
            xs: "24px",
            lg: "64px",
          },
          gap: "24px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",  // Make the container a column flexbox
          maxWidth: "40%",
          margin: "20px auto",
          overflowY: "auto",
          height: "450px",
        }}
      >
        {/* Modal Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
            flexGrow: 1,  // Allow content to grow and push buttons down
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "36px"
            }}
          >
            Send Assessment
          </Typography>

          {/* Instruction message depending on the role */}
          <Typography
            sx={{
              color: "#828282",
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "Lexend"
            }}
          >
            {(isOwner || isSuperAdmin) ? "Please select a team." : `Assign to one of your teams:`}
          </Typography>

          {/* Dropdown for both superadmins and admins */}
          {(isSuperAdmin || isAdmin || isOwner) && (
            <Box
              sx={{
                marginTop: "20px",
                width: "90%",
                gap: "4px"
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  fontWeight: 700,
                  letterSpacing: 2
                }}
              >
                Teams
              </Typography>
              <Select
                fullWidth
                required
                value={selectedTeam}  // Set the selected team in the dropdown
                onChange={handleTeamChange}  // Handle team selection
                IconComponent={CustomIcon}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: 'white',
                      maxHeight: 300,
                      overflow: 'auto',
                      maxWidth: '20%',
                      width: '20%',
                    },
                  },
                }}
                sx={{
                  color: "black",
                  background: "white",
                  borderRadius: "16px",
                  border: "1px solid #C4CAD4",
                  "&:before, &:after": {
                    display: "none",
                  },
                  "& fieldset": {
                    border: "none !important",
                  },
                }}
              >
                {(teams && teams.length > 0) && teams.map((team) => (
                  <MenuItem
                    key={team.team_id}
                    value={team}  // Set value as team object
                    sx={{
                      background: "white",
                      color: "black",
                      "&:hover": {
                        background: "#F7F7F7",
                        cursor: "pointer"
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "12px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word"
                      }}
                    >
                      {team.team_name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          {/* Spacer to push the buttons to the bottom */}
          <Box sx={{ flexGrow: 1 }} />
          
          {/* Button Section */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
              alignItems: "center",  // Ensure buttons are centered horizontally
            }}
          >
            <DeclineButton
              onClick={handleClose}
              label={"Cancel"}
            />
            <ConfirmButton
              onClick={assignAssessment}
              loading={loading}
              disabled={(!selectedTeam && (isSuperAdmin || isAdmin || isOwner)) || !assessment}  // Disable confirm button if no team selected
              label={"Confirm"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignAssessmentToTeamModal;
