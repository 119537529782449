import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ToolBar from "./ToolBar/ToolBar";
import AdminPortalViewTabs from "./Tabs/AdminPortalViewTabs"
import OrganizationChart from "./OrganizationChart/OrganizationChart";
import axios from "axios";
import UsersTable from "components/Table/CustomTable";
import OwnerAddMemberModal from "./AddMembers/OwnerAddMemberModal";
import OwnerAddSuperAdminModal from "./AddMembers/OwnerAddSuperAdminModal";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import CreateTeamModal from "./CreateTeams/CreateTeamModal";
import DeleteMembersModal from "./DeleteMembers/DeleteMembersModal";
import AdminPortalActionIcon from "../Icons/AdminPortalAction.svg";
import ActionOnUserModal from "./UserActions/ActionOnUserModal";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/data";
import { checkUserGroupAlignment } from "../../helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import { GreenStatusPill, OrangeStatusPill } from "components/Table/StatusPills";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const Owner = ({ user, userData }) => {
  // Initialize selectedView from localStorage, default to 'list' if not set
  const [selectedView, setSelectedView] = useState(
    localStorage.getItem("selectedAdminView") || "list"
  );

  // Function to handle view change and store it in localStorage
  const handleViewChange = (newValue) => {
    const view = newValue === 0 ? "list" : "chart";
    setSelectedView(view);
    localStorage.setItem("selectedAdminView", view); // Store the selected view in localStorage
  };
  const [selected, setSelected] = useState([]);
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false); // Modal state
  const [openAddSuperAdminModal, setOpenAddSuperAdminModal] = useState(false); // Modal state
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);
  const [openActionOnUserModal, setOpenActionOnUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [availableSeats, setAvailableSeats] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);
  const [myPrivateUsers, setMyPrivateUsers] = useState(null)
  const [internalCompanyMembers, setInternalCompanyMembers] = useState(null)
  const [orgChartMembers, setOrgChartMembers] = useState(null);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
            companyId: userData?.companyInformation?.company_id,
          },
        }
      );

      console.log("owner fetch res: ", response)
      setCompanyMembers(response.allMembers);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyPrivateUsers(response.myPrivateUsers);
      setInternalCompanyMembers(response.internalCompanyMembers); //excludes any private users
      setOrgChartMembers(response.orgChartMembers);

      // Update selectedUser with the latest data if it's set
      if (selectedUser && selectedUser.user_id) {
        const updatedUser = response.members.find(
          (member) => member.user_id === selectedUser.user_id
        );
        if (updatedUser) {
          setSelectedUser({
            ...updatedUser,
            id: selectedUser.id, // Preserve the id if needed
            title: updatedUser.company_role_name,
          });
        } else {
          // If the user is no longer in the members list (e.g., deleted), close the modal
          setSelectedUser(null);
          setOpenActionOnUserModal(false);
        }
      }

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: user?.sub,
        });

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleDelete = async (keepData) => {
    try {
      setLoadingDelete(true);

      // Call the API to delete users
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-company-members`,
        {
          userIds: selected,
          companyId: userData?.companyInformation?.company_id,
          user_id: user?.sub,
          keepData
        }
      );

      const { deletedCount } = response.data; // API should return the count of deleted users

      // Decrement the seat count based on the number of deleted users
      for (let i = 0; i < deletedCount; i++) {
        await resourceClient.mutations.decrementCoacheeSeatCount({
          user_id: user?.sub,
        });
      }

      toast.success(
        `${deletedCount} user${
          deletedCount > 1 ? "s" : ""
        } deleted successfully!`
      );
      setOpenDeleteModal(false);
      fetchCompanyData(); // Refresh company data after deletion
    } catch (error) {
      console.error("Error deleting users:", error);
      toast.error("Failed to delete users. Please try again.");
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true); // Open the delete modal
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };

  //define the columns
  const columns = [
    {
      field: "full_name",
      headerName: (
        <span id="hint-anchor-2" style={{ color: "inherit" }}>
          Full Name
        </span>
      ),
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value}
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              textTransform: "none"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "team_name",
      headerName: "Team",
      flex: 1,
      renderCell: (params) => {
        const value = Array.isArray(params.value) ? params.value.join(", ") : params.value || "No Team";
        return (
          <Tooltip title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
      renderCell: (params) => {
        const roleMap = {
          owner: "Owner",
          super_admin: "Super Admin",
          admin: "Admin",
          user: "User",
          private: "Private User",
        };

        return (
          <Tooltip title={params.value || ""}>
              {roleMap[params.value] || "No role"}
          </Tooltip>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 0.8,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value || "No Title"}
        </Tooltip>
      ),
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 0.5,
      renderCell: (params) => {
        const value = Array.isArray(params.value) ? params.value.join(", ") : params.value || "No Manager";
        return (
          <Tooltip title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value === "accepted" ? (
            <GreenStatusPill text={params.value} />
          ) : params.value === "pending" || params.value === "expired" ? (
            <OrangeStatusPill text={params.value} />
          ) : null}
        </Tooltip>
      ),
    },    
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     if(
    //       (params.row.role === 'private' && !myPrivateUsers.includes(params.row.user_id))
    //     ){
    //       return null;
    //     }
    //     return (
    //       <Box sx={{ display: "flex", gap: "8px" }}>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "row",
    //             gap: "4px",
    //             alignItems: "center",
    //             "&:hover": {
    //               cursor: "pointer",
    //             },
    //           }}
    //           onClick={() => handleActionOnUser(params.row)}
    //         >
    //           <AdminPortalActionIcon />
    //           <Typography
    //             sx={{
    //               color: "#93989A",
    //               fontSize: 14,
    //               fontFamily: "Lexend",
    //               fontWeight: "400",
    //               wordWrap: "break-word",
    //             }}
    //           >
    //             Actions
    //           </Typography>
    //         </Box>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const rows = companyMembers.map((member, index) => {
    const teams = member.teams
      .map((team) => team.team_name)
      .sort((a, b) => a.localeCompare(b)); // Sort teams alphabetically
  
    // Normalize manager_full_name to an array for consistent handling
    const managerNames = Array.isArray(member.manager_full_name)
      ? member.manager_full_name
      : member.manager_full_name
      ? [member.manager_full_name]
      : []; // Handle missing or null manager_full_name
  
    return {
      id: index,
      user_id: member.user_id,
      full_name: member.full_name,
      email: member.email,
      team_name: teams.length > 0 ? teams.join(", ") : "No Team", // Convert to a string for sorting
      teams: member.teams,
      role: member.role || "No Role",
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
      manager: managerNames.length > 0 ? managerNames.join(", ") : "No Manager", // Convert to a string for sorting
      manager_user_id: member.manager_user_id || "",
    };
  });

  // // Function to handle switching team
  // const handleActionOnUser = (member) => {
  //   setSelectedUser(member);
  //   handleOpenActionOnUserModal();
  // };

  // Function to open/close the Add Member modal
  const handleOpenAddMemberModal = () => setOpenAddMemberModal(true);
  const handleOpenAddSuperAdminModal = () => setOpenAddSuperAdminModal(true);
  const handleOpenCreateTeamModal = () => setOpenCreateTeamModal(true);
  const handleOpenActionOnUserModal = () => {
    if(selected?.length === 1){
      setSelectedUser(selected[0] || "");
      setOpenActionOnUserModal(true)
    }
  };
  const handleopenAddPrivateUserModal = () => setOpenAddPrivateUserModal(true);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_wbnnlALW"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
          overflow: "hidden"
        }}
      >
        <Box
          id="hint-anchor-1"
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1">Teams & Members</span>
          </Typography> */}
          {/* @m3zi3 */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedView === 'list' ? (
              <Box
                id="hint-anchor-1"
              >
              <ToolBar
                user={user}
                userData={userData}
                selected={selected}
                selectedUser={selectedUser}
                fetchCompanyData={fetchCompanyData}
                openDeleteModal={() => setOpenDeleteModal(true)}
                handleOpenActionOnUserModal={handleOpenActionOnUserModal}
                companyInformation={companyInformation}
                myPrivateUsers={myPrivateUsers}
              />
              </Box>
            ) : (
              <Typography
                sx={{
                  color: "black",
                  fontSize: 28,
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Organization Chart
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "15px",
              }}
            >
              {selectedView === 'list' && (
              <>
              <Typography
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  padding: "12px",
                  background: "#F1F1F1",
                }}
              >
                <span id="hint-anchor-3">
                  {`${availableSeats} seats are open`}
                </span>
              </Typography>
              <Button
                onClick={handleOpenAddSuperAdminModal}
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  border: "1px black solid",
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                <span id="hint-anchor-4">Add a Super Admin</span>
              </Button>
              <Button
                onClick={handleOpenAddMemberModal}
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  border: "1px black solid",
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                <span id="hint-anchor-5">Add Members</span>
              </Button>
              <Button
                onClick={handleopenAddPrivateUserModal}
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  border: "1px black solid",
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                <span id="hint-anchor-6">Add Private Users</span>
              </Button>
              <Button
                onClick={handleOpenCreateTeamModal}
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  border: "1px black solid",
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                <span id="hint-anchor-7">Create Team</span>
              </Button>
              </>
              )}
              <AdminPortalViewTabs setSelectedView={handleViewChange} />
            </Box>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            overflow: "auto",
          }}
        >
          {selectedView === 'list' ? (
            <>
              <UsersTable
                rows={rows}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[10, 20, 25, 30]}
                checkboxSelection
                loading={loading}
                onSelectionModelChange={(newSelection) => {
                  const selectedUsers = newSelection.map((selectionId) => {
                    const selectedUserInfo = rows.find((row) => row.id === selectionId);
                    return selectedUserInfo; // Return the user_id instead of the index id
                  });
                  setSelected(selectedUsers); // Update state with selected user_ids
                }}
                disableColumnFilter
                disableSelectionOnClick
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                localeText={{
                  noRowsLabel: "No data available",
                }}
              />
            </>
          ) : (
            <OrganizationChart user={user} userData={userData} orgChartMembers={orgChartMembers} loadingCompanyData={loading}/>
          )}
        </Box>
      </Box>

      {/* Add Super Admin Modal */}
      <OwnerAddSuperAdminModal
        user={user}
        userData={userData}
        openAddSuperAdminModal={openAddSuperAdminModal}
        setOpenAddSuperAdminModal={setOpenAddSuperAdminModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
        internalCompanyMembers={internalCompanyMembers}
      />

      {/* Add Member Modal */}
      <OwnerAddMemberModal
        user={user}
        userData={userData}
        openAddMemberModal={openAddMemberModal}
        setOpenAddMemberModal={setOpenAddMemberModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
        internalCompanyMembers={internalCompanyMembers}
      />

      <AddPrivateUserModal
        user={user}
        userData={userData}
        openAddPrivateUserModal={openAddPrivateUserModal}
        setOpenAddPrivateUserModal={setOpenAddPrivateUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      <CreateTeamModal
        user={user}
        userData={userData}
        openCreateTeamModal={openCreateTeamModal}
        setOpenCreateTeamModal={setOpenCreateTeamModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        fetchCompanyData={fetchCompanyData}
      />

      <ActionOnUserModal
        user={user}
        userData={userData}
        openActionOnUserModal={openActionOnUserModal}
        setOpenActionOnUserModal={setOpenActionOnUserModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        myPrivateUsers={myPrivateUsers}
        internalCompanyMembers={internalCompanyMembers}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        fetchCompanyData={fetchCompanyData}
        loadingCompanyData={loading}
      />

      <DeleteMembersModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={handleDelete}
        selectedCount={selected.length}
        selected={selected}
        loading={loadingDelete}
      />
    </Box>
  );
};

export default Owner;
