import React, { useState } from "react";

// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, ListItemText, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "black",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "8px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "20px",
    },
    "& .MuiSelect-icon": {
      top: 0,
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  select: {
    fontFamily: "Lexend",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "-0.02",
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
    },
  },
  selectIcon: {
    position: "relative",
    color: "black",
    fontSize: "16px",
  },
  paper: {
    borderRadius: 8,
    maxWidth: "unset",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontFamily: "Lexend",
      fontWeight: 500,
      padding: 12,
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "-0.02",
      background: "white !important",
      color: "black",
    },
    "& li:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#F5F6F4 !important",
    },
    "& li.Mui-selected:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
  },
  checkbox: {
    color: "#A0A0A0",
    "&.Mui-checked": {
      color: "#294F4A",
    },
  },
  list_item_text: {
    fontFamily: "Lexend !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "1.5 !important",
    letterSpacing: "-0.02 !important",
    color: "black",
  },
}));

export const _ActivityFeedFilter = ({ value, handleChange, items }) => {
  const [opened, setOpened] = useState(false);

  // Validate items but ignore the first one (index 0)
  const allItemsInvalid =
    Array.isArray(items) &&
    items.slice(1).every(
      (item) =>
        !item.key || item.key === "none" || !item.value || item.value === "none"
    );

  const disabled = allItemsInvalid; // Disable the dropdown if all items except the first are invalid

  const selectedItem = items.find((item) => item.value === value);
  const displayValue = selectedItem ? selectedItem.key : "Event Type";

  return (
    <Box
      sx={{
        padding: "8px 6px",
        position: "relative",
        borderWidth: "1px",
        borderColor: "#D5D7DA",
        borderStyle: "solid",
        borderRadius: opened ? "8px 8px 0 0" : "8px",
        backgroundColor: disabled ? "#FAFCFE" : "white",
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        width: "160px",
        maxWidth: "160px",
        height: "36px",
      }}
      aria-disabled={disabled} // Accessibility attribute
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!disabled && value === "none") setOpened(!opened); // Only open if no item is selected
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: disabled ? "#B0B7BD" : "black",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0.4px",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
          >
            {displayValue}
          </Typography>
        </Box>
        {value !== "none" && value !== "" && value !== null ? (
          <CloseIcon
            sx={{
              color: "#8093A1",
              fontSize: "10px",
              cursor: "pointer",
              borderRadius: "50%",
              padding: "2px",
              transition: "all 0.3s ease",
              width: "20px",
              height: "20px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleChange("none");
              setOpened(false);
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{
              color: "#8093A1",
              transition: "all 0.3s ease",
              transform: `rotate(${opened === true ? "180deg" : "0deg"})`,
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>
      {!disabled && (
        <Box
          sx={{
            display: opened === true ? "flex" : "none",
            flexDirection: "column",
            position: "absolute",
            top: "30px",
            left: "-1px",
            zIndex: 10,
            width: "calc(100% + 2px)",
            borderWidth: "0 1px 1px",
            borderColor: "#CBD0C8",
            borderStyle: "solid",
            borderRadius: "0 0 8px 8px",
            maxHeight: "210px",
            overflowY: "auto",
            background: "white",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {items.map(
            (item, index) =>
              index > 0 && // Ignore the first item
              item.key &&
              item.value &&
              item.value !== "none" &&
              item.key !== "none" && (
                <Box
                  key={item.value}
                  sx={{
                    padding: "10px",
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    letterSpacing: "0.4px",
                    color: "black",
                    background: "white",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#EFF3F6",
                    },
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    margin: "4px 6px"
                  }}
                  onClick={() => {
                    handleChange(item.value);
                    setOpened(false);
                  }}
                >
                  {item.key}
                </Box>
              )
          )}
        </Box>
      )}
      {opened && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
