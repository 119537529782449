import React, { useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import { Header } from "../components/GlobalHeader/GlobalHeader";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import "../index.css";
import device from "assets/devices.png";
import vector from "assets/Vector.png";
import virtualCoachImg from "assets/virtualCoachImg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignOut } from "hooks/useSignOut";

const HomePage = ({ user, userData }) => {
  const [shouldNavigateToAltercallDotCom, setShouldNavigateToAltercallDotCom] = useState(true);
  useEffect(() => {
    window.location.href = 'https://altercall.com/aai';
  }, []);



  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionClassName = queryParams.get("sectionClassName");
    if (sectionClassName) {
      const section = document.getElementsByClassName(sectionClassName)[0];
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const navigate = useNavigate();

  // const CompanyLogo = () => {
  //   return (
  //     <Box sx={{ display: "block" }}>
  //       <a
  //         href="https://altercall.com/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         <img src={Logo} style={{ width: "150px" }} alt="Company Logo" />
  //       </a>
  //     </Box>
  //   );
  // };

  const handleSignOut = useSignOut();

  // useEffect(() => {
  //   document.getElementById("homepagevid").play();
  // }, []);

  useEffect(() => {
    if (user) {
      if (userData.accessTo === 'virtual_coach') {
        navigate("/virtualcoach/bot");
      } else if (userData.accessTo === 'client_portal' && userData.isClient === true) {
        navigate("/platform/coachee");
      } else if (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') {
        navigate("/platform/coach");
      } else {
        handleSignOut();
      }
    }
  }, [user, userData, navigate]);



  const [selectedPricing, setSelectedPricing] = useState("Monthly");

  const [loadingEnterprise, setLoadingEnterprise] = useState(false);


  const plans = [
    {
      name: "Virtual Coach",
      monthlyPrice: "$49",
      annualPrice: "$490",
      description: "Gain access to our cutting edge AI powered virtual coach",
    },
    {
      name: "Professional",
      monthlyPrice: "$99",
      annualPrice: "$990",
      description:
        "Optimize your own performance by improving your productivity and organization",
    },
    {
      name: "Coaching Tier 1",
      monthlyPrice: "$149",
      annualPrice: "$1490",
      description: "1-3 Clients: Become an AI powered coach",
    },
    {
      name: "Coaching Tier 2",
      monthlyPrice: "$399",
      annualPrice: "$3990",
      description:
        "4-10 Clients: Ideal for growing an AI powered coaching practice",
    },
    {
      name: "Coaching Tier 3",
      monthlyPrice: "$799",
      annualPrice: "$7990",
      description:
        "11-25 Clients: Ideal for scaling & retaining coaching clients",
    },
    {
      name: "Enterprise",
      monthlyPrice: "Inquire",
      annualPrice: "Inquire",
      description:
        "26+ Clients: An advanced platform for large organizations with multiple executives & coaches",
    },
  ];



  return (
    <>
      {
        shouldNavigateToAltercallDotCom ?
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              background: 'linear-gradient(to bottom, #3c74d3, #071326)',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
          :
          <>
            <Header
              user={user}
              // hideLogo={false}
              hasRightSidebar={false}
              hideGlobalSearchbar={true}
            />

            <Grid
              id="OuterContainer"
              container
              padding={{ xs: "0px 0px", lg: "0px 100px 30px" }}
              sx={{
                width: "100vw",
                height: "100vh",
                overflowX: "hidden",
                height: "calc(100vh - 8vh)",
                backgroundColor: "#EFF3F6",
              }}
            >
              <Grid item xs={12} lg={12}>
                <Box
                  padding={{
                    xs: "35px 15px",
                    lg: "50px 0px",
                  }}
                  fontFamily={"Montserrat"}
                  sx={{
                    backgroundColor: "#EFF3F6",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <Box
                    padding={{
                      xs: "35px 0px",
                      md: "60px 120px",
                    }}
                    display={"flex"}
                    justifyContent={"space-around"}
                    flexDirection={"column"}
                    position="relative"
                  >
                    <Box
                      color={"black"}
                      fontFamily={"Montserrat"}
                      textAlign={{ xs: "center", md: "center" }}
                      display="flex"
                      flexDirection={"column"}
                      alignItems={"center"}
                      zIndex={1}
                    >
                      <Typography
                        fontSize={{
                          // xs: "24px",
                          xs: "34px",
                          sm: "44px",
                          md: "54px",
                        }}
                        fontWeight={300}
                        letterSpacing={{
                          xs: "0",
                          lg: "-2.16px",
                        }}
                        lineHeight={{ xs: 1.2, lg: 1 }}
                      >
                        <span style={{ color: "#0076BA" }}>AI-Powered</span> Coaching
                        Platform
                      </Typography>
                      <Typography
                        letterSpacing={"0.22px"}
                        marginTop={{
                          xs: "20px",
                          md: "20px",
                        }}
                        marginBottom={{
                          xs: "10px",
                        }}
                        fontSize={{
                          xs: "16px",
                          md: "22px",
                        }}
                        fontWeight={400}
                        lineHeight={{ xs: 1.2, lg: 1 }}
                        fontFamily={"Lexend"}
                      >
                        AAI is Revolutionizing the Coaching Game: We measure the
                        previously unmeasurable.{" "}
                      </Typography>
                    </Box>
                    <Box
                      margin={{ xs: "60px 0px", lg: "60px" }}
                      position="relative"
                      zIndex={1}
                      className="benefits"
                    >
                      <video
                        id="homepagevid"
                        autoPlay
                        playsInline
                        loop
                        width="100%"
                        muted
                        style={{ borderRadius: "50px" }} // Adjust this value as needed
                      >
                        <source
                          src="https://ac-media-files.s3.amazonaws.com/promovid/homepagevid.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }} // Set to row on medium devices and up
                      justifyContent="space-around"
                      alignItems="center"
                      fontSize="10px"
                      color="#777"
                      fontWeight={500}
                      marginTop={{ xs: "30px", md: "70px" }}
                      marginRight={{ xs: "0px", md: "0px", lg: "60px" }}
                      marginBottom={{ xs: "60px", md: "0px" }}
                      paddingRight={{ xs: "0px" }}
                      gap={{ xs: "25px", md: "0px" }}
                    >
                      <Box // First column
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "12px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Innovative Virtual Coach
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "12px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Document Your Journey
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "12px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Scale Your Coaching Practice
                          </Typography>
                        </Box>
                      </Box>

                      <Box // Second column
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          marginLeft={-1}
                        >
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "0px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Serve Your Coachees
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "12px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Foster Impactful Relationships
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <svg
                            width="38"
                            height="30"
                            style={{ marginRight: "12px", marginTop: "15px" }}
                            viewBox="0 0 38 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.95 0.416504L32.2843 2.16245L12.5161 22.7583L5.1493 15.6813L3.40748 14.01L0 17.4273L1.74181 19.0985L10.8788 27.8838L12.6492 29.5832L14.353 27.8091L35.8249 5.43028L37.5 3.69359L33.95 0.416504Z"
                              fill="#535353"
                            />
                          </svg>
                          <Typography
                            fontSize="1.2rem"
                            marginTop="18px"
                            textAlign="center"
                            width="200px"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Add Purpose & Precision to Your Coaching
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="products"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      padding="0px 10px"
                    >
                      <Typography
                        fontSize={{ xs: "24px", lg: "54px" }}
                        fontWeight="300"
                        textAlign={{ xs: "center", lg: "left" }}
                        fontFamily={"Poppins"}
                      >
                        Virtual <span style={{ color: "#0076BA" }}>Coach</span>
                      </Typography>
                      <Typography
                        fontSize={{ xs: "12px", lg: "22px" }}
                        textAlign={{ xs: "center", lg: "left" }}
                        fontFamily={"Lexend"}
                      >
                        AAI goes beyond with a virtual coach trained by Ryan Blair and
                        some of the world’s most influential mentors - offering 24/7
                        mentorship, tailored to each user. Engage in self-discovery
                        through conversational AI, that evolves with every interaction
                        allowing users to explore introspective questions and receive
                        personalized guidance.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      component="img"
                      src={virtualCoachImg}
                      alt="virtualCoachImg"
                      width="100%"
                    />
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection={{ xs: "column-reverse", lg: "row" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      component="img"
                      src={device}
                      alt="virtualCoachImg"
                      width="100%"
                      marginTop="40px"
                    />
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      display="flex"
                      flexDirection={"column"}
                      justifyContent="center"
                      padding="0px 10px"
                    >
                      <Typography
                        fontSize={{ xs: "24px", lg: "54px" }}
                        fontWeight="300"
                        textAlign={{ xs: "center", lg: "left" }}
                        fontFamily={"Poppins"}
                        sx={{ whiteSpace: { lg: "nowrap" } }}
                      >
                        Coaching <span style={{ color: "#0076BA" }}>Platform</span>
                      </Typography>
                      <Typography
                        fontSize={{ xs: "12px", lg: "22px" }}
                        textAlign={{ xs: "center", lg: "left" }}
                        fontFamily={"Lexend"}
                      >
                        AAI  leverages Ai to analyze calls, provide actionable
                        insights to improve coaching effectiveness and
                        personalization, and quantify measurable impact of sessions.
                        Our app seamlessly integrates with Zoom in order to review
                        calls, extract actions, and help generate data on your
                        performance.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    textAlign={"center"}
                    marginBottom={{
                      xs: "30px",
                      md: "70px",
                    }}
                    padding={{
                      xs: "0px 20px",
                      md: "auto",
                    }}
                    marginRight={{
                      xs: "0px",
                    }}
                  >
                    {/* <Typography
                className="pricing"
                marginBottom={{
                  xs: "10px",
                  md: "20px",
                }}
                fontSize={{
                  xs: "24px",
                  md: "54px",
                }}
                fontWeight={500}
                color={{
                  xs: "#070728",
                  md: "black",
                }}
              >
                Our Pricing
              </Typography> */}
                    {/* <Typography
                fontSize={{
                  xs: "16px",
                  md: "22px",
                }}
                fontWeight={400}
              >
                Try our innovative products to revolutionize your leadership
              </Typography> */}

                    {/* hereski */}
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        overflowY: "none",
                        background: "#EFF3F6",
                      }}
                    >
                      <Box
                        sx={{
                          overflowX: { lg: "auto", md: "auto", xs: "hidden" },
                          justifyContent: "center",
                          transform: "scale(0.7)",
                          marginTop: { md: "-50px", sm: "-350px", xs: "-400px" },
                          marginBottom: { lg: "-200px", md: "-200px", xs: "-400px" },
                        }}
                      >
                        <Box
                          textAlign={"center"}
                          marginBottom={{
                            xs: "30px",
                            md: "70px",
                          }}
                          padding={{
                            xs: "0px 20px",
                            md: "auto",
                          }}
                          marginRight={{
                            xs: "0px",
                          }}
                        >
                          <Typography
                            className="pricing"
                            color="black"
                            textAlign={"center"}
                            lineHeight={"67.2px"}
                            fontWeight={300}
                            letterSpacing={"-1.12px"}
                            marginBottom={{
                              xs: "10px",
                              md: "20px",
                            }}
                            fontSize={{
                              xs: "24px",
                              md: "66px",
                            }}
                          >
                            Start using AlterCall AI{" "}
                            <span style={{ color: "#568FF3" }}>today</span>.
                          </Typography>
                          <Typography
                            className="pricing"
                            color="black"
                            textAlign={"center"}
                            lineHeight={"67.2px"}
                            fontWeight={300}
                            letterSpacing={"-1.12px"}
                            marginBottom={{
                              xs: "10px",
                              md: "20px",
                            }}
                            fontSize={{
                              xs: "14px",
                              md: "26px",
                            }}
                          >
                            *If you are in our Beta Group, pick your plan, enter your
                            promo code on the next page, and you will get your first
                            month free
                          </Typography>
                          <Box
                            sx={{
                              marginTop: "40px",
                              display: "inline-flex",
                              height: "94px",
                              padding: "30px 5px 30px 5px",
                              alignItems: "center",
                              gap: "12px",
                              flexShrink: 0,
                              borderRadius: "18px",
                              border: "2px solid #DDE5ED",
                              marginBottom: { lg: "-30px", md: "-30px" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "209px",
                                height: "80px",
                                background:
                                  selectedPricing === "Monthly"
                                    ? "#DDE5ED"
                                    : "transparent",
                                borderRadius: "16px",
                                justifyContent: "center",
                                alignItems: "center",
                                transition:
                                  "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => setSelectedPricing("Monthly")}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "194px",
                                  height: "33px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0D162F",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "32.69px",
                                  }}
                                >
                                  Monthly Pricing
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                width: "209px",
                                height: "80px",
                                background:
                                  selectedPricing === "Annual"
                                    ? "#DDE5ED"
                                    : "transparent",
                                borderRadius: "16px",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => setSelectedPricing("Annual")}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "194px",
                                  height: "33px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0D162F",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "32.69px",
                                    transition:
                                      "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                  }}
                                >
                                  Annual Pricing
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            paddingBottom: "40px",
                            paddingX: "40px",
                            overflowX: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              display: { xs: "none", md: "flex" },
                              justifyContent: "center",
                              gap: "210px",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "center",
                                color: "#969696",
                                textTransform: "uppercase",
                                marginLeft: "20px",
                                fontSize: "18px",
                              }}
                            >
                              Price
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "center",
                                color: "#969696",
                                textTransform: "uppercase",
                                fontSize: "18px",
                              }}
                            >
                              Includes
                            </Typography>
                          </Box>
                          {/* {selectedPricing === "Annual" && (
                    <Box
                        sx={{
                            display: {xs:"none", md:"flex"},
                            width: "100%",
                            justifyContent: "center",
                            gap: "210px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                color: "#568FF3",
                                textTransform: "uppercase",
                                marginLeft: "100px",
                                fontSize: "18px"
                            }}
                        >
                            Save over 15%
                        </Typography>
                    </Box>
                    )} */}
                          {plans.map((plan) => (
                            <Box
                              key={plan.name}
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: "60px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: { xs: "column", md: "row" },
                                  gap: { xs: "10px", md: "0px" },
                                  background: "#DDE5ED",
                                  padding: "30px 39px 29px 29px",
                                  borderRadius: "18px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0D162F",
                                    fontSize: "24px",
                                    fontWeight: 400,
                                    lineHeight: "32.69px",
                                    width: { md: "226px" },
                                    height: "33px",
                                  }}
                                >
                                  {plan.name}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display:
                                    selectedPricing === "Monthly" ? "flex" : "none",
                                  width: "200px",
                                  height: "43px",
                                  justifyContent: "center",
                                  marginRight: "40px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontSize:
                                      plan.monthlyPrice === "Inquire"
                                        ? "24px"
                                        : "42px",
                                    fontWeight: "300px",
                                    lineHeight: "43.58px",
                                    textAlign: "center",
                                  }}
                                >
                                  {plan.monthlyPrice}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display:
                                    selectedPricing === "Annual" ? "flex" : "none",
                                  width: "200px",
                                  height: "43px",
                                  justifyContent: "center",
                                  marginRight: "40px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontSize:
                                      plan.annualPrice === "Inquire"
                                        ? "24px"
                                        : "42px",
                                    fontWeight: "300px",
                                    lineHeight: "43.58px",
                                    textAlign: "center",
                                  }}
                                >
                                  {plan.annualPrice}
                                  {selectedPricing === "Annual" &&
                                    plan.annualPrice === "$490" && (
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          fontFamily: "Lexend",
                                          color: "#568FF3",
                                          whiteSpace: "nowrap",
                                          marginBottom: "5px",
                                          marginTop: {
                                            lg: "-90px",
                                            md: "-90px",
                                            xs: "-200px",
                                          },
                                        }}
                                      >
                                        2 MONTHS FREE
                                      </Typography>
                                    )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  marginLeft: { md: "40px" },
                                  width: { md: "600px" },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontSize: "22px",
                                    fontWeight: "300px",
                                    lineHeight: "43.58px",
                                    textAlign: { xs: "center", md: "start" },
                                  }}
                                >
                                  {plan.description}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* and here */}
                  <Box
                    display={"flex"}
                    flexDirection={{
                      xs: "column",
                      lg: "row",
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    marginBottom={"100px"}
                  >
                    <Typography
                      fontSize={"22px"}
                      fontWeight={400}
                      letterSpacing={"0.22px"}
                      marginRight={{
                        lg: "36px",
                      }}
                      marginBottom={{ xs: "10px", lg: "0px" }}
                      textAlign={{
                        xs: "center",
                        lg: "auto",
                      }}
                    >
                      Experience the power of AI for yourself
                    </Typography>
                    <Button
                      onClick={() => {
                        window.location.href = "/signup";
                      }}
                      sx={{
                        height: { xs: "30px", md: "40px" },
                        fontSize: "16px",
                        marginLeft: { xs: "10px", md: "0px" },
                        marginRight: "20px",
                        borderRadius: "43px",
                        backdropFilter: "blur(25px)",
                        color: "white",
                        textTransform: "none",
                        padding: "0px",
                        background:
                          "linear-gradient(330deg, #0076BA 21%, #03093A 67%, #000000 100%)",
                        display: "block",
                        width: "120px",
                        transition: "0.5s all ease-in-out",
                        "&:hover": {
                          filter: "brightness(2)",
                          transition: "0.5s all ease-in-out",
                        },
                        fontFamily: "Lexend",
                      }}
                    >
                      Sign Up
                    </Button>
                    <Button
                      sx={{
                        height: { xs: "30px", md: "40px" },
                        marginLeft: { xs: "10px", md: "0px" },
                        marginRight: "20px",
                        marginTop: { xs: "10px", md: "8px" },
                        display: "block",
                        fontSize: "15px",
                        fontWeight: 500,
                        borderRadius: "50px",
                        backdropFilter: "blur(25px)",
                        textTransform: "none",
                        padding: { md: "8px 20px", xs: "3px 10px" },
                        color: "black",
                        background: "white",
                        transform: "translate(0, -4px)",
                        textAlign: "center", // Add this line to center the text
                        border: "1px solid #242F8C",
                        ":hover": {
                          color: "white",
                          border: "1px solid white",
                          background:
                            "linear-gradient(330deg, #0076BA 21%, #03093A 67%, #000000 100%)",
                        },
                      }}
                      onClick={() =>
                      (window.location.href =
                        "https://calendly.com/altercallai/aai-product-demo")
                      }
                    >
                      Request a Demo
                    </Button>
                  </Box>

                  <Footer></Footer>
                </Box>
              </Grid>
            </Grid>
          </>
      }
    </>

  );
};

export default HomePage;
