import React from 'react';
import Lottie from "react-lottie";
import typingAnimationLottie from "data/typing-animation-vc.json";
import { Box, Typography } from '@mui/material';
import vcProfileImage from '../../assets/vc-profile-picture.png';

const TypingLottie = ({ loadingForAiResponse }) => {
    return (
        <Box sx={{
            display: loadingForAiResponse ? 'flex' : 'none',
            justifyContent: 'flex-start',
            width: '80%',
            mx: 'auto',
        }}>
            <Box sx={{
                width: 'auto',
                height: 'auto',
                paddingTop: '10px',
                // border: '1px solid red',
            }}>
                <Box
                    component="img"
                    src={vcProfileImage}
                    alt="Assistant"
                    sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                    }}
                />
            </Box>
            <Box
                sx={{
                    paddingX: '10px',
                    color: 'black',
                    maxWidth: '60%',
                    wordWrap: 'break-word',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center'
                }}
            >
                <Typography sx={{ fontFamily: 'Lexend' }} >
                    Thinking
                </Typography>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: typingAnimationLottie,
                    }}
                    width={40}
                />


            </Box>
        </Box>
    );
};

export default TypingLottie;