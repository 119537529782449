export const personalityGuidelines = [
    {
        trait: "Openness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Present ideas in an engaging, thought-provoking manner. Encourage exploration and provide a big picture view. They tend to be excited by abstract concepts and innovative approaches."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Frame feedback as an opportunity for personal growth or a chance to explore new perspectives. They appreciate insightful feedback that challenges their thinking."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Focus on long-term goals and personal development. Set challenging, creative objectives. Regular check-ins should be flexible to allow for adaptability and learning."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Give them the freedom to experiment and explore. High Openness individuals are self-driven by curiosity and a desire to learn."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Encourage them to explore alternative viewpoints. Use their curiosity to introduce new ideas and challenge the current way of thinking."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Be open to exploring creative solutions and emphasize the learning potential in resolving conflicts. Encourage dialogue and allow for the free expression of ideas."
            },
            {
                question: "How to reward them",
                answer: "Offer opportunities for creative freedom and exploration, such as new projects, learning experiences, or leadership in innovative initiatives."
            }
        ]
    },
    {
        trait: "Conscientiousness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Present structured, well-organized information with clear evidence and details. High Conscientiousness individuals appreciate logical, well-thought-out arguments."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be specific and detail-oriented, focusing on how to improve processes or systems. They value actionable, organized feedback that helps them achieve their goals more effectively."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Set clear, achievable goals with measurable milestones. Regularly check in on progress to ensure accountability, offering structured feedback and recognition of accomplishments."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Appeal to their sense of responsibility. Provide clear guidelines and deadlines, ensuring they know exactly what is expected and the steps to achieve it."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Use facts, data, and a clear logical argument. Provide a detailed explanation and evidence to support new perspectives, particularly if it involves improving efficiency or productivity."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Keep the discussion organized and goal-oriented. Focus on finding a well-defined solution, avoiding unnecessary distractions or emotions."
            },
            {
                question: "How to reward them",
                answer: "Provide recognition for their reliability and achievements, such as awards, acknowledgment in team settings, or opportunities to take on important responsibilities."
            }
        ]
    },
    {
        trait: "Extraversion",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Present ideas energetically and enthusiastically. High Extraversion individuals will respond well to interactive discussions and group collaboration."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Keep feedback positive and energetic. Frame it as a challenge to take on, and encourage them to stay motivated and engaged."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Include social components or collaborative elements in goal-setting. Regular check-ins with open, enthusiastic communication will keep them engaged."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Appeal to their desire for social engagement and recognition. Give them opportunities to lead or work in dynamic, team-oriented environments."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Use social examples or peer discussions to highlight alternative viewpoints. Encourage group brainstorming or interaction with others who might hold different perspectives."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Encourage open dialogue and group discussions to resolve conflicts. Focus on maintaining energy and positivity, ensuring all voices are heard."
            },
            {
                question: "How to reward them",
                answer: "Reward with social recognition, such as public praise, opportunities to lead team activities, or invitations to social events and gatherings."
            }
        ]
    },
    {
        trait: "Agreeableness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Be gentle and empathetic in your approach. Frame information in a way that emphasizes its positive impact on others and social harmony."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be supportive and kind. Frame feedback in a constructive, non-judgmental manner, and focus on how they can improve to benefit the team."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Design goals that emphasize teamwork, collaboration, and helping others. Offer consistent encouragement and positive reinforcement."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Highlight how their involvement benefits others or contributes to a collective goal. Provide encouragement and praise for efforts that align with the team's needs."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Frame different perspectives in terms of their social benefits. Encourage empathy and understanding of differing views, particularly in group contexts."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Focus on maintaining harmony and understanding during conflicts. Encourage compromise and ensure the conversation remains compassionate."
            },
            {
                question: "How to reward them",
                answer: "Provide social recognition and rewards that emphasize teamwork, such as praise for collaborative efforts or opportunities to mentor others."
            }
        ]
    },
    {
        trait: "Neuroticism",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Be patient, reassuring, and clear. Avoid overwhelming them with too much information at once, and check in frequently to ensure they understand."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be gentle, empathetic, and focus on offering solutions. Ensure feedback is balanced with reassurance to avoid making them feel defensive."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Set realistic, achievable goals to avoid overwhelming them. Provide regular, supportive check-ins to keep them feeling confident and on track."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Offer frequent encouragement and focus on small wins. Make sure they understand that taking ownership will reduce stress and lead to greater control."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Use a calm, methodical approach to discuss new perspectives. Offer reassurances that challenges are manageable and can be handled with patience."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Be gentle and reassuring. Focus on managing emotions and resolving the conflict in a way that reduces stress and creates a sense of security."
            },
            {
                question: "How to reward them",
                answer: "Offer reassurance and support, such as providing a calming work environment, positive feedback, or opportunities to take time off to recharge."
            }
        ]
    }
];


export const personalityLowsGuidelines = [
    {
        trait: "Openness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Be direct, practical, and provide concrete examples. Stick to the facts and avoid abstract or overly creative ideas that might feel impractical."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Focus on practical, actionable suggestions for improvement. Avoid theoretical or overly philosophical feedback that might be perceived as irrelevant."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Set clear, specific, and structured goals. Provide steady, consistent check-ins to monitor progress without overwhelming them with ambiguity."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Emphasize how tasks align with their existing strengths and how they can meet familiar, practical challenges. Keep tasks well-defined and clear."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Use step-by-step reasoning to introduce new perspectives, starting from their current viewpoint. Offer examples of small changes that have proven to be effective."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Stick to the facts and focus on practical, solution-oriented discussions. Minimize emotional involvement and highlight the benefits of resolving the issue in a straightforward way."
            },
            {
                question: "How to reward them",
                answer: "Reward with clear, practical incentives like tangible benefits, a structured bonus system, or promotions based on predictable milestones."
            }
        ]
    },
    {
        trait: "Conscientiousness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Present ideas in a casual, flexible manner. Avoid being overly rigid or structured. Appeal to their curiosity or interest rather than forcing a strict set of facts."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be gentle with feedback, emphasizing flexibility and the opportunity to learn. Avoid being too critical or focused on details that may overwhelm them."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Focus on broader goals rather than specific, rigid tasks. Keep goals flexible and offer a supportive environment where progress can be made without strict timelines."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Offer opportunities for them to take initiative without being overly controlling. Highlight the benefits of taking ownership, especially in terms of personal freedom or flexibility."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Focus on the practical, real-world benefits of a new perspective. Offer suggestions that can be easily integrated into their lifestyle without imposing too much structure."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Keep the resolution process informal and emphasize open-mindedness. Focus on solutions that allow flexibility and minimize rigid expectations."
            },
            {
                question: "How to reward them",
                answer: "Offer rewards that focus on flexibility and autonomy, such as extra time off or less structured work environments."
            }
        ]
    },
    {
        trait: "Extraversion",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Be calm, clear, and respectful of their need for personal space. Present information in a low-pressure environment, avoiding overwhelming them with too many people or stimuli."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be straightforward and respectful, providing feedback in a one-on-one setting. Avoid overly enthusiastic or pressure-filled approaches."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Allow for independent goal-setting with fewer social demands. Provide regular but non-intrusive check-ins to ensure they are on track."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Emphasize personal growth and internal satisfaction. Provide opportunities for quiet reflection and responsibility without social pressure."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Encourage quiet reflection and provide ample time for them to think things through at their own pace. Offer written resources or individual conversations to help them process new information."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Minimize emotional intensity and provide space for personal reflection. Engage in direct, one-on-one discussions to resolve conflicts thoughtfully."
            },
            {
                question: "How to reward them",
                answer: "Offer private recognition or rewards that cater to their personal interests, such as gift cards, quiet time, or opportunities to work independently."
            }
        ]
    },
    {
        trait: "Agreeableness",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Be direct and clear, focusing on facts and the potential for improvement. Avoid overly emotional or diplomatic language."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be straightforward and no-nonsense. Focus on the impact of their actions or decisions, and keep feedback task-oriented rather than personal."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Focus on individual goals and competition. Make progress measurable and emphasize how they can achieve success independently."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Appeal to their desire for personal achievement and mastery. Frame goals as challenges that can lead to tangible rewards or recognition."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Offer a logical, objective argument with clear benefits. Keep emotions and social harmony secondary and focus on practical outcomes."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Be direct and assertive. Avoid excessive concern with emotional impact, focusing on resolving the issue efficiently and logically."
            },
            {
                question: "How to reward them",
                answer: "Offer rewards that acknowledge their individual achievements, such as independent projects or opportunities to showcase their unique contributions."
            }
        ]
    },
    {
        trait: "Neuroticism",
        guidelines: [
            {
                question: "How to share new information or ideas",
                answer: "Present information in a straightforward and confident manner. They appreciate clarity and can handle information without excessive reassurance."
            },
            {
                question: "How to provide constructive feedback",
                answer: "Be concise and matter-of-fact. They can handle direct, actionable feedback without it being overly softened or emotional."
            },
            {
                question: "How to design goals and follow up on progress",
                answer: "Set challenging but achievable goals and give them the freedom to manage them independently. Minimal intervention is needed unless a major issue arises."
            },
            {
                question: "How to motivate them to take ownership",
                answer: "Appeal to their internal drive for competence and independence. Provide opportunities for autonomy and responsibility."
            },
            {
                question: "How to help them shift their perspective on something",
                answer: "Present logical, evidence-based arguments. They can often see things from multiple perspectives, so offer a balanced view to help them reassess the situation effectively."
            },
            {
                question: "How to approach and manage conflict resolution",
                answer: "Keep the focus on rational problem-solving, and allow space for calm, practical discussions."
            },
            {
                question: "How to reward them",
                answer: "Provide recognition based on their accomplishments, such as performance-based bonuses or increased autonomy in decision-making."
            }
        ]
    }
];
