import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

export function useSignOut() {
  const navigate = useNavigate();

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
        window.location.href = "/sign-in";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  }, []);

  return handleSignOut;
}
