import { useEffect, useState, useRef } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { uploadData } from "aws-amplify/storage";
import axios from "axios";
import { v4 as uuid } from "uuid";
import "react-pro-sidebar/dist/css/styles.css";
import Dashboard from "components/Icons/Dashboard.svg";
import Client from "components/Icons/Client.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "slices/LayoutSlice";
import Actions from "components/Icons/Actions.svg";
import VirtualCoach from "components/Icons/VirtualCoach.svg";
import LaraIcon from "components/Icons/LaraIcon.svg";
import AdminIcon from "components/Icons/AdminIcon.svg";
import * as Frigade from "@frigade/react";
import Getstarted from "scenes/GuidedOnboarding/GetStarted";
import Assessment from "components/Icons/Assessment.svg";
import Setting from "components/Icons/setting.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getUrl } from "aws-amplify/storage";
import avatar from "assets/avatar.png";
import SupportIcon from "components/Icons/SupportIcon.svg";
import SupportArrowIcon from "components/Icons/SupportArrowIcon.svg";
import ChevronDownArrowIcon from "components/Icons/ChevronDownArrowIcon.svg";
import { handleCompanySelect } from "helpers/companySwitchingHelper";
import CheckTickSquareIcon from "components/Icons/CheckTickSquareIcon.svg";
import SquareIcon from "components/Icons/SquareIcon.svg";
import { useSignOut } from "hooks/useSignOut";
import SignoutIcon from "components/Icons/Signout.svg";
import { useFlowHandlers } from "@frigade/react";
import AlterCallLogoIcon from "components/Icons/AlterCallLogoIcon.svg";
import AddIconSquare from "components/Icons/AddIcon.svg";
import AddProductModal from "components/Sidebar/AddProductModal";
import { ProgressBar } from "scenes/GuidedOnboarding/ProgressBar";
import { AssessmentsRestart } from "scenes/GuidedOnboarding/AssessmentsRestart";
import { ProcessCallRestart } from "scenes/GuidedOnboarding/ProcessCallRestart";
import { CallInsightsRestart } from "scenes/GuidedOnboarding/CallInsightsRestart";
import { LeadershipCenterRestart } from "scenes/GuidedOnboarding/LeadershipCenterRestart";
import { ExploreLARARestart } from "scenes/GuidedOnboarding/ExploreLARARestart";
import { VirtualCoachRestart } from "scenes/GuidedOnboarding/VirtualCoachRestart";
import { VirtualCoachRestartVC } from "scenes/GuidedOnboarding/VirtualCoachRestartVC";
import { AddMemberRestartOwner } from "scenes/GuidedOnboarding/AddMemberRestartOwner";
import { AddMemberRestartAdmin } from "scenes/GuidedOnboarding/AddMemberRestartAdmin";
import { AddMemberRestartSuperAdmin } from "scenes/GuidedOnboarding/AddMemberRestartSuperAdmin";
import { AddMemberRestartUser } from "scenes/GuidedOnboarding/AddMemberRestartUser";
import ProfilePicture from "components/ProfilePicture";

const Item = ({
  title,
  to,
  icon,
  hoverIcon,
  selected,
  setSelected,
  mouseOver,
  setMouseOver,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    setSelected(to);
    dispatch(setCollapsed(true)); // Collapse the sidebar
  };

  const sidebarTextStyle = {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Poppins",
  };

  return (
    <MenuItem
      active={selected === to}
      style={{
        color: "#555555",
        background: selected === to ? "#F5F7FF" : mouseOver === to ? "#F5F7FF" : "white",
        padding: "2px 0px",
        border: selected === to || mouseOver === to ? "1px solid #E6E7EC" : "1px solid transparent",
        borderRadius: "8px",
        marginTop: "4px", // Fixed margin to top
        marginLeft: "4px", // Fixed margin to left
      }}
      onMouseOver={() => setMouseOver(to)}
      onMouseLeave={() => setMouseOver("")}
      onClick={handleClick} // Collapse the sidebar on item click
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: 35,
            height: 35,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "10px",
          }}
        >
          {mouseOver === to || selected === to ? hoverIcon : icon}
        </div>
        <Typography
          fontWeight={500}
          color={mouseOver === to || selected === to ? "black" : "#555555"}
          sx={{ ...sidebarTextStyle }} // Added padding between icon and text
        >
          {title}
        </Typography>
      </div>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ user, userData }) => {
  const [selected, setSelected] = useState("Dashboard");
  const [mouseOver, setMouseOver] = useState("Dashboard");
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const { nextStep } = useFlowHandlers("flow_HER5jgDw"); // Get the nextStep handler
  const location = useLocation();
  const accessTo = userData?.accessTo;
  const navigate = useNavigate();
  const pictureRef = useRef(null);
  const [selectedCompany, setSelectedCompany] = useState();
  const [companyAnchorEl, setCompanyAnchorEl] = useState(null);
  const [loadingCompanySwitch, setLoadingCompanySwitch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [preloaded, setPreloaded] = useState(false); // New state to manage preloaded image
  const companyMenuOpen = Boolean(companyAnchorEl);
  const currentPath = location.pathname;
  const [openAddProductModal, setOpenAddProductModal] = useState(false);

  const handleCompanyClick = () => {
    setCompanyAnchorEl((prevAnchorEl) => !prevAnchorEl);
  };

  const handleCompanyClose = () => {
    setCompanyAnchorEl(null);
  };
  const handleCloseCrossIcon = () => {
    setIsVisible(false);
  };

  const handleSignOut = useSignOut();
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("all companies: ", userData?.allCompanies);

  const handleNextStep = () => {
    if (typeof nextStep === "function") {
      nextStep(); // Move to the next step
    } else {
      console.log("nextStep is not a function");
    }
  };

  // console.log("user data: ", userData);

  // Update selectedCompany based on the user's current company
  useEffect(() => {
    if (userData?.companyInformation) {
      setSelectedCompany({
        company_id: userData.companyInformation.company_id,
        company_name: userData.companyInformation.company_name,
      });
    }
  }, [userData]);

  // Update selected state based on the current location pathname
  useEffect(() => {
    const path = location.pathname;
    setSelected(path);
  }, [location]);

  const isClient = () => {
    return user?.["custom:is_coachee"] === "true";
  };

  // Helper function to encode file paths
  const encodeFilePath = (path) => {
    const fileName = path.split("/").pop();
    return encodeURIComponent(fileName).replace(/%20/g, " ");
  };

  // Fetch user profile picture from S3
  const fetchUserProfilePicture = async () => {
    if (!user || !user.picture || user.picture === "") {
      pictureRef.current = avatar; // Default avatar
      setLoading(false);
      return;
    }

    try {
      const encodedFileName = encodeFilePath(user.picture);
      const filePath = `public/avatar/${encodedFileName}`;

      const result = await getUrl({
        path: filePath,
        expires: 900, // URL valid for 1 minute
        validateObjectExistence: true,
      });

      const profilePictureUrl = result?.url || avatar;
      pictureRef.current = profilePictureUrl; // Store in ref
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      pictureRef.current = avatar; // Fallback to default avatar
    } finally {
      setLoading(false);
    }
  };

  // Handle image change when user uploads a new profile picture
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file && user) {
      const s3Key = `public/avatar/${uuid()}-${file.name}`;

      try {
        if (user?.["custom:is_coachee"] === "true") {
          await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-client-avatar`,
            {
              params: { client_id: user?.sub, s3Key: s3Key },
            }
          );
        } else {
          await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-coach-avatar`,
            {
              params: { coach_id: user?.sub, s3Key: s3Key },
            }
          );
        }

        await uploadData({
          path: s3Key,
          data: file,
          options: {
            bucket: { name: "profilePictures", region: "us-east-1" },
            contentType: file.type,
            metadata: { "Cache-Control": "max-age=31536000" },
            level: "public",
          },
        });

        // Update profile picture URL after uploading
        const newProfilePictureUrl = await getUrl({
          path: s3Key,
          expires: 900,
        });
        pictureRef.current = newProfilePictureUrl.url; // Update the ref with the new picture URL
        setLoading(false);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        console.error("Error uploading profile picture:", error);
      }
    }
  };

  // Fetch profile picture only once on mount or when the user data changes
  useEffect(() => {
    if (!pictureRef.current) {
      fetchUserProfilePicture();
    }
  }, [user]);

  // const handleSupportClick = () => {
  //   window.location.href = "https://altercall.ai/resources"; // change it to the support link
  //   //can use navigate instead of windows.location if we want to navigate internally navigate("/support")
  // };

  const [isOnboardingStarted, setIsOnboardingStarted] = useState(false);

  const startOnboarding = () => {
    // Start the Frigade onboarding flow
    window.Frigade.startFlow("flow_HER5jgDw");
    setIsOnboardingStarted(true); // Show the progress tracker after starting
  };

  // Define the state for the drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Create the toggleDrawer function
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Function to handle support component click
  const handleSupportClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (collapsed) {
      setIsDrawerOpen(false);
    }
  }, [collapsed]);

  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          lg: "flex",
        },
        backgroundColor: "white",
        margin: {
          lg: "16px 0 16px 16px",
        },
        position: {
          lg: "sticky",
          xs: "fixed",
        },
        top: {
          lg: "20px",
          xs: "82px",
        },
        left: {
          xs: !collapsed ? "0" : "-100vw",
        },
        transition: "all 0.3s",
        maxHeight: {
          lg: "calc(100vh - 2px)",
        },
        WebkitTransform: "translateZ(0)",
        borderRadius: {
          lg: isDrawerOpen ? "40px 0px 0px 40px" : "40px",
        },
        zIndex: {
          xs: "99",
        },
      }}
    >
      <Box
        width={{ xs: "100vw", sm: "100vw", md: "auto" }}
        sx={{
          borderRadius: {
            lg: "40px",
          },
          "& .pro-sidebar": {
            paddingTop: 0,
            width: {
              xs: "100% !important",
              lg: "270px !important",
            },
            height: {
              xs: "100vh",
              lg: "100%",
            },
            backgroundColor: "#fff",
            borderRadius: "40px",
          },
          "& .pro-sidebar-layout": {
            height: "100%",
          },
          "& .pro-sidebar-inner": {
            background: "white",
          },
          "& .pro-sidebar .pro-menu": {
            height: "100%",
            paddingTop: "10px",
            paddingBottom: 0,
            overflowY: "auto",
          },
          "& .pro-sidebar .pro-menu > ul": {
            height: "100%",
          },
          "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item": {
            xs: {
              padding: "18px 30px",
            },
            lg: {
              padding: "8px 35px 8px 20px",
            },
            color: "#555555",
          },
          "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper":
            {
              xs: {
                marginRight: "28px",
              },
              lg: {
                marginRight: "10px",
              },
            },
          "& .pro-inner-item:hover": {
            color: "black !important",
            background: "#F5F7FF",
          },
          "& .svg:hover": {
            color: "black !important",
          },
          overflow: "auto",
        }}
      >
        <ProSidebar
          width="270px"
          height="100%"
          style={{
            display: { xs: collapsed ? "none" : "flex", lg: "flex" },
            borderRadius: {
              lg: "40px",
            },
          }}
        >
          <Box
            sx={{
              margin: "25px 0px 20px 0",
              display: { xs: "none", sm: "none", lg: "flex" },
              justifyContent: "center",
              borderBottom: "1px solid #FFFFFF",
            }}
          >
            <Box>
              {/* <Logo
                user={user}
                accessTo={userData?.accessTo}
                style={{ transform: "scale(0.4)" }}
              /> */}
              <AlterCallLogoIcon />
            </Box>
          </Box>

          <Box sx={{ display: "flex", paddingX: "12px" }}>
            <Box
              sx={{
                position: "relative", // Relative positioning for dropdown to position correctly
                display: "flex",
                width: "100%", // Full width
                background: "#FFFFFF",
                border: "1px solid #E6E7EC",
                borderBottomLeftRadius: companyMenuOpen ? "0px" : "10px", // Bottom-left radius
                borderBottomRightRadius: companyMenuOpen ? "0px" : "10px", // Bottom-right radius
                borderTopLeftRadius: "10px", // Top-left radius
                borderTopRightRadius: "10px", // Top-right radius
                padding: "8px",
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box", // Ensure padding doesn't affect width
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: companyMenuOpen ? "inherit" : "#F4F7FF", // Apply hover only when the dropdown is closed
                },
              }}
              onClick={handleCompanyClick} // Updated to handle company menu clicks
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column", // Keeps content stacked vertically
                  width: "100%",
                  boxSizing: "border-box", // Ensure padding doesn't affect width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <ProfilePicture src={pictureRef.current} size={60} addBorder={false} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: 14,
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        {`${user?.given_name}` + " " + `${user?.family_name}`}
                      </Typography>
                      {userData?.companyInformation && (
                        <Typography
                          sx={{
                            color: "#555555",
                            fontSize: 12,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          {userData?.companyInformation?.company_name ?? ""}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <IconButton
                    //onClick={handleCompanyClick} // Updated to handle company menu clicks
                    size="small"
                    sx={{
                      padding: 0,
                      "&:hover": {
                        backgroundColor: "#F4F7FF", // Hover effect on the button
                      },
                      transition: "transform 0.3s ease-in-out", // Smooth rotation transition
                      transform: companyMenuOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)", // Rotate the chevron
                    }}
                    aria-controls={companyMenuOpen ? "company-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={companyMenuOpen ? "true" : undefined}
                  >
                    <ChevronDownArrowIcon />
                  </IconButton>
                </Box>

                {/* Render the dropdown directly within the parent component */}
                {companyMenuOpen && (
                  <Box
                    sx={{
                      position: "absolute", // Ensure it overlays content below
                      top: "100%", // Align directly below the parent component
                      left: 0, // Align it with the left side of the parent
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      background: "white",
                      borderLeft: "1px solid #E6E7EC", // Left border
                      borderRight: "1px solid #E6E7EC", // Right border
                      borderBottom: "1px solid #E6E7EC", // Bottom border
                      borderBottomLeftRadius: "10px", // Bottom-left radius
                      borderBottomRightRadius: "10px", // Bottom-right radius
                      zIndex: 10, // Ensure the dropdown appears above other elements
                      width: "100%",
                      padding: "8px 8px", // Add padding for better appearance
                      boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)", // Adjust shadow to appear only on the sides and bottom
                      boxSizing: "border-box", // Include borders in the box size calculation
                    }}
                  >
                    {userData?.allCompanies && userData.allCompanies.length > 0
                      ? [...userData.allCompanies]
                          .sort((a, b) => {
                            // Move selected company to the top
                            if (a.company_id === selectedCompany?.company_id)
                              return -1;
                            if (b.company_id === selectedCompany?.company_id)
                              return 1;
                            return 0;
                          })
                          .map((company) => (
                            <Button
                              key={company.company_id}
                              onClick={() =>
                                handleCompanySelect(
                                  company,
                                  user,
                                  userData,
                                  setSelectedCompany,
                                  navigate,
                                  loadingCompanySwitch,
                                  setLoadingCompanySwitch,
                                  currentPath
                                )
                              }
                              disabled={
                                company.company_id ===
                                  selectedCompany?.company_id ||
                                loadingCompanySwitch
                              }
                              sx={{
                                padding: "8px",
                                cursor: "pointer",
                                backgroundColor:
                                  company.company_id ===
                                  selectedCompany?.company_id
                                    ? "#F4F7FF"
                                    : "white", // Default background color
                                border: "1px solid transparent",
                                borderRadius: "8px",
                                "&:hover": {
                                  backgroundColor: "#F4F7FF", // Hover effect only on the item
                                  border: "1px solid #E6E7EC", // Optional: border on hover
                                  borderRadius: "8px",
                                },
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      background: "white",
                                      padding: "4px",
                                      borderRadius: "50%",
                                      border: "1px solid #DBDBDB",
                                      width: "30px",
                                      height: "30px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {company.company_picture ? (
                                      <LazyLoadImage
                                        alt="Profile Picture"
                                        src={company.company_picture} // Display signed URL or fallback
                                        effect="blur"
                                        width="30px"
                                        height="30px"
                                        style={{ borderRadius: "50%" }}
                                      />
                                    ) : (
                                      <Typography
                                        sx={{ color: "black", fontSize: 16 }}
                                      >
                                        {company?.company_name?.charAt(0) || ""}{" "}
                                        {/* Display first initial */}
                                      </Typography>
                                    )}
                                  </Box>
                                  <Typography
                                    sx={{
                                      color: "black",
                                      fontSize: 14,
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                      wordWrap: "break-word",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {company.company_name}
                                  </Typography>
                                </Box>
                              </Box>
                              {company.company_id ===
                              selectedCompany?.company_id ? (
                                <CheckTickSquareIcon />
                              ) : (
                                <SquareIcon />
                              )}
                            </Button> // Close Button here
                          ))
                      : null}

                    {/* Display the "Add Product" button if the user doesn't own any company */}
                    {!userData?.allCompanies?.some(
                      (company) => company.owner_user_id === user?.sub
                    ) && (
                      <Button
                        onClick={() => setOpenAddProductModal(true)} // Define the function to handle the Add Product action
                        sx={{
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "8px",
                          border: "1px solid transparent",
                          "&:hover": {
                            backgroundColor: "#F4F7FF",
                            border: "1px solid #E6E7EC",
                          },
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Add Product
                        </Typography>
                        <AddIconSquare />
                      </Button>
                    )}

                    <Box
                      onClick={async () => {
                        try {
                          handleClose();
                          handleSignOut();
                          console.log("Signed out successfully.");
                        } catch (error) {
                          console.error("Error signing out: ", error);
                        }
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        cursor: "pointer",
                        backgroundColor: "white", // Default background color
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#F4F7FF", // Hover effect only on the item
                          border: "1px solid #E6E7EC", // Optional: border on hover
                          borderRadius: "8px",
                        },
                        // opacity: company.company_id === userData?.companyInformation?.company_id ? 0.6 : 1,
                        // pointerEvents: company.company_id === userData?.companyInformation?.company_id ? 'none' : 'auto', // Disable click if same company
                      }}
                    >
                      <SignoutIcon
                        sx={{
                          marginRight: "8px",
                          padding: "3px 0px 0px 0px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          textAlign: "left",
                          color: "#555555",
                          height: "24px",
                          width: "100%",
                          marginLeft: "20px",
                        }}
                        data-cy="sign-out-button"
                      >
                        Sign Out
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Menu sx={{ height: "100%", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: { xs: "auto", lg: "270px" },
                height: "100%",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(207, 207, 207)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgb(207, 207, 207)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Box sx={{padding: "0px 6px 0px 6px"}}>
                {accessTo !== "virtual_coach" && (
                  <Item
                    title="Dashboard"
                    to={
                      isClient() && accessTo === "client_portal"
                        ? "/platform/coachee"
                        : "/platform/coach"
                    }
                    icon={Dashboard({ color: "#555555" })}
                    hoverIcon={Dashboard({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                {accessTo !== "virtual_coach" && (
                  <Item
                    title="Leadership Center"
                    to="/platform/coach/leadership-center"
                    icon={Client({ color: "#555555" })}
                    hoverIcon={Client({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                {accessTo !== "virtual_coach" && (
                  <Item
                    title="LARA Analytics"
                    to={
                      isClient() && accessTo === "client_portal"
                        ? "/platform/coachee/lara-analytics"
                        : "/platform/coach/lara-analytics"
                    }
                    icon={LaraIcon({ color: "#555555" })}
                    hoverIcon={LaraIcon({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                {accessTo !== "virtual_coach" && (
                  <Item
                    title="Goals and Actions"
                    to={
                      isClient() && accessTo === "client_portal"
                        ? "/platform/coachee/actions"
                        : "/platform/coach/actions"
                    }
                    icon={Actions({ color: "#555555" })}
                    hoverIcon={Actions({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                {accessTo !== "virtual_coach" && (
                  <Item
                    title="OCEAN Assessments"
                    to={
                      isClient() && accessTo === "client_portal"
                        ? "/platform/coachee/assessments"
                        : "/platform/coach/assessments"
                    }
                    icon={Assessment({ color: "#555555" })}
                    hoverIcon={Assessment({ color: "black" })}
                    selected={selected}
                    setSelected={setSelected} // Pass the setSelected function
                    mouseOver={mouseOver}
                    setMouseOver={setMouseOver}
                  />
                )}
                <Item
                  title="Virtual Coach"
                  to={
                    isClient() && accessTo === "client_portal"
                      ? "/platform/coachee/virtualcoach"
                      : accessTo === "platform" ||
                        accessTo === "platform_professional"
                      ? "/platform/coach/virtualcoach"
                      : accessTo === "virtual_coach"
                      ? "/virtualcoach/bot"
                      : "/authenticating"
                  }
                  icon={VirtualCoach({ color: "#555555" })}
                  hoverIcon={VirtualCoach({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                />
                {(user?.role === "OWNER" ||
                  user?.role === "SUPERADMINS" ||
                  user?.role === "ADMINS" ||
                  user?.role === "USERS") &&
                  userData?.companyInformation &&
                  accessTo !== "platform_professional" && (
                    <Item
                      title="Admin Portal"
                      to={"/platform/enterprise/admin-portal"}
                      icon={AdminIcon({ color: "#555555" })}
                      hoverIcon={AdminIcon({ color: "black" })}
                      selected={selected}
                      setSelected={setSelected} // Pass the setSelected function
                      mouseOver={mouseOver}
                      setMouseOver={setMouseOver}
                      data-cy="sidebar-admin-portal"
                    />
                  )}
                <Item
                  title="Settings"
                  to="/settings"
                  icon={Setting({ color: "#555555" })}
                  hoverIcon={Setting({ color: "black" })}
                  selected={selected}
                  setSelected={setSelected} // Pass the setSelected function
                  mouseOver={mouseOver}
                  setMouseOver={setMouseOver}
                  data-cy="settings-button"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                  padding: "8px 16px 16px 16px",
                }}
              >
                {accessTo !== "virtual_coach" && (
                  // frigade Progress Badge
                  <Box
                    sx={{
                      marginBottom: "-20px",
                    }}
                  >
                    <ProgressBar defaultOpen={true} />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    opacity: 1,
                    marginTop: "10px",
                  }}
                ></Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "72px",
                    background: "#EFF3F6",
                    border: "1px solid #E6E7EC",
                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleSupportClick}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        borderRadius: "50%",
                        background: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <SupportIcon />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        Support
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "#555555",
                        }}
                      >
                        Answers Here
                      </Typography>
                    </Box>
                  </Box>

                  <SupportArrowIcon />
                </Box>

                {/* Drawer Component */}
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={toggleDrawer(false)}
                  ModalProps={{
                    BackdropProps: {
                      style: {
                        opacity: 0, // Make backdrop invisible
                      },
                    },
                  }}
                  sx={{
                    "& .MuiDrawer-paper": {
                      width: 360,
                      boxSizing: "border-box",
                      overflowX: "hidden",
                      position: "absolute",
                      left: `${275}px`, // Adjust as necessary
                      marginY: "16px",
                      height: "auto",
                      boxShadow: "none",
                      borderRadius: "0 30px 30px 0",
                      backgroundColor: "#ffffff",
                      display: "flex",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      background: "white",
                      border: "2px solid white",
                      boxShadow: 24,
                      padding: "20px 30px 10px",
                      borderRadius: "16px",
                      gap: "20px",
                      margin: "0 auto",
                      width: "100%",
                      height: "96.3vh",
                    }}
                  >
                    {/* Close Button */}
                    <IconButton
                      onClick={toggleDrawer(false)}
                      sx={{ alignSelf: "flex-end" }}
                    >
                      <CloseIcon style={{ color: "black" }} />
                    </IconButton>

                    <Box>
                      <Typography
                        color="black"
                        fontWeight="400"
                        fontSize={20}
                        fontFamily={"Lexend"}
                      >
                        Get to know the platform
                      </Typography>
                      <Box
                        display="flex"
                        gap="8px"
                        alignItems="center"
                        width={400}
                      >
                        <Typography
                          color="#727272"
                          fontWeight="400"
                          fontSize={12}
                          marginBottom={0}
                        >
                          Learn the in's and out's of your Leadership Platform
                        </Typography>
                      </Box>
                    </Box>
                    {/* Onboarding Modules */}
                    {/* Process Call */}
                    {accessTo !== "virtual_coach" && (
                      <ProcessCallRestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}

                    {/* Call Insights */}
                    {accessTo !== "virtual_coach" && (
                      <CallInsightsRestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}

                    {/* Assessments */}
                    {accessTo !== "virtual_coach" && (
                      <AssessmentsRestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}

                    {/* Add members */}
                    {user?.role === "OWNER" &&
                    accessTo !== "platform_professional" ? (
                      <AddMemberRestartOwner
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    ) : user?.role === "SUPERADMINS" &&
                      accessTo !== "platform_professional" ? (
                      <AddMemberRestartSuperAdmin
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    ) : user?.role === "ADMINS" &&
                      accessTo !== "platform_professional" ? (
                      <AddMemberRestartAdmin
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    ) : user?.role === "USER" &&
                      accessTo !== "platform_professional" ? (
                      <AddMemberRestartUser
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    ) : null}

                    {/* Leadership Center */}
                    {accessTo !== "virtual_coach" && (
                      <LeadershipCenterRestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}

                    {/* LARA */}
                    {accessTo !== "virtual_coach" && (
                      <ExploreLARARestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}

                    {/* Virtual Coach */}
                    {accessTo !== "virtual_coach" && (
                      <VirtualCoachRestart
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}
                    {accessTo === "virtual_coach" && (
                      <VirtualCoachRestartVC
                        closeDrawer={() => setIsDrawerOpen(false)}
                      />
                    )}
                  </Box>
                </Drawer>
              </Box>
            </Box>
          </Menu>
        </ProSidebar>
        <AddProductModal
          user={user}
          userData={userData}
          openAddProductModal={openAddProductModal}
          setOpenAddProductModal={setOpenAddProductModal}
        />
      </Box>
    </Box>
  );
};

export default Sidebar;

<Box
  sx={{
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  }}
/>;
