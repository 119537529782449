import { Box } from '@mui/material';
import { API, Auth } from 'aws-amplify';
import AddNewResource from 'components/ResourceEditingSections/AddNewResource';
import DeleteSection from 'components/ResourceEditingSections/DeleteSection';
import EditSection from 'components/ResourceEditingSections/EditSection';
import { formatData } from 'helpers/ResourceHelper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';

const ResourceEditingPage = () => {
    const [isAllowedToView, setIsAllowedToView] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fetchedData, setFetchedData] = useState([])
    const navigate = useNavigate()


    useEffect(() => {
        async function handleRouteAccess() {
            try {
                await getCurrentUser()
                const user = await fetchUserAttributes()
                if (user && user.email.endsWith("@altercall.com")) {
                    setIsAllowedToView(true)
                } else {
                    toast.error('Unauthorized !!')
                    navigate('/')
                }


            } catch (error) {
                toast.error('Unauthorized!!')
                navigate('/')
            }
        }
        handleRouteAccess()
    }, [])

    // Fetch the data from the DB
    useEffect(() => {
        try {
            async function getResourcePageAndSectionNames() {
                setLoading(true)
                const dbResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-resource-page-section-name-and-id`)
                const formattedData = formatData(dbResponse.data)
                setFetchedData(formattedData)
                setLoading(false)
            }
            getResourcePageAndSectionNames();
        } catch (error) {
            console.log(error);
        }
    }, [])
    return (
        <Box width={'85%'} mx={'auto'} >
            {
                isAllowedToView && <Box>
                    <AddNewResource />
                    <Box sx={{ width: '90%', py: '80px' }}> <hr /> </Box>
                    <DeleteSection dataFetchLoading={loading} fetchedData={fetchedData} />
                    <Box sx={{ width: '90%', py: '80px' }}> <hr /> </Box>
                    <EditSection fetchedData={fetchedData} dataFetchLoading={loading} />
                </Box>
            }
        </Box>
    );
};

export default ResourceEditingPage;