import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ToolBar from "./ToolBar/ToolBar";
import axios from "axios";
import UsersTable from "components/Table/CustomTable";
import VcOwnerAddAdminModal from "./VcAddMembers/VcOwnerAddAdminModal";
import VcAddUserModal from "./VcAddMembers/VcAddUserModal";
import DeleteMembersModal from "./DeleteMembers/DeleteMembersModal";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/data";
import { checkUserGroupAlignment } from "../../helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import ActionOnVcUserModal from "./VcUserActions/ActionOnVcUserModal";
import { GreenStatusPill, OrangeStatusPill } from "components/Table/StatusPills";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const VcOwner = ({ user, userData }) => {
  const [selected, setSelected] = useState([]);
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [openAddUserModal, setOpenAddUserModal] = useState(false); // Modal state
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false); // Modal state
  const [openActionOnVcUserModal, setOpenActionOnVcUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [availableSeats, setAvailableSeats] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
            companyId: userData?.companyInformation?.company_id,
          },
        }
      );
      setCompanyMembers(response?.allMembers);
      setCompanyInformation(response?.companyInformation);
      setTeamsInformation(response?.teams);

      // Update selectedUser with the latest data if it's set
      if (selectedUser && selectedUser.user_id) {
        const updatedUser = response.members.find(
          (member) => member.user_id === selectedUser.user_id
        );
        if (updatedUser) {
          setSelectedUser({
            ...updatedUser,
            id: selectedUser.id, // Preserve the id if needed
            title: updatedUser.company_role_name,
          });
        } else {
          // If the user is no longer in the members list (e.g., deleted), close the modal
          setSelectedUser(null);
          setOpenActionOnVcUserModal(false);
        }
      }

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: user?.sub,
        });

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);

      // Call the API to delete users
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-company-members`,
        {
          userIds: selected,
          companyId: userData?.companyInformation?.company_id,
          user_id: user?.sub,
        }
      );

      const { deletedCount } = response.data; // API should return the count of deleted users

      // Decrement the seat count based on the number of deleted users
      for (let i = 0; i < deletedCount; i++) {
        await resourceClient.mutations.decrementCoacheeSeatCount({
          user_id: user?.sub,
        });
      }

      toast.success(
        `${deletedCount} user${
          deletedCount > 1 ? "s" : ""
        } deleted successfully!`
      );
      setOpenDeleteModal(false);
      fetchCompanyData(); // Refresh company data after deletion
    } catch (error) {
      console.error("Error deleting users:", error);
      toast.error("Failed to delete users. Please try again.");
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true); // Open the delete modal
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };

  //define the columns
  const columns = [
    {
      field: "full_name",
      headerName: (
        <span id="hint-anchor-2" style={{ color: "inherit" }}>
          Full Name
        </span>
      ),
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value}
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              textTransform: "none"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        const roleMap = {
          owner: "Owner",
          super_admin: "Super Admin",
          admin: "Admin",
          user: "User",
          private: "Private User",
        };

        return (
          <Tooltip title={params.value || ""}>
              {roleMap[params.value] || "No role"}
          </Tooltip>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value || "No Title"}
        </Tooltip>
      ),
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value === "accepted" ? (
            <GreenStatusPill text={params.value} />
          ) : params.value === "pending" || params.value === "expired" ? (
            <OrangeStatusPill text={params.value} />
          ) : null}
        </Tooltip>
      ),
    },
    // {
    //     field: "actions",
    //     headerName: "Actions",
    //     flex: 1,
    //     renderCell: (params) => {
    //       return (
    //         <Box sx={{ display: "flex", gap: "8px" }}>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               flexDirection: "row",
    //               gap: "4px",
    //               alignItems: "center",
    //               "&:hover": {
    //                 cursor: "pointer",
    //               },
    //             }}
    //             onClick={() => handleActionOnVcUser(params.row)}
    //           >
    //             <AdminPortalActionIcon />
    //             <Typography
    //               sx={{
    //                 color: "#93989A",
    //                 fontSize: 14,
    //                 fontFamily: "Lexend",
    //                 fontWeight: "400",
    //                 wordWrap: "break-word",
    //               }}
    //             >
    //               Actions
    //             </Typography>
    //           </Box>
    //         </Box>
    //       );
    //     },
    //   },
  ];

  const rows = companyMembers.map((member, index) => {
    const teams = member.teams.map((team) => team.team_name);

    // Create a Set to keep track of unique admin IDs to avoid duplicates
    const uniqueAdminNames = new Set();

    // Iterate over each team to collect the admin names
    member.teams.forEach((team) => {
      if (team.team_admins) {
        team.team_admins.forEach((admin) => {
          uniqueAdminNames.add(admin.full_name);
        });
      }
    });

    // Convert the Set to an array to list all unique admin names
    const adminFullNames = Array.from(uniqueAdminNames);

    return {
      id: index,
      user_id: member.user_id,
      full_name: member.full_name,
      email: member.email,
      role: member.role || "No Role",
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
    };
  });

    // Function to handle switching team
    const handleActionOnVcUser = (member) => {
        setSelectedUser(member);
        handleOpenActionOnVcUserModal();
      };


  // Function to open/close the Add Member modal
  const handleOpenAddAdminModal = () => setOpenAddAdminModal(true);
  const handleOpenActionOnVcUserModal = () => {
    if(selected?.length === 1){
      setSelectedUser(selected[0] || "");
      setOpenActionOnVcUserModal(true)
    }
  };
  const handleOpenAddUserModal = () => setOpenAddUserModal(true);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_NafpXKKT"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1">Teams & Members</span>
          </Typography> */}
          {/* @m3zi3 */}
          <Box
            id="hint-anchor-1"
          >
          <ToolBar
            user={user}
            userData={userData}
            selected={selected}
            fetchCompanyData={fetchCompanyData}
            openDeleteModal={() => setOpenDeleteModal(true)}
            handleOpenActionOnUserModal={handleOpenActionOnVcUserModal}
            companyInformation={companyInformation}
          />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                padding: "12px",
                background: "#F1F1F1",
              }}
            >
              <span id="hint-anchor-3">
                {`${availableSeats} seats are open`}
              </span>
            </Typography>
            <Button
              onClick={handleOpenAddAdminModal}
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                border: "1px black solid",
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
            >
              <span id="hint-anchor-4">Add Admins</span>
            </Button>
            <Button
              onClick={handleOpenAddUserModal}
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                border: "1px black solid",
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
            >
              <span id="hint-anchor-5">Add Users</span>
            </Button>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 25, 30]}
            checkboxSelection
            loading={loading}
            onSelectionModelChange={(newSelection) => {
              const selectedUsers = newSelection.map((selectionId) => {
                const selectedUserInfo = rows.find((row) => row.id === selectionId);
                return selectedUserInfo; // Return the user_id instead of the index id
              });
              setSelected(selectedUsers); // Update state with selected user_ids
            }}
            disableColumnFilter
            disableSelectionOnClick
            isRowSelectable={(params) => params.row.user_id !== user?.sub}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
          />
        </Box>
      </Box>

      {/* Add Admin Modal */}
      <VcOwnerAddAdminModal
        user={user}
        userData={userData}
        openAddAdminModal={openAddAdminModal}
        setOpenAddAdminModal={setOpenAddAdminModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      <VcAddUserModal
        user={user}
        userData={userData}
        openAddUserModal={openAddUserModal}
        setOpenAddUserModal={setOpenAddUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      <ActionOnVcUserModal
        user={user}
        userData={userData}
        openActionOnVcUserModal={openActionOnVcUserModal}
        setOpenActionOnVcUserModal={setOpenActionOnVcUserModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        fetchCompanyData={fetchCompanyData}
        loadingCompanyData={loading}
      />

      <DeleteMembersModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={handleDelete}
        selectedCount={selected.length}
        loading={loadingDelete}
      />
    </Box>
  );
};

export default VcOwner;
