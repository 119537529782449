import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, useFormik } from "formik";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { API, Auth, Storage } from "aws-amplify";
import GlobalSidebar from "components/GlobalSidebar/GlobalSidebar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "components/GlobalHeader/GlobalHeader";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import UserGuide from "components/Retune/UserGuide";

const UserGuidePage = () => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);


  return (
    <>


        <GlobalPageComponent>
          <UserGuide
            fullWidth={true}
          />
        </GlobalPageComponent>
      
    </>
  );
};

export default UserGuidePage;
