import { Box, Button, Typography } from "@mui/material";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import Lottie from "react-lottie";
import { useNavigate } from "react-router";
import * as animationData from "../../data/lottieAnimation.json"



const VirtualCoachCard = ( { user } ) => {
    const navigate = useNavigate()
    return(
        <Box
        sx={{
          padding: {
            xs: "30px",
            md: "16px",
          },
          borderRadius: "40px",
          background: "white",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          height: "100%"
        }}
      >
        {/* <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: {
              xs: "24px"
            },
            fontWeight: 400,
            color: "#000",
            textAlign: "center",
          }}
        >
          Virtual Coach
        </Typography> */}
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
            height: "80%",
            marginY: "-100px",
          }}
        />
          <Button
            sx={{
              display: " flex",
              padding: "10px 18px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "52px",
              border: "1px solid #578FF2",
              borderColor: "#578FF2",
              background: "white",
              opacity: 1,
              transition: "all 0.3s",
              maxWidth: "70%",
              "&:hover": {
                cursor: "pointer",
                background: "black",
                borderColor: "black",
              },
              "&:hover *": {
                color: "white",
              },
              "&:hover svg *": {
                stroke: "white",
              },
            }}
            onClick={() => {
              if(user?.["custom:is_coachee"] === "true")
                navigate("/platform/coachee/virtualcoach");
              else
                navigate("/platform/coach/virtualcoach")
            }}
            data-cy="virtual-coach-button"
          >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0.05em",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
            data-cy="virtual-coach-button-text"
          >
            Use Virtual Coach
          </Typography>
          {/* {ArrowForwardBlack()} */}
        </Button>
      </Box>
    )
}

export default VirtualCoachCard;