import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { KeyboardArrowDown } from "@mui/icons-material";
import ToggleIcon from "components/Icons/Toggle.svg";
import { generateClient } from "aws-amplify/data";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const ActionOnUserModal = ({
  user,
  userData,
  openActionOnUserModal,
  setOpenActionOnUserModal,
  companyMembers,
  companyInformation,
  teamsInformation,
  selectedUser,
  setSelectedUser,
  fetchCompanyData,
  loadingCompanyData,
  myPrivateUsers,
  internalCompanyMembers
}) => {
  const [userTeams, setUserTeams] = useState([]);
  const [loadingResendInvite, setLoadingResendInvite] = useState(false);
  const [title, setTitle] = useState(selectedUser?.title || "");
  const [savingTitle, setSavingTitle] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [addingToTeam, setAddingToTeam] = useState(false);
  const [removingTeam, setRemovingTeam] = useState(null);
  const [selectedManager, setSelectedManager] = useState(selectedUser?.manager_user_id || "");
  const [savingManager, setSavingManager] = useState(false);
  const [markingAsAdmin, setMarkingAsAdmin] = useState(null);
  const [removingAdminRole, setRemovingAdminRole] = useState(null);
  const [initialTitle, setInitialTitle] = useState("");
  const [loadingPrivateUserRemoval, setLoadingPrivateUserRemoval] =
    useState(false);
  const [markingAsSuperAdmin, setMarkingAsSuperAdmin] = useState(false);
  const [removeAsSuperAdmin, setRemoveAsSuperAdmin] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [keepData, setKeepData] = useState(false);
  const [openDropdown, setOpenDropdown] = useState({});
  const handleConfirmChange = (event) => {
    setConfirmChecked(event.target.checked);
  };
  console.log("selected user: ", selectedUser)
  const handleKeepDataChange = (event) => {
    setKeepData(event.target.checked);
  };
  const handleRemovePrivateUser = async () => {
    if (confirmChecked) {
      try {
        setLoadingPrivateUserRemoval(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-private-user`,
          {
            user_id: selectedUser.user_id,
            company_id: companyInformation?.company_id,
            caller_id: user?.sub,
            keep_data: keepData,
          }
        );

        if (response.status === 200) {
          // Decrement the seat count only on successful response
          await resourceClient.mutations.decrementCoacheeSeatCount({
            user_id: companyInformation.owner_user_id,
          });
          toast.success("Private user removed successfully.");
          fetchCompanyData(); // Refresh data after successful removal
        } else {
          // Display error if status isn't 200
          toast.error("Failed to remove private user.");
        }

        setLoadingPrivateUserRemoval(false);
        handleCloseActionOnUserModal();
      } catch (error) {
        setLoadingPrivateUserRemoval(false);
        toast.error("An error occurred while removing the private user.");
      }
    }
  };

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: "black" }} />
  );

  useEffect(() => {
    // Initialize state with the selected user's current team and admin status
    if (selectedUser && selectedUser.teams) {
        setUserTeams(selectedUser.teams);
    }
    if(selectedUser && selectedUser.title){
      setTitle(selectedUser?.title || '');
      setInitialTitle(selectedUser?.title || '');
    }
    if (selectedUser?.manager_user_id) {
        setSelectedManager(selectedUser.manager_user_id);
    }
}, [selectedUser]);

  const handleCloseActionOnUserModal = () => {
    setAddingToTeam(false);
    setLoadingResendInvite(false);
    setConfirmChecked(false);
    setKeepData(false);
    setOpenActionOnUserModal(false);
  };

  const handleTitleSave = async () => {
    setSavingTitle(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-user-title`,
        {
          user_id: selectedUser.user_id,
          new_title: title,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );
      console.log(
        "body: ",
        selectedUser.user_id,
        title,
        companyInformation?.company_id
      );
      toast.success("Title updated successfully");
      fetchCompanyData();
    } catch (error) {
      console.error("Error updating title:", error);
      toast.error("Error while updating title");
    } finally {
      setSavingTitle(false);
    }
  };

  const handleRemoveTeam = async (teamId) => {
    try {
      setRemovingTeam(teamId);
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-user-from-team`,
        {
          user_id: selectedUser.user_id,
          team_id: teamId,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );
      toast.success("User removed from team successfully");
      fetchCompanyData();
    } catch (error) {
      console.error("Error removing user from team:", error);
      toast.error("Error while removing user from team");
    } finally {
      setRemovingTeam(null);
    }
  };

  const handleMarkAsAdmin = async (teamId) => {
    try {
      setMarkingAsAdmin(teamId);
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-user-as-team-admin`,
        {
          user_id: selectedUser.user_id,
          team_id: teamId,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );
      toast.success("User marked as admin successfully");
      fetchCompanyData();
    } catch (error) {
      console.error("Error marking user as admin:", error);
      toast.error("Error while marking user as admin");
    } finally {
      setMarkingAsAdmin(null);
    }
  };

  const handleMarkAsTeamMember = async (teamId) => {
    try {
      setRemovingAdminRole(teamId);
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-user-as-team-member`,
        {
          user_id: selectedUser.user_id,
          team_id: teamId,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );
      toast.success("User marked as team member successfully");
      fetchCompanyData();
    } catch (error) {
      console.error("Error marking user as team member:", error);
      toast.error("Error while removing admin role");
    } finally {
      setRemovingAdminRole(null);
    }
  };

  const handleResendInvite = async () => {
    try {
      if (!companyInformation) {
        throw new Error("Company information is not available");
      }
      setLoadingResendInvite(true);
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/resend-invite`,
        {
          invited_user_id: selectedUser.user_id,
          user_id: user?.sub,
          company_id: companyInformation?.company_id,
          company_name: companyInformation?.company_name,
        }
      );
      toast.success("Invitation sent successfully");
    } catch (error) {
      console.error("Error resending invite:", error);
      alert(error.message || "Failed to resend invite. Please try again.");
    } finally {
      setLoadingResendInvite(false);
    }
  };

  const handleAddToTeam = async () => {
    if (!selectedTeam) return;
    setAddingToTeam(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-user-to-team`,
        {
          user_id: selectedUser.user_id,
          team_id: selectedTeam,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );
      toast.success("User added to team successfully");
      fetchCompanyData(); // Refresh data
      setSelectedTeam(""); // Clear selection
    } catch (error) {
      console.error("Error adding user to team:", error);
      toast.error("Error while adding user to team");
    } finally {
      setAddingToTeam(false);
    }
  };

  const handlePromoteToSuperAdmin = async () => {
    try {
      setMarkingAsSuperAdmin(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/promote-user-to-super-admin`,
        {
          user_id: selectedUser.user_id,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );

      // If the request is successful
      if (response.status === 200) {
        toast.success("User promoted to super admin successfully");
        fetchCompanyData();
      }
    } catch (error) {
      // Check if there is a specific error message from the server
      if (error.response) {
        const { status, data } = error.response;

        // Display error messages based on the status code and error content
        if (status === 403 || status === 400) {
          toast.error(data.error); // Display server-provided error message
        } else if (status === 500) {
          toast.error("Internal server error. Please try again later.");
        } else {
          toast.error("Unexpected error occurred while promoting user.");
        }
      } else {
        // General error handling for network issues or unexpected errors
        console.error("Error promoting user to super admin:", error);
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setMarkingAsSuperAdmin(false);
    }
  };

  const handleRemoveAsSuperAdmin = async () => {
    try {
      setRemoveAsSuperAdmin(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/remove-user-super-admin`,
        {
          user_id: selectedUser.user_id,
          company_id: companyInformation?.company_id,
          caller_id: user?.sub,
        }
      );

      // Handle success response
      if (response.status === 200) {
        toast.success("User removed as super admin successfully");
        fetchCompanyData();
      }
    } catch (error) {
      // Check for specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 403) {
          // Display specific error message from the endpoint
          toast.error(data.error);
        } else if (status === 500) {
          toast.error("Internal server error.");
        } else {
          toast.error("Error while removing user as super admin");
        }
      } else {
        // Network or unexpected errors
        console.error("Error removing user as super admin:", error);
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setRemoveAsSuperAdmin(false);
    }
  };

  const checkCircularReference = (currentUser, selectedManager, allMembers) => {
    const checkLoop = (userId, targetManagerId) => {
        if (!userId) return false;
        
        let managerId = allMembers.find(member => member.user_id === userId)?.manager_user_id;
        
        while (managerId) {
            if (managerId === targetManagerId) return true; // Circular reference detected
            managerId = allMembers.find(member => member.user_id === managerId)?.manager_user_id;
        }
        
        return false;
    };

        // Direct circular reference check
        if (checkLoop(currentUser.user_id, selectedManager) || checkLoop(selectedManager, currentUser.user_id)) {
            return true;
        }
        return false;
    };

    // Usage
    const handleSaveManager = async () => {
        if (checkCircularReference(selectedUser, selectedManager, internalCompanyMembers)) {
            toast.error("Circular management relationship detected. Please select a different manager.");
            return;
        }

        // Proceed with manager assignment if no circular relationship
        setSavingManager(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-manager`, {
                user_id: selectedUser.user_id,
                manager_user_id: selectedManager || null,
                company_id: companyInformation?.company_id,
            });
            toast.success("Manager updated successfully");
            fetchCompanyData(); // Refresh company data to reflect changes
        } catch (error) {
            console.error("Error updating manager:", error);
            toast.error("Error while updating manager");
        } finally {
            setSavingManager(false);
        }
    };

    const checkCircularRelationship = (currentUserId, potentialManagerId, allMembers) => {
        const visited = new Set();

        // Walk up the hierarchy to see if we encounter the current user ID, which would create a loop
        let managerId = potentialManagerId;
        while (managerId) {
            if (managerId === currentUserId) return true; // Circular relationship detected
            if (visited.has(managerId)) break; // Prevent infinite loop if something goes wrong
            visited.add(managerId);

            // Find the next manager up in the chain
            managerId = allMembers.find(member => member.user_id === managerId)?.manager_user_id;
        }

        return false;
    };

  const toggleDropdown = (teamId) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [teamId]: !prev[teamId],
    }));
  };

  return (
    <Modal
      open={openActionOnUserModal}
      onClose={handleCloseActionOnUserModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxWidth: "calc(100% - 40px)",
          maxHeight: "80%",
          overflowY: "hidden",
          boxShadow: 24,
          borderRadius: "30px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 21,
                fontFamily: "Poppins",
                fontWeight: "400",
                wordWrap: "break-word",
                lineHeight: "24px",
              }}
            >
              Editing: {selectedUser?.full_name || ""}
            </Typography>
            {selectedUser?.invite_status &&
              selectedUser?.invite_status === "pending" && (
                <Button
                  sx={{
                    color: "black",
                    fontSize: 12,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    letterSpacing: "0.05em",
                    borderRadius: 50,
                    border: "1px black solid",
                    width: "200px",
                    height: "30px",
                    lineHeight: "18px",
                    transition: "all 0.3s",
                    "&:hover": {
                      cursor: "pointer",
                      background: "black",
                      borderColor: "black",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  onClick={handleResendInvite}
                  disabled={loadingResendInvite || loadingCompanyData}
                >
                  {loadingResendInvite ? (
                    <CircularProgress size={15} style={{ color: "black" }} />
                  ) : (
                    "Resend Invite"
                  )}
                </Button>
              )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: 14,
                fontFamily: "Lexend",
                fontWeight: "400",
                textTransform: "capitalize",
                wordWrap: "break-word",
                lineHeight: "20px",
              }}
            >
              Title
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <TextField
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px #C4CAD4 solid",
                    },
                  },
                }}
              />
              <Button
                onClick={handleTitleSave}
                disabled={
                  savingTitle || loadingCompanyData || title === initialTitle
                }
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  border: "1px solid #000",
                  borderRadius: 50,
                  padding: "6px 12px",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  textTransform: "capitalize",
                  "&.Mui-disabled": {
                    color: "black", // Ensure text remains black when disabled
                    borderColor: "#000", // Ensure border remains visible
                    opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                  },
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                    color: "white",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                {savingTitle ? (
                  <CircularProgress size={15} style={{ color: "black" }} />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Box>

          {(selectedUser?.role && selectedUser?.role !== "private") && (
            <>
              {(user?.role === 'OWNER' || user?.role === 'SUPERADMINS') && (
              <>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: 14,
                    fontFamily: "Lexend",
                    fontWeight: "400",
                    textTransform: "none",
                    wordWrap: "break-word",
                    marginTop: "1px",
                    lineHeight: "20px",
                  }}
                >
                  Add to a Team
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedTeam}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedTeam(value);
                      }}
                      IconComponent={CustomIcon}
                      displayEmpty
                      inputProps={{
                        style: { color: "#4F4F4F" },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: "white",
                            maxHeight: 300,
                            overflow: "auto",
                            maxWidth: "20%",
                            width: "20%",
                          },
                        },
                      }}
                      sx={{
                        color: "black",
                        background: "white",
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    >
                      {teamsInformation?.length > 0 &&
                        teamsInformation.map((team) => (
                          <MenuItem
                            key={team.team_id}
                            value={team.team_id}
                            disabled={
                              userTeams &&
                              userTeams.some(
                                (userTeam) => userTeam.team_id === team.team_id
                              )
                            } // Disable if user is in team
                            sx={{
                              background: "white",
                              color: "black",
                              "&:hover": {
                                background: "#F7F7F7",
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "black",
                                fontSize: "12px",
                                whiteSpace: "normal",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word",
                              }}
                            >
                              {team.team_name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={handleAddToTeam}
                    disabled={!selectedTeam || addingToTeam || loadingCompanyData}
                    sx={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      border: "1px solid black",
                      borderRadius: 50,
                      lineHeight: "18px",
                      letterSpacing: "0.05em",
                      textTransform: "capitalize",
                      padding: "6px 12px",
                      "&.Mui-disabled": {
                        color: "black", // Ensure text remains black when disabled
                        borderColor: "#000", // Ensure border remains visible
                        opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                      },
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                  >
                    {addingToTeam ? (
                      <CircularProgress size={15} style={{ color: "black" }} />
                    ) : (
                      "Add"
                    )}
                  </Button>
                </Box>
              </>
              )}

              <Typography sx={{ color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'none', letterSpacing: 2, wordWrap: 'break-word', marginTop: 2 }}>Assign Manager</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <FormControl fullWidth>
                                <Select
                                    value={selectedManager || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSelectedManager(value);
                                    }}
                                    IconComponent={CustomIcon}
                                    displayEmpty
                                    inputProps={{
                                        style: { color: "#4F4F4F" },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                >
                                    {/* Option to Remove Manager if user has a manager */}
                                    {selectedUser?.manager_user_id && (
                                        <MenuItem
                                            value=""
                                            onClick={() => setSelectedManager(null)} // Set manager to null to remove
                                            sx={{
                                                background: "white",
                                                color: "red",
                                                fontWeight: "bold",
                                                "&:hover": {
                                                    background: "#F7F7F7",
                                                    cursor: "pointer",
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                Remove Manager
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    {internalCompanyMembers?.length > 0 && internalCompanyMembers?.map((member) => (
                                        <MenuItem 
                                            key={member.user_id} 
                                            value={member.user_id} 
                                            disabled={
                                                (selectedUser?.user_id === user?.sub && member.user_id === user?.sub) 
                                                    || (selectedUser?.user_id === member.user_id)
                                                        || checkCircularRelationship(selectedUser.user_id, member.user_id, internalCompanyMembers)
                                            }
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                  background: "#F7F7F7",
                                                  cursor: "pointer"
                                                },
                                              }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    whiteSpace: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordBreak: "break-word"
                                                }}
                                            >
                                                {member?.full_name || "Unknown"}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={handleSaveManager}
                            disabled={ savingManager || loadingCompanyData || selectedUser?.manager_user_id === selectedManager}
                            sx={{
                                color: 'black',
                                fontFamily: "Poppins",
                                fontWeight: '500',
                                border: '1px solid black',
                                borderRadius: 50,
                                lineHeight: "18px",
                                letterSpacing: "0.05em",
                                textTransform: 'capitalize',
                                padding: '6px 12px',
                                '&.Mui-disabled': {
                                color: 'black', // Ensure text remains black when disabled
                                borderColor: '#000', // Ensure border remains visible
                                opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                                },
                                transition: "all 0.3s",
                                "&:hover": {
                                cursor: "pointer",
                                background: "black",
                                borderColor: "black",
                                color: "white",
                                },
                                "&:hover *": {
                                color: "white",
                                },
                                "&:hover svg *": {
                                stroke: "white",
                                },
                            }}
                            >
                            {savingManager ? <CircularProgress size={15} style={{ color: "black" }} /> : "Save"}
                        </Button>
                    </Box>

              {userTeams && userTeams.length > 0 && (
              <Typography
                sx={{
                  color: "black",
                  fontSize: 14,
                  fontFamily: "Lexend",
                  fontWeight: "400",
                  textTransform: "nond",
                  wordWrap: "break-word",
                  marginTop: "8px",
                  lineHeight: "20px",
                }}
              >
                Already part of the following teams:
              </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  maxHeight: "300px",
                  overflowY: "auto",
                  flexDirection: "column",
                }}
              >
                {/* ADMIN AND USER */}
                {userTeams && userTeams.map((team, index, array) =>(
                  <Box
                    key={team.team_id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom:
                        index === array.length - 1
                          ? "3px solid #ddd"
                          : "1px solid #ddd",
                      paddingBottom: "8px",
                      marginBottom: "8px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: 14,
                          fontFamily: "Lexend",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {team.team_name}
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline-flex",
                          transform: "rotate(0.05deg)",
                          padding: "5px var(--spacing-space-300, 12px)",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          borderRadius: "50px",
                          background: "#F3F7FA",
                          color: "#000",
                          fontFamily: "Lexend",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "20px", // 142.857%
                        }}
                      >
                        {team.role_in_team === "team_admin" ? "Admin" : "User"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        width: "50%",
                      }}
                    >
                      <Button
                        onClick={() => toggleDropdown(team.team_id)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          textTransform: "capitalize",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontFamily: "Lexend",
                            fontSize: 14,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                        >
                          Settings
                        </Typography>
                        <IconButton
                          sx={{
                            transform: openDropdown[team.team_id]
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s",
                            padding: 0, // Removes extra padding for a cleaner look
                          }}
                        >
                          <ToggleIcon style={{ width: 24, height: 24 }} />
                        </IconButton>
                      </Button>
                      {openDropdown[team.team_id] && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            width: "90%",
                            padding: "6px",
                          }}
                        >
                          <Button
                            onClick={() => handleRemoveTeam(team.team_id)}
                            disabled={
                              removingTeam === team.team_id ||
                              loadingCompanyData
                            }
                            sx={{
                              border: "1px solid #F04438",
                              borderRadius: 50,
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              transition: "all 0.3s",
                              "&:hover": {
                                cursor: "pointer",
                                background: "#F04438",
                                borderColor: "#F04438",
                                color: "white",
                              },
                              "&:hover *": {
                                color: "white",
                              },
                              "&:hover svg *": {
                                stroke: "white",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#F04438",
                                fontSize: 12,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                letterSpacing: "0.05em",
                                lineHeight: "18px",
                              }}
                            >
                              {removingTeam === team.team_id ? (
                                <CircularProgress
                                  size={10}
                                  style={{ color: "#F04438" }}
                                />
                              ) : (
                                "Remove"
                              )}
                            </Typography>
                          </Button>
                          {team.role_in_team === "team_member" ? (
                            <Button
                              onClick={() => handleMarkAsAdmin(team.team_id)}
                              disabled={
                                markingAsAdmin === team.team_id ||
                                loadingCompanyData
                              }
                              sx={{
                                border: "1px solid #F04438",
                                borderRadius: 50,
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                color: "#F04438",
                                "&.Mui-disabled": {
                                  background: "#F0F0F0",
                                  color: "gray", // Gray text
                                  borderColor: "gray", // Optional: change border color when disabled
                                },
                                transition: "all 0.3s",
                                "&:hover": {
                                  cursor: "pointer",
                                  background: "#F04438",
                                  borderColor: "#F04438",
                                  color: "white",
                                },
                                "&:hover *": {
                                  color: "white",
                                },
                                "&:hover svg *": {
                                  stroke: "white",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  letterSpacing: "0.05em",
                                  lineHeight: "18px",
                                }}
                              >
                                {markingAsAdmin === team.team_id ? (
                                  <CircularProgress
                                    size={10}
                                    style={{ color: "#F04438" }}
                                  />
                                ) : (
                                  "Mark as Admin Role"
                                )}
                              </Typography>
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                handleMarkAsTeamMember(team.team_id)
                              }
                              disabled={
                                removingAdminRole === team.team_id ||
                                loadingCompanyData
                              }
                              sx={{
                                border: "1px solid #F04438",
                                borderRadius: 50,
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                color: "#F04438",
                                "&.Mui-disabled": {
                                  background: "#F0F0F0",
                                  color: "gray", // Gray text
                                  borderColor: "gray", // Optional: change border color when disabled
                                },
                                transition: "all 0.3s",
                                "&:hover": {
                                  cursor: "pointer",
                                  background: "#F04438",
                                  borderColor: "#F04438",
                                  color: "white",
                                },
                                "&:hover *": {
                                  color: "white",
                                },
                                "&:hover svg *": {
                                  stroke: "white",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  letterSpacing: "0.05em",
                                  lineHeight: "18px",
                                }}
                              >
                                {removingAdminRole === team.team_id ? (
                                  <CircularProgress
                                    size={10}
                                    style={{ color: "#F04438" }}
                                  />
                                ) : (
                                  "Remove Admin Role"
                                )}
                              </Typography>
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
              {user.role === "OWNER" &&
                selectedUser.role !== "private" &&
                selectedUser.user_id !== user?.sub && (
                  <>
                    <Box
                      key={companyInformation.company_id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: "8px",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          width: "60%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "Lexend",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          Role in Company
                        </Typography>
                        <Typography
                          sx={{
                            display: "inline-flex",
                            transform: "rotate(0.05deg)",
                            padding: "5px var(--spacing-space-300, 12px)",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "50px",
                            background: "#F3F7FA",
                            color: "#000",
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px", // 142.857%
                            textTransform: "capitalize"
                          }}
                        >
                          {(selectedUser?.role || "Unknown").replace(/_/g, " ")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          width: "40%",
                        }}
                      >
                        <Button
                          onClick={() => toggleDropdown(selectedUser.user_id)}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            textTransform: "capitalize",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#000",
                              fontFamily: "Lexend",
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                          >
                            Settings
                          </Typography>
                          <IconButton
                            sx={{
                              transform: openDropdown[selectedUser.user_id]
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s",
                              padding: 0, // Removes extra padding for a cleaner look
                            }}
                          >
                            <ToggleIcon style={{ width: 24, height: 24 }} />
                          </IconButton>
                        </Button>
                        {openDropdown[selectedUser.user_id] && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                width: "100%",
                                padding: "6px",
                              }}
                            >
                              {selectedUser.role === "super_admin" ? (
                                <Button
                                  onClick={handleRemoveAsSuperAdmin}
                                  disabled={
                                    markingAsSuperAdmin || loadingCompanyData
                                  }
                                  sx={{
                                    border: "1px solid #F04438",
                                    borderRadius: 50,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    color: "#F04438",
                                    "&.Mui-disabled": {
                                      background: "#F0F0F0",
                                      color: "gray",
                                      borderColor: "gray",
                                    },
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      cursor: "pointer",
                                      background: "#F04438",
                                      borderColor: "#F04438",
                                      color: "white",
                                    },
                                    "&:hover *": {
                                      color: "white",
                                    },
                                    "&:hover svg *": {
                                      stroke: "white",
                                    },
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      letterSpacing: "0.05em",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {removeAsSuperAdmin ? (
                                      <CircularProgress
                                        size={10}
                                        style={{ color: "#F04438" }}
                                      />
                                    ) : (
                                      "Remove Super Admin Role"
                                    )}
                                  </Typography>
                                </Button>
                              ) : (
                                <Button
                                  onClick={handlePromoteToSuperAdmin}
                                  disabled={
                                    markingAsSuperAdmin || loadingCompanyData
                                  }
                                  sx={{
                                    border: "1px solid #F04438",
                                    borderRadius: 50,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    color: "#F04438",
                                    "&.Mui-disabled": {
                                      background: "#F0F0F0",
                                      color: "gray",
                                      borderColor: "gray",
                                    },
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      cursor: "pointer",
                                      background: "#F04438",
                                      borderColor: "#F04438",
                                      color: "white",
                                    },
                                    "&:hover *": {
                                      color: "white",
                                    },
                                    "&:hover svg *": {
                                      stroke: "white",
                                    },
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      letterSpacing: "0.05em",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {markingAsSuperAdmin ? (
                                      <CircularProgress
                                        size={10}
                                        style={{ color: "#F04438" }}
                                      />
                                    ) : (
                                      "Mark as Super Admin"
                                    )}
                                  </Typography>
                                </Button>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
            </>
          )}

          {myPrivateUsers &&
            selectedUser &&
            selectedUser?.role &&
            selectedUser?.role === "private" &&
            myPrivateUsers.includes(selectedUser?.user_id) && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: 14,
                        fontFamily: "Lexend",
                        fontWeight: "400",
                        textTransform: "none",
                        lineHeight: "20px",
                        wordWrap: "break-word",
                        marginTop: "1px",
                      }}
                    >
                      Remove as Private User
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: 14,
                        fontFamily: "Lexend",
                        fontWeight: "500",
                        textTransform: "none",
                        letterSpacing: 1.2,
                        lineHeight: "20px",
                        wordWrap: "break-word",
                      }}
                    >
                      Mark if you would like to keep the data and confirm. Then
                      click 'remove' to proceed.
                    </Typography>
                    {/* Keep Data Checkbox */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={keepData}
                          onChange={handleKeepDataChange}
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "Lexend",
                            fontWeight: "500",
                            letterSpacing: 1.2,
                            lineHeight: "20px",
                          }}
                        >
                          Keep the data shared between my private user and I.
                        </Typography>
                      }
                    />

                    {/* Confirm Removal Checkbox */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={confirmChecked}
                          onChange={handleConfirmChange}
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "Lexend",
                            fontWeight: "500",
                            letterSpacing: 1.2,
                            lineHeight: "20px",
                          }}
                        >
                          I understand and confirm this action.
                        </Typography>
                      }
                    />
                  </Box>
                  <Button
                    onClick={handleRemovePrivateUser}
                    disabled={!confirmChecked || loadingPrivateUserRemoval}
                    sx={{
                      border: "1px solid black",
                      borderRadius: 50,
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      color: "black",
                      "&.Mui-disabled": {
                        background: "#F0F0F0",
                        color: "gray", // Gray text
                        borderColor: "gray", // Optional: change border color when disabled
                      },
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        letterSpacing: "0.05em",
                        lineHeight: "18px",
                      }}
                    >
                      {loadingPrivateUserRemoval ? (
                        <CircularProgress
                          size={10}
                          style={{ color: "black" }}
                        />
                      ) : (
                        "Remove"
                      )}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ActionOnUserModal;
