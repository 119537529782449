import React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useGridApiContext, useGridSelector, gridPageSelector, gridPageCountSelector, gridRowCountSelector, gridPageSizeSelector } from "@mui/x-data-grid";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";

export const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  // Calculate visible range
  const startRow = page * pageSize + 1;
  const endRow = Math.min(rowCount, startRow + pageSize - 1);

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
      {/* Row range text */}
      <span style={{ color: "#667085", fontSize: "12px", fontFamily: "Poppins", fontWeight: "400", letterSpacing: 0.4 }}>
        {startRow}-{endRow} of {rowCount} Results
      </span>
      {/* Pagination controls */}
      <Pagination
        count={pageCount}
        page={page + 1}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              color: "black", // Set the font color to black
              "&.Mui-selected": {
                backgroundColor: "#0076BA", // Change background for selected page
                color: "white", // Ensure selected page text is white
              },
              border: "1px #D9E4EC solid",
              borderRadius: "8px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#f5f5f5", // Add a hover effect
              },
            }}
            slots={{
              previous: () => (
                <div style={{ fontSize: "12px", display: "flex", alignItems: "center", gap: "4px", color: "black" }}>
                  <span>
                    <KeyboardArrowLeft />
                  </span>
                  Back
                </div>
              ),
              next: () => (
                <div style={{ fontSize: "12px", display: "flex", alignItems: "center", gap: "4px", color: "black" }}>
                  Next
                  <span>
                    <KeyboardArrowRight />
                  </span>
                </div>
              ),
            }}
          />
        )}
      />
      </div>
    </div>
  );
};
