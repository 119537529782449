import { Box, Button, Divider, Typography } from "@mui/material";
import ClientLaraLineChart from "components/ClientLaraAnalytics/ClientLaraLineChart";
import ClientLaraPieCharts from "components/ClientLaraAnalytics/ClientLaraPieCharts";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { _ClientLaraFilter } from "components/ClientLaraAnalytics/ClientLaraFilter";
import { useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const ClientLaraAnalytics = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [laraChartFileData, setLaraChartFileData] = useState([]);
  const [filteredLaraChartFileData, setFilteredLaraChartFileData] = useState(
    []
  );
  const [filteredLaraLineChartFileData, setFilteredLaraLineChartFileData] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [filterCoachLaraPies, setFilterCoachLaraPies] = useState("none");
  const [filterCoachLaraLine, setFilterCoachLaraLine] = useState("none");
  const [piesChartLaraCount, setPiesChartLaraCount] = useState("-");
  const [lineChartLaraCount, setLineChartLaraCount] = useState("-");

  const [filterCoachLaraPiesItems, setFilterCoachLaraPiesItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterCoachLaraLineItems, setFilterCoachLaraLineItems] = useState([
    { key: "Coach", value: "none" },
  ]);

  const fetchCallData = async () => {
    setLoading(true);
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coach`,
        {
          params: { client_id: user?.sub },
        }
      );

      const fetchedData = response.data;
      fetchedData.sort((a, b) => new Date(b.zoom_date) - new Date(a.zoom_date));

      const parsedChartFileData = await Promise.all(
        fetchedData.map(async (row) => {
          let filteredLara = { coach: [], client: [] };

          try {
            const { data: laraResult, errors: resultErrors } =
              await resourceClient.models.lara.get(
                { call_id: row.id },
                {
                  selectionSet: [
                    "call_id",
                    "coach_id",
                    "coach_name",
                    "coach_listen_score",
                    "coach_affirm_score",
                    "coach_respond_score",
                    "coach_ask_score",
                    "coachees.coachee_name",
                    "coachees.listen_score",
                    "coachees.affirm_score",
                    "coachees.respond_score",
                    "coachees.ask_score",
                  ],
                }
              );

            if (resultErrors && resultErrors.length > 0) {
              return { ...row, lara: filteredLara };
            }

            if (!laraResult) {
              return { ...row, lara: filteredLara };
            }

            if (laraResult.coach_id === row.coach_id) {
              const coachData = {
                listen_score: laraResult.coach_listen_score,
                affirm_score: laraResult.coach_affirm_score,
                respond_score: laraResult.coach_respond_score,
                ask_score: laraResult.coach_ask_score,
                overall_score: Math.round(
                  ((laraResult.coach_listen_score +
                    laraResult.coach_affirm_score +
                    laraResult.coach_respond_score +
                    laraResult.coach_ask_score) /
                    4) *
                    20
                ),
              };
              filteredLara.coach.push(coachData);
              row.coachOverallScore = coachData.overall_score;
            }

            if (laraResult.coachees && Array.isArray(laraResult.coachees)) {
              const clientLara = laraResult.coachees.find(
                (c) => c.coachee_name === row.client_name
              );
              if (clientLara) {
                const clientData = {
                  listen_score: clientLara.listen_score,
                  affirm_score: clientLara.affirm_score,
                  respond_score: clientLara.respond_score,
                  ask_score: clientLara.ask_score,
                  overall_score: Math.round(
                    ((clientLara.listen_score +
                      clientLara.affirm_score +
                      clientLara.respond_score +
                      clientLara.ask_score) /
                      4) *
                      20
                  ),
                };
                filteredLara.client.push(clientData);
                row.clientOverallScore = clientData.overall_score;
              }
            }

            row.coachLaraData = filteredLara.coach;
            row.clientLaraData = filteredLara.client;

            row.chartData = {
              month: new Date(row.zoom_date).toLocaleString("default", {
                month: "long",
              }),
              overallScore:
                row.coachOverallScore || row.clientOverallScore || 0,
              listenScore:
                filteredLara.coach[0]?.listen_score ||
                filteredLara.client[0]?.listen_score ||
                0,
              affirmScore:
                filteredLara.coach[0]?.affirm_score ||
                filteredLara.client[0]?.affirm_score ||
                0,
              respondScore:
                filteredLara.coach[0]?.respond_score ||
                filteredLara.client[0]?.respond_score ||
                0,
              askScore:
                filteredLara.coach[0]?.ask_score ||
                filteredLara.client[0]?.ask_score ||
                0,
            };

            return { ...row, lara: filteredLara };
          } catch (error) {
            return { ...row, lara: filteredLara };
          }
        })
      );

      setLaraChartFileData(parsedChartFileData);

      const filteredPiesData = parsedChartFileData.filter(
        (row) =>
          filterCoachLaraPies === "none" ||
          row.coach_name === filterCoachLaraPies
      );
      const filteredLineData = parsedChartFileData.filter(
        (row) =>
          filterCoachLaraLine === "none" ||
          row.coach_name === filterCoachLaraLine
      );

      setFilteredLaraChartFileData(filteredPiesData);
      setFilteredLaraLineChartFileData(filteredLineData);

      setPiesChartLaraCount(filteredPiesData.length);
      setLineChartLaraCount(filteredLineData.length);

      const uniqueCoaches = [
        ...new Set(
          fetchedData.map((row) => row.coach_name).filter((name) => name)
        ),
      ];
      const formattedCoaches = uniqueCoaches.map((name) => ({
        key: name,
        value: name,
      }));
      setFilterCoachLaraPiesItems([
        { key: "Coach", value: "none" },
        ...formattedCoaches,
      ]);
      setFilterCoachLaraLineItems([
        { key: "Coach", value: "none" },
        ...formattedCoaches,
      ]);
    } catch (error) {
      setLaraChartFileData([]);
      setFilteredLaraChartFileData([]);
      setFilteredLaraLineChartFileData([]);
      setPiesChartLaraCount("-");
      setLineChartLaraCount("-");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCallData();
  }, []);

  useEffect(() => {
    let filteredData = laraChartFileData;

    if (filterCoachLaraPies !== "none") {
      filteredData = filteredData.filter(
        (item) => item.coach_name === filterCoachLaraPies
      );
    }
    setFilteredLaraChartFileData(filteredData);
    setPiesChartLaraCount(filteredData.length);
  }, [filterCoachLaraPies, laraChartFileData]);

  useEffect(() => {
    let filteredData = laraChartFileData;

    if (filterCoachLaraLine !== "none") {
      filteredData = laraChartFileData.filter(
        (item) => item.coach_name === filterCoachLaraLine
      );
    }

    setFilteredLaraLineChartFileData(filteredData);
    setLineChartLaraCount(filteredData.length);
  }, [filterCoachLaraLine, laraChartFileData]);

  const handleCoachFilterChange = (value) => {
    setFilterCoachLaraPies(value);
  };

  const handleCoachLineFilterChange = (value) => {
    setFilterCoachLaraLine(value);
  };

  const handleResetLaraPiesFilters = () => {
    setFilterCoachLaraPies("none");
  };

  const handleResetLaraChartFilters = () => {
    setFilterCoachLaraLine("none");
  };

  const handleClick = () => {
    window.open(
      "/blog?title=Enhancing%20Communication%20and%20Coaching%20Through%20the%20Power%20of%20the%20LARA%20Framework&id=21#:~:text=The%20LARA%20score%20is%20calculated%20based",
      "_blank"
    );
  };

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: {
            xs: "16px",
            md: "32px 40px",
          },
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontWeight: 300,
              marginBottom: "16px",
            }}
            data-cy="lara-analytics-title"
          >
            LARA Analytics
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: {
                xs: "unset",
                md: "200px",
              },
              zIndex: 1100,
              position: "relative",
              paddingBottom: "24px",
              alignSelf: {
                xs: "center",
                md: "unset",
              },
            }}
          >
            <Button
              sx={{
                display: " flex",
                width: {
                  xs: "100%",
                  lg: "220px",
                },
                padding: "10px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: " 10px",
                borderRadius: "52px",
                border: "1px solid black",
                borderColor: "black",
                background: "white",
                opacity: 1,
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                  color: "white",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
              onClick={handleClick}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  letterSpacing: "2px",
                  lineHeight: "1.5",
                  textTransform: "uppercase",
                }}
              >
                Learn More
              </Typography>
              {ArrowForwardBlack()}
            </Button>
          </Box>
        </Box> */}
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "50px",
            },
            paddingY: {
              xs: "30px",
              md: "30px",
            },
            maxHeight: { md: "calc(100vh - 100px)" },
            height: "95%",
            overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                Overall LARA Score
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  Sessions: {piesChartLaraCount}
                </Typography>
                <_ClientLaraFilter
                  value={filterCoachLaraPies}
                  items={filterCoachLaraPiesItems}
                  handleChange={handleCoachFilterChange}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F7F7F7",
                    },
                  }}
                  onClick={handleResetLaraPiesFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <ClientLaraPieCharts
              laraData={filteredLaraChartFileData}
              loading={loading}
            />
            <Divider color={"#DDE5ED"} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                Score Comparison
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  marginLeft: { xs: "0px", md: "50px" },
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  Sessions: {lineChartLaraCount}
                </Typography>
                <_ClientLaraFilter
                  value={filterCoachLaraLine}
                  items={filterCoachLaraLineItems}
                  handleChange={handleCoachLineFilterChange}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F7F7F7",
                    },
                  }}
                  onClick={handleResetLaraChartFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ClientLaraLineChart
                laraData={filteredLaraLineChartFileData}
                loading={loading}
              />
            </Box>
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // Changed to place at extreme right
              marginRight: {
                xs: "unset",
                md: "0px",
              },
              zIndex: 1100,
              position: "relative",
              paddingBottom: "24px",
              alignSelf: {
                xs: "center",
                md: "unset",
              },
            }}
          >
            <Button
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                  lg: "220px",
                },
                padding: "10px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "52px",
                border: "1px solid black",
                borderColor: "black",
                background: "white",
                opacity: 1,
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                  color: "white",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
              onClick={handleClick}
              data-cy="learn-more-button"
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "black",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  letterSpacing: "2px",
                  lineHeight: "1.5",
                  textTransform: "capitalize",
                }}
              >
                Learn More
              </Typography>
              {/* {ArrowForwardBlack()} */}
            </Button>
          </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default ClientLaraAnalytics;
