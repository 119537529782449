import React from "react";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const StyledDataGridWrapper = styled(Box)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    border: "none",
    backgroundColor: "white",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    overflow: "unset!important",
    whiteSpace: "normal",
    lineHeight: "normal",
    minHeight: "50px",
    maxHeight: "none !important",
    wordWrap: "break-word",
    padding: "20px 0",
    "&:focus": {
      outline: "none!important",
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "white",
    border: "none",
    color: "black",
    fontSize: "12px",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    backgroundColor: "white",
    "& .MuiTablePagination-toolbar": {
      color: "#202226",
    },
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    overflow: "visible!important",
  },
  "& .MuiCheckbox-root": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "white",
    border: "1px solid #F3F3F3",
    color: "#93989A",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    minHeight: "50px",
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-row:hover": {
    color: "#202226",
    cursor: "pointer",
  },
}));

const _DataGrid = ({
  rows,
  columns,
  onRowClick,
  height,
  loading,
  rowHeight = 50,
  checkboxSelection,
  onSelectionModelChange,
  localeText,
  selectionModel,
}) => {
  return (
    <StyledDataGridWrapper height={height}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        loading={loading}
        onRowClick={onRowClick}
        localeText={{ noRowsLabel: localeText || "No data" }}
        getRowHeight={() => "auto"}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        disableColumnFilter
        disableColumnMenu
      />
    </StyledDataGridWrapper>
  );
};

export default _DataGrid;
