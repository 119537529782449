import React from "react";

function ToggleThreadSidebarIcon({ color = '#111111' }) {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="none" viewBox="0 0 52 52">
        <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 26h6m-6 6h6m-6-12h16m1 12.5-1.379-1.379m0 0A2.998 2.998 0 0 0 31.5 26c-1.659 0-3 1.341-3 3a2.998 2.998 0 0 0 5.121 2.121Z"/>
        </svg>
    )
}

export default ToggleThreadSidebarIcon;