import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  InputLabel
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { signInUser } from "slices/UserSlice";
import { Box } from "@mui/system";
import { confirmSignUp, resendSignUpCode} from "aws-amplify/auth";

const VerifyUser = ({ email, password }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);

  const sendCodeAgain = async () => {
    try {
      setLoading(true);
      await resendSignUpCode({username: email});
      setResent(true);
      toast.success("Verification code sent successfully");
      setLoading(false);
    } catch (err) {
      console.log("Error in sending code again", err);
      toast.error(err.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: email,
        confirmationCode: verificationCode
      });
      //user verfiied successfully
      // localStorage.setItem("authRole", 1);
      await dispatch(signInUser({ username: email, password })).unwrap();
      setSubmitting(false);
      //navigate to signin page upon succcess
      toast.success("User verified successfully.");
      window.location.reload();
    } catch (error) {
      console.log("Error in verify", error);
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
      toast.error(error.message);
    }
  };
  return (
    <Grid sx={{width: "50%"}}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        gap="20px"
        width="300px"
      >
        <InputLabel
        htmlFor="component-helper"
        sx={{
          color: "#454F5F",
          fontSize: "14px",
          lineHeight: "1.33",
          fontWeight: "500",
          textTransform: "capitalize",
        }}
      >
        Check Email for Verification Code
      </InputLabel>
        <TextField
          required
          fullWidth
          id="verificationCode"
          name="verificationCode"
          autoComplete="verification-code"
          value={verificationCode}
          placeholder="Enter your verification code"
          onChange={(e) => setVerificationCode(e.target.value)}
          InputProps={{
            style: {
              color: "#22272F",
              outline: "none",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#454F5F",
            },
          }}
          sx={{
            color: "#22272F",
            background: "white",
            borderRadius: "16px",
            width: "100%",
            border: "1px solid #C4CAD4",
            "&:before, &:after": {
              display: "none",
            },
            "& fieldset": {
              border: "none !important",
            },
          }}
        />
        <Button
          disabled={submitting}
          onClick={handleSubmit}
          sx={{
            marginTop: "32px",
            width: "100%",
            padding: "18px 0",
            background: "white",
            border: "1px solid black",
            borderRadius: "43px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            transition: "all 0.3s",
            "&:hover": {
              background: "black",
              "& *": {
                color: "white",
              },
            },
          }}
        >

          {submitting ? (
            <CircularProgress size={20} sx={{marginRight: "5px", marginBottom: "-3px", color: "black"}} /> 
          ) : (
            <Typography
              sx={{
                fontFamily: "Montserrat",
                color: "black",
                fontSize: "12px",
                fontWeight: "600",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
            Verify
            </Typography>
          )}
        </Button>
        <Box display="flex" alignItems="center" gap="5px">
          <span style={{ color: "black" }}>Didn't receive code?</span>
          <Box
            component="span"
            onClick={sendCodeAgain}
            sx={{
              textDecoration: "underline",
              color: "black",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Send again
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerifyUser;
