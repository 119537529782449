import React from "react";
import { useNavigate } from "react-router-dom";
import { useFlow } from "@frigade/react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import SupportArrowIcon from "components/Icons/SupportArrowIcon.svg.js"; // Correct import path for the React component

const AddMemberRestartAdmin = ({ closeDrawer }) => {
  const navigate = useNavigate();
  const { flow } = useFlow("flow_qVx6zuSX");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleRestart = () => {
    if (flow) {
      if (flow.isStarted) {
        console.log(
          "Flow has already started, navigating to the current step."
        );
        flow.restart();
        closeDrawer();
        toggleDrawer(false);
        window.location.href = "/platform/enterprise/admin-portal";
      } else {
        flow
          .start()
          .then(() => {
            console.log("Flow started successfully");
            flow.reload();
            closeDrawer();
            window.location.href = "/platform/enterprise/admin-portal";
          })
          .catch((error) => {
            console.error("Error starting flow:", error);
          });
      }
    } else {
      console.error("Flow is not ready or available");
    }
  };

  return (
    <Box
      onClick={handleRestart}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        width: "300px",
        height: "72px",
        background: "white",
        border: "1px solid #E6E7EC",
        borderRadius: "10px",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            color: "#000000",
          }}
        >
          Add new members
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            color: "#555555",
          }}
        >
          Get started with adding new members
        </Typography>
      </Box>
      <SupportArrowIcon color="#000000" width="11" height="11" />
    </Box>
  );
};

export { AddMemberRestartAdmin };
