import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';


export const _FilterContentFacilitator = ({
  value,
  items = [],
  handleContentLibraryFilterOptionChange,
  handleFilterOptionCancel
}) => {
  const [opened, setOpened] = useState(false);
  // console.log('facilitator options -----', items);

  const selectedFormatName = value === 'none' ? "Facilitator" : value;


  return (
    <Box
      sx={{
        paddingY: "10px",
        paddingX: '5px',
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
        width: "70%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "10px",
              fontWeight: 500,
              letterSpacing: "0.5px",
              lineHeight: "150%",
              textTransform: "capitalize",
            }}
          >
            {selectedFormatName}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "10px",
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleFilterOptionCancel('facilitator');
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: "30px",
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
        }}
      >
        {items.map((item, index) =>
          item !== "none" && item && (
            <Box
              key={index}
              sx={{
                padding: "12px",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "1.5",
                color: "black",
                background: "white",
                cursor: "pointer",
                "&:hover": {
                  background: "#F5F6F4",
                }
              }}
              onClick={() => {
                setOpened(false);
                handleContentLibraryFilterOptionChange('facilitator', item);
              }}
            >
              {item}
            </Box>
          )
        )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
