import {
    Box,
    Typography,
  } from "@mui/material";

export const GrayStatusPill = ({ text }) => {
    return(
        <Box
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "12px",
                backgroundColor: "#F2F4F7",
                paddingX: "6pt",
                paddingY: "2pt"
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#364254', 
                    fontSize: "14px", 
                    fontFamily: 'Inter', 
                    fontWeight: '500', 
                    wordWrap: 'break-word'
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    )
}

export const GreenStatusPill = ({ text }) => {
    return(
        <Box
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "12px",
                backgroundColor: "#B4FFD2",
                paddingX: "6pt",
                paddingY: "2pt"
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#00643A', 
                    fontSize: "14px", 
                    fontFamily: 'Inter', 
                    fontWeight: '500', 
                    wordWrap: 'break-word'
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    )
}

export const BlueStatusPill = ({ text }) => {
    return(
        <Box
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "12px",
                backgroundColor: "#C5E8FF",
                paddingX: "6pt",
                paddingY: "2pt"
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#00588B', 
                    fontSize: "14px", 
                    fontFamily: 'Inter', 
                    fontWeight: '500', 
                    wordWrap: 'break-word'
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    )
}

export const OrangeStatusPill = ({ text }) => {
    return(
        <Box
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "12px",
                backgroundColor: "#f8e9e2",
                paddingX: "6pt",
                paddingY: "2pt"
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#FF5E00', 
                    fontSize: "14px", 
                    fontFamily: 'Inter', 
                    fontWeight: '500', 
                    wordWrap: 'break-word'
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    )
}

