import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import googleImg from "assets/google.png";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Hub } from "@aws-amplify/core";
import VerifyUser from "scenes/VerifyUser/VerifyUser";
import Lottie from "react-lottie";
import * as animationData from "data/lottieAnimation.json";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "react-toastify/dist/ReactToastify.css";
import { fetchAuthSession, signIn, signInWithRedirect } from "aws-amplify/auth";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const LoginForm = ({ user, userData }) => {
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState(false);
  const [loggingInUser, setLoggingInUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const checkIfAlreadyAuthenticated = async () => {
      try {
        const session = await fetchAuthSession();
        console.log("session access token: ", session);
        if (session && session?.tokens?.accessToken) {
          console.log("User is already signed in, redirecting...");
          navigate("/authenticating");
        }
      } catch (error) {
        console.warn("Error checking authentication status:", error);
      }
    };

    checkIfAlreadyAuthenticated();
  }, [navigate]);

  const trackLogin = async (userID) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ENDPOINT_TRACK_LOGIN}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID: userID,
            loginTime: new Date().toISOString(),
          }),
        }
      );

      const responseData = await response.json();
      return responseData; // Optional: return the response if needed for further processing
    } catch (error) {
      console.error("Failed to track login:", error);
      // toast.error("Error tracking login");
    }
  };

  const handleFormSubmit = async (values) => {
    const checkUserInfoForSSO = async (email) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SSO_CHECK_URL}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );
        const data = await response.json();
        console.log("Response from checkUserForSSO:", data);

        if (data.message === "Please use Google SSO to sign in.") {
          toast.info(data.message); // Display the toast message
          throw new Error("SSORequired"); // Throw a specific error to catch later
        }

        return data; // Proceed if no special instructions
      } catch (error) {
        console.error("Error calling API:", error);
        throw error; // Rethrow to handle in the calling function
      }
    };
    setLoggingInUser(true);
    const username = values.email;
    const password = values.password;

    try {
      await checkUserInfoForSSO(username); // This may throw an error if SSO is required
    
      // Attempt to sign in
      const user = await signIn({
        username: username,
        password: password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH",
        },
      });
    
      console.log("User object after signing in:", user);
    
      // Check for specific sign-in responses
      switch (user.nextStep.signInStep) {
        // case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
        //   toast.info("Please set a new password to continue.");
        //   // Call a function to prompt the user for the new password and complete the process with confirmSignIn
        //   await confirmSignInWithNewPassword(user);
        //   break;
        // case "RESET_PASSWORD":
        //   toast.info("Password reset required. Please reset your password.");
        //   // Redirect to a password reset flow
        //   await resetPassword(user);
        //   break;
        case "CONFIRM_SIGN_UP":
          toast.info("Please confirm your sign-up to proceed.");
          // Redirect to or initiate the confirmSignUp flow
          setVerifyCode(true);
          break;
    
        case "DONE":
          // Track login if sign-in is completed
          await trackLogin(user?.sub);
          navigate("/authenticating");
          break;
        default:
          // Handle any unexpected challenge names
          toast.error("Unexpected sign-in response. Please try again.");
      }
    } catch (err) {
      console.error("Caught Error:", err); // Log the detailed error
      setLoggingInUser(false); // Reset the login indicator
    
      if (err.message === "SSORequired") {
        toast.info("HELLO");
      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        toast.error(
          "Email and password combination is not valid. Please check Google SSO or sign up."
        );
      } else if (err.message === "User is not confirmed.") {
        setVerifyCode(true);
      } else {
        toast.error(
          err.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  }
    

  Hub.listen("auth", ({ payload: { event, data } }) => {
    if (event === "signedIn") {
      if (data && data.signInUserSession) {
        const userID = data.signInUserSession.idToken.payload.sub;
        trackLogin(userID);
        navigate("/authenticating");
      }
    } else if (event === "UserAlreadyAuthenticatedException") {
      navigate("/authenticating");
    }
  });


  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            overflowY: "scroll",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              flex: {
                md: "50%",
              },
              marginLeft: { md: "40px" },

            }}
          >
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={{ email: "", password: "" }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                submitForm,
                isSubmitting,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                >
                  {!verifyCode && (
                    <Box
                      color="black"
                      position={"relative"}
                      zIndex={1}
                      maxWidth="400px"
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "48px",
                            lineHeight: "1.17",
                            letterSpacing: "-0.02em",
                            color: "#22272F",
                          }}
                        >
                          Sign In
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          marginBottom: "16px",
                        }}
                      >
                        <InputLabel
                          htmlFor="component-helper"
                          sx={{
                            color: "#454F5F",
                            fontSize: "14px",
                            lineHeight: "1.33",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Email
                        </InputLabel>
                        <Box
                          sx={{
                            position: "relative",
                          }}
                        >
                          <TextField
                            name="email"
                            type="email"
                            required
                            onChange={handleChange}
                            value={values.email}
                            handleBlur={handleBlur}
                            error={touched.email && errors.email}
                            InputProps={{
                              style: {
                                color: "#22272F",
                                outline: "none",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "#454F5F",
                              },
                            }}
                            sx={{
                              color: "#22272F",
                              background: "white",
                              borderRadius: "16px",
                              width: "100%",
                              border: "1px solid #C4CAD4",
                              "&:before, &:after": {
                                display: "none",
                              },
                              "& fieldset": {
                                border: "none !important",
                              },
                            }}
                            placeholder="Enter your Email"
                          />
                          {touched.email && errors.email && (
                            <Typography variant="caption" color="error">
                              {errors.email}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <InputLabel
                          htmlFor="component-helper"
                          sx={{
                            color: "#454F5F",
                            fontSize: "14px",
                            lineHeight: "1.33",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Password
                        </InputLabel>
                        <Box
                          sx={{
                            position: "relative",
                          }}
                        >
                          <TextField
                            type="password"
                            name="password"
                            id="password"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            error={touched.password && errors.password}
                            value={values.password}
                            InputProps={{
                              style: {
                                color: "#22272F",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "#454F5F",
                              },
                            }}
                            sx={{
                              color: "#22272F",
                              background: "white",
                              borderRadius: "16px",
                              width: "100%",
                              border: "1px solid #C4CAD4",
                              "&:before, &:after": {
                                display: "none",
                              },
                              "& fieldset": {
                                border: "none !important",
                              },
                            }}
                          />
                          {touched.password && errors.password && (
                            <Typography variant="caption" color="error">
                              {errors.password}
                            </Typography>
                          )}
                          {showPassword === true ? (
                            <VisibilityOffIcon
                              color="#454F5F"
                              sx={{
                                position: "absolute",
                                top: "50%",
                                right: "16px",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowPassword(false);
                                const passwordElement =
                                  document.getElementById("password");
                                passwordElement.setAttribute(
                                  "type",
                                  "password"
                                );
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              color="#454F5F"
                              sx={{
                                position: "absolute",
                                top: "50%",
                                right: "16px",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowPassword(true);
                                const passwordElement =
                                  document.getElementById("password");
                                passwordElement.setAttribute("type", "text");
                              }}
                            />
                          )}
                        </Box>
                      </Box>

                      <Button
                        sx={{
                          marginTop: "32px",
                          width: "100%",
                          padding: "18px 0",
                          background: "white",
                          border: "1px solid black",
                          borderRadius: "43px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          transition: "all 0.3s",
                          "&:hover": {
                            background: "black",
                            "& *": {
                              color: "white",
                            },
                          },
                          "&:disabled": {
                            background: "#d3d3d3",
                            color: "rgba(0, 0, 0, 0.26)",
                            border: "none",
                            cursor: "not-allowed",
                          },
                        }}
                        disabled={
                          isSubmitting ||
                          !!errors.email ||
                          !!errors.password ||
                          !values.email ||
                          !values.password
                        }
                        onClick={submitForm}
                      >
                        {loggingInUser ? (
                          <CircularProgress
                            size={20}
                            sx={{
                              marginRight: "5px",
                              marginBottom: "-3px",
                              color: "black",
                            }}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              color: "black",
                              fontSize: "12px",
                              fontWeight: "600",
                              letterSpacing: "0.4px",
                              textTransform: "capitalize",
                            }}
                          >
                            Sign In
                          </Typography>
                        )}
                      </Button>
                      <Button
                        sx={{
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "600",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "32px",
                          borderRadius: "40px",
                          border: "1px solid black",
                          backdropFilter: "blur(25px)",
                          textTransform: "none",
                          color: "black",
                          background: "white",
                          transition: "all 0.4s",
                          width: "100%",
                          padding: "8px",
                          marginBottom: "24px",
                        }}
                        onClick={async () => {
                          try {
                            // Initiate the sign-in process with Google SSO
                            await signInWithRedirect({ provider: "Google" });
                          } catch (error) {
                            console.error("Google SSO Sign-in failed:", error);
                            toast.error("SSO Sign-in failed: " + error.message);
                          }
                        }}
                      >
                        <Box
                          sx={{
                            width: "38px",
                            height: "38px",
                            backgroundImage: `url(${googleImg})`,
                            color: "#",
                          }}
                        />
                        Continue with Google
                      </Button>

                      <Box
                        fontSize={"14px"}
                        fontWeight={400}
                        marginTop={"25px"}
                        width={"100%"}
                        textAlign={"center"}
                      >
                        <Typography component={"span"}>
                          Don’t Have An Account? &nbsp;
                        </Typography>
                        <Typography
                          onClick={() => navigate("/signup")}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          component={"span"}
                        >
                          Create Account
                        </Typography>
                      </Box>
                      <Box
                        width={"100%"}
                        fontSize={"14px"}
                        fontWeight={400}
                        textAlign={"center"}
                        marginTop="8px"
                      >
                        <Typography component={"span"}>
                          Forgot Password?&nbsp;
                        </Typography>
                        <Typography
                          component={"span"}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/resetpassword")}
                        >
                          Reset Password
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {verifyCode && (
                    <VerifyUser
                      email={values.email}
                      password={values.password}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                lg: "block",
              },
              flex: {
                lg: "50%",
              },
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width="100%"
              height="auto"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default LoginForm;