import React from "react";

function LaraIcon({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3349 15.5728C18.9393 18.7669 15.7522 20.999 12.0437 20.999C7.049 20.999 3 16.95 3 11.9553C3 8.24683 5.23216 5.05969 8.42622 3.66415M12.0437 4.26819V10.5988C12.0437 11.348 12.651 11.9553 13.4002 11.9553L19.7308 11.9553C20.48 11.9553 21.0981 11.3444 20.987 10.6035C20.4006 6.69103 17.308 3.5984 13.3955 3.01199C12.6546 2.90093 12.0437 3.51898 12.0437 4.26819Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaraIcon;
