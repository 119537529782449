import { Avatar, Button } from '@mui/material'
import React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AvatarForHeader = ({onClickFunction, initialToShow}) => {
  return (
    <>
    <Avatar
        onClick={onClickFunction}
        sx={{
            color: "white",
            fontWeight: "bold",
            backgroundImage: 'linear-gradient(330deg, #242e8c 13.07%, #03093a 43.61%, #D8659B 89.22%)',
        }}
    >
        {initialToShow}
    </Avatar>
    </>
  )
}

export default AvatarForHeader