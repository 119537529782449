import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssignAssessmentModal from "components/Assessments/AssignAssessmentModal";
import AssignAssessmentToTeamModal from "components/Assessments/AssignAssessmentToTeamModal";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import AssessmentsTable from "components/Assessments/AssessmentsTable";
import { _IndividualAssessmentsFilter } from "components/Assessments/IndividualAssessmentsFilter";
import { _TeamAssessmentsFilter } from "components/Assessments/TeamAssessmentsFilter";
import GeneralBigFiveScore from "components/Assessments/TheBigFive/GeneralBigFiveScore";
import { toast } from "react-toastify";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl"
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const CoachAssessments = ({ user, userData }) => {
  const [selectedAssessment, setSelectedAssessment] = useState(
    "The Big Five Personality Test"
  );

  const [openAssignToStudent, setOpenAssignToStudent] = useState(false);
  const [openAssignToTeam, setOpenAssignToTeam] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientDataLoading, setClientDataLoading] = useState(false);
  const [assessmentDataLoading, setAssessmentDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("none");
  const [selectedTeamId, setSelectedTeamId] = useState("none");
  const [viewAssessment, setViewAssessment] = useState({});
  const [assessmentsData, setAssessmentsData] = useState([]);

  const navigate = useNavigate();

  const handleOpenAssignToStudentModal = () => {
    setOpenAssignToStudent(true);
  };

  const handleOpenAssignToTeamModal = () => {
    setOpenAssignToTeam(true);
  };
  useEffect(() => {
    console.log("user for subscription", user?.sub);
    const updateSub = resourceClient.models.assessments
      .onUpdate({
        filter: {
          user_id: {
            contains: `${user?.sub}`,
          },
        },
      })
      .subscribe({
        authMode: "userPool",
        next: (data) => {
          console.log("Subscription Update:", data);

          // Check if the required fields are updated
          const updatedAssessment = data.element;
          const requiredFields = [
            "summary",
            "communication_style",
            "communication_tips",
          ];

          if (
            updatedAssessment &&
            requiredFields.every(
              (field) =>
                updatedAssessment[field] !== undefined &&
                updatedAssessment[field] !== null
            )
          ) {
            toast.success("Assessment Analysis ready", {
              duration: 3000,
              position: "top-center",
            });
          }
        },
        error: (error) => console.warn("Subscription Error:", error),
      });

    // Clean up on component unmount
    return () => updateSub.unsubscribe();
  }, [user?.sub]);

  const collapsed = useSelector((state) => state.layout.collapsed);

  const fetchClientData = async () => {
    try {
      const userId = user?.sub;
      let response;
      let members = [];
      let teams = [];
  
      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          userId,
          user?.role,
          userData?.companyInformation?.company_id
        );
  
        // Fetch based on user role
        const endpointMap = {
          OWNER: "/zoom/owner-fetch-company-information",
          SUPERADMINS: "/zoom/super-admin-fetch-company-members",
          ADMINS: "/zoom/admin-fetch-company-members",
          USERS: "/zoom/user-fetch-company-members",
        };
  
        const endpoint = endpointMap[user?.role];
  
        if (endpoint) {
          response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, {
            params: { userId, userGroup: user?.role },
          });
          members = response.data.members || [];
          teams = response.data.teams || response.data.myTeams || [];
        }
      } else {
        // Non-company case
        response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach-test`, {
          params: { coach_id: userId },
        });
        members = response.data.members || [];
      }
  
      if (members.length === 0) {
        setClientData([]);
        return;
      }
  
      // Sort teams alphabetically
      if (teams.length > 0) {
        teams = teams.sort((a, b) => a.team_name.localeCompare(b.team_name));
      }
  
      setClientData(members);
      setTeams(teams);
      console.log("Fetched members: ", members);
      console.log("Fetched teams: ", teams);
    } catch (error) {
      console.error("Error fetching client data: ", error);
    }
  };
  

  const fetchAssessmentData = async () => {
    try {
      setAssessmentDataLoading(true);
      const userId = user?.sub;
      let assessments = [];
  
      // Fetch assessments based on the user's role
      if (user?.role === "OWNER" || user?.role === "SUPERADMINS") {
        assessments = await fetchAllMemberAssessments();
      } else {
        assessments = await fetchUserAssessments(userId);
      }
  
      const coachAssessments = await fetchCoachAssessments(userId);
  
      // Merge, deduplicate, and sort assessments
      const mergedAssessments = mergeAndSortAssessments(
        assessments,
        coachAssessments,
        userId
      );
  
      console.log("Merged and deduplicated assessments: ", mergedAssessments);
  
      // Fetch user names and enrich assessments
      const userNames = await fetchUserNames(mergedAssessments, userId);
      const finalAssessments = attachUserNamesAndCounts(mergedAssessments, userNames);
  
      setAssessmentsData(finalAssessments);
  
      // Set the latest completed assessment for the current user
      const latestUserAssessment = finalAssessments.find(
        (assessment) => assessment.user_id === userId && assessment.completed
      );
      if (latestUserAssessment) {
        setViewAssessment(latestUserAssessment);
      }
  
      console.log("Final assessments: ", finalAssessments);
    } catch (error) {
      console.error("Error fetching assessment data: ", error);
    } finally {
      setAssessmentDataLoading(false);
    }
  };  
  

  const fetchPaginatedAssessments = async (userId) => {
    let hasNextPage = true;
    let nextToken = null;
    let assessments = [];
  
    while (hasNextPage) {
      try {
        const response = await resourceClient.models.assessments.list({
          filter: { user_id: { eq: userId } },
          limit: 300,
          nextToken, // Pass the token for pagination
        });
        assessments = assessments.concat(response.data || []);
        nextToken = response.nextToken;
        hasNextPage = !!nextToken; // Continue if there is a nextToken
      } catch (error) {
        console.error(`Error fetching assessments for ${userId}: `, error);
        hasNextPage = false;
      }
    }
  
    return assessments;
  };
  
  const fetchAllMemberAssessments = async () => {
    const userIds = clientData?.map((member) => member.user_id);
  
    // Batch user IDs into smaller chunks to avoid overwhelming the server
    const chunkSize = 5; // Adjust this based on API/server limits
    const userIdChunks = [];
    for (let i = 0; i < userIds.length; i += chunkSize) {
      userIdChunks.push(userIds.slice(i, i + chunkSize));
    }
  
    const memberAssessments = [];
    for (const chunk of userIdChunks) {
      const chunkPromises = chunk.map((memberId) => fetchPaginatedAssessments(memberId));
      const chunkResults = await Promise.all(chunkPromises);
      memberAssessments.push(...chunkResults.flat());
    }
  
    return memberAssessments;
  };
  
  

// Fetch assessments where the user is the coachee with pagination
const fetchUserAssessments = async (userId) => {
  let hasNextPage = true;
  let nextToken = null;
  let assessments = [];

  while (hasNextPage) {
    try {
      const response = await resourceClient.models.assessments.list({
        filter: { user_id: { eq: userId } },
        limit: 300,
        nextToken, // Pass the token for pagination
      });
      assessments = assessments.concat(response.data || []);
      nextToken = response.nextToken;
      hasNextPage = !!nextToken; // Continue if there is a nextToken
    } catch (error) {
      console.error(`Error fetching user assessments for ${userId}: `, error);
      hasNextPage = false;
    }
  }

  return assessments;
};

// Fetch assessments where the user is the coach with pagination
const fetchCoachAssessments = async (userId) => {
  let hasNextPage = true;
  let nextToken = null;
  let assessments = [];

  while (hasNextPage) {
    try {
      const response = await resourceClient.models.assessments.list({
        filter: { coach_id: { eq: userId } },
        limit: 300,
        nextToken, // Pass the token for pagination
      });
      assessments = assessments.concat(response.data || []);
      nextToken = response.nextToken;
      hasNextPage = !!nextToken; // Continue if there is a nextToken
    } catch (error) {
      console.error(`Error fetching coach assessments for ${userId}: `, error);
      hasNextPage = false;
    }
  }

  return assessments;
};

  const mergeAndSortAssessments = (coacheeAssessments, coachAssessments, userId) => {
    const assessmentsMap = new Map();
  
    // Iterate over all assessments and deduplicate by `assessment.id`
    [...coacheeAssessments, ...coachAssessments].forEach((assessment) => {
      const key = assessment.id; // Use `assessment.id` for deduplication
      if (!assessmentsMap.has(key)) {
        assessmentsMap.set(key, assessment);
      } else {
        const existing = assessmentsMap.get(key);
        const newUpdatedAt = new Date(assessment.updatedAt || assessment.createdAt);
        const existingUpdatedAt = new Date(existing.updatedAt || existing.createdAt);
        if (newUpdatedAt > existingUpdatedAt) {
          assessmentsMap.set(key, assessment); // Keep the latest assessment
        }
      }
    });
  
    // Convert the map values to an array and sort
    return Array.from(assessmentsMap.values()).sort((a, b) => {
      // Prioritize assessments by the current user
      if (a.user_id === userId && b.user_id !== userId) return -1;
      if (b.user_id === userId && a.user_id !== userId) return 1;
  
      // Sort by most recent `updatedAt` or `createdAt`
      return new Date(b.updatedAt || b.createdAt) - new Date(a.updatedAt || a.createdAt);
    });
  };
  
  

  const fetchUserNames = async (assessments, userId) => {
    const uniqueUserIds = [...new Set([...assessments.map((a) => a.user_id), userId])];
  
    const { data: userNames } = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-name-by-user-id`,
      { userIds: uniqueUserIds }
    );
  
    // Fetch avatars only if necessary
    const userNamesWithUrls = await Promise.all(
      userNames.map(async (user) => {
        if (user.avatar) {
          user.avatarUrl = await fetchAvatarUrl(user.avatar);
        }
        return user;
      })
    );
  
    return userNamesWithUrls.reduce((acc, user) => {
      acc[user.user_id] = { name: user.name, avatar: user.avatarUrl };
      return acc;
    }, {});
  };
  

  // Attach user names, avatars, and count of completed assessments to each assessment
  const attachUserNamesAndCounts = (assessments, userNamesMap) => {
    // Count the number of completed assessments for each user
    const assessmentsCountMap = assessments.reduce((acc, assessment) => {
      if (assessment.completed) {
        acc[assessment.user_id] = (acc[assessment.user_id] || 0) + 1;
      }
      return acc;
    }, {});

    // Attach the user names, avatars, and completed assessment count to each assessment
    return assessments.map((assessment) => ({
      ...assessment,
      user_name: userNamesMap[assessment.user_id]?.name || "Unknown Coachee",
      user_avatar: userNamesMap[assessment.user_id]?.avatar || null, // Attach avatar
      total_completed_assessments_count:
        assessmentsCountMap[assessment.user_id] || 0,
    }));
  };

// Create options for the user selection dropdown based on filtered assessments data
const assessmentDataOptions = [
  { key: "Name", value: "none" }, // Default option
  ...assessmentsData
    ?.map((assessment) => ({
      key: assessment.user_name || "Unknown", // Display name
      value: assessment.user_id, // Use user_id as the value
    }))
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.value === item.value) === index // Ensure uniqueness by user_id
    )
    .sort((a, b) => a.key.localeCompare(b.key)), // Sort alphabetically by user_name (key)
];


  let teamFilterOptions;
  // Build the team options for the team filter
  teamFilterOptions = [
    { key: "Team", value: "none" }, // Default option
    ...teams?.map((team) => ({
      key: team.team_name,
      value: team.team_id,
    })),
  ];

  useEffect(() => {
    let filteredData = assessmentsData || [];

    console.log("assessment data check: ", filteredData)
  
    // Filter by team if a team is selected
    if (selectedTeamId !== "none" && selectedTeamId !== undefined) {
      const selectedTeam = teams.find(
        (team) => team.team_id === selectedTeamId
      );
      const teamMemberUserIds = selectedTeam
        ? selectedTeam.members.map((member) => member.user_id)
        : [];
      filteredData = filteredData.filter((assessment) =>
        teamMemberUserIds.includes(assessment.user_id)
      );
    }
  
    // Filter by user if a user is selected
    if ( selectedUserId && selectedUserId !== "none" && selectedUserId !== undefined) {
      filteredData = filteredData.filter(
        (assessment) => assessment.user_id === selectedUserId
      );
    }

  
    // Add a unique `key` to each item
    const keyedData = filteredData.map((item, index) => ({
      ...item,
      key: `${item.user_id}-${item.createdAt || index}`,
    }));
  
    setFilteredAssessments(keyedData);
    console.log("Filtered assessments: ", keyedData);
  }, [selectedTeamId, selectedUserId, assessmentsData, teams]);

  useEffect(() => {
    setLoading(clientDataLoading || assessmentDataLoading);
  }, [clientDataLoading, assessmentDataLoading]);  


  // Update this useEffect to check for data loading status
  
  // Update this useEffect to check for data loading status
  useEffect(() => {
    const fetchClientAndAssessmentData = async () => {
      try {
        setLoading(true);
        if (user) {
          setClientDataLoading(true);
          await fetchClientData(); // Fetch client data first
        }
      } catch (error) {
        setClientData([]);
        console.error("Error in client data fetch: ", error);
      } finally {
        setClientDataLoading(false);
      }
    };
  
    fetchClientAndAssessmentData();
  }, [user]);
  

  useEffect(() => {
    const fetchAndSetAssessments = async () => {
      try {
        if (clientData?.length > 0) {
          setAssessmentDataLoading(true);
          await fetchAssessmentData();
        }
      } catch (error) {
        console.error("Error in assessment data fetch: ", error);
        setAssessmentsData([]);
      } finally {
        setAssessmentDataLoading(false);
      }
    };
  
    fetchAndSetAssessments();
  }, [clientData]);
  

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <Frigade.Tour
        flowId="flow_USgvDBYB"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <ClientLimitReached user={user} userData={userData} />
      <AssignAssessmentModal
        user={user}
        userData={userData}
        open={openAssignToStudent}
        clientData={clientData}
        assessment={selectedAssessment}
        fetchData={fetchAssessmentData}
        setOpenAssignToStudent={setOpenAssignToStudent}
      />
      <AssignAssessmentToTeamModal
        user={user}
        userData={userData}
        open={openAssignToTeam}
        assessment={selectedAssessment}
        fetchData={fetchAssessmentData}
        setOpenAssignToTeam={setOpenAssignToTeam}
        teams={teams}
      />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                            }}
                        >
                            Assessments
                        </Typography>
                    </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              borderRadius: "40px",
              padding: "24px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              alignItems: "center",
              gap: { xs: "16px", md: "0px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: { xs: "90%", md: "40%" },
              }}
            >
              <span id="hint-anchor-2">
                <Typography
                  sx={{
                    color: "#8F8F8F",
                    fontSize: "18px",
                    fontFamily: "Lexend",
                    fontWeight: 400,
                  }}
                >
                  The assessments page is your portal into managing the teams
                  and the individuals that you coach. You can assign tests,
                  check the scores, and even compare results!
                </Typography>
              </span>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              color="#EFF3F6"
              sx={{ marginLeft: "30px" }}
            />

            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex", // Use flexbox for alignment
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <Box
                sx={{
                  display: "flex", // Flexbox to center content
                  justifyContent: "center", // Horizontal centering
                  alignItems: "center", // Vertical centering
                  height: "75px", // Set a height to center content vertically (optional, can adjust)
                  width: "70%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "18px",
                      fontFamily: "Lexend",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    {selectedAssessment
                      ? selectedAssessment === "The Big Five Personality Test"
                        ? "The OCEAN Personality Assessment"
                        : "Assessment"
                      : ""
                    }
                  </Typography>
                  {/* <FormControl sx={{ width: 150 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedAssessment ?? ""}
                                            onChange={handleChange}
                                            open={open}              // Controls the open state of Select
                                            onOpen={handleOpen}       // Set open to true
                                            onClose={handleClose}     // Set open to false
                                            displayEmpty
                                            autoWidth
                                            renderValue={(selected) => {
                                                return selected ? "Change" : "Select";
                                            }}
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: 'white',
                                                            color: "black",
                                                        }
                                                    }
                                                }
                                            }}
                                            IconComponent={(props) => (
                                                <Box
                                                    onClick={open ? handleClose : handleOpen}  // Handle open/close on icon click
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {open ? <KeyboardArrowUpIcon sx={{ color: "black" }} /> : <KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                                </Box>
                                            )}
                                            sx={{
                                                color: "black", // Text color
                                                height: "32px", // Adjust height here
                                                padding: "0 6px", // Adjust padding
                                                fontSize: "14px", // Optional: Adjust font size to match height
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Border color
                                                    borderRadius: "40px", // Custom border radius
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Hover border color
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Focused border color
                                                },
                                            }}
                                        >
                                            <MenuItem value="The Big Five Personality Test">The Big Five Personality Test</MenuItem>
                                        </Select>
                                    </FormControl> */}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              color="#EFF3F6"
              sx={{ marginLeft: "0px" }}
            />

            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex",
                flexDirection: "column",
                gap: { xs: "12px", md: "8px" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  width: "60%",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    padding: "10px",
                    color: "black",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={selectedAssessment === "" || !selectedAssessment}
                  onClick={() =>
                    navigate(
                      `/platform/coach/assessments/${selectedAssessment}/${user.sub}/-1/${user.sub}`
                    )
                  }
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                    data-cy="take-test-button"
                  >
                    <span id="hint-anchor-3">Take Test</span>
                  </Typography>
                </Button>
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    padding: "10px",
                    color: "black",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={
                    !selectedAssessment ||
                    !user?.sub ||
                    filteredAssessments.length === 0 // Use combined filtered data
                  }
                  onClick={() => {
                    setSelectedUserId(user.sub);

                    const firstCompletedAssessment = filteredAssessments.find(
                      (assessment) => assessment.completed
                    );

                    if (firstCompletedAssessment) {
                      setViewAssessment(firstCompletedAssessment);
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    <span id="hint-anchor-4">My Results</span>
                  </Typography>
                </Button>
              </Box>
              {(user?.role === "OWNER" ||
                user?.role === "SUPERADMINS" ||
                user?.role === "ADMINS") &&
                userData?.accessTo === 'platform' && (
                <Button
                  sx={{
                    background:
                      "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                    padding: "10px",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                  }}
                  disabled={
                    user?.role === "SUPERADMINS"
                      ? userData?.teamsInformation?.length === 0
                      : userData?.myTeamsInformation?.members?.length === 0
                  }
                  onClick={() => {
                    handleOpenAssignToTeamModal();
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "white",
                      textTransform: "none",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    {user?.role === "SUPERADMINS" || user?.role === "OWNER"
                      ? "Assign to a Team"
                      : "Assign to my Team"}
                  </Typography>
                </Button>
              )}
              {userData?.companyInformation && user?.role !== "PRIVATE" && userData?.accessTo === 'platform' && (
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    color: "black",
                    padding: "10px",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={selectedAssessment === "" || !selectedAssessment}
                  onClick={handleOpenAssignToStudentModal}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "none",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    Assign to a Member
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "8px",
              width: "100%",
              height: "100%",
              flexGrow: 1,
              overflow: "hidden"
            }}
          >
            <Box
              sx={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "50%" },
                borderRadius: "40px",
                padding: "24px",
                gap: "20px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "28px",
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    wordWrap: "break-word",
                  }}
                >
                  <span id="hint-anchor-1">Individuals</span>
                </Typography>
                {/* INSERT FILTER HERE */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {userData?.companyInformation && user?.role !== "PRIVATE" && (
                    <_TeamAssessmentsFilter
                      value={selectedTeamId}
                      handleChange={setSelectedTeamId}
                      items={teamFilterOptions}
                    />
                  )}

                  <_IndividualAssessmentsFilter
                    value={selectedUserId}
                    handleChange={setSelectedUserId} // Update selectedUserId state when filter changes
                    items={assessmentDataOptions} // Use combined data options for filtering
                  />
                </Box>
              </Box>
              {
                loading ? (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : filteredAssessments.length > 0 ? (
                  <Box
                    sx={{
                      height: "100%",
                      flexGrow: 1, // Ensures the table grows and fills available height
                      overflow: "hidden", // Prevents content overflow outside the box
                    }}
                  >
                    <AssessmentsTable
                      user={user}
                      individualData={filteredAssessments}
                      loading={loading}
                      setViewAssessment={setViewAssessment}
                      viewAssessment={viewAssessment}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        width: "60%",
                      }}
                    >
                      No assessments to display.
                      <br/>
                      {(user?.role === 'OWNER' || user?.role === 'SUPERADMINS' || user?.role === 'ADMINS') && (userData?.accessTo === 'platform') ?
                      ' Please assign an assessment to a member using the button on the top section.' : ' Please take the test using the button on the top section.'}
                    </Typography>
                  </Box>
                )
              }
            </Box>
            <Box
              sx={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "50%" },
                borderRadius: "40px",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "28px",
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    wordWrap: "break-word",
                  }}
                >
                  Results
                </Typography>
                {viewAssessment && viewAssessment.id && (
                  <Button
                    sx={{
                      background: "white",
                      border: "1px solid black",
                      color: "black",
                      padding: "10px",
                      borderRadius: "50px",
                      "&:disabled": {
                        background: "#d3d3d3",
                        color: "#B7B7B7",
                        border: "none",
                        cursor: "not-allowed",
                      },
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/platform/coach/assessments/view/individual-breakdown/${viewAssessment.id}/${viewAssessment.user_id}`
                      )
                    }
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "500",
                        textTransform: "none",
                        letterSpacing: "0.05em",
                        lineHeight: "18px",
                      }}
                      data-cy="expand-score-button"
                    >
                      <span id="hint-anchor-5">Expand Score</span>
                    </Typography>
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  <GeneralBigFiveScore assessmentData={viewAssessment} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 18,
                      fontFamily: "Lexend",
                      fontWeight: 400,
                      width: "60%",
                      maxHeight: "230px",
                      overflowY: "auto",
                    }}
                  >
                    {viewAssessment && viewAssessment.id
                      ? ""
                      : "The chart above will showcase your score’s overview, after you complete a test or selecting one from the list."}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default CoachAssessments;

const tabStyle = {
  color: "black",
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 1.5,
  fontFamily: "Lexend",
  borderBottom: "1px solid #E8E8E8",
  "&.Mui-selected": {
    borderBottom: "1px solid #000",
  },
};
