import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import UsersTable from "components/Table/CustomTable";
import AdminAddMemberModal from "./AddMembers/AdminAddMemberModal";
import ToolBar from "./ToolBar/ToolBar";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import { generateClient } from "aws-amplify/data";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import VcAddUserModal from "./VcAddMembers/VcAddUserModal";
import { GreenStatusPill, OrangeStatusPill } from "components/Table/StatusPills";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const VcAdmin = ({ user, userData }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [myTeamsInformation, setMyTeamsInformation] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false); // Modal state
  const [availableSeats, setAvailableSeats] = useState("");
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
          },
        }
      );
      setCompanyMembers(response.members);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyTeamsInformation(response.myTeamsAdmin);

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: response?.companyInformation?.owner_user_id,
        });

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: (
        <span id="hint-anchor-2" style={{ color: "inherit" }}>
          Full Name
        </span>
      ),
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value}
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              textTransform: "none"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        const roleMap = {
          owner: "Owner",
          super_admin: "Super Admin",
          admin: "Admin",
          user: "User",
          private: "Private User",
        };

        return (
          <Tooltip title={params.value || ""}>
            {roleMap[params.value] || "No role"}
          </Tooltip>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value || ""}
        </Tooltip>
      ),
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value === "accepted" ? (
            <GreenStatusPill text={params.value} />
          ) : params.value === "pending" || params.value === "expired" ? (
            <OrangeStatusPill text={params.value} />
          ) : null}
        </Tooltip>
      ),
    },
  ];

  const rows = companyMembers.map((member, index) => {
    // Handle potential null/undefined for teams and map over it


    return {
      id: index,
      user_id: member.user_id || "", // Handle potential null/undefined user_id
      full_name: member.full_name || "", // Handle potential null/undefined full_name
      email: member.email || "", // Handle potential null/undefined email
      role: member.role || "No role", // Handle potential null/undefined role
      title: member.company_role_name || "No Title", // Handle multiple titles and join them with commas
      invite_status: member.invite_status || "", // Handle potential null/undefined invite_status
    };
  });

  const handleOpenAddUserModal = () => setOpenAddUserModal(true);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_qVx6zuSX"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1"> Teams & Members</span>
          </Typography> */}
          <Box
            id="hint-anchor-1"
          >
          <ToolBar
            user={user}
            userData={userData}
            selected={selected}
            fetchCompanyData={fetchCompanyData}
            companyInformation={companyInformation}
          />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                padding: "12px",
                background: "#F1F1F1",
              }}
            >
              <span id="hint-anchor-3">{`${availableSeats} seats are open`}</span>
            </Typography>
            <Button
              onClick={handleOpenAddUserModal}
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                border: "1px black solid",
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
            >
              <span id="hint-anchor-4">Add Users</span>
            </Button>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 25, 30]}
            checkboxSelection={false}
            loading={loading}
            onSelectionModelChange={(newSelection) => setSelected(newSelection)}
            disableColumnFilter
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
          />
        </Box>
      </Box>
      <VcAddUserModal
        user={user}
        userData={userData}
        openAddUserModal={openAddUserModal}
        setOpenAddUserModal={setOpenAddUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        myTeamsInformation={myTeamsInformation}
        fetchCompanyData={fetchCompanyData}
      />
    </Box>
  );
};

export default VcAdmin;
