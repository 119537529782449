import { Button, Typography } from "@mui/material";
import { useSignOut } from "hooks/useSignOut";

const SignOutButton = ({}) => {

    const handleSignOut = useSignOut();

    return(
        <Button
            id="sign-out-button"
            sx={{
                color: "black",
                background: 'white',
                border: "1px solid black",
                borderRadius: 52,
                width: "100%",
                padding: "10px 8px",
            }}
            onClick={async () => {
              try{
                handleSignOut();
              } catch (error) {
                console.error("Error signing out: ", error);
              }
            }}
        >
            <Typography
                sx={{
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 2,
                }}

            >
                Sign Out
            </Typography>
        </Button>
    )
}

export default SignOutButton;