import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import aclogo from "components/Icons/AcLogo.svg";
import Logo from "scenes/Layout/logo";

const paragraphStyle = { lineHeight: "1.5", marginBottom: "10px" };
const listItemStyle = { lineHeight: "1.5", fontSize: "1rem" };

const Terms = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${aclogo})`,
        backgroundSize: "35%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: " left",
        backgroundColor: "white",
        // overflowX: "hidden",
      }}
    >
      <Logo />
      <Box
        style={{
          backgroundColor: "#F7F7F7",
          color: "black",
          paddingBottom: "50px",
        }}
      >
        <Box
          margin={{
            xs: "0px 30px",
            lg: "0px 100px",
          }}
          paddingTop="50px"
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "24px", md: "40px" }}
            sx={{ textAlign: "center", padding: "20px" }}
          >
            ALTERCALL AI USER AGREEMENT
          </Typography>
          <Typography variant="h5" sx={paragraphStyle}>
            Permission to use and access any Software, Services, Intellectual
            Property, and Web Content associated with AlterCall, LLC’s services
            (hereinafter, the “Program(s)”) is conditional upon You, the
            participant, agreeing to the terms set out below. The use and access
            of the Program(s) is only offered to you on condition that you read
            and accept all the terms of this agreement (hereinafter, “the
            Agreement”) and wish to become a Participant to use and access the
            Program(s). Acceptance will bind you and all your employees to the
            terms of the Agreement.
          </Typography>

          <Typography variant="h5" sx={paragraphStyle}>
            By clicking “I Accept” at the end of these terms and conditions, you
            will be deemed to have accepted the following terms. If you do not
            wish to accept the terms, you must not click “I Accept” and you may
            not use or access any of the Program(s).
          </Typography>

          <Typography
            variant="h5"
            sx={{ ...paragraphStyle, marginBottom: "30px" }}
          >
            NOW, THEREFORE, the Parties agree as follows:
          </Typography>

          <Typography variant="h5" sx={paragraphStyle}>
            1. DEFINITIONS
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            1.1 Definitions. Capitalized terms in this Agreement will have the
            following meanings:
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Agreement” means this License Agreement between AlterCall, LLC and
            You.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “AlterCall, LLC” means AlterCall, LLC and its
            affiliates/contributors.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “You” and “Participant” mean you, the person or company, who is
            being granted use of and/or access to the Program(s) and its
            associated content.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “The Parties” means both AlterCall, LLC and You.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “License Fee” means the fee indicated on the website as the one-off
            License Fee.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Renewal Fee” means the fee indicated on the website as the Annual
            Renewal Fee.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Intellectual Property” means any picture, video, music, written
            text, software, design, layout, trademark, copyright, patent, color,
            or any other proprietary property of AlterCall, LLC.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Name, image, and likeness rights” refers to any proprietary
            right(s) of an individual to restrict the use of their identity or
            identifiable characteristics for commercial purposes.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Software” means any software or application owned, hosted by,
            developed by or for AlterCall, LLC.
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            “Services” means the services provided by AlterCall, LLC.
          </Typography>
          <Typography
            variant="h5"
            sx={{ ...listItemStyle, marginBottom: "30px" }}
          >
            “Program(s)” means any of the services and associated content
            provided by AlterCall, LLC.
          </Typography>

          <Typography variant="h5" sx={paragraphStyle}>
            2. LIMITATIONS OF USE AND RENEWAL
          </Typography>
          <Typography variant="h5" sx={listItemStyle}>
            2.1 Pursuant to this Agreement, You, as a participant in the
            Program(s), acquire a non-exclusive right to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="a) use and access the Program(s) on the terms and conditions set out in this Agreement;"
                sx={listItemStyle}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="b) use and access the Program(s) strictly in accordance with the provisions of this Agreement."
                sx={listItemStyle}
              />
            </ListItem>
          </List>
          <Typography variant="h5" sx={listItemStyle}>
            2.2 You may not make or attempt to make any back-up copy or copy of
            the Program(s) and/or its associated content.
          </Typography>
          <Typography
            variant="h5"
            sx={{ ...listItemStyle, marginBottom: "30px" }}
          >
            2.3 You may apply for a renewal of your participation in the
            Program(s) during or after the term of this agreement according to
            the following procedure . . . .
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            3. PARTICIPANT OBLIGATIONS{" "}
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            You, as a Participant, hereby undertake the following obligations:
          </Typography>

          <List>
            <ListItem sx={listItemStyle}>
              a) to not copy, reproduce, translate, adapt, vary, or modify the
              Program(s) and/or its associated content without the express
              written consent of AlterCall, LLC;
            </ListItem>
            <ListItem sx={listItemStyle}>
              b) to not modify, adapt, translate, or reverse engineer any
              portion of the Program(s);
            </ListItem>
            <ListItem sx={listItemStyle}>
              c) to supervise and control the use of and access to the
              Program(s) in accordance with the terms of this Agreement;
            </ListItem>
            <ListItem sx={listItemStyle}>
              d) to ensure your employees, subcontractors and other agents who
              have authorized access to the Program(s) are made aware of the
              terms of this Agreement;
            </ListItem>
            <ListItem sx={listItemStyle}>
              e) to not provide or otherwise make available the Program(s) in
              any form to any person other than those referred to in paragraph
              (d) without the written consent of AlterCall, LLC;
            </ListItem>
            <ListItem sx={listItemStyle}>
              f) to not use any of the Program(s) and/or its associated content
              for any purpose that is unlawful or prohibited by this Agreement,
              its terms and conditions or any other law or statute;
            </ListItem>
            <ListItem sx={listItemStyle}>
              g) to not use any of the Program(s) and/or its associated content
              in any manner that could damage, disable, overburden, or impair
              AlterCall, LLC, or the network(s) connected to any AlterCall, LLC
              or its affiliates/contributors’ server(s), or interfere with any
              other party’s use and enjoyment of the Program(s) and/or its
              associated content;
            </ListItem>
            <ListItem sx={listItemStyle}>
              h) to not attempt to gain unauthorized access to any computer
              systems or networks connected to any of the Program(s) and/or its
              associated content or any server used by the Program(s) through
              any unauthorized means;
            </ListItem>
            <ListItem sx={listItemStyle}>
              i) to not attempt obtain any materials or information through any
              means not intentionally made available through the Program(s)
              and/or its associated content;
            </ListItem>
            <ListItem sx={listItemStyle}>
              j) to not use the Program(s) to defame, abuse, harass, stalk,
              threaten or otherwise violate the legal rights (such as rights of
              privacy and publicity) of others;
            </ListItem>
            <ListItem sx={listItemStyle}>
              k) to not use the Program(s) to publish, post, upload, distribute
              or disseminate any inappropriate, profane, defamatory, obscene,
              indecent, or unlawful topic, name, material, or information;
            </ListItem>
            <ListItem sx={listItemStyle}>
              l) to not upload or incorporate into the Program(s) and/or its
              associated content in any way, any malicious software or code;
            </ListItem>
            <ListItem sx={listItemStyle}>
              m) to not create any false identity or use the Program(s) to
              harvest personal information of any other user of the Program(s)
              for any purpose;
            </ListItem>
            <ListItem sx={listItemStyle}>
              n) if You are given or choose a password and username for an
              account, You are entirely responsible for maintaining the
              confidentiality of any password and username;
            </ListItem>
            <ListItem sx={listItemStyle}>
              o) You agree to notify AlterCall, LLC immediately of any
              unauthorized use of your account or any other breach of security;
            </ListItem>
            <ListItem sx={listItemStyle}>
              p) You agree AlterCall, LLC will not be liable for any loss that
              You may incur as a result of someone else using your password or
              account, either with or without your knowledge;
            </ListItem>
            <ListItem sx={listItemStyle}>
              q) if You are under 18 years of age, then You must obtain your
              parent or guardian’s consent before accessing and using or
              accessing the Program(s) and/or its associated content; and
            </ListItem>
            <ListItem sx={listItemStyle}>
              r) You are responsible for any costs associated with your access
              to or use of the Program(s) and/or its associated content,
              including Internet access fees.
            </ListItem>
          </List>

          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            4. DAMAGE WAIVER AND WARRANTY
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            4.1 IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY
            HEREUNDER FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, INCIDENTAL,
            INDIRECT, SPECIAL OR OTHER SIMILAR DAMAGES, EVEN IF SUCH PARTY HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES
            OCCUR AS A RESULT OF THE NEGLIGENCE OF SUCH PARTY.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            4.2 IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR
            EXEMPLARY OR PUNITIVE DAMAGES.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            4.3 NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
            ALTERCALL, LLC’S LIABILITY TO YOU FOR ANY CAUSE OF ACTION ARISING
            OUT OF THIS AGREEMENT OR RESULTING FROM YOUR PARTICIPATION IN THE
            PROGRAM, AND REGARDLESS OF ANY NEGLIGENCE BY ALTERCALL, LLC OR ITS
            AFFILIATES, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
            BY YOU TO ALTERCALL, LLC FOR THE SERVICES.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            4.4 Nothing in this Agreement excludes, restricts, or modifies any
            condition, warranty, right or liability implied in this Agreement or
            protected by law to the extent that such exclusion, restriction, or
            modification would render this Agreement or any provision of this
            Agreement void, illegal or unenforceable. Subject to that, any
            condition, warranty, right or liability which would otherwise be
            implied in this Agreement or protected by law is excluded.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            4.5 You, as a Participant, hereby acknowledge that:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              a) prior to entering into this Agreement, You have been given a
              reasonable opportunity to examine and satisfy yourself regarding
              all goods and services which are the subject of this Agreement and
              that prior to entering into this Agreement You have availed
              yourself of that opportunity;
            </ListItem>
            <ListItem sx={listItemStyle}>
              b) at no time prior to entering into this Agreement have You
              relied on the skill or judgment of AlterCall, LLC and that it
              would be unreasonable for You to rely on any such skill or
              judgment for the purpose of evaluating the terms and conditions of
              this Agreement presented herein.
            </ListItem>
          </List>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            5. INTELLECTUAL PROPERTY (IP) LICENSE AND RELEASE
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            5.1 As a part of your participation in any Program(s) provided by
            AlterCall, LLC subject to the terms of this agreement, You hereby
            irrevocably authorize AlterCall, LLC and its affiliates/contributors
            to record, collect, or otherwise capture and store indefinitely any
            and all communications provided or otherwise produced during the
            course of the Program(s) including but not limited to:
            teleconference calls; video conferencing calls; transcriptions;
            emails; chat sessions; and/or SMS messages (hereinafter, “recorded
            materials”). The recorded materials may be stored in their original
            form or in modified form, including de-identified form or form where
            personally identifiable information (PII) has been scrubbed or
            otherwise removed, in perpetuity by AlterCall, LLC and its
            affiliates/contributors in accordance with any data privacy and
            security policies as implemented by AlterCall, LLC.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            5.2 ANY RECORDED MATERIALS, AS DESCRIBED ABOVE, INCLUDING TEXT,
            SPEECH, AND AUDIOVISUAL CONTENT, MAY HAVE INTELLECTUAL PROPERTY
            RIGHTS AND/OR NAME, IMAGE, AND LIKENESS RIGHTS OWNED BY THE
            PARTICIPANT. HOWEVER, FOR ALL ASPECTS OF THE PROGRAM(S) YOU
            PARTICIPATE IN FOR WHICH RECORDED MATERIALS ARE PROVIDED OR
            OTHERWISE PRODUCED, YOU GRANT ALTERCALL AND ITS
            AFFILIATES/CONTRIBUTORS AN IRREVOCABLE WORLDWIDE, PERPETUAL,
            ROYALTY-FREE, SUBLICENSABLE, AND TRANSFERABLE LICENSE TO HOST,
            STORE, CACHE, USE, DISPLAY, REPRODUCE, MODIFY, ADAPT, EDIT, PUBLISH,
            ANALYZE, TRANSMIT, AND DISTRIBUTE THAT CONTENT.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            5.3 You, as a Participant, hereby acknowledge and agree to the
            following:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a) I hereby expressly acknowledge that, notwithstanding any
                local laws or regulations to the contrary, my right to have my
                name, image, likeness, and/or personally identifiable
                information (PII), as captured in audio, visual, or audiovisual
                form, removed from the AlterCall, LLC recorded materials as
                defined above is irrevocably waived.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b) I hereby irrevocably waive any right I may have to inspect,
                audit, edit/correct, and/or approve any photographs, audio, or
                video and transcripts thereof, and information pertaining to
                myself within any of the recorded materials as defined above
                irrespective of any local laws or regulations to the contrary.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                c) I hereby expressly acknowledge that the content of any
                recorded materials, as defined above, may include personally
                identifiable information (PII) including but not limited to
                demographic and/or biometric information.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                d) I understand and agree that all recordings produced during my
                participation in the Program(s) are automatically licensed to
                AlterCall, LLC under the terms provided above and I do not ask
                for nor expect compensation for the use of the recordings or
                photographs in which I appear or speak.
              </Typography>
            </ListItem>
          </List>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            6. AI-PRODUCT DEVELOPMENT
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            6.1 The above-described license of Part 5 is, in part, for the
            purpose of operating, developing, providing, promoting, and
            improving AlterCall, LLC and its affiliates/contributors’ services
            and researching and developing new ones including but not limited to
            machine learning and/or artificial intelligence (AI) based products
            and research (hereinafter, “AI-product development”).
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            6.2 THIS MAY INCLUDE BUT IS NOT LIMITED TO USE OF ANY CONTENT
            CONTAINED WITHIN THE RECORDED MATERIALS FOR TRAINING/DEVELOPING
            GENERATIVE-AI PRODUCTS AND TOOLS OR THE USE OF THE CONTENT WITHIN
            THE RECORDED MATERIALS FOR RETRIEVAL-AUGMENTED GENERATION.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            6.3 THIS INCLUDES A RIGHT FOR ALTERCALL, LLC AND ITS
            AFFILIATES/CONTRIBUTORS TO MAKE ANY CONTENT CONTAINED IN THE
            RECORDED MATERIALS AS DESCRIBED ABOVE AVAILABLE TO, AND PASS THESE
            RIGHTS ALONG TO, SERVICE PROVIDERS WITH WHOM ALTERCALL, LLC HAS
            CONTRACTUAL RELATIONSHIPS RELATED TO THE PROVISION OF THEIR
            SERVICES, SOLELY FOR THE PURPOSE OF PROVIDING SUCH SERVICES.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            6.4 You, as a Participant, hereby acknowledge and agree to the
            following:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a) I hereby expressly acknowledge that, notwithstanding any
                local laws or regulations to the contrary, my right to have my
                name, image, likeness, and/or personally identifiable
                information (PII) including demographic and/or biometric data,
                as contained in audio, visual, audiovisual, or any processed
                and/or analyzed form within any of the recorded materials as
                described above, removed from the AlterCall, LLC materials used
                for the purposes of machine learning and/or AI-product
                development is irrevocably waived.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b) I understand that, in the event I terminate or otherwise end
                my participation in any Program(s) subject to this agreement,
                any prior authorization for the use of my name, image, voice,
                personally identifiable information (PII) including biometric
                data, demographic data, and any other data contained in or
                derived from the recorded materials for the purpose of machine
                learning and/or AI-product development is irrevocable.
                ALTERCALL, LLC AND ITS AFFILIATES/CONTRIBUTORS MAY USE ALL
                CONTENT CONTAINED IN THE RECORDED MATERIALS SUBJECT TO THIS
                AGREEMENT IN PERPETUITY FOR THE PURPOSE OF SUCH MACHINE LEARNING
                AND/OR AI-PRODUCT DEVELOPMENT.
              </Typography>
            </ListItem>
          </List>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7. SUBSCRIPTION AND BILLING
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.1 Subscription Fee: The Virtual Coach service is available at a
            monthly subscription fee of $49.00. This fee is subject to change
            with prior notice.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.2 Automatic Renewal of Subscription: Your Virtual Coach
            subscription will automatically renew each month. The renewal charge
            will be the same as the initial subscription fee unless you are
            otherwise notified in advance.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.3 Agreement to Pay: By subscribing to the Virtual Coach service,
            you agree to a monthly payment of the subscription fee. The fee will
            be automatically charged to your chosen payment method each month.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.4 Membership Cancellation: It is the responsibility of the user to
            cancel their membership. You may cancel your subscription at any
            time. However, upon cancellation, you will be charged for the
            current month, and your access to the Virtual Coach service will
            continue until the end of the billing period.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.5 No Refunds: Our policy is to not issue refunds for any
            subscription fees. This includes, but is not limited to, situations
            where you have not used the Virtual Coach service during your
            billing cycle.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.6 Cancellation Process: To cancel your subscription, you must
            follow the prescribed cancellation process as outlined on our
            website [or specify the exact location/method]. This process is
            designed to ensure a clear and documented trail of the cancellation
            request.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.7 Chargebacks and Disputes: In the event of a chargeback or
            payment dispute initiated by you with your financial institution, we
            reserve the right to dispute such actions. If a chargeback is made,
            we may require additional information from you to resolve the
            matter.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.8 Availability: We strive to ensure that the Virtual Coach service
            is available at all times. However, there may be occasions when the
            service will be interrupted for maintenance, upgrades, or emergency
            repairs.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.9 Notification of Downtime: We will make reasonable efforts to
            inform you of any significant interruptions to the service and will
            attempt to schedule maintenance work during low-usage times.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.10 Securing Your Account: You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.11 Reporting Unauthorized Use: Please notify us immediately if you
            become aware of any unauthorized use of your account or any other
            breach of security.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.12 Acceptable Use: You agree to use the Virtual Coach service only
            for lawful purposes and in a way that does not infringe the rights
            of, restrict, or inhibit anyone else's use and enjoyment of the
            service.
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            7.13 Prohibited Behavior: The following are strictly prohibited:
            sharing of accounts, misuse of the service, engaging in fraudulent
            or illegal activities, and any other activities deemed inappropriate
            by AlterCall.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            7.14 Enforcement and Termination: We reserve the right to suspend or
            terminate your access to the Virtual Coach service if you are found
            to be in violation of these terms.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            8. GOVERNING LAW, JURISDICTION, AND VENUE
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            This Agreement shall be construed and enforced in accordance with
            the laws of the State of California, without giving effect to its
            principles of conflicts of laws. The Parties agree that The Parties
            irrevocably consent to the exclusive personal jurisdiction and
            exclusive venue of the federal or state Courts located in Los
            Angeles County, California for all disputes arising out of or
            relating to this Agreement.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            9. EXPRESS AMENDMENT
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            This Agreement may be amended, modified, or supplemented only by a
            written instrument executed by the party against which enforcement
            of the amendment, modification, or supplement is sought.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            10. ENTIRE AGREEMENT
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            This Agreement represents the entire understanding between
            AlterCall, LLC and You relating to the Services and supersedes any
            prior or contemporaneous, conflicting, or additional communications.
            This Agreement may be executed in any number of counterparts, each
            of which shall be deemed an original, but all of which taken
            together shall constitute one instrument.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            11. SEVERABILITY
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            If any provision of this Agreement is held to be illegal, invalid,
            or unenforceable, that provision shall be fully severable, and this
            Agreement shall be construed and enforced as if the illegal,
            invalid, or unenforceable provision were never a part of the
            Agreement; the remaining provisions shall remain in full force and
            effect and shall not be affected by the illegal, invalid, or
            unenforceable provision or by its severance (except to the extent
            the remaining provisions constitute obligations of another party to
            this Agreement corresponding to the unenforceable provision); and in
            lieu of such illegal, invalid, or unenforceable provision, there
            shall be added automatically as part of this Agreement, a provision
            as similar in its terms to the illegal, invalid, or unenforceable
            provision as may be possible and be legal, valid, and enforceable.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            12. INTERPRETATION
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginBottom: "20px" }}
          >
            This Agreement shall be construed according to the fair meaning of
            its language. The rule of construction to the effect that
            ambiguities are to be resolved against the drafting party shall not
            be employed in interpreting this Agreement. Whenever the term
            “including” is used in this Agreement, it shall be interpreted as
            meaning “including, but not limited to” the matter or matters
            thereafter enumerated.
          </Typography>

          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            13. NO WAIVER
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            No waiver of any term of this Agreement shall be deemed a further or
            continuing waiver of such term or any other term, and AlterCall,
            LLC’s failure to assert any right or provision under this Agreement
            shall not constitute a waiver of such right or provision.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default Terms;
