import React from "react";

function SectionDropDownIcon({ color = '#111111' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" fill="none" viewBox="0 0 71 71">
      <circle cx="35.345" cy="35.345" r="34.934" stroke={color} strokeWidth=".822" transform="rotate(90 35.345 35.345)" />
      <path fill={color} d="M36.168 23.836a.822.822 0 0 0-1.644 0h1.644Zm-1.404 23.596a.822.822 0 0 0 1.163 0l5.23-5.23a.822.822 0 1 0-1.161-1.163l-4.65 4.65-4.65-4.65a.822.822 0 1 0-1.163 1.162l5.231 5.231Zm-.24-23.596V46.85h1.644V23.836h-1.644Z" />
    </svg>
  );
}

export default SectionDropDownIcon;
