import { Box, Typography } from '@mui/material';
import React from 'react';

const DefaultResourcesPage = () => {
    return (
        <Box fontFamily={'Poppins'}>
            <Box component="div" gap="15px" height='80vh' width={'100vh'}>

                <Typography variant='h4' sx={{ fontSize: { xs: '24px', md: '32px' } }} fontWeight="300" color="black" style={{ fontFamily: 'Poppins', fontSize: '32px' }} > Resources</Typography>
                <br />

                <Box width={{ xs: '350px', lg: '100%' }}>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '16px' }} >Welcome to the AlterCall AI Resources page! We're here to help you find answers to your questions and ensure you get the most out of your experience with our products.</Typography>
                    <br />
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '16px' }}>On the left sidebar, you'll find links to different sections related to our various offerings. Each section is designed to provide specific information, guides, and videos tailored to the product you are subscribed to.</Typography>
                    <br />
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '16px' }}>We encourage you to explore these sections thoroughly. Whether you need help with subscription upgrades, navigating your Virtual Coach settings, or managing client and coach functionalities, you'll find detailed instructions and solutions here.</Typography>
                    <br />
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '16px' }}>Most of your questions can be answered by exploring these pages. Take your time to review the content and watch the instructional videos provided to enhance your understanding and usage of our products.</Typography>
                    <br />
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '16px' }}> Thank you for choosing AlterCall AI. We are excited to support you on your journey to success!</Typography>
                    <br /> <br /> <br />
                </Box>

            </Box>

        </Box >
    );
};

export default DefaultResourcesPage;