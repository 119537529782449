import { Box, InputAdornment, TextField, Avatar, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const GlobalSearchbar = () => {
  const [picture, setPicture] = useState("");
  const user = useSelector((state) => state.user.user);
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUserData = async () => {
    const pictureUrl = ""
    setPicture(pictureUrl);
  };

  useEffect(() => {
    fetchUserData();
  }, [user?.picture]);

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          marginLeft: "200px",
          padding: "0px 30px",
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          "& .MuiInputBase-root": {
            color: "#A2A5B1",
            background: "#F7F7F7",
            height: "40px",
            borderRadius: "12px",
          },
        }}
      >
        <TextField
          sx={{
            width: {
                md: "455px"
            },
            color: "#A2A5B1",
          }}
          placeholder="Search or type a command"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#A2A5B1" }} />
              </InputAdornment>
            ),
          }}
        />
        
      </Box>
    </>
  );
};

export default GlobalSearchbar;
