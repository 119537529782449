import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ urlInfo, forContentLibrary = false }) => {
    const { url, videoTimeStamp } = urlInfo;
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        const startInSeconds = videoTimeStamp === 'null' ? '0' : convertTimeToSeconds(videoTimeStamp);

        if (video) {
            // Wait for the video to be ready before setting the time
            video.addEventListener('loadedmetadata', () => {
                video.currentTime = startInSeconds;
            });
        }

        return () => {
            if (video) {
                video.removeEventListener('loadedmetadata', () => { });
            }
        };
    }, [videoTimeStamp]);


    // Helper function to convert hh:mm:ss to seconds
    const convertTimeToSeconds = (time) => {
        let newTime = time;
        if (!forContentLibrary && time.length === 4) {
            newTime = `00:0${time}`;
        }
        if (forContentLibrary) {
            const subStr = time.slice(0, -3)
            newTime = `00:${subStr}`;
        }
        const parts = newTime.split(':'); // Split the time string into [hours, minutes, seconds]

        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseInt(parts[2], 10);

        // Convert the time into total seconds
        const startTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
        // console.log('start time in second + time', startTimeInSeconds, time);
        return startTimeInSeconds > 0 ? startTimeInSeconds : 0;
    };

    return (
        <video
            ref={videoRef}
            src={url}
            type="video/mp4"
            controls
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            style={{ width: "100%", height: "250px" }}
            poster="https://ik.imagekit.io/dns5janxf/Frame+1000004745.jpg"
        />
    );
};

export default VideoPlayer;