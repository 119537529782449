import React from "react";

function SettingIcon({ color = "black" }) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3981 5.25817C10.785 3.55262 13.2149 3.55262 13.6018 5.25817L13.6531 5.48428C13.9037 6.58946 15.1719 7.11473 16.1306 6.51052L16.3268 6.3869C17.8063 5.45445 19.5245 7.17264 18.5921 8.6522L18.4684 8.84836C17.8642 9.80709 18.3895 11.0752 19.4947 11.3259L19.7208 11.3772C21.4263 11.7641 21.4263 14.1939 19.7208 14.5808L19.4947 14.6321C18.3895 14.8828 17.8642 16.1509 18.4684 17.1097L18.5921 17.3058C19.5245 18.7854 17.8063 20.5036 16.3268 19.5711L16.1306 19.4475C15.1719 18.8433 13.9037 19.3686 13.6531 20.4737L13.6018 20.6998C13.2149 22.4054 10.785 22.4054 10.3981 20.6998L10.3469 20.4737C10.0962 19.3686 8.82804 18.8433 7.86931 19.4475L7.67315 19.5711C6.19359 20.5036 4.4754 18.7854 5.40785 17.3058L5.53147 17.1097C6.13568 16.1509 5.61041 14.8828 4.50523 14.6321L4.27912 14.5808C2.57357 14.1939 2.57357 11.7641 4.27912 11.3772L4.50523 11.3259C5.61041 11.0752 6.13568 9.80709 5.53147 8.84836L5.40785 8.6522C4.4754 7.17263 6.19358 5.45445 7.67315 6.3869L7.86931 6.51052C8.82804 7.11473 10.0962 6.58946 10.3469 5.48428L10.3981 5.25817Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.9908 12.979C10.9908 12.4267 11.4477 11.979 12 11.979C12.5523 11.979 13.0092 12.4267 13.0092 12.979C13.0092 13.5313 12.5523 13.979 12 13.979C11.4477 13.979 10.9908 13.5313 10.9908 12.979Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SettingIcon;