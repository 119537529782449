import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import { v4 as uuid } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { uploadData } from 'aws-amplify/storage';

const AddNewBlog = () => {
  const [isAllowedToView, setIsAllowedToView] = useState(true);
  const [textEditorValue, setTextEditorValue] = useState("");
  const [blogData, setBlogData] = useState({
    title: "",
    author: "",
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function handleRouteAccess() {
  //     try {
  //       await getCurrentUser();
  //       const user = await fetchUserAttributes();
  //       if (user && user?.email.endsWith("@altercall.com")) {
  //         setIsAllowedToView(true);
  //       } else {
  //         toast.error("Unauthorized !!");
  //         navigate("/");
  //       }
  //     } catch (error) {
  //       toast.error("Unauthorized!!");
  //       navigate("/");
  //     }
  //   }
  //   handleRouteAccess();
  // }, []);

  function handleInputChage(e) {
    setBlogData({
      ...blogData,
      [e.target.id]: e.target.value
    })
  }

  async function handleSaveBlogInDB() {
    const dataToSaveInDB = {
      title: blogData.title,
      author: blogData.author,
      blogHtml: textEditorValue,
      thumbnailUrl: imgUrl
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/blog`, dataToSaveInDB);
      if (response) {
        toast.success('Blog Saved Successfully')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleFileChange = (e) => {
    setUploadedImage(e.target.files[0]);
  }

  const saveImageInS3 = async () => {
    if (!uploadedImage) {
      toast.error('Please Select An Image')
      return;
    }

    const filepath = `blogsThumbnails/${uuid() + uploadedImage.name}`

    try {
      // const result = await Storage.put(filepath, uploadedImage, {
      //     contentType: uploadedImage.type,
      //     level: 'public',
      //     bucket: "acprofilepictures205648-vitucoach"
      //     // bucket: "ac-media-files"
      // })

      const result = await uploadData({
        path: filepath,
        data: uploadedImage,
        options: {
          level: 'public',
          contentType: uploadedImage.type,
          bucket: {
            bucketName: 'amplify-reactadmin-admin--profilepicturesbucket46b-gfsfrqjifprr',
            region: 'us-east-1'
          }
        }
      });
      if (result) {
        toast.success('Successfully Uploaded Image')
      }
      setImgUrl(filepath);


    } catch (error) {
      console.error(error, 'Error while uploading image in S3');
    }
  }


  const modules = {
    toolbar: [

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      // [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <Box color={"black"} width={"100%"}>
      {isAllowedToView && (
        <Box>
          <Typography
            textAlign={"center"}
            fontSize={"30px"}
            mt={"20px"}
            mb={"30px"}
          >
            Add New Blog
          </Typography>

          <Box width={"70%"} mt={"30px"} mx={"auto"}>
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", lg: "row" }}
              gap={"15px"}
            >
              <TextField
                onChange={handleInputChage}
                id="title"
                label="Blog Title"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#000000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#000000",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
              />

              <TextField
                onChange={handleInputChage}
                id="author"
                label="Author Name"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#000000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#000000",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
              />

              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"10px"}
                border={"1px solid grey"}
                p={"10px"}
                borderRadius={"5px"}
                ml={"20px"}
              >
                <Typography fontSize={"10px"}>
                  Upload Thumbnail Image
                </Typography>
                <input
                  type="file"
                  onChange={(event) => handleFileChange(event)}
                  name=""
                  id="thumbnailUpload"
                />
              </Box>
            </Box>

            <ReactQuill
              modules={modules}
              style={{ marginTop: "20px", height: "500px" }}
              theme="snow"
              value={textEditorValue}
              onChange={setTextEditorValue}
            />

            <Box display={"flex"} justifyContent={"end"} mt={"70px"}>
              <Button
                disabled={imgUrl.length > 0}
                onClick={saveImageInS3}
                variant="contained"
              >
                Save Image in S3
              </Button>
            </Box>

            <Box display={"flex"} justifyContent={"end"} mt={"70px"}>
              <Button
                disabled={!imgUrl}
                onClick={handleSaveBlogInDB}
                variant="contained"
              >
                Save Blog In Database
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddNewBlog;
