import Box from '@mui/material/Box';
// import { Header } from "components/GlobalHeader/GlobalHeader";
import { Outlet } from "react-router-dom";
import ResourceSidebar from "components/ResourcePage/ResourceSidebar";
import ResourceChatbot from './ResourceChatbot';


const Resources = ({ user, allowAccessToBot, allowAccessToPlatform }) => {

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "hidden",
        color: "black",
      }}
    >
      {/* <Header
        user={user}
        allowAccessToBot={allowAccessToBot}
        allowAccessToPlatform={allowAccessToPlatform}
        hasRightSidebar={false}
        hideGlobalSearchbar={true}
      /> */}

      <Box

        sx={{
          height: "100%",
          width: "92%",
          display: "flex",
          flexDirection: { xs: 'column', lg: 'row' },
          marginX: 'auto'
        }}>

        {/* Sidebar */}

        <ResourceSidebar />

        <Outlet></Outlet>


      </Box>

      <ResourceChatbot />

    </Box >


  );
};

export default Resources;



