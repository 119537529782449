import { signIn as amplifySignIn } from "aws-amplify/auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const signInUser = createAsyncThunk(
  "user/signIn",
  async ({ username, password }) => {
    console.log("username: ", username);
    console.log("password: ", password);
    const response = await amplifySignIn({username: username, password: password});
    // localStorage.setItem(
    //   "authRole",
    //   response.attributes["custom:is_coachee"] == "true" ? 2 : 1
    // );
    console.log("response from sign in: ", response)
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: { user: null, status: "idle", error: null, picture: "" },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
