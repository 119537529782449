import React, { useState } from "react";

const PlatformFAQ = () => {
  const { Remove, Add } = require("@mui/icons-material");
  const {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
  } = require("@mui/material");
  const [expanded, setExpanded] = useState({});
  const handleFaqChange = (panel) => (event, isExpanded) => {
    setExpanded({
      [panel]: isExpanded,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "56px",
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: "300",
          color: "black",
          marginBottom: "-50px",
          // marginTop: "-30px",
          paddingBottom: "15px",  
        }}
      >
        F.A.Q.
      </Typography>
      <Box>
        <Accordion
          expanded={expanded["panel1"] === true}
          onChange={handleFaqChange("panel1")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel1"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Actions?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Actions represent the actions you and your Coachee have agreed to
            complete in order to move on to the Next Steps in the coaching
            journey.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel2"] === true}
          onChange={handleFaqChange("panel2")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel2"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I review a call?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Dashboard, navigate to Session List. Select the call you
            wish to view by clicking the checkbox and then selecting Generate
            Insights to view the call. Insights require 1-3 minutes to generate.
            The moment insights generation is complete, click on the call to
            review.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel3"] === true}
          onChange={handleFaqChange("panel3")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel3"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I share calls?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Dashboard, you first need to ensure a call has insights
            generated. Once insights are available, you may select the calls you
            wish to share and click on Share. You must have active coachees in
            order to share calls.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel4"] === true}
          onChange={handleFaqChange("panel4")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel4"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The LARA framework stands for Listen, Affirm, Respond, and Ask
            Questions. It is designed to facilitate empathetic engagement and
            conflict resolution by inviting diverse perspectives to create
            shared meaning.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel5"] === true}
          onChange={handleFaqChange("panel5")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel5"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does AlterCall AI use the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AlterCall AI (AAI) leverages AI technology to assess verbal features
            based on the LARA framework, ensuring precise evaluation and
            feedback to enhance each coaching session.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel6"] === true}
          onChange={handleFaqChange("panel6")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel6"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are the components of the LARA score and how is it calculated?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The LARA score includes four sub-scores:
            <br />
            <br />
            <b>Listen:</b> Evaluates the ability to listen without interrupting
            and show understanding.
            <br />
            <b>Affirm:</b> Measures how well the user acknowledges and validates
            the coach’s input.
            <br />
            <b>Respond:</b> Assesses the ability to provide thoughtful and
            respectful reactions.
            <br />
            <b>Ask Questions:</b> Gauges the quality and relevance of the
            user’s questions.
            <br />
            <br />
            These sub-scores are averaged to produce an overall LARA score,
            providing a comprehensive overview of the session’s effectiveness.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel7"] === true}
          onChange={handleFaqChange("panel7")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel7"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does AAI generate and display the LARA score?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AAI uses Large Language Models (LLMs) to analyze transcripts and
            audio of coaching sessions, generating detailed scores and feedback
            based on verbal and non-verbal cues. Both the Coachee and Coach
            scores are displayed in a user-friendly interface with visual aids
            like graphs.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel8"] === true}
          onChange={handleFaqChange("panel8")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel8"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can users improve their LARA scores?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AAI offers specific, actionable suggestions using cited instances
            from coaching calls to enhance communication skills, such as:
            <br />
            <br />
            <b>Avoiding interruptions and summarizing the coach's points.</b>
            <br />
            <b>Reflecting deeper on how feedback applies to personal
            situations.</b>
            <br />
            <b>Providing more thoughtful responses.</b>
            <br />
            <b>Asking insightful, open-ended questions.</b>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel9"] === true}
          onChange={handleFaqChange("panel9")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel9"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does the LARA framework benefit coaches?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Coaches can continuously refine their communication skills using the
            detailed feedback provided by the LARA framework, helping them
            become more empathetic and effective.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel10"] === true}
          onChange={handleFaqChange("panel10")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel10"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does the LARA framework benefit coachees?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Coachees benefit from structured and supportive coaching sessions
            that provide clear, actionable advice. It helps them improve their
            communication skills and achieve their goals through empathetic
            engagement and constructive feedback.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel11"] === true}
          onChange={handleFaqChange("panel11")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel11"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the role of verbal features assessment in the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Using LLMs, AAI measures verbal signs of active listening and other
            communication skills based on the LARA framework, ensuring that each
            coaching session is aligned with best practices.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel12"] === true}
          onChange={handleFaqChange("panel12")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel12"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I add a Coachee?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Leadership Center, at the top right click on ADD A COACHEE and
            fill out your Coachee's information.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel13"] === true}
          onChange={handleFaqChange("panel13")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel13"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I disable a Coachee and regain a license?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Leadership Center, click on your Coachee's profile card, select
            the 3 ellipses menu then select Disable.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel14"] === true}
          onChange={handleFaqChange("panel14")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel14"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I edit my Coachee's information?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Leadership Center, click on your Coachee's profile card, navigate
            to coachee info, and then click on the pencil icon to change and save
            the updated information.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel15"] === true}
          onChange={handleFaqChange("panel15")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel15"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the Leadership Center Activity Feed?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Your Leadership Center Activity Feed displays the most recent coaching
            calls and actions across your coaching practice for all your
            Coachees.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel16"] === true}
          onChange={handleFaqChange("panel16")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel16"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I review and add to my Coachee's Goals?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            In your Leadership Center, click on your Coachee's profile card. Bottom
            right module of the profile, you will see a GOALS tab. You can click
            ADD A GOAL, your Coachee will automatically receive the updated goals
            in their account as well.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel17"] === true}
          onChange={handleFaqChange("panel17")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel17"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the Sessions tab on my Coachee's profile?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The sessions tab contains extracted and summarized information.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel18"] === true}
          onChange={handleFaqChange("panel18")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel18"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I get the most out of Virtual Coach?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The Virtual Coach Guide is a comprehensive manual designed to help
            you maximize the benefits of Virtual Coach. It includes detailed
            instructions and a variety of base prompts that you can customize to
            meet your specific needs. We recommend downloading the Virtual
            Coach user guide. To access the guide, please use the menu sidebar
            located on the left, navigate to the Virtual Coach section and click
            on DOWNLOAD USER GUIDE located at the top right corner.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel19"] === true}
          onChange={handleFaqChange("panel19")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel19"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Virtual Coach threads?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Virtual Coach Threads represent conversations. This is helpful when
            keeping interactions with Virtual Coach organized. We recommend
            starting a new thread when changing conversation topics and
            revisiting previous threads to continue conversations related to a
            thread's topic.
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default PlatformFAQ;
