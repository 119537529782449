/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Footer } from "scenes/login/Footer";
import { Header } from "components/GlobalHeader/GlobalHeader";
import { useSelector } from "react-redux";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import ClientLimitReached from "components/ClientLimitReachedModal";
import RetuneIframe from "components/Retune/VirtualCoachIframe";
import * as Frigade from "@frigade/react";

const CoachVirtualCoachPage = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);

  return (
    <div>
      {user ? (
        <GlobalPageComponent user={user} userData={userData}>
          <ClientLimitReached user={user} userData={userData} />


          <Box
            sx={{
              backgroundColor: "#EFF3F6",
              width: "100%",
              height: {
                xs: "90vh",
                sm: "90vh",
                md: "99vh",
              },
              // border: '1px solid green',
              padding: {
                xs: "0px 0px",
                sm: "0px 0px",
                md: "16px",
              },
              display: {
                xs: !collapsed ? "none" : "block",
              },
            }}
          >
            <RetuneIframe user={user} userData={userData} />
          </Box>
        </GlobalPageComponent>
      ) : (
        <Header typeOfNav="virtualcoach" hideGlobalSearchbar={true} />
      )}

      {!user && <Footer />}
    </div>
  );
};

export default CoachVirtualCoachPage;
