import React, { useState, useRef } from "react";

// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DateIcons from "./Icons/DateIcon.svg";
import EndDateIcon from "./Icons/EndDateIcon.svg";
dayjs.extend(utc);

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "black",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "8px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "20px",
    },
    "& .MuiSelect-icon": {
      top: 0,
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  select: {
    fontFamily: "Lexend",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "-0.02",
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
    },
  },
  selectIcon: {
    position: "relative",
    color: "black",
    fontSize: "16px",
  },
  paper: {
    borderRadius: 8,
    maxWidth: "unset",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontFamily: "Lexend",
      fontWeight: 500,
      padding: 12,
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "-0.02",
      background: "white !important",
      color: "black",
    },
    "& li:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#F5F6F4 !important",
    },
    "& li.Mui-selected:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
  },
  checkbox: {
    color: "#A0A0A0",
    "&.Mui-checked": {
      color: "#294F4A",
    },
  },
  list_item_text: {
    fontFamily: "Lexend !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "1.5 !important",
    letterSpacing: "-0.02 !important",
    color: "black",
  },
}));

export const ActionListFilterSelect = ({ value, handleChange, items }) => {
  const [opened, setOpened] = useState(false);

  // Check if all items are invalid
  const allItemsInvalid =
    Array.isArray(items) &&
    items.every(
      (item) =>
        !item.key || item.key === "none" || !item.value || item.value === "none"
    );

  const disabled = allItemsInvalid; // Disable the dropdown if all items are invalid

  return (
    <Box
      sx={{
        padding: "8px 6px",
        position: "relative",
        borderWidth: opened ? "1px 1px 0" : "1px",
        borderColor: "#D5D7DA",
        borderStyle: "solid",
        borderRadius: opened ? "8px 8px 0 0" : "8px",
        backgroundColor: disabled ? "#FAFCFE" : "white",
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        width: "160px",
        maxWidth: "160px",
        height: "36px",
      }}
      aria-disabled={disabled} // Accessibility attribute
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!disabled) setOpened(!opened);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: disabled ? "#B0B7BD" : "black",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0.4px",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
          >
            {value === "none" ? items[0]?.key : value}
          </Typography>
        </Box>

        {/* Show CloseIcon when a value is selected, else show KeyboardArrowDownIcon */}
        {value !== "none" && value !== "" && value !== null ? (
          <CloseIcon
            sx={{
              color: "#8093A1",
              fontSize: "16px", // Matches KeyboardArrowDownIcon size
              cursor: "pointer",
              transition: "all 0.3s ease",
              width: "20px",
              height: "20px",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent dropdown toggle
              handleChange("none"); // Reset value
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{
              color: "#8093A1",
              transition: "all 0.3s ease",
              transform: `rotate(${opened ? "180deg" : "0deg"})`,
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>

      {!disabled && (
        <Box
          sx={{
            display: opened ? "flex" : "none",
            flexDirection: "column",
            position: "absolute",
            top: {
              xs: "30px",
            },
            left: "-1px",
            zIndex: 10,
            width: "calc(100% + 2px)",
            borderWidth: "0 1px 1px",
            borderColor: "#CBD0C8",
            borderStyle: "solid",
            borderRadius: "0 0 8px 8px",
            maxHeight: "210px",
            overflowY: "auto",
            background: "white",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              width: "calc(100% - 30px)",
              borderTop: "1px solid #E0E3DE",
              margin: "0 15px 16px",
              position: "sticky",
              top: "0",
            }}
          />
          {items.map(
            (item) =>
              item.value !== "none" &&
              item.value !== "" &&
              item.value !== null && (
                <Box
                  key={item.value}
                  sx={{
                    padding: "10px",
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "10px",
                    },
                    fontWeight: "500",
                    lineHeight: "18px",
                    letterSpacing: "0.4px",
                    color: "black",
                    background: "white",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#EFF3F6",
                    },
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    margin: "4px 6px",
                  }}
                  onClick={() => {
                    setOpened(false);
                    handleChange(item.value);
                  }}
                >
                  {item.key}
                </Box>
              )
          )}
        </Box>
      )}

      {opened === true && !disabled && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};


export const ActionListFilterDateRange = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  const [opened, setOpened] = useState(false);
  const inputRef = useRef(null); // Create a ref for the input field

  return (
    <Box
      sx={{
        padding: {
          xs: "8px 6px",
          // xl: "16px",
        },
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#D5D7DA",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
        height: "36px",
        maxWidth: "160px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.4px",
            lineHeight: "18px",
            textTransform: "capitalize",
          }}
        >
          Action Dates
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            color: "#8093A1",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? "180deg" : "0deg"})`,
            width: "20px",
            height: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: {
            xs: "30px",
            // xl: "56px",
          },
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "10px",
              // xl: "16px",
            },
            fontWeight: "500",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            color: "black",
            background: "white",
            cursor: "pointer",
            "&:hover": {
              background: "#EFF3F6",
            },
            "& .MuiFormControl-root": {
              width: "100%",
            },
            margin: "4px 8px",
            border: "1px solid transparent",
            borderRadius: "8px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="" // Remove default label behavior
              value={startDate ? dayjs(startDate).utc() : null}
              onChange={(date) => {
                if (date) {
                  const formattedDate = dayjs(date).utc().format("YYYY-MM-DD");
                  handleStartDateChange(formattedDate);
                }
              }}
              components={{
                OpenPickerIcon: () => null, // Remove the default right-hand icon
              }}
              slotProps={{
                textField: {
                  InputProps: {
                    startAdornment: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginLeft: "8px",
                          cursor: "pointer", // Pointer cursor for better UX
                        }}
                        onClick={() => {
                          inputRef.current.click(); // Open the calendar popup programmatically
                        }}
                      >
                        <DateIcons style={{ color: "#8093A1" }} />
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "10px",
                            color: "black",
                            lineHeight: "18px",
                            letterSpacing: "0.4px",
                          }}
                        >
                          {startDate
                            ? dayjs(startDate).format("YYYY-MM-DD") // Show selected date
                            : "Start Date"}
                        </span>
                      </div>
                    ),
                  },
                  inputRef, // Attach the ref to the hidden input
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  height: "100%", // Ensure consistent height
                  padding: "8px 0", // Add padding for better alignment
                },
                "& input": {
                  position: "absolute",
                  opacity: 1, // Visually hide the input
                  zIndex: -1, // Ensure it doesn't block clicks
                  pointerEvents: "none", // Prevent direct interaction
                },
                "& fieldset": {
                  border: "none !important", // Remove the border
                },
                "& .MuiInputAdornment-root": {
                  position: "absolute",
                  right: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  "& .MuiIconButton-root": {
                    paddingLeft: "115px",
                    paddingRight1: "0px",
                    marginLeft: "-25px",
                    marginRight: "40px",
                    width: "100%",
                    height: "100%",
                    "& .MuiTouchRipple-root": {
                      width: "100%",
                      height: "100%",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "10px",
              // xl: "16px",
            },
            fontWeight: "500",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            color: "black",
            background: "white",
            cursor: "pointer",
            "&:hover": {
              background: "#EFF3F6",
            },
            "& .MuiFormControl-root": {
              width: "100%",
            },
            margin: "4px 8px",
            border: "1px solid transparent",
            borderRadius: "8px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="" // Remove default label behavior
              value={endDate ? dayjs(endDate).utc() : null}
              onChange={(date) => {
                if (date) {
                  const formattedDate = dayjs(date).utc().format("YYYY-MM-DD");
                  handleEndDateChange(formattedDate);
                }
              }}
              components={{
                OpenPickerIcon: () => null, // Remove the default right-hand icon
              }}
              slotProps={{
                textField: {
                  InputProps: {
                    startAdornment: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginLeft: "8px",
                          cursor: "pointer", // Pointer cursor for better UX
                        }}
                        onClick={() => {
                          inputRef.current.click(); // Open the calendar popup programmatically
                        }}
                      >
                        <EndDateIcon style={{ color: "#8093A1" }} />
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "10px",
                            color: "black",
                            lineHeight: "18px",
                            letterSpacing: "0.4px",
                          }}
                        >
                          {endDate
                            ? dayjs(endDate).format("YYYY-MM-DD") // Show selected date
                            : "End Date"}
                        </span>
                      </div>
                    ),
                  },
                  inputRef, // Attach the ref to the hidden input
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  height: "100%", // Ensure consistent height
                  padding: "8px 0", // Add padding for better alignment
                },
                "& input": {
                  position: "absolute",
                  opacity: 1, // Visually hide the input
                  zIndex: -1, // Ensure it doesn't block clicks
                  pointerEvents: "auto", // Prevent direct interaction
                },
                "& fieldset": {
                  border: "none !important", // Remove the border
                },
                "& .MuiInputAdornment-root": {
                  position: "absolute",
                  right: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  "& .MuiIconButton-root": {
                    paddingLeft: "115px",
                    paddingRight1: "0px",
                    marginLeft: "-25px",
                    marginRight: "40px",
                    width: "100%",
                    height: "100%",
                    "& .MuiTouchRipple-root": {
                      width: "100%",
                      height: "100%",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
