import React from "react";

function RefreshIcon({ color, width = "25", height = "25" }) {
  if (color === "disabled") {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5695 2.88379C18.0787 4.07589 18.431 5.32848 18.618 6.61015C18.6501 6.83005 18.5273 7.04269 18.3209 7.12485C18.2605 7.14885 18.2001 7.17246 18.1395 7.19567M6.56945 21.9363C6.06024 20.7442 5.70791 19.4917 5.52088 18.21C5.48879 17.9901 5.61156 17.7774 5.81804 17.6953C5.87837 17.6713 5.93884 17.6477 5.99945 17.6245M14.5695 8.07994C15.7919 7.93266 16.9909 7.6355 18.1395 7.19567M18.1395 7.19567C17.5668 6.52906 16.8737 5.94619 16.0695 5.48187C12.2431 3.27273 7.35039 4.58373 5.14125 8.41007C4.1268 10.1671 3.85464 12.1491 4.22747 13.9928M19.9781 11.2026C20.2441 12.9377 19.9436 14.7716 18.9977 16.4101C16.7885 20.2364 11.8958 21.5474 8.06945 19.3383C7.26522 18.8739 6.57211 18.2911 5.99945 17.6245M9.56945 16.7402C8.34697 16.8875 7.148 17.1846 5.99945 17.6245"
          stroke="#A6A6A6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (color === "enabled") {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5695 2.88379C18.0787 4.07589 18.431 5.32848 18.618 6.61015C18.6501 6.83005 18.5273 7.04269 18.3209 7.12485C18.2605 7.14885 18.2001 7.17246 18.1395 7.19567M6.56945 21.9363C6.06024 20.7442 5.70791 19.4917 5.52088 18.21C5.48879 17.9901 5.61156 17.7774 5.81804 17.6953C5.87837 17.6713 5.93884 17.6477 5.99945 17.6245M14.5695 8.07994C15.7919 7.93266 16.9909 7.6355 18.1395 7.19567M18.1395 7.19567C17.5668 6.52906 16.8737 5.94619 16.0695 5.48187C12.2431 3.27273 7.35039 4.58373 5.14125 8.41007C4.1268 10.1671 3.85464 12.1491 4.22747 13.9928M19.9781 11.2026C20.2441 12.9377 19.9436 14.7716 18.9977 16.4101C16.7885 20.2364 11.8958 21.5474 8.06945 19.3383C7.26522 18.8739 6.57211 18.2911 5.99945 17.6245M9.56945 16.7402C8.34697 16.8875 7.148 17.1846 5.99945 17.6245"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
      <path
        d="M17.5695 2.88379C18.0787 4.07589 18.431 5.32848 18.618 6.61015C18.6501 6.83005 18.5273 7.04269 18.3209 7.12485C18.2605 7.14885 18.2001 7.17246 18.1395 7.19567M6.56945 21.9363C6.06024 20.7442 5.70791 19.4917 5.52088 18.21C5.48879 17.9901 5.61156 17.7774 5.81804 17.6953C5.87837 17.6713 5.93884 17.6477 5.99945 17.6245M14.5695 8.07994C15.7919 7.93266 16.9909 7.6355 18.1395 7.19567M18.1395 7.19567C17.5668 6.52906 16.8737 5.94619 16.0695 5.48187C12.2431 3.27273 7.35039 4.58373 5.14125 8.41007C4.1268 10.1671 3.85464 12.1491 4.22747 13.9928M19.9781 11.2026C20.2441 12.9377 19.9436 14.7716 18.9977 16.4101C16.7885 20.2364 11.8958 21.5474 8.06945 19.3383C7.26522 18.8739 6.57211 18.2911 5.99945 17.6245M9.56945 16.7402C8.34697 16.8875 7.148 17.1846 5.99945 17.6245"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      </svg>
    );
  }
}

export default RefreshIcon;