import React from "react";
import { Box } from "@mui/material";
import aclogo from "components/Icons/AcLogo.svg";
import Logo from "scenes/Layout/logo";

const Support = () => {
  return (
    <div
      style={{
        backgroundSize: "35%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: " left",
        backgroundColor: "white",
      }}
    >
      <Box marginBottom={-2}>
        <Logo />
      </Box>
      <Box
        style={{
          background: "#F7F7F7",
          color: "black",
          minHeight: "100vh",
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 20px 0px",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            border: "1px solid #ccc",
            background: "white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            borderRadius: "10px",
            margin: "auto",
            width: {
              xs: "100%",
              lg: "50vw",
            },
          }}
        >
          <h1>Support Case</h1>
          <Box marginBottom="16px">
            <h3>
              Please fill out this{" "}
              <a
                href="https://forms.gle/8Za1pPX9xgmTAadK8"
                style={{ color: "#6495ED", textDecoration: "none" }}
              >
                form{" "}
              </a>
              to submit a support request
            </h3>
            <h3>
              or email us at{" "}
              <a
                href="mailto:aaisupport@altercall.com"
                style={{ color: "#6495ED", textDecoration: "none" }}
              >
                aaisupport@altercall.com
              </a>
              . We will respond within 24 hours.
            </h3>
          </Box>
        </Box>
        <Box
          style={{
            justifyContent: "center",
            display: "flex",

            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>Hours of Operation: Monday-Friday 9:00am - 5:00pm PST</h3>
        </Box>
      </Box>
    </div>
  );
};

export default Support;
