import React from 'react';

function VirtualCoachSendIcon({ color = '#111111' }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="vector"
                d="M2.93367 10.5L1.08987 4.23239C0.608672 2.59667 2.14094 1.08539 3.75514 1.60362C8.20665 3.03274 12.4553 5.03713 16.3948 7.56662C17.5259 8.29292 19 8.9417 19 10.5C19 12.0583 17.5259 12.7071 16.3948 13.4334C12.4553 15.9629 8.20666 17.9673 3.75514 19.3964C2.14094 19.9146 0.608671 18.4033 1.08987 16.7676L2.93367 10.5ZM2.93367 10.5H7.83493"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default VirtualCoachSendIcon;
