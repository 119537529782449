import React from 'react';

function EditIcon({ color, width = 25, height = 25 }) {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 25 25" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M12.0696 21.5315C16.0876 18.2571 16.1587 24.8889 21.0696 19.5315M3.06958 21.527L5.79623 21.5314C6.18566 21.532 6.38037 21.5323 6.56364 21.4883C6.72612 21.4493 6.88146 21.3848 7.02393 21.2972C7.18463 21.1984 7.32232 21.0602 7.59769 20.7836L20.571 7.75326C21.101 7.2209 21.2328 6.3978 20.829 5.74732C20.3409 4.96097 19.6732 4.2848 18.8963 3.78281C18.2735 3.38045 17.4558 3.46857 16.9322 3.99446L3.87948 17.1045C3.61402 17.3711 3.48129 17.5045 3.38502 17.6596C3.29965 17.7973 3.23566 17.9471 3.19526 18.1041C3.14969 18.2812 3.14504 18.4696 3.13574 18.8466L3.06958 21.527Z" 
                stroke={color} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EditIcon;
