import React from 'react';

const TrophyIcon = ({ fill = "#B6B6B6", width = 25, height = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.09717 21.439L12.0972 21.439M12.0972 21.439L16.0972 21.439M12.0972 21.439L12.0972 16.439M17.2967 13.4351C19.9656 13.3303 22.0972 11.1336 22.0972 8.43896L22.0972 7.03897C22.0972 6.15531 21.3808 5.43896 20.4972 5.43896L18.0923 5.43896M17.2967 13.4351C17.8058 12.5534 18.0972 11.5302 18.0972 10.439L18.0972 6.03896C18.0972 5.79036 18.0972 5.59715 18.0923 5.43896M17.2967 13.4351C16.2599 15.2306 14.3196 16.439 12.0972 16.439M12.0972 16.439C9.87471 16.439 7.93447 15.2306 6.8976 13.4351M18.0923 5.43896C18.0862 5.24223 18.0725 5.09966 18.0419 4.97207C17.8663 4.24078 17.2954 3.66979 16.5641 3.49423C16.3339 3.43896 16.055 3.43896 15.4972 3.43896L8.69717 3.43896C8.13936 3.43896 7.86045 3.43896 7.63028 3.49423C6.89898 3.66979 6.328 4.24078 6.15243 4.97207C6.1218 5.09966 6.10814 5.24223 6.10206 5.43896M6.10206 5.43896L3.69717 5.43896C2.81351 5.43896 2.09717 6.15531 2.09717 7.03896L2.09717 8.43896C2.09717 11.1336 4.22869 13.3303 6.8976 13.4351M6.10206 5.43896C6.09717 5.59715 6.09717 5.79036 6.09717 6.03897L6.09717 10.439C6.09717 11.5302 6.38849 12.5534 6.8976 13.4351"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrophyIcon;
