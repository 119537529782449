
import { BorderColor, Check, ErrorOutline } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, Grid, Modal, TextField, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "@emotion/styled";
import ArrowForward from "components/Icons/ArrowForward.svg";
import AddGoal from "./AddGoal";
import PositiveActionButton from "components/PositiveActionButton";
import NegativeActionButton from "components/NegativeActionButton";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import { formatDistanceToNow } from 'date-fns';
import utc from 'dayjs/plugin/utc';
import axios from "axios";
import EditActionIcon from "components/Icons/EditAction.svg";
import CompletedIcon from "components/Icons/Completed.svg";
dayjs.extend(utc);



const GoalCard = ({ user, clientData, loading, setLoading, fetchClientGoalsData, goals, setGoals }) => {
    const [completeGoal, setCompleteGoal] = useState({});
    const [openCompleteGoalModal, setOpenCompleteGoalModal] = useState(false);
    const [editGoal, setEditGoal] = useState({});
    const [openEditGoalModal, setOpenEditGoalModal] = useState(false);
    const [editGoalDate, setEditGoalDate] = useState("");
    const [initialGoalDate, setInitialGoalDate] = useState("");
    const [activeTab, setActiveTab] = useState("editGoal");

    const [tabs, setTabs] = useState("todo");
    const handleTabClick = (tab) => {
      setTabs(tab);
    };
    
    const handleEditClose = () => {
      setOpenEditGoalModal(false);
      setActiveTab("editGoal")
      setEditGoalDate("");
      setInitialGoalDate("")
    };

    const handleCompleteClose = () => {
      setOpenCompleteGoalModal(false)
    }


    const formik5 =
    useFormik({
      initialValues: {
        goal_title: editGoal.title,
        goal_description: editGoal.description,
      },
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        goal_title: Yup.string().required("required"),
        goal_description: Yup.string().required("required"),
      }),
      onSubmit: async (values) => {
        try {
          const body = {
            goal_id: editGoal.goal_id,
            client_id: clientData.user_id,
            title: values.goal_title,
            description: values.goal_description,
            goal_type: null,
            due_date: editGoalDate ? editGoalDate : null,
            created_by_userID: user?.sub,
            created_by_name: user?.given_name + " " + user?.family_name,
            edited_by_userID: user?.sub,
            edited_by_name: user?.given_name + " " + user?.family_name,
            initial_title: formik5.initialValues.goal_title,
            initial_description: formik5.initialValues.goal_description,
            initial_due_date: initialGoalDate,
            add: false,
          };
          setOpenEditGoalModal(false);
          setLoading(true);
          const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/save-client-goal-test`, body);
          setEditGoal({});
          setEditGoalDate("");
          await fetchClientGoalsData();
          setLoading(false);
        } catch (err) {
          console.log("Error in update", err.message);
          toast.error(err.message, {
            hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
          });
          setLoading(false);
        }
      },
    });

    const completeGoalModal = () => {
        return (
          <Modal
            open={openCompleteGoalModal}
            onClose={() => setOpenCompleteGoalModal(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "2px solid white",
                boxShadow: 24,
                paddingX: "50px",
                paddingY: "30px",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
                margin: "0 auto",
                maxWidth: "calc(100% - 40px)",
              }}
            >
              <Box>
                <Typography color="black" fontWeight="400">
                  {`Are you sure you want to mark selected goals as completed?`}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" 
                sx={{
                  gap: {xs: "8px", md: "15px"},
                  flexDirection: {xs: "column-reverse", md: "row"}
                }}
              >
                <DeclineButton 
                  onClick={handleCompleteClose}
                  label={"No"}
                />
                <ConfirmButton 
                  onClick={completeGoals}
                  label={"Yes"}
                />
              </Box>
            </Box>
          </Modal>
        );
      };

    const editGoalModal = () => {
      const handleTabChange = (tab) => {
        setActiveTab(tab);
      };
      let originalGoal = {};
      let sortedEdits = [];
      if (editGoal && editGoal.edits && editGoal.edits.length > 0) {
        sortedEdits = [...editGoal.edits].sort((a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp));
        originalGoal = {
          title: sortedEdits[0].previous_title,
          description: sortedEdits[0].previous_description,
          due_date: sortedEdits[0].previous_due_date,
          created_by: editGoal.created_by_name,
        };
      }
      else{
        originalGoal = {
          title: editGoal.title,
          description: editGoal.description,
          due_date: editGoal.due_date,
          created_by: editGoal.created_by_name,
        }
      }

        return(
          <Modal
          open={openEditGoalModal}
          onClose={handleEditClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          justifyContent="center"
        >
          <Box
            sx={{
              position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                },
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: {
                  xs: "8px",
                  md: "16px",
                }
              }}
            >
              <Box display="flex" gap="10px">
               <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "4px",
                    borderBottom: activeTab === "editGoal" ? "1px solid black" : "none",
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": {
                      cursor: "pointer"
                    },
                  }}
                  onClick={() => handleTabChange("editGoal")}
               >
                  Edit Goal
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "4px",
                    borderBottom: activeTab === "viewHistory" ? "1px solid black" : "none",
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": {
                      cursor: "pointer"
                    },
                  }}
                  onClick={() => handleTabChange("viewHistory")}
                >
                    View History
                </Typography>
              </Box>
            </Box>
            {activeTab === "viewHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "432px",
                  overflowY: "auto",
                  width: "100%"
                }}
              >
                {sortedEdits &&
                  sortedEdits
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px"}}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "black"
                          }}
                        >
                          {`${edit.new_title}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black"
                          }}
                        >
                          {`${edit.new_description}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "black"
                          }}
                        >
                          Due on {`${dayjs(edit.new_due_date).format("MM-DD-YYYY")}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px"
                          }}
                        >
                          edited by {edit.edited_by_userid === user?.sub ? 'me' : edit.edited_by_name} {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                  ))}
                  {editGoal.edits && originalGoal && (
                  <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "black"
                    }}
                  >
                    {`${originalGoal.title}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "black"
                    }}
                  >
                    {`${originalGoal.description}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "black"
                    }}
                  >
                    Due on {`${dayjs(originalGoal.due_date).format("MM-DD-YYYY")}`}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D2D2D",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    orginal goal by {originalGoal.created_by}
                  </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editGoal" && ( 
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <form onSubmit={formik5.handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "22px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        Title
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        error={formik5.touched.goal_title && Boolean(formik5.errors.goal_title)}
                        {...formik5.getFieldProps("goal_title")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                      {formik5.touched.goal_title &&
                        formik5.errors.goal_title && (
                          <Typography
                            variant="caption"
                            color="error"
                          >
                            {formik5.errors.goal_title}
                          </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        Description
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        multiline
                        rows={4}
                        error={formik5.touched.goal_description && Boolean(formik5.errors.goal_description)}
                        {...formik5.getFieldProps("goal_description")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                      {formik5.touched.goal_description &&
                        formik5.errors.goal_description && (
                          <Typography
                            variant="caption"
                            color="error"
                          >
                            {formik5.errors.goal_description}
                          </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        Due Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY-MM-DD"
                          value={editGoal.due_date && dayjs(editGoal.due_date).isValid() ? dayjs(editGoal.due_date) : null}
                          onChange={(date) => {
                            if (date) {
                              const formattedDate = dayjs(date).format("YYYY-MM-DD");
                              setEditGoalDate(formattedDate);
                            }
                          }}
                          sx={{
                            borderRadius: "16px",
                            border: "1px solid #C4CAD4",
                            "& svg": {
                              color: "#22272F",
                            },
                            "& input": {
                              color: "#22272F",
                            },
                            "& fieldset": {
                              border: "none !important",
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Box
                    marginTop="42px"
                    display="flex"
                    justifyContent="flex-end"
                    gap="15px"
                  >
                    <NegativeActionButton 
                      onClick={handleEditClose}
                      label={"Cancel"}
                    />
                    <PositiveActionButton 
                      onClick={formik5.handleSubmit}
                      label={"Save Goal"}
                      disabled={
                        editGoalDate === initialGoalDate &&
                        formik5.values.goal_title === editGoal.title &&
                        formik5.values.goal_description === editGoal.description
                      }
                    />
                  </Box>
                </form>
              </Box>
            )}
          </Box>
        </Modal>
      );
    }


    const completeGoals = async () => {
      try {
        const body = {
          status: "complete",
          goal_id: completeGoal.goal_id,
        };
        const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-goal-status`, body);
    
        setOpenCompleteGoalModal(false);
        setGoals((prev) =>
          prev.map((prevRow) =>
            prevRow.goal_id === completeGoal.goal_id
              ? { ...prevRow, status: "complete" }
              : prevRow
          )
        );
        setCompleteGoal({});
    
        toast(`Goal marked as complete`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } catch (err) {
        console.log("Error in update", err.message);
        toast.error(err.message, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
        });
      }
    };    

      const GoalComponent = ({ title, description, index, setGoals, goalId, status, type, goalDate, goal }) => {
    
        return (
          <Box
          sx={{
            padding: "24px",
            borderRadius: "40px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            background: "#F6F6F6",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="8px"
            width="100%"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center"
                }}
              >
                <Typography
                  sx={subHeadingStyle}
                >
                  {goal.title}
                </Typography>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "2.6",
                    fontWeight: "500",
                    color: "#B6B6B6",
                  }}
                >
                  {dayjs(goal.due_date).utc().format('MM.DD.YY')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "35px",
                overflowY:"auto",
                "&::-webkit-scrollbar": {
                  display: "none",  // Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.)
                },
                "-ms-overflow-style": "none",  // Hide the scrollbar for Internet Explorer and Edge
                scrollbarWidth: "none",        // Hide the scrollbar for Firefox
              }}
            >
              <Typography
                sx={textStyle}
              >
                {goal.description}
              </Typography>
              </Box>
              {goal.status === 'incomplete' && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    borderRadius: "43px",
                    paddingX: "30px",
                    paddingY: "6px",
                    gap: "10px",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    setEditGoal(goal);
                    setInitialGoalDate(goal.due_date);
                    setEditGoalDate(goal.due_date);
                    setOpenEditGoalModal(true);
                  }}
                >
                  <Typography
                    sx={{
                      color: "#202226",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                      Edit
                  </Typography>
                    <BorderColor
                      sx={{
                          fill: "black",
                          fontSize: "12px"
                      }}
                    />
                </Button>
                <Button
                  sx={{
                    background: "white",
                    borderRadius: "43px",
                    paddingX: "30px",
                    paddingY: "6px",
                    alignItems: "center",
                    color: "#50B500",
                    "&:hover":{
                      cursor: "pointer",
                      color: "white",
                      background: "rgba(80, 181, 0, 0.4)",
                    }
                  }}
                  onClick={()=> {
                    setCompleteGoal(goal);
                    setOpenCompleteGoalModal(true);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                      Mark as Complete
                  </Typography>
                </Button>
              </Box>
              )}
          </Box>
        </Box>
      )
    };
    
    
      const goalComponent = () => {
        return (
          <>
                {tabs === "todo" && (
                  goals?.map((goal, index) => (
                    goal.status === "incomplete" && (
                      <GoalComponent
                        key={index}
                        goal={goal}
                      />
                    )
                  ))
                )}
                {tabs === "completed" && (
                  goals?.map((goal, index) => (
                    goal.status === "complete" && (
                      <GoalComponent
                        key={index}
                        goal={goal}
                      />
                    )
                  ))
                )}
            </>
        )
      };

    return(
      <>
      {editGoalModal()}
      {completeGoalModal()}
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxHeight: "535px",
          overflowY: {
            xs: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          },
          paddingRight: {
            lg: "8px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              borderBottom: "1px solid #E8E8E8",
              maxWidth: "187px",
              marginLeft: "8px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px", // Space between icon and text
                fontFamily: "Poppins",
                fontSize: 12,
                color: "black",
                fontWeight: "500",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                textTransform: "capitalize",
                paddingBottom: "8px",
                borderBottom: tabs === "todo" ? "2px solid black" : "none",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => handleTabClick("todo")}
            >
              <EditActionIcon />
              <Box component="span" sx={{ whiteSpace: "nowrap"}}>To-Do</Box>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px", // Space between icon and text
                fontFamily: "Poppins",
                fontSize: 12,
                color: "black",
                fontWeight: "500",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                textTransform: "capitalize",
                paddingBottom: "8px",
                borderBottom: tabs === "completed" ? "2px solid black" : "none",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => handleTabClick("completed")}
            >
              <CompletedIcon />
              <Box component="span">Completed</Box>
            </Typography>
          </Box>
          <AddGoal 
            user={user}
            clientData={clientData}
            loading={loading}
            setLoading={setLoading}
            fetchClientGoalsData={fetchClientGoalsData}
          />
        </Box>
        {goalComponent()}
    </Box>
    </>
    )
}
export default GoalCard;

const textStyle = {
    color: "#202226",
    fontWeight: "300",
    fontSize: "14px",
    fontFamily: "Lexend",
  }

  const subHeadingStyle = {
    color: "black",
    fontWeight: "500",
    fontSize: "22px",
    fontFamily: "Lexend",
    lineHeight: "135%",
  }