import { Check, ErrorOutline } from "@mui/icons-material";
import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
//import { API } from "aws-amplify";
import { usePayment } from "hooks/PaymentContext";
import React, { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import { useDecrementClientCount } from "hooks/useDecrementClientCount";
import DeclineButton from "./DeclineButton";
import ConfirmButton from "./ConfirmButton";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import avatar from "assets/avatar.png";
import { useIncrementClientCount } from "hooks/useIncrementClientCount";
import axios from "axios";

const ClientLimitReached = ({ user, userData }) => {
  const [clientData, setClientData] = useState([]);
  const [clientStatusArray, setClientStatusArray] = useState([]);
  const [clientPicture, setClientPicture] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menuShow, setMenuShow] = useState([]);
  const [isLimit, setIsLimit] = useState(false);
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [selectedDeleteClient, setSelectedDeleteClient] = useState({});
  const { checkClientInviteAllowance, loadingCounter, error } =
    useCheckClientInviteAllowance();
  const { decrementClientCount, loadingIncrement, errorIncrement } =
    useDecrementClientCount();
  const { incrementClientCount, loadingIncrease, errorIncrease } =
    useIncrementClientCount();

    const checkClientCount = async () => {
      try {
        const clientCounter = await checkClientInviteAllowance(user?.sub);
    
        if (!clientCounter) {
          console.error("ClientCounter is null or undefined.");
          return;
        }
    
        // Destructure the values safely, handling both object and array scenarios
        const {
          clientCount = 0, // Default to 0 if undefined
          clientTierAllowance = 0, // Default to 0 if undefined
        } = Array.isArray(clientCounter) ? clientCounter[0] : clientCounter;
    
        if (clientCount > clientTierAllowance) {
          setIsLimit(true);
          fetchClientData();
        } else {
          setIsLimit(false);
        }
      } catch (error) {
        console.log("Error fetching client count", error);
      }
    };
    

  const fetchClientData = async () => {
    setLoading(true);
    try {
      let response;
      const userId = user?.sub;
      
      response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach`, {
        params: {
          coach_id: userId,
        },
      });

      if (!response.client_data && !response.client_status_objects) {
        setLoading(false);
        return;
      }

      const data = response.client_data;
      const client_status_array = response.client_status_objects;

      const pictureData = [];
      data.map(async (row, index) => {
        row.avatar = row.avatar ? "https://d7joen7tb0xwx.cloudfront.net/public/" + row.avatar : null
        setMenuShow((prev) => [...prev, false]);
      });
      client_status_array.map(async(row, index) => {
        row.avatar = row.avatar ? "https://d7joen7tb0xwx.cloudfront.net/public/" + row.avatar : null
        setMenuShow((prev) => [...prev, false]);
      })

      setClientData(data);
      setClientStatusArray(client_status_array);
      setClientPicture(pictureData);
      setMenuShow(Array(data.length).fill(false));
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendInvite = async (client_id, client_name) => {
    try {
      setLoading(true);
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/resend_invite_test`, {
        client_id: client_id,
      });
  
      toast(`Invite has been resent to ${client_name}`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
  
      setLoading(false);
    } catch (err) {
      console.error("Error in resend invite", err.message);
      setLoading(false);
    }
  };

  const deleteClientModalContent = () => {
    return (
      <Modal open={openDeleteClientModal} onClose={closeDeleteClientModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: { xs: "50%", lg: "60%", xl: "58%" },
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="#160042" fontWeight="400">
              Are you sure you want to delete {selectedDeleteClient.name} from your coachee roster?
            </Typography>
            <Box display="flex" gap="8px" alignItems="center">
              <ErrorOutline
                sx={{
                  color: "#CC5F0A",
                }}
              />
              <Typography color="#6B7094" fontWeight="400">
                All of their data will be erased and cannot be retrieved.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeDeleteClientModal} label={"No"} />
            <ConfirmButton onClick={handleDeleteClient} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleDeleteClient = async () => {
    if (!user?.sub) {
      console.error("User ID is undefined or null.");
      toast.error("User authentication is required.");
      return;
    }
    try {
      setLoading(true);
      setOpenDeleteClientModal(false);
      const client_id = selectedDeleteClient?.client_id;
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete_client_test`, {
        client_id: client_id,
        coach_id: user?.sub
      });
      // Decrement client count in DB after successful client deletion
      const { clientCount } = await decrementClientCount(user?.sub);
      toast(`Client deleted successfully, checking client count...`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      checkClientCount();
      setLoading(false);
    } catch (err) {
      console.error("Error in delete client", err.message);
      console.error("Failed to decrement client count:", error);
      toast.error("Failed to update client count.");
      toast.error(err.message);
      setLoading(false);
    }
  };

  const closeDeleteClientModal = () => {
    setOpenDeleteClientModal(false);
    setSelectedDeleteClient({});
  };

  const handleDisableClient = async (client_id) => {
    try {
      setLoading(true);
      const responce = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/disable_client_test`, {
        client_id: client_id,
      });
      const { clientCount } = await decrementClientCount(user?.sub);
      console.log(`Client count decremented to ${clientCount}.`);
      toast(`Client disabled successfully, checking client count...`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      checkClientCount();
      setLoading(false);
    } catch (err) {
      console.log("Error in disable client", err.message);
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleEnableClient = async (client_id) => {
    try {
      setLoading(true);
      const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/enable_client_test`, {
          client_id: client_id,
      });
      const { clientTierAllowance, clientCount } = await incrementClientCount(
        user?.sub
      );
      toast(`Client enabled successfully, checking client count...`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      checkClientCount();
      setLoading(false);
    } catch (err) {
      console.log("Error in enable client", err.message);
      toast.error("Failed to update client count.");
      setLoading(false);
    }
  };

  useEffect(() => {
    checkClientCount();
  }, []);

  //   useEffect(() => {
  //     const handleWindowReload = () => {
  //       checkClientCount();
  //     };

  //     window.addEventListener("beforeunload", handleWindowReload);

  //     return () => {
  //       window.removeEventListener("beforeunload", handleWindowReload);
  //     };
  //   }, []);

  return (
    <>
      <Modal
        open={false} // ->>>> modify
        hideBackdrop={true}
        disableEnforceFocus // Let the user focus on elements outside the dialog
        style={{ position: "initial" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
        disableBackdropClick // Remove the backdrop click (just to be sure)
        sx={{
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: { xs: "50%", lg: "60%", xl: "58%" },
            transform: "translate(-50%, -50%)",
            width: 800,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            zIndex: 500,
            padding: "20px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: "0 auto",
            height: "90%",
            maxWidth: "calc(100% - 40px)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "90%"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: { md: "10px" },
                paddingBottom: "40px",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Lexend",
                  fontSize: "24px",
                }}
              >
                We've noticed a change in subscription.
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                }}
              >
                Please disable or delete coachees to stay within your tier
                limits:
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                }}
              >
                {userData.coachingTier ? userData.coachingTier === 'tier1' && "1-3 Clients" : null}
                {userData.coachingTier ? userData.coachingTier === 'tier2' && "4-10 Clients" : null}
                {userData.coachingTier ? userData.coachingTier === 'tier3' && "11-25 Clients" : null}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "50px",
                gap: "12px",

                maxHeight: "90%",
                overflowY: "auto",
              }}
            >
              {deleteClientModalContent()}
              {clientStatusArray.map((row, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: {
                      xs: "center",
                      lg: "center",
                    },
                    position: "relative",
                    gap: {
                      xs: "8px",
                      xl: "24px",
                    },
                    flexDirection: {
                      xs: "column",
                      lg: "row",
                    },
                    padding: "16px 8px",
                    bgcolor: "white",
                    borderRadius: "40px",
                  }}
                >
                  <Box
                    sx={{
                      color: "#C4C4C4",
                      position: "absolute",
                      width: "30px",
                      height: "30px",
                      top: "24px",
                      right: "24px",
                      cursor: "pointer",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      const newStates = [...menuShow];
                      newStates[index] = !newStates[index];
                      setMenuShow(newStates);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: "#C4C4C4",
                        fontSize: "32px",
                      }}
                    />
                  </Box>
                  {menuShow[index] && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        paddingY: "6px",
                        top: "70px",
                        right: "24px",
                        width: "10%",
                        borderRadius: "12pt",
                        background: "#fff",
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                        zIndex: "999",
                      }}
                    >
                      {row.status === "disabled" ? (
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          disabled={row.status === "accepting" || row.status === "pending"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEnableClient(row.client_id);
                          }}
                        >
                          Enable
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          disabled={row.status === "0"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDisableClient(row.client_id);
                          }}
                        >
                          Disable
                        </Button>
                      )}
                  {row.status !== "accepted" && row.status !== "disabled" && ( // Invite Pending status check
                    <Button
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        lineHeight: "1.4",
                        padding: "8px",
                        textAlign: "center",
                        textTransform: "none",
                        fontWeight: "200",
                        "&:hover": {
                          background: "#F5F5F5",
                          cursor: "pointer",
                        },
                        "&:disabled": {
                          color: "#d3d3d3",
                          cursor: "not-allowed",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleResendInvite(row.client_id, row.full_name);
                      }}
                    >
                      Resend Invite
                    </Button>
                  )}
                      <Button
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          lineHeight: "1.4",
                          padding: "8px",
                          textAlign: "center",
                          textTransform: "none",
                          fontWeight: "200",
                          "&:hover": {
                            background: "#F5F5F5",
                            cursor: "pointer",
                          },
                          "&:disabled": {
                            color: "#d3d3d3",
                            cursor: "not-allowed",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedDeleteClient(row);
                          const newStates = [...menuShow];
                          newStates[index] = false;
                          setMenuShow(newStates);
                          setOpenDeleteClientModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                  <Box
                    sx={{
                      background: "#0076BA",
                      padding: "3px",
                      borderRadius: "50%",
                      width: {
                        xs: "60px",
                        lg: "80px",
                      },
                      height: {
                        xs: "60px",
                        lg: "80px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        background: "white",
                        padding: "4px",
                        borderRadius: "50%",
                        width: {
                          xs: "54px",
                          lg: "74px",
                        },
                        height: {
                          xs: "54px",
                          lg: "74px",
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          width: {
                            xs: "46px",
                            lg: "66px",
                          },
                          height: {
                            xs: "46px",
                            lg: "66px",
                          },
                        }}
                        src={avatar}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      minWidth: "fit-content",
                    }}
                  >
                    {row.status !== "accepted" && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "10px",
                            fontWeight: "700",
                            lineHeight: "1.5",
                            letterSpacing: "2px",
                            textTransform: "uppercase",
                            color:
                              row.status === "accepted"
                                ? "#499A0A"
                                : row.status === "disabled" || row.status === "expired"
                                ? "#FF6A55"
                                : "#F69607",
                          }}
                        >
                          {row.status === "pending"
                            ? "Invite Pending"
                            : row.status === "accepted"
                              ? "Active Status"
                              : row.status === "disabled"
                                ? "Disabled Status"
                                : row.status === "expired"
                                  ? "Invite Expired"
                                  : "Unknown Status"}
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      sx={{
                        ...headingStyle,
                        fontWeight: "400",
                      }}
                    >
                      {row.full_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      marginRight: {
                        lg: "60px",
                        xl: "100px",
                      },
                      marginLeft: {
                        lg: "auto",
                      },
                    }}
                  ></Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ClientLimitReached;

const headingStyle = {
  color: "black",
  fontWeight: "600",
  fontSize: "28px",
  fontFamily: "Montserrat",
  lineHeight: "120%",
};
