/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from "@mui/material";
import { Footer } from "scenes/login/Footer";
import { Header } from "components/GlobalHeader/GlobalHeader";
import { useSelector } from "react-redux";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import * as animationData from "data/lottieAnimation.json";

import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import ClientHasInactiveCoachModal from "components/ClientHasInactiveCoach/ClientHasInactiveCoachModal";
import RetuneIframe from "components/Retune/VirtualCoachIframe";


const ClientVirtualCoachPage = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);

  return (
    <div>
      {user && (
        <GlobalPageComponent user={user} userData={userData}>
          <ClientHasInactiveCoachModal clientHasInactiveCoach={userData.clientHasInactiveCoach} isLocked={true}/>
          <Box
            sx={{
              backgroundColor: "#EFF3F6",
              width: "100%",
              height: {
                xs: '90vh',
                sm: '90vh',
                md: '99vh',
              },
              // border: '1px solid green',
              padding: {
                xs: "0px 0px",
                sm: "0px 0px",
                md: "20px 40px",
              },
              display: {
                xs: !collapsed ? "none" : "block",
              },
            }}
          >

            <RetuneIframe user={user} userData={userData} />

          </Box>
        </GlobalPageComponent>
      )}

      {!user && (
        <Box
          sx={{
            background: "white",
          }}
        >
          <Header typeOfNav="virtualcoach" hideGlobalSearchbar={true} />
          <Box
            minHeight={"calc(100vh - 295px)"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              width={{
                xs: "100%",
                lg: "90%",
              }}
              justifyContent={"center"}
              background=" linear-gradient(110deg, #FFF -17.37%, rgba(255, 255, 255, 0.53) 100.33%)"
              border={"2px solid white"}
              display={"flex"}
              alignItems={"center"}
              flexDirection={{ xs: "column", md: "row" }}
              marginTop={{
                xs: "40px",
                lg: "20px",
              }}
            >
              <Box
                marginLeft={{
                  xs: "",
                  lg: "100px",
                }}
                display={{
                  xs: "none",
                  lg: "flex",
                }}
                flexDirection={"column"}
                justifyContent={"space-between"}
                padding={{
                  xs: "0 20px",
                  lg: "0 30px",
                }}
              ></Box>
            </Box>
          </Box>
          <Footer />
        </Box>
      )}
    </div>
  );
};

export default ClientVirtualCoachPage;

export const getPaymentsInComponent = /* GraphQL */ `
  query GetPayments($userID: String!) {
    getPayments(userID: $userID) {
      userID
      customer
      membershipType
      purchasedOn
      virtualcoach
    }
  }
`;