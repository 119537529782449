import React from 'react';

function PdfDownloadIconForVC({ color = '#111111', width = '19', height = '17' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 19 17">
            <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1.625 11.125A4.375 4.375 0 0 0 6 15.5h7a4.375 4.375 0 0 0 4.375-4.375m-10.5-2.46c.648.864 1.4 1.643 2.237 2.322.113.092.25.138.388.138m2.625-2.46c-.648.864-1.4 1.643-2.237 2.322a.615.615 0 0 1-.388.138m0 0V1.5" />
        </svg>

    )
}

export default PdfDownloadIconForVC;





