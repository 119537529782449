import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";
import aclogo from "components/Icons/AcLogo.svg";
import Logo from "scenes/Layout/logo";

const listItemStyle = { lineHeight: "1.5", fontSize: "1rem" };

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        backgroundSize: "35%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: " left",
        backgroundColor: "white",
      }}
    >
      <Logo />
      <Box
        style={{
          backgroundColor: "#F7F7F7",
          color: "black",
          overflowX: "hidden",
        }}
      >
        <Box
          margin={{
            xs: "0px 30px",
            lg: "0px 100px",
          }}
          paddingTop="50px"
          height="100vh"
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "24px", md: "40px" }}
            sx={{ textAlign: "center", padding: "20px" }}
          >
            ALTERCALL AI Privacy Policy
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            INTELLECTUAL PROPERTY (IP) LICENSE AND RELEASE
          </Typography>
          <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
            As a part of your participation in any Program(s) provided by
            AlterCall, LLC subject to the terms of this agreement, You hereby
            irrevocably authorize AlterCall, LLC and its affiliates/contributors
            to record, collect, or otherwise capture and store indefinitely any
            and all communications provided or otherwise produced during the
            course of the Program(s) including but not limited to:
            teleconference calls; video conferencing calls; transcriptions;
            emails; chat sessions; and/or SMS messages (hereinafter, “recorded
            materials”). The recorded materials may be stored in their original
            form or in modified form, including de-identified form or form where
            personally identifiable information (PII) has been scrubbed or
            otherwise removed, in perpetuity by AlterCall, LLC and its
            affiliates/contributors in accordance with any data privacy and
            security policies as implemented by AlterCall, LLC.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            ANY RECORDED MATERIALS, AS DESCRIBED ABOVE, INCLUDING TEXT, SPEECH,
            AND AUDIOVISUAL CONTENT, MAY HAVE INTELLECTUAL PROPERTY RIGHTS
            AND/OR NAME, IMAGE, AND LIKENESS RIGHTS OWNED BY THE PARTICIPANT.
            HOWEVER, FOR ALL ASPECTS OF THE PROGRAM(S) YOU PARTICIPATE IN FOR
            WHICH RECORDED MATERIALS ARE PROVIDED OR OTHERWISE PRODUCED, YOU
            GRANT ALTERCALL AND ITS AFFILIATES/CONTRIBUTORS AN IRREVOCABLE
            WORLDWIDE, PERPETUAL, ROYALTY-FREE, SUBLICENSABLE, AND TRANSFERABLE
            LICENSE TO HOST, STORE, CACHE, USE, DISPLAY, REPRODUCE, MODIFY,
            ADAPT, EDIT, PUBLISH, ANALYZE, TRANSMIT, AND DISTRIBUTE THAT
            CONTENT.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            You, as a Participant, hereby acknowledge and agree to the
            following:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a) I hereby expressly acknowledge that, notwithstanding any
                local laws or regulations to the contrary, my right to have my
                name, image, likeness, and/or personally identifiable
                information (PII), as captured in audio, visual, or audiovisual
                form, removed from the AlterCall, LLC recorded materials as
                defined above is irrevocably waived.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b) I hereby irrevocably waive any right I may have to inspect,
                audit, edit/correct, and/or approve any photographs, audio, or
                video and transcripts thereof, and information pertaining to
                myself within any of the recorded materials as defined above
                irrespective of any local laws or regulations to the contrary.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                c) I hereby expressly acknowledge that the content of any
                recorded materials, as defined above, may include personally
                identifiable information (PII) including but not limited to
                demographic and/or biometric information.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                d) I understand and agree that all recordings produced during my
                participation in the Program(s) are automatically licensed to
                AlterCall, LLC under the terms provided above and I do not ask
                for nor expect compensation for the use of the recordings or
                photographs in which I appear or speak.
              </Typography>
            </ListItem>
          </List>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            This Privacy Policy describes how altercall.ai collects, uses, and
            discloses personal information when you use the OAuth consent screen
            provided by Google to log in to our application using your google
            email address. By logging in with your email address, you consent to
            the practices described in this policy. Our Services offer you the
            ability to register and login using your third party social account
            details (like your google logins). Where you choose to do this, we
            will receive certain profile information about you from your social
            media provider. The profile Information we receive may vary
            depending on the social media provider concerned, but will often
            include your name, e-mail address, friends list, profile picture as
            well as other information you choose to make public. We will use the
            information we receive only for the purposes that are described in
            this privacy policy or that are otherwise made clear to you on the
            Services. Please note that we do not control, and are not
            responsible for, other uses of your personal information by your
            third party social media provider. We recommend that you review
            their privacy policy to understand how they collect, use and share
            your personal information, and how you can set your privacy
            preferences on their sites and apps. To use our Services, you’ll
            need to create an account, either via AlterCall or through a
            third-party service such as Google. In the latter case, personal
            information you provide to that third party (such as your name,
            email address, and other information) will be provided to AlterCall
            through the third party service in accordance with your privacy
            settings on that third party service. We will use the personal
            information provided by the third party service to create your
            AlterCall account. You agree to provide us with complete and
            accurate information when you register for an account. You will not
            share your account credentials, or use them in multiple locations at
            the same time. You must use a unique identity to access and use the
            Services, and may access the Services only to the extent purchased
            by you.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Information We Collect When you use the OAuth consent screen to sign
            up or log in to our application using your personal google account,
            we may collect the following types of personal information:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a. Personal Information: We may collect your name, email
                address, and other contact details associated with your google
                account.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b. OAuth Permissions: We may collect and store the permissions
                you grant to our application during the OAuth consent process.
                These permissions allow us to access certain information from
                your google account, as authorized by you.
              </Typography>
            </ListItem>
          </List>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Use of Information We use the personal information collected through
            the OAuth consent screen for the following purposes:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a. Authentication and Account Access: We use your google email
                address and associated permissions to authenticate your identity
                and provide you with access to our altercall.ai application.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b. Service Provision: We may use the information to provide you
                with the requested services, including personalized features,
                support, and updates related to our application.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                c. Communication: We may use your email address to send you
                important notifications, updates, and information related to
                your use of our application.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                d. Improvements and Analytics: We may use aggregated and
                anonymized data for analytical purposes to improve our
                application, understand user preferences, and optimize our
                services.
              </Typography>
            </ListItem>
          </List>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Disclosure of Information We do not sell, trade, or otherwise
            transfer your personal information to third parties without your
            explicit consent, except in the following circumstances:
          </Typography>
          <List>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                a. Service Providers: We may share your personal information
                with trusted third-party service providers who assist us in
                operating our application and providing services to you. These
                service providers are contractually obligated to maintain the
                confidentiality and security of your personal information.
              </Typography>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Typography variant="h5" sx={{ lineHeight: "1.5" }}>
                b. Legal Compliance: We may disclose your personal information
                if required by law, governmental or regulatory authorities, or
                to protect our rights, property, or safety, or that of our users
                or the public.
              </Typography>
            </ListItem>
          </List>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Data Security We take reasonable measures to protect the personal
            information collected through the OAuth consent screen from
            unauthorized access, use, or disclosure. However, please note that
            no method of transmission over the internet or method of electronic
            storage is 100% secure.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Data Retention We retain your personal information for as long as
            necessary to fulfill the purposes outlined in this Privacy Policy,
            unless a longer retention period is required or permitted by law.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Your Rights You may have certain rights regarding your personal
            information, including the right to access, correct, or delete your
            information. To exercise these rights or if you have any questions
            or concerns about the processing of your personal information,
            please contact us using the information provided below.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Children’s Privacy These Services do not address anyone under the
            age of 13. We do not knowingly collect personally identifiable
            information from children under 13 years of age. In the case we
            discover that a child under 13 has provided us with personal
            information, we immediately delete this from our servers. If you are
            a parent or guardian and you are aware that your child has provided
            us with personal information, please contact us so that we will be
            able to do necessary actions.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            Changes to this Privacy Policy We may update this Privacy Policy
            from time to time to reflect changes in our practices or legal
            requirements. We will notify you of any material changes by posting
            the updated Privacy Policy on our website or through other
            communication channels.
          </Typography>
          <Typography
            variant="h5"
            sx={{ lineHeight: "1.5", marginTop: "20px" }}
          >
            By using the OAuth consent screen provided by Google and logging in
            with your google email address, you acknowledge that you have read
            and understood this Privacy Policy and agree to the collection, use,
            and disclosure of your personal information as described herein.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
