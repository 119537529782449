import React, { useState } from 'react';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import resourceBotLogo from '../../assets/resourceBotLogo.png';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


const ResourceChatbot = () => {
    const [showChatbot, setShowChatbot] = useState(false);

    return (
        <div style={{
            position: 'fixed',
            bottom: "3%",
            right: "2%",
            zIndex: 100,

        }}>
            <Box sx={{
                position: "relative",
                display: showChatbot ? 'block' : 'none',
            }}>
                <CloseFullscreenIcon
                    onClick={() => setShowChatbot(!showChatbot)}
                    sx={{
                        fontSize: 20,
                        position: "absolute",
                        top: 30, right: 20,
                        color: 'white',
                        '&:hover': {
                            cursor: 'pointer',
                        },

                    }} />
                <iframe
                    title="Resource Chatbot"
                    src='https://useful-trout-84-cdekkba18cfj.deno.dev/?popup=true'
                    style={{
                        width: '20vw',
                        height: '70vh',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        background: 'transparent',
                        border: 'none',
                        borderRadius: "20px",
                    }}

                />
            </Box>
            {
                !showChatbot &&
                <div>
                    <Tooltip open={true}
                        title="Click here to speak with the Support Assistant"
                        placement="top-end"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -3],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box onClick={() => {
                            setShowChatbot(!showChatbot)

                        }} component="img" src={resourceBotLogo}
                            sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    cursor: 'pointer',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        />
                    </Tooltip>
                </div>
            }


        </div>

    );
};

export default ResourceChatbot;