import { Modal, Box, Button, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import { toast } from "react-toastify";
import { KeyboardArrowDown } from "@mui/icons-material";

const CreateTeamModal = ({ user, userData, openCreateTeamModal, setOpenCreateTeamModal, companyInformation, teamsInformation, companyMembers, fetchCompanyData }) => {
    const [teamName, setTeamName] = useState("");
    const [selectedAdmin, setSelectedAdmin] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const CustomIcon = (props) => (
        <KeyboardArrowDown {...props} style={{ color: 'black' }} />
      );


    const handleCloseCreateTeamModal = () => {
        setOpenCreateTeamModal(false);
        setTeamName("");
        setSelectedAdmin("");
        setSelectedUsers([]);
        setErrorMessage("");
    };

    const handleCreateTeam = async () => {
        if (!teamName || !selectedAdmin) {
            setErrorMessage("Team name and admin selection are required.");
            return;
        }

        // Check if the team name already exists
        const teamExists = teamsInformation.some(team => team.team_name.toLowerCase() === teamName.toLowerCase());
        if (teamExists) {
            setErrorMessage("A team with this name already exists. Please choose a different name.");
            return;
        }

        try {
            setLoading(true);
            const body = {
                team_name: teamName,
                admin_id: selectedAdmin,
                users: selectedUsers,
                company_id: companyInformation.company_id,
                user_id: user?.sub, // Super Admin's user ID
                user_group: user?.role
            };

            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/create-team`, body);
            toast.success("Team created successfully!");
            handleCloseCreateTeamModal();
            await fetchCompanyData();
        } catch (error) {
            console.error("Error creating team:", error);
            toast.error("Failed to create team.");
        } finally {
            setLoading(false);
            setErrorMessage('')
        }
    };

    // Handle admin selection change
    const handleAdminChange = (e) => {
        const newAdmin = e.target.value;
        // If the new admin is already selected in users, remove them from users
        if (selectedUsers.includes(newAdmin)) {
            setSelectedUsers(selectedUsers.filter(userId => userId !== newAdmin));
        }
        setSelectedAdmin(newAdmin);
    };

    // Handle user selection change
    const handleUserChange = (e) => {
        const newSelectedUsers = e.target.value;
        // If the selected admin is also in the selected users, remove them
        if (newSelectedUsers.includes(selectedAdmin)) {
            setSelectedAdmin(""); // Clear the admin if they're selected as a user
        }
        setSelectedUsers(newSelectedUsers);
    };

    return (
        <Modal
            open={openCreateTeamModal}
            onClose={handleCloseCreateTeamModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "white",
                    color: "black",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    maxWidth: "calc(100% - 40px)",
                    maxHeight: { xs: "calc(100% - 50px)", md: "80%" },
                    overflowY: "hidden",
                    boxShadow: 24,
                    borderRadius: "30px",
                    padding: { xs: "24px", md: "64px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography sx={{ color: 'black', fontSize: 36, fontFamily: 'Lexend', fontWeight: '300' }}>
                        Create a Team
                    </Typography>
                </Box>
                <Box
                    sx={{
                        overflowY: "auto", // Add scrolling to this section
                        flexGrow: 1, // Ensure this section grows to fill available space
                        marginBottom: "16px", // Ensure some margin before the footer
                        paddingRight: '16px', // For scrollbar padding (optional)
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "capitalize",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: 500,
                                letterSpacing: "0.05em"
                                }}
                            >
                                Team Name
                            </Typography>
                            <TextField
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "capitalize",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: 500,
                                letterSpacing: "0.05em"
                                }}
                            >
                                Select Admin
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedAdmin}
                                    onChange={handleAdminChange}
                                    IconComponent={CustomIcon}
                                    displayEmpty
                                    inputProps={{ style: { color: "#4F4F4F" } }}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                >
                                    {companyMembers?.length > 0 && companyMembers.map(member => (
                                        <MenuItem key={member.user_id} value={member.user_id} disabled={selectedUsers.includes(member.user_id)}
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                background: "#F7F7F7",
                                                cursor: "pointer"
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                color: "black",
                                                fontSize: "12px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word"
                                                }}
                                            >
                                                {member.full_name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "capitalize",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: 500,
                                letterSpacing: "0.05em"
                                }}
                            >
                                Select Users
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    multiple
                                    value={selectedUsers}
                                    onChange={handleUserChange}
                                    renderValue={(selected) => selected.map((userId) => companyMembers.find(member => member.user_id === userId)?.full_name).join(", ")}
                                    IconComponent={CustomIcon}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                >
                                    {companyMembers?.length > 0 && companyMembers.map(member => (
                                        <MenuItem 
                                            key={member.user_id} 
                                            value={member.user_id} 
                                            disabled={selectedAdmin === member.user_id}
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                  background: "#F7F7F7",
                                                  cursor: "pointer"
                                                },
                                              }}
                                        >
                                            <Typography
                                                sx={{
                                                color: "black",
                                                fontSize: "12px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word"
                                                }}
                                            >
                                                {member.full_name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Typography sx={{color: "red", fontSize: "12px", fontFamily: "Lexend"}}>
                    {errorMessage ?? ""}
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}>
                    <ConfirmButton 
                        onClick={handleCreateTeam}
                        label={"Create"}
                        disabled={teamName === "" || !selectedAdmin}
                        loading={loading}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateTeamModal;