import React from 'react';

function PdfFullscreenIcon({ color = '#111111' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none" viewBox="0 0 19 18">
            <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.833 16.744a18.476 18.476 0 0 1-4.753.179.555.555 0 0 1-.503-.503 18.476 18.476 0 0 1 .18-4.753m10.41 5.077c1.58.264 3.178.324 4.753.179a.555.555 0 0 0 .503-.503 18.475 18.475 0 0 0-.18-4.753M1.758 6.333a18.476 18.476 0 0 1-.18-4.753.555.555 0 0 1 .503-.503 18.476 18.476 0 0 1 4.753.18m5.334 0a18.476 18.476 0 0 1 4.753-.18.555.555 0 0 1 .503.503 18.476 18.476 0 0 1-.18 4.753" />
        </svg>

    )
}

export default PdfFullscreenIcon;





