import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "slices/UserSlice";
import sessionReducer from "slices/SessionSlice";
import CoachSlice from "slices/CoachSlice";
import ClientSlice from "slices/ClientSlice";
import LayoutSlice from "slices/LayoutSlice";

const rootReducer = combineReducers({
  user: userReducer,
  session: sessionReducer,
  coach: CoachSlice,
  client: ClientSlice,
  layout: LayoutSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["persist/PERSIST"],
    },
  }),
});

export const persistor = persistStore(store);
