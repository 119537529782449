import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import { formatData } from "helpers/ResourceHelper";

const ResourceSidebar = () => {
  const [loading, setLoading] = useState(false);
  const [responseFromDb, setResponseFromDB] = useState([]);
  const [hierarchicalData, setHierarchicalData] = useState([]);
  const [currentPageId, setCurrentPageId] = useState();
  const [currentSectionId, setCurrentSectionId] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageIdFromParams = queryParams.get("pageId");
    const sectionIdFromParams = queryParams.get("sectionId");
    setCurrentSectionId(sectionIdFromParams);
    setCurrentPageId(pageIdFromParams);
  }, [location]);

  useEffect(() => {
    async function getResourcePagesSectionsDataForSidebar() {
      try {
        setLoading(true);
        const dbResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-resource-page-section-name-and-id`
        );
        if (dbResponse.data && Array.isArray(dbResponse.data)) {
          setResponseFromDB(dbResponse.data);
          const formattedData = formatData(dbResponse.data);
          console.log("formattedData", formattedData);
          setHierarchicalData(formattedData);
        } else {
          console.error("Unexpected response format:", dbResponse.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    getResourcePagesSectionsDataForSidebar();
  }, []);

  const [open, setOpen] = useState({});

  const handleClick = (page_id) => {
    setOpen((prev) => ({
      ...prev,
      [page_id]: !prev[page_id],
    }));
  };

  const formatStringForUrl = (string) => {
    return string.split(" ").join("-");
  };

  return (
    <Box
      position={{ xs: "static", lg: "fixed" }}
      color={"black"}
      sx={{
        paddingBottom: { xs: "20px", lg: "300px" },
        height: "100%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        width: { xs: "auto", lg: "18vw" },
        paddingTop: "20px",
      }}
      borderRight={{ xs: "none", lg: "1px solid grey" }}
      mx={{ xs: "auto" }}
    >
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            width: "100vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        hierarchicalData.map((resource) => (
          <Box key={resource.resource_id}>
            <Typography
              color={"black"}
              paddingBottom={"10px"}
              style={{ fontFamily: "Poppins" }}
              fontSize={"14px"}
              fontWeight={"500"}
            >
              {resource.resource_name}
            </Typography>

            {resource.pages.map((page) => (
              <List
                key={page.page_id}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {page.sections.length === 1 ? (
                  <ListItemButton sx={{ py: "0px", mt: "-15px" }}>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          fontSize: "13px",
                          color: "#93989A",
                        },
                      }}
                      onClick={() =>
                        navigate(
                          `/resources?search=${formatStringForUrl(
                            page.sections[0].section_name
                          )}&pageId=${page.page_id}&sectionId=${page.sections[0].section_id}`
                        )
                      }
                      primary={page.sections[0].section_name}
                    />
                  </ListItemButton>
                ) : (
                  <>
                    <ListItemButton
                      sx={{ py: "0px", mt: "-15px" }}
                      onClick={() => handleClick(page.page_id)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "#93989A",
                          },
                        }}
                        primary={page.page_name}
                      />
                    </ListItemButton>

                    <Collapse
                      in={open[page.page_id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {page.sections.map((section) => (
                          <ListItemButton
                            onClick={() =>
                              navigate(
                                `/resources?search=${formatStringForUrl(
                                  section.section_name
                                )}&pageId=${page.page_id}&sectionId=${section.section_id}`
                              )
                            }
                            key={section.section_id}
                            sx={{
                              pl: 4,
                              py: "1px",
                              width: "auto",
                              borderRadius: "8px",
                              color:
                                currentPageId === `${page.page_id}` &&
                                currentSectionId === `${section.section_id}`
                                  ? "white"
                                  : "#93989A",
                              backgroundColor:
                                currentPageId === `${page.page_id}` &&
                                currentSectionId === `${section.section_id}`
                                  ? "#cdd0d1"
                                  : "transparent",
                              "&:hover": {
                                backgroundColor:
                                  currentPageId === `${page.page_id}` &&
                                  currentSectionId === `${section.section_id}`
                                    ? "#b5b8b9"
                                    : "white",
                                color:
                                  currentPageId === `${page.page_id}` &&
                                  currentSectionId === `${section.section_id}`
                                    ? "white"
                                    : "#93989A",
                              },
                            }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "11px",
                                },
                              }}
                              primary={section.section_name}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              </List>
            ))}
          </Box>
        ))
      )}
    </Box>
  );
};

export default ResourceSidebar;
