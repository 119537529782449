/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import Topbar from "scenes/Layout/Topbar";
import Logo from "scenes/Layout/logo";
import RetuneIframe from "components/Retune/VirtualCoachIframe";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  handleStripeRedirect,
  updateUserStateAfterStripe,
} from "utils/stripeRedirect";


const RetuneBotPage = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const refreshUserSession = async () => {
    try {
      await fetchAuthSession({ forceRefresh: true });
      console.log("Session refreshed successfully");
      window.location.replace("/virtualcoach/bot");
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  };

  useEffect(() => {
    // Call the utility function to handle the Stripe redirect
    handleStripeRedirect(() => updateUserStateAfterStripe(refreshUserSession));
  }, []);

  return (
    <>
      <GlobalPageComponent user={user} userData={userData}>
        {/* <Box
            sx={{
              backgroundColor: "#EFF3F6",
              width: "100%",
              height: "auto",
              padding: "16px",
              display: {
                  xs: !collapsed ? "none" : "block",
              },
              overflowY: "auto",
          }}
          >
            <Box
              width={{ xs: "100%", lg: "100%" }}
              height={{ xs: "unset", lg: "100%" }}
              justifyContent="center"
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column-reverse", md: "row" }}
              marginTop={{ xs: "0px", lg: "0px" }}
            >
              <RetuneIframe user={user} userData={userData} />

            </Box>
          </Box> */}



        <Box
          sx={{
            backgroundColor: "#EFF3F6",
            width: "100%",
            height: {
              xs: "90vh",
              sm: "90vh",
              md: "99vh",
            },
            // border: '1px solid green',
            padding: {
              xs: "0px 0px",
              sm: "0px 0px",
              md: "16px",
            },
            display: {
              xs: !collapsed ? "none" : "block",
            },
          }}
        >
          <RetuneIframe user={user} userData={userData} />
        </Box>
      </GlobalPageComponent>
    </>
  );
};

export default RetuneBotPage;