import React from "react";

function Dashboard({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 12.7577C3 11.7408 3 11.2324 3.11866 10.7561C3.22385 10.334 3.39721 9.93178 3.63191 9.56543C3.89667 9.15215 4.26632 8.80304 5.00561 8.10483L7.60561 5.64927C9.15211 4.18868 9.92537 3.45839 10.8075 3.18288C11.584 2.94034 12.416 2.94034 13.1925 3.18288C14.0746 3.45839 14.8479 4.18868 16.3944 5.64927L18.9944 8.10483C19.7337 8.80304 20.1033 9.15215 20.3681 9.56543C20.6028 9.93178 20.7761 10.334 20.8813 10.7561C21 11.2324 21 11.7408 21 12.7577V16.3323C21 17.4173 21 17.9598 20.8807 18.4049C20.5571 19.6127 19.6137 20.5561 18.4059 20.8798C17.9608 20.999 17.4183 20.999 16.3333 20.999C16.0233 20.999 15.8683 20.999 15.7412 20.9649C15.3961 20.8725 15.1265 20.6029 15.0341 20.2578C15 20.1307 15 19.9757 15 19.6657V16.999C15 16.5345 15 16.3023 14.9692 16.1079C14.7998 15.0382 13.9608 14.1992 12.8911 14.0298C12.6968 13.999 12.4645 13.999 12 13.999C11.5355 13.999 11.3032 13.999 11.1089 14.0298C10.0392 14.1992 9.20021 15.0382 9.03078 16.1079C9 16.3023 9 16.5345 9 16.999V19.6657C9 19.9757 9 20.1307 8.96593 20.2578C8.87346 20.6029 8.60391 20.8725 8.25882 20.9649C8.13165 20.999 7.97666 20.999 7.66667 20.999C6.5817 20.999 6.03922 20.999 5.59413 20.8798C4.38631 20.5561 3.44289 19.6127 3.11926 18.4049C3 17.9598 3 17.4173 3 16.3323V12.7577Z"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dashboard;
