import React from "react";

function NewThreadIcon({ color = '#111111' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
            <path
                stroke={color} // Use the color prop here
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
                d="M17.166 12.167c0 2.333 0 3.5-.454 4.391a4.166 4.166 0 0 1-1.821 1.821c-.891.454-2.058.454-4.392.454H8.833c-2.334 0-3.5 0-4.392-.454a4.166 4.166 0 0 1-1.82-1.82c-.455-.892-.455-2.059-.455-4.392V10.5c0-2.333 0-3.5.454-4.392a4.167 4.167 0 0 1 1.821-1.82c.891-.455 2.058-.455 4.392-.455m-1.616 7.918c.008-.292.011-.438.046-.575a1.24 1.24 0 0 1 .148-.345c.074-.12.177-.224.383-.43l7.83-7.875a1.216 1.216 0 0 1 1.523-.164 4.89 4.89 0 0 1 1.436 1.427c.021.032.043.063.063.096.313.504.211 1.143-.2 1.556l-7.769 7.812c-.213.215-.32.322-.444.399a1.238 1.238 0 0 1-.357.148c-.143.034-.294.034-.596.033l-2.114-.003.051-2.079Z"
            />
        </svg>
    );
}

export default NewThreadIcon;
