// navigationItems.js
import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import CallIcon from '@mui/icons-material/Call';
import StarIcon from '@mui/icons-material/Star';
import ListIcon from '@mui/icons-material/List';
import CultureIcon from '@mui/icons-material/Accessibility'; // Example icon for "Culture"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SettingsInputComponent from '@mui/icons-material/SettingsInputComponent';
import Logout from '@mui/icons-material/Logout';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ChatIcon from '@mui/icons-material/Chat';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BookIcon from '@mui/icons-material/Book';

export const navItems = {
    coachingPlatform: [
        { text: 'Dashboard', icon: <DashboardIcon />, url: '/platform/coach', action: null, needsHardRefresh: true },
        { text: 'Client Center', icon: <PeopleIcon />, url: '/platform/coach/leadership-center', action: null, needsHardRefresh: false },
        { text: 'Contacts', icon: <BookIcon />, url: '/platform/coach/contacts', action: null, needsHardRefresh: false },
        { text: 'Virtual Coach', icon: <ChatIcon />, url: '/platform/coach/virtualcoach', action: null, needsHardRefresh: true },
        { text: 'Call List', icon: <CallIcon />, url: '/platform/coach/calls', action: null, needsHardRefresh: false },
        { text: 'Principles', icon: <StarIcon />, url: '/platform/coach/principles', action: null, needsHardRefresh: false },
        { text: 'Next Steps', icon: <ListIcon />, url: '/platform/coach/steps', action: null, needsHardRefresh: false },
        { text: 'Culture', icon: <LightbulbIcon />, url: '/platform/coach/culture', action: null, needsHardRefresh: false },
        { text: 'Calendar', icon: <CalendarTodayIcon />, url: '/platform/coach/calendar', action: null, needsHardRefresh: false }
    ],
    clientPlatform: [
        { text: 'Dashboard', icon: <DashboardIcon />, url: '/platform/coachee', action: null, needsHardRefresh: true },
        { text: 'Call List', icon: <CallIcon />, url: '/platform/client/calls', action: null, needsHardRefresh: false },
        { text: 'Virtual Coach', icon: <ChatIcon />, url: '/platform/client/virtualcoach', action: null, needsHardRefresh: true },
        { text: 'Assignments', icon: <WorkHistoryIcon />, url: '/platform/client/assignments', action: null, needsHardRefresh: false },
        { text: 'Principles', icon: <StarIcon />, url: '/platform/client/principles', action: null, needsHardRefresh: false },
        { text: 'Next Steps', icon: <ListIcon />, url: '/platform/client/steps', action: null, needsHardRefresh: false },
    ],
    virtualcoach: [
        { text: 'Virtual Coach', icon: <TextsmsIcon />, url: '/bot', action: null, needsHardRefresh: true },
        { text: 'User Guide', icon: <PeopleIcon />, url: '/user_guide', action: null, needsHardRefresh: false },
        { text: 'User Settings', icon: <CallIcon />, url: '/settings', action: null, needsHardRefresh: false }
    ],
    genericPageItems: [
        { text: 'My Products', icon: <DashboardIcon />, url: '/products', action: null, needsHardRefresh: false },
        { text: 'Join the Discord', icon: <SettingsInputComponent />, url: 'https://discord.gg/6g4qbQbtbd', action: null, needsHardRefresh: false },
        { text: 'My Profile', icon: <PeopleIcon />, url: '/settings', action: null, needsHardRefresh: false },
        { text: 'Sign Out', icon: <Logout />, url: null, action: 'handleSignOut', needsHardRefresh: false },
    ],
    genericLastItems: [
        { text: 'Settings', icon: <SettingsInputComponent />, url: '/settings', action: null, needsHardRefresh: false },
        { text: 'Sign Out', icon: <Logout />, url: null, action: 'handleSignOut', needsHardRefresh: false },
    ]
};

