import { Box, Text, useFlow } from "@frigade/react";
import SupportArrowIcon from "components/Icons/SupportArrowIcon.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function ProgressBar({ defaultOpen = false }) {
  const flowId = "flow_HER5jgDw";
  const { flow } = useFlow(flowId);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen); // Track open/closed state with default

  const stepsCompleted = flow?.getNumberOfCompletedSteps() || 0;
  const totalSteps = flow?.getNumberOfAvailableSteps() || 0;
  const navigate = useNavigate();

  // Function to start the flow or continue from the saved step
  const handleStartFlow = () => {
    if (flow) {
      if (flow.isStarted) {
        console.log(
          "Flow has already started, navigating to the current step."
        );
        flow.restart(); // Restart the flow
        navigate("/platform/coach"); // Navigate after restarting
      } else {
        // Flow hasn't started, so start it
        flow
          .start()
          .then(() => {
            console.log("Flow started successfully");
            flow.reload();
            navigate("/platform/coach"); // Navigate after successful start
          })
          .catch((error) => {
            console.error("Error starting flow:", error);
          });
      }
    } else {
      console.error("Flow is not ready or available");
    }
  };

  // Toggle open/closed state
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (stepsCompleted === totalSteps && totalSteps > 0) {
      setIsCompleted(true);
    }
  }, [stepsCompleted, totalSteps]);

  if (!flow || isCompleted) {
    return null;
  }

  return (
    <Box
      onClick={handleStartFlow} // Toggle open/closed on click
      display="flex"
      flexDirection="column"
      border="1px solid #E6E7EC"
      borderRadius="10px"
      backgroundColor="#EFF3F6"
      py={2}
      px={3}
      gap={1}
      width="100%"
      padding="8px 16px 16px 16px"
      style={{ cursor: "pointer" }} // Change cursor to indicate clickability
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <Text.Body2 fontWeight="medium" color="black" fontSize="12.9px">
            Complete your onboarding
          </Text.Body2>
        </Box>
        <Box display="flex">
          <SupportArrowIcon />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        mt={1}
      >
        <Box display="flex" alignItems="center">
          <Text.Caption fontWeight="medium" color="black">
            {stepsCompleted}/{totalSteps}
          </Text.Caption>
        </Box>
        <Box
          display="flex"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {Array.from({ length: totalSteps }, (_, i) => {
            const stepNumber = i + 1;
            const isCompleted = stepNumber <= stepsCompleted;
            return (
              <Box
                key={i}
                backgroundColor={isCompleted ? "#007BFF" : "#E0E0E0"}
                borderRadius="5px"
                height="8px"
                width={`${100 / totalSteps}%`} // Ensures each step's bar is proportional
                display="flex"
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
