import React from "react";

function AssessmentHistoryIcon() {
  return (
    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9308 8.8076L14.8947 10.2018C13.5841 10.4618 12.6341 11.625 12.6259 12.9812L12.5774 20.2677M21.9308 8.8076L22.1365 8.76683C23.8765 8.42183 25.4915 9.78308 25.4797 11.5856L25.4782 11.7983M21.9308 8.8076L21.9322 8.59301C21.9435 6.79114 20.3285 5.42927 18.5885 5.77427L11.3466 7.20989C10.036 7.46926 9.08665 8.63301 9.0779 9.99051L9.0279 17.4892C9.01602 19.2917 10.631 20.6536 12.371 20.3086L12.5774 20.2677M12.5774 20.2677L12.5759 20.4824C12.5641 22.2843 14.1791 23.6456 15.9191 23.3012L16.1249 23.2604M16.1249 23.2604L16.1234 23.4733C16.1124 25.2751 17.7273 26.6368 19.4672 26.2918L26.709 24.8562C28.0196 24.5968 28.969 23.4337 28.9778 22.0762L29.0278 14.5762C29.0396 12.7743 27.4246 11.4124 25.6846 11.7574L25.4782 11.7983M16.1249 23.2604L16.174 15.973C16.1828 14.6155 17.1322 13.4531 18.4428 13.1931L25.4782 11.7983" stroke="#B0B7BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default AssessmentHistoryIcon;
