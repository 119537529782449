import { Box } from '@mui/material';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import VcProfilePicture from 'components/Icons/VcProfilePicture';

const LoadingSkeleton = () => {
    const dataArrayForSkeleton = [2, 3, 4, 5, 6];
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            height: 'auto',
            width: '100%',
            overflowY: 'scroll',
            my: 'end',
            color: 'black',
            paddingBottom: '20px',
        }}>
            {
                dataArrayForSkeleton?.map((number) => {
                    return number % 2 === 1 ?
                        // Assistant Messages
                        <Box key={number} sx={{
                            // border: '1px solid red',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: '80%',
                            mx: 'auto',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                maxWidth: "560px",
                                gap: '10px',
                            }}>
                                <Box sx={{
                                    height: 'auto',
                                    paddingTop: '10px',
                                }}>

                                    <VcProfilePicture />
                                </Box>
                                {/* <Box
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#EFF3F6',
                                        width: "300px",
                                        height: '120px'
                                    }}
                                >
                                </Box> */}
                                <Skeleton sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#EFF3F6',
                                    width: "300px",
                                    height: '120px'
                                }} variant="rounded" />

                            </Box>
                        </Box>
                        :
                        // User Messages
                        <Box
                            key={number}
                            sx={{
                                // border: '1px solid green',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '80%',
                                mx: 'auto',
                            }}>
                            {/* <Box sx={{
                                borderRadius: '10px',
                                backgroundColor: '#EFF3F6',
                                width: "300px",
                                height: '50px'
                            }}>     
                            </Box>
                            */}

                            <Skeleton sx={{
                                borderRadius: '10px',
                                backgroundColor: '#EFF3F6',
                                width: "300px",
                                height: '50px'
                            }} variant="rounded" animation='wave' />

                        </Box>
                })
            }
        </Box>
    );
};

export default LoadingSkeleton;

