import React from "react";

function AdminIcon({ color = "black" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1758 14.999H8C5.79086 14.999 4 16.7899 4 18.999C4 20.1036 4.89543 20.999 6 20.999H11.1758M18 17.999H18.01M16 6.99902C16 9.20816 14.2091 10.999 12 10.999C9.79086 10.999 8 9.20816 8 6.99902C8 4.78988 9.79086 2.99902 12 2.99902C14.2091 2.99902 16 4.78988 16 6.99902ZM18 13.999L19.1787 15.1535L20.8284 15.1706L20.8455 16.8204L22 17.999L20.8455 19.1777L20.8284 20.8275L19.1787 20.8446L18 21.999L16.8213 20.8446L15.1716 20.8275L15.1545 19.1777L14 17.999L15.1545 16.8204L15.1716 15.1706L16.8213 15.1535L18 13.999Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AdminIcon;
