import React, {useState} from "react";
import { Box, Modal, Button, CircularProgress, Typography, Divider } from "@mui/material";
import ProductChoose from "components/ProductChoose";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import { useStripeCheckoutVirtualCoach } from "hooks/useStripeCheckoutVirtualCoach";
import { useStripeCheckoutVirtualCoachAnnual } from "hooks/useStripeCheckoutVirtualCoachAnnual";
import { useNavigate } from "react-router-dom";

const AddProductModal = ({ user, userData, openAddProductModal, setOpenAddProductModal }) => {
  const navigate = useNavigate();
  const [selectedPricing, setSelectedPricing] = useState("Monthly");

  const {
    handleStripeCheckoutVirtualCoach,
    setProductIdVirtualCoach,
    loadingStripeCheckoutVirtualCoach,
    errorWithStripeCheckoutVirtualCoach,
  } = useStripeCheckoutVirtualCoach();

  const {
    handleStripeCheckoutVirtualCoachAnnual,
    setProductIdVirtualCoachAnnual,
    loadingStripeCheckoutVirtualCoachAnnual,
    setLoadingStripeCheckoutVirtualCoachAnnual,
    errorWithStripeCheckoutVirtualCoachAnnual,
  } = useStripeCheckoutVirtualCoachAnnual();

  // Personal Subs
  const [
    loadingStripeCheckoutCoachingPlatformPersonal,
    setLoadingStripeCheckoutCoachingPlatformPersonal,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatformPersonalAnnual,
    setLoadingStripeCheckoutCoachingPlatformPersonalAnnual,
  ] = useState(false);

  // Tier 1 Subs
  const [
    loadingStripeCheckoutCoachingPlatform1,
    setLoadingStripeCheckoutCoachingPlatform1,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform1Annual,
    setLoadingStripeCheckoutCoachingPlatform1Annual,
  ] = useState(false);

  // Tier 2 Subs
  const [
    loadingStripeCheckoutCoachingPlatform2,
    setLoadingStripeCheckoutCoachingPlatform2,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform2Annual,
    setLoadingStripeCheckoutCoachingPlatform2Annual,
  ] = useState(false);

  // Tier 3 Subs
  const [
    loadingStripeCheckoutCoachingPlatform3,
    setLoadingStripeCheckoutCoachingPlatform3,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform3Annual,
    setLoadingStripeCheckoutCoachingPlatform3Annual,
  ] = useState(false);

  // Hooks Coaching Platform
  const {
    handleStripeCheckoutCoachingPlatform,
    setProductIdCoachingPlatform,
    // loadingStripeCheckoutCoachingPlatform,
    errorWithStripeCheckoutCoachingPlatform,
  } = useStripeCheckoutCoachingPlatform();

  const [loadingEnterprise, setLoadingEnterprise] = useState(false);

  const handleCoachingPlatformClick = async (coachingPlatformProductId) => {
    await handleStripeCheckoutCoachingPlatform(coachingPlatformProductId);
  };

  const plans = [
    {
      name: "Virtual Coach",
      monthlyPrice: "$49/seat",
      annualPrice: "$490",
      description:
        "The Virtual Coach from AlterCall AI is a cutting-edge tool that provides 24/7 mentorship by combining the wisdom of world-renowned mentors with the precision of AI. This innovative platform offers personalized guidance, making high-quality executive coaching accessible and affordable for professionals at all levels.",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              handleStripeCheckoutVirtualCoach();
            }
          : () => {
              handleStripeCheckoutVirtualCoachAnnual();
            },
      loading:
        loadingStripeCheckoutVirtualCoach ||
        loadingStripeCheckoutVirtualCoachAnnual, // Combine loading states
      error:
        errorWithStripeCheckoutVirtualCoach ||
        errorWithStripeCheckoutVirtualCoachAnnual, // Display any error
      annualLoading: loadingStripeCheckoutVirtualCoachAnnual,
    },
    {
      name: "Executive",
      monthlyPrice: "$99",
      annualPrice: "$990",
      description:
        "Optimize performance by automatically analyzing our zoom calls to extract actions. Track those actions overtime to measure progress and improve productivity, communication, and organization.",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatformPersonal(true);
              handleCoachingPlatformClick("price_1OyblKLKxpQPkYt5aHNWNzzc");
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatformPersonalAnnual(true);
              handleCoachingPlatformClick("price_1PE2jFLKxpQPkYt5LyraVYUZ");
            },
      loading: loadingStripeCheckoutCoachingPlatformPersonal,
      annualLoading: loadingStripeCheckoutCoachingPlatformPersonalAnnual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
    {
      name: "Team",
      monthlyPrice: "$79/seat",
      annualPrice: "$1490",
      description:
        "Designed for large organizations and teams seeking to optimize leadership and coaching dynamics at scale, this advanced platform empowers executives to foster a positive and productive organizational culture while delivering measurable value to their clients. With comprehensive monitoring and analysis tools, it streamlines leadership, enhances client relationships, and drives impactful results.",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatform1(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1PubD5LKxpQPkYt5uTy53yac"
              );
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatform1Annual(true);
              handleStripeCheckoutCoachingPlatform();
              // ("price_1PE2ewLKxpQPkYt5Tj2tmaGQ");
            },
      loading: loadingStripeCheckoutCoachingPlatform1,
      annualLoading: loadingStripeCheckoutCoachingPlatform1Annual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
  ];

  const handleClose = () => {
    setOpenAddProductModal(false);
  };

  return (
    <Modal
      open={openAddProductModal}
      onClose={handleClose} // Corrected this to onClose
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          maxWidth: "calc(100% - 40px)",
          maxHeight: {
            xs: "calc(100% - 50px)",
            md: "80%",
          },
          overflowY: "auto",
          boxShadow: 24,
          borderRadius: "30px",
          padding: {
            xs: "24px",
            md: "64px",
          },
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box sx={{width: "100%", justifyContent: "center", textAlign: "center"}}>
        <Typography sx={{ color: "black", fontSize: "30px", fontFamily: "Poppins"}}>
          Select a Product
        </Typography>
        </Box>
            {plans.map((plan, index) => (
              <Box
                key={plan.name}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: "10px", md: "0px" },
                    background: "rgba(221, 229, 237, 0.2)",
                    padding: "30px 39px 29px 29px",
                    borderRadius: "18px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0D162F",
                      fontSize: "24px",
                      fontWeight: 400,
                      lineHeight: "32.69px",
                      width: { md: "226px" },
                      height: "33px",
                    }}
                  >
                    {plan.name}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (selectedPricing === "Monthly" && !plan.loading) {
                        plan.handleButtonClick();
                      } else if (
                        selectedPricing === "Annual" &&
                        !plan.annualLoading
                      ) {
                        plan.handleButtonClick();
                      }
                    }}
                    disabled={
                      selectedPricing === "Monthly"
                        ? plan.loading
                        : plan.annualLoading
                    }
                    sx={{
                      padding: "10px 20px",
                      width: "180px",
                      height: "35px",
                      border: "1px solid black",
                      borderRadius: "52px",
                      background:
                        plan.name === "Enterprise"
                          ? "transparent"
                          : "linear-gradient(88deg, #000 -6.51%, #535353 111.7%)",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        cursor: "pointer",
                        background:
                          plan.name !== "Enterprise" &&
                          "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                      },
                    }}
                  >
                    {plan.loading || plan.annualLoading ? (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    ) : (
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "10px",
                          color: plan.name === "Enterprise" ? "black" : "white",
                          textTransform: "uppercase",
                          fontWeight: 700,
                          lineHeight: "150%",
                          letterSpacing: "2px",
                        }}
                      >
                        {plan.name === "Enterprise"
                          ? "Contact Us"
                          : "Choose this plan"}
                      </Typography>
                    )}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: selectedPricing === "Monthly" ? "flex" : "none",
                    width: "200px",
                    height: "43px",
                    justifyContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize:
                        plan.monthlyPrice === "Inquire" ? "16px" : "24px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: "center",
                    }}
                  >
                    {plan.monthlyPrice}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: selectedPricing === "Annual" ? "flex" : "none",
                    width: "200px",
                    height: "43px",
                    justifyContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize:
                        plan.annualPrice === "Inquire" ? "16px" : "24px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: "center",
                    }}
                  >
                    {plan.annualPrice}
                    {selectedPricing === "Annual" &&
                      plan.annualPrice === "$490" && (
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Lexend",
                            color: "#568FF3",
                            whiteSpace: "nowrap",
                            marginBottom: "5px",
                            marginTop: {
                              lg: "-90px",
                              md: "-90px",
                              xs: "-230px",
                            },
                          }}
                        >
                          2 MONTHS FREE
                        </Typography>
                      )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginLeft: { md: "40px" },
                    width: { md: "600px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    {plan.description}
                  </Typography>
                </Box>
                {index < plans.length - 1 && (
                  <Divider 
                    key={`divider-${index}`} 
                    sx={{ width: "100%", my: 2, bgcolor: "grey.300" }} // Adjust styling if needed
                  />
                )}
              </Box>

            ))}
      </Box>
    </Modal>
  );
};

export default AddProductModal;
