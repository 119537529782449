import Agreeableness from './agreeableness'
import Extraversion from './extraversion'
import Neuroticism from './neuroticism'
import Conscientiousness from './conscientiousness'
import Openness from './openness'

const General = {
    domain: 'G',
    title: 'General',
}

const domains = [General, Agreeableness, Extraversion, Neuroticism, Conscientiousness, Openness]

export default domains
