import React, { useState, useEffect } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import UsersTable from "components/Table/CustomTable";
import ToolBar from "./ToolBar/ToolBar";
import { generateClient } from "aws-amplify/data";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import { GreenStatusPill, OrangeStatusPill } from "components/Table/StatusPills";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const VcUser = ({ user, userData }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [myTeamInformation, setMyTeamInformation] = useState([]);
  const [availableSeats, setAvailableSeats] = useState("");

  console.log("user data: ", user);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
          },
        }
      );
      setCompanyMembers(response.members);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyTeamInformation(response.myTeam);

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: response?.companyInformation?.owner_user_id,
        });

      console.log("seat info: ", responseSeats, errors);

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );

      console.log("response from fetch company information: ", response);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value}
        </Tooltip>
      ),
    },
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   width: 230,
    //   renderCell: (params) => (
    //     <Typography
    //     sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
    //     >
    //       {params.value}
    //     </Typography>
    //   )
    // },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value || ""}
        </Tooltip>
      ),
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value === "accepted" ? (
            <GreenStatusPill text={params.value} />
          ) : params.value === "pending" || params.value === "expired" ? (
            <OrangeStatusPill text={params.value} />
          ) : null}
        </Tooltip>
      ),
    },
  ];

  const rows = companyMembers.map((member, index) => {
    const teams = Array.isArray(member.teams)
      ? member.teams.map((team) => team.team_name || "").join(", ")
      : "No Team"; // Default to 'No Team' if teams array is not present or empty

    // Create a Set to keep track of unique admin IDs to avoid duplicates
    const uniqueAdminNames = new Set();

    // Convert the Set to an array to list all unique admin names
    const adminFullNames = Array.from(uniqueAdminNames);

    console.log("admin full names: ", adminFullNames);

    return {
      id: index,
      user_id: member.user_id || "",
      full_name: member.full_name || "",
      email: member.email || "",
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
    };
  });

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_6Qn3uhRC"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1">Teams & Members</span>
          </Typography> */}
          <Box
            id="hint-anchor-1"
          >
          <ToolBar
            user={user}
            userData={userData}
            selected={selected}
            fetchCompanyData={fetchCompanyData}
            companyInformation={companyInformation}
          />
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 25, 30]}
            checkboxSelection={false}
            loading={loading}
            onSelectionModelChange={(newSelection) => setSelected(newSelection)}
            disableColumnFilter
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VcUser;
