import { Button, Typography } from "@mui/material";
import { useState } from "react";
import ClientAddActionModal from "./ClientAddActionModal";


const ClientAddActionButton = ( {user, userData, loading, setLoading, fetchUserData} ) => {
    const [openAddActionModal, setOpenAddActionModal] = useState(false);
    return(
      <>
        <Button
          onClick={() => setOpenAddActionModal(true)}
          sx={{
            paddingX: "30px",
            paddingY: "10px",
            background: "white",
            border: "1px solid black",
            color: "black",
            borderRadius: "43px",
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s",
            "&:hover": {
              cursor: "pointer",
              background: "black",
              borderColor: "black",
            },
            "&:hover *": {
              color: "white",
            },
            "&:hover svg *": {
              stroke: "white",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 500,
              letterSpacing: "0.05em",
              textTransform: "none",
            }}
          >
            Add an Action
          </Typography>
        </Button>
        <ClientAddActionModal 
          user={user} 
          userData={userData}
          openAddActionModal={openAddActionModal}
          setOpenAddActionModal={setOpenAddActionModal}
          loading={loading}
          setLoading={setLoading}
          fetchUserData={fetchUserData}
        />
      </>
    )
}

export default ClientAddActionButton;