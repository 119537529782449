import { getUrl } from "aws-amplify/storage";

export const fetchAvatarUrl = async (avatarPath, cache = {}) => {
    if (!avatarPath) return null;

    // Return cached result if available
    if (cache[avatarPath]) return cache[avatarPath];

    try {
        const encodeFilePath = (path) => {
            const fileName = path.split("/").pop();
            return encodeURIComponent(fileName).replace(/%20/g, " ");
        };

        const encodedFileName = encodeFilePath(avatarPath);
        const filePath = `public/avatar/${encodedFileName}`;
        const { url } = await getUrl({
            path: filePath,
            expires: 900,
            validateObjectExistence: true,
        });

        // Cache the fetched URL
        cache[avatarPath] = url ? url.href : null;
        return cache[avatarPath];
    } catch (error) {
        console.error("Error fetching avatar URL:", error);
        return null;
    }
};
