/**
 * @param {*} date   "2023-02-01T00:00:00.000Z"
 * @returns  "Wednesday, February 1, 2023 at 12:00 AM"
 */
export function toDateTimeFormat(date) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

/**
 * @param {*} date   "2023-02-01T00:00:00.000Z"
 * @returns  "Wednesday, February 1, 2023"
 */
export function toDateFormat(date) {
  if (date) {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  } else {
    return ""
  }
}

export function getFormattedDate(date) {
  if (date) {
    const dates = new Date(date.toLocaleString());

    const day = dates.getDate().toString().padStart(2, "0");
    const month = dates.toLocaleString("default", { month: "short" });
    const year = dates.getFullYear();

    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  } else {
    return "";
  }
}