import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';

export default function AdminPortalViewTabs({ setSelectedView }) {
  // Determine the initial tab index from localStorage
  const initialTab = localStorage.getItem("selectedAdminView") === "chart" ? 1 : 0;

  return (
    <Tabs 
      defaultValue={initialTab} // Set the default value based on the initialTab
      onChange={(event, newValue) => setSelectedView(newValue)}
      style={{
        display: "flex",
        border: '1px solid black',
        borderRadius: '50pt',
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <TabsList>
        <Tab value={0}>List</Tab>
        <Tab value={1}>Chart</Tab>
      </TabsList>
    </Tabs>
  );
}

  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  //color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', lineHeight: 15, letterSpacing: 2, wordWrap: 'break-word'
  const Tab = styled(BaseTab)`
    color: black;
    font-family: Poppins;
    letter-spacing: 0.05em;
    cursor: pointer;
    font-size: 10pt;
    font-weight: 500;
    background-color: transparent;
    text-transform: capitalize;
    padding: 10px 30px;
    margin: 2px;
    border: none;
    border-radius: 50pt;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${grey[100]};
    }
  
    &:focus {
      outline: none;
    }
  
    &.${tabClasses.selected} {
      background-color: black;
      color: white;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(BaseTabPanel)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    border-radius: 50pt;
    border: black;
    opacity: 0.6;
    `,
  );
  
  const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
    background-color: white;
    color: black;
    border-radius: 50pt;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    `,
  );