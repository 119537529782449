import React from 'react';

const ToggleIcon = ({ isOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{
      transform: `rotate(${isOpen ? "180deg" : "0.05deg"})`,
      transition: "transform 0.3s ease",
      width: "var(--Spacing-spacing-06, 24px)",
      height: "var(--Spacing-spacing-06, 24px)",
      flexShrink: 0,
    }}
  >
    <path
      d="M8.00002 10.1392C9.06208 11.601 10.3071 12.9104 11.7021 14.0334C11.8774 14.1744 12.1226 14.1744 12.2979 14.0334C13.6929 12.9104 14.938 11.601 16 10.1392"
      stroke="#111111"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ToggleIcon;
