import React from 'react';

function VirtualCoach({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.57744 5.7627C9.03713 4.86966 9.26697 4.42314 9.57575 4.27846C9.84458 4.1525 10.1555 4.1525 10.4243 4.27846C10.7331 4.42314 10.9629 4.86966 11.4226 5.7627L12.6143 8.0778C12.694 8.23252 12.7338 8.30988 12.7853 8.37819C12.831 8.43884 12.8835 8.49407 12.9417 8.54283C13.0073 8.59776 13.0826 8.64148 13.233 8.72891L15.6194 10.1156C16.4013 10.57 16.7923 10.7972 16.9237 11.0933C17.0384 11.3516 17.0384 11.6464 16.9237 11.9047C16.7923 12.2009 16.4013 12.428 15.6194 12.8824L13.233 14.2691C13.0826 14.3566 13.0073 14.4003 12.9417 14.4552C12.8835 14.504 12.831 14.5592 12.7853 14.6199C12.7338 14.6882 12.694 14.7655 12.6143 14.9203L11.4226 17.2353C10.9629 18.1284 10.7331 18.5749 10.4243 18.7196C10.1555 18.8455 9.84458 18.8455 9.57575 18.7196C9.26697 18.5749 9.03713 18.1284 8.57744 17.2353L7.38575 14.9203C7.30611 14.7655 7.26629 14.6882 7.21477 14.6199C7.16905 14.5592 7.11656 14.504 7.05833 14.4552C6.99273 14.4003 6.9175 14.3566 6.76704 14.2691L4.38067 12.8824C3.59876 12.428 3.2078 12.2009 3.07635 11.9047C2.96169 11.6464 2.96169 11.3516 3.07635 11.0933C3.2078 10.7972 3.59876 10.57 4.38066 10.1156L6.76704 8.72891C6.9175 8.64148 6.99273 8.59776 7.05833 8.54283C7.11656 8.49407 7.16905 8.43884 7.21477 8.37819C7.26629 8.30988 7.30611 8.23252 7.38575 8.0778L8.57744 5.7627Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.4596 19.4052C17.2058 19.088 17.0789 18.9294 17.0314 18.7465C16.9896 18.5857 16.9896 18.4124 17.0314 18.2515C17.0789 18.0686 17.2058 17.91 17.4596 17.5929L18.2751 16.5734C18.5288 16.2562 18.6557 16.0976 18.802 16.0382C18.9307 15.986 19.0693 15.986 19.198 16.0382C19.3443 16.0976 19.4712 16.2562 19.725 16.5734L20.5405 17.5929C20.7943 17.91 20.9211 18.0686 20.9687 18.2515C21.0105 18.4124 21.0105 18.5857 20.9687 18.7465C20.9211 18.9294 20.7943 19.088 20.5405 19.4052L19.725 20.4246C19.4712 20.7418 19.3443 20.9004 19.198 20.9598C19.0693 21.0121 18.9307 21.0121 18.802 20.9598C18.6557 20.9004 18.5288 20.7418 18.2751 20.4246L17.4596 19.4052Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.2298 4.36149C18.1029 4.23461 18.0395 4.17118 18.0157 4.09803C17.9948 4.03368 17.9948 3.96437 18.0157 3.90002C18.0395 3.82687 18.1029 3.76343 18.2298 3.63656L18.6376 3.22878C18.7644 3.10191 18.8279 3.03847 18.901 3.0147C18.9654 2.9938 19.0347 2.9938 19.099 3.0147C19.1722 3.03847 19.2356 3.10191 19.3625 3.22878L19.7703 3.63656C19.8971 3.76343 19.9606 3.82687 19.9843 3.90002C20.0053 3.96437 20.0053 4.03368 19.9843 4.09803C19.9606 4.17118 19.8971 4.23461 19.7703 4.36149L19.3625 4.76926C19.2356 4.89614 19.1722 4.95957 19.099 4.98334C19.0347 5.00425 18.9654 5.00425 18.901 4.98334C18.8279 4.95957 18.7644 4.89614 18.6376 4.76926L18.2298 4.36149Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default VirtualCoach;
