import React from "react";

function Actions({ color, size = 24 }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99995 22.979H14M12 12.979V16.979M13 12.979H11M4.99995 10.7158C4.99995 6.99519 8.13396 3.979 12 3.979C15.8659 3.979 19 6.99519 19 10.7158C19 12.7553 18.0583 14.5832 16.5705 15.8186C15.9421 16.3404 15.4018 16.9883 15.1965 17.7789L14.9166 18.8561C14.7449 19.5174 14.148 19.979 13.4648 19.979H10.5351C9.85191 19.979 9.25504 19.5174 9.08327 18.8561L8.80343 17.7789C8.59806 16.9883 8.0578 16.3404 7.42939 15.8186C5.94164 14.5832 4.99995 12.7553 4.99995 10.7158Z" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Actions;
