import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';


export const _LeadershipCenterTeamFilter = ({
  value,
  handleChange,
  items = []
}) => {
  const [opened, setOpened] = useState(false);

  // Validate items
  const allItemsInvalid =
    Array.isArray(items) &&
    items.every(
      (item) =>
        !item.key || item.key === "none" || !item.value || item.value === "none"
    );

  const disabled = allItemsInvalid; // Disable the dropdown if all items are invalid

  const selectedTeamName =
    items.find((item) => item.value === value)?.key || "Team";

  return (
    <Box
      sx={{
        padding: "8px 6px",
        position: "relative",
        borderWidth: "1px",
        borderColor: "#D5D7DA",
        borderStyle: "solid",
        borderRadius: opened ? "8px 8px 0 0" : "8px",
        backgroundColor: disabled ? "#FAFCFE" : "white",
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        width: opened ? "150px" : "125px", // Adjust width based on `opened`
        minWidth: "125px",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        transition: "width 0.3s ease", // Smooth transition for width change
        height: "36px",
      }}
      aria-disabled={disabled} // Accessibility attribute
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!disabled) setOpened(!opened);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: disabled ? "#B0B7BD" : "black",
              fontSize: "10px",
              fontWeight: 500,
              letterSpacing: "0.4px",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
          >
            {selectedTeamName}
          </Typography>
          {!disabled &&
            value !== "none" &&
            value !== "" &&
            value !== null && (
              <CloseIcon
                sx={{
                  color: "black",
                  fontSize: "10px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange("none");
                }}
              />
            )}
        </Box>
        
          <KeyboardArrowDownIcon
            sx={{
              color: "#8093A1",
              transition: "all 0.3s ease",
              transform: `rotate(${opened === true ? "180deg" : "0deg"})`,
              width: "20px",
              height: "20px",
            }}
          />
        
      </Box>
      {!disabled && (
        <Box
          sx={{
            display: opened === true ? "flex" : "none",
            flexDirection: "column",
            position: "absolute",
            top: "30px",
            left: "-1px",
            zIndex: 10,
            width: "calc(100% + 2px)",
            borderWidth: "0 1px 1px",
            borderColor: "#CBD0C8",
            borderStyle: "solid",
            borderRadius: "0 0 8px 8px",
            maxHeight: "210px",
            overflowY: "auto",
            background: "white",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {items.map(
            (item, index) =>
              item.value !== "none" &&
              item.value &&
              item.key &&
              item.key !== "none" && (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    letterSpacing: "0.4px",
                    color: "black",
                    background: "white",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#EFF3F6",
                    },
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    margin: "4px 6px"
                  }}
                  onClick={() => {
                    setOpened(false);
                    handleChange(item.value);
                  }}
                >
                  {item.key}
                </Box>
              )
          )}
        </Box>
      )}
      {opened && !disabled && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
