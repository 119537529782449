import { useState, useCallback } from "react";
import { generateClient } from 'aws-amplify/data';
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

export const useCheckClientInviteAllowance = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkClientInviteAllowance = useCallback(async (userID) => {
    setLoading(true);

    if (!userID) {
      console.error("UserID is null or undefined.");
      setError(new Error("UserID is required and cannot be null."));
      setLoading(false);
      return;
    }

    // Construct the variables object to be passed into the GraphQL query
    // const variables = {
    //   userID: userID,
    // };

    try {
      // Use the `graphqlOperation` helper to construct the GraphQL operation
      // const response = await API.graphql(
      //   graphqlOperation(queries.checkClientInviteAllowance, variables)
      // );

      const { data, errors } = await resourceClient.models.coacheeSeatTracker.get({
        coach_id: userID
      });

      // console.log("data from check coachee invite allow: ", data)
      // console.log("errors from check coachee invite allow: ", errors)
      // Assuming your GraphQL response structure, adjust as necessary
      const { coachee_seat_tier_allowance, coachee_seat_count } = data || {};

      // Here you can return the response or handle it as needed
      return { clientTierAllowance: coachee_seat_tier_allowance, clientCount: coachee_seat_count };
    } catch (error) {
      console.error("Error fetching client counter:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    checkClientInviteAllowance,
    loading,
    error,
  };
};