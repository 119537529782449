import { useState, useCallback } from "react";
import { generateClient } from 'aws-amplify/data';
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({authMode: "userPool"});

export const useStripeCheckoutCoachingPlatform = () => {
  const [productId, setProductIdCoachingPlatform] = useState("");
  // const [
  //   loadingStripeCheckoutCoachingPlatform,
  //   setLoadingStripeCheckoutCoachingPlatform,
  // ] = useState(false);
  const [
    errorWithStripeCheckoutCoachingPlatform,
    setErrorWithStripeCheckoutCoachingPlatform,
  ] = useState(null);

  // Personal Subs
  const [
    loadingStripeCheckoutCoachingPlatformPersonal,
    setLoadingStripeCheckoutCoachingPlatformPersonal,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatformPersonalAnnual,
    setLoadingStripeCheckoutCoachingPlatformPersonalAnnual,
  ] = useState(false);

  // Tier 1 Subs
  const [
    loadingStripeCheckoutCoachingPlatform1,
    setLoadingStripeCheckoutCoachingPlatform1,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform1Annual,
    setLoadingStripeCheckoutCoachingPlatform1Annual,
  ] = useState(false);

  // Tier 2 Subs
  const [
    loadingStripeCheckoutCoachingPlatform2,
    setLoadingStripeCheckoutCoachingPlatform2,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform2Annual,
    setLoadingStripeCheckoutCoachingPlatform2Annual,
  ] = useState(false);

  // Tier 3 Subs
  const [
    loadingStripeCheckoutCoachingPlatform3,
    setLoadingStripeCheckoutCoachingPlatform3,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform3Annual,
    setLoadingStripeCheckoutCoachingPlatform3Annual,
  ] = useState(false);

  const [
    loadingStripeCheckoutCoachingPlatform4,
    setLoadingStripeCheckoutCoachingPlatform4,
  ] = useState(false);

  const handleStripeCheckoutCoachingPlatform = useCallback(
    async (selectedProductId) => {
      // setLoadingStripeCheckoutCoachingPlatformPersonal - 4 have been moved to be handled during click events
      // change is located in PricingTable.jsx
      // setLoadingStripeCheckoutCoachingPlatform(true);
      const stringifiedInput = JSON.stringify({
        selected_product_id: selectedProductId,
      });

      try {
        // const response = await API.graphql({
        //   query: fetchStripeCoachingPlatformTier,
        //   authMode: "AMAZON_COGNITO_USER_POOLS",
        //   variables: {
        //     input: stringifiedInput,
        //   },
        // });

        const { data, errors } = await resourceClient.queries.fetchStripeCoachingPlatformTier({
          input: stringifiedInput,
        });

        if (errors && errors.length > 0) {
          console.error("GraphQL query returned errors:", errors);
          setErrorWithStripeCheckoutCoachingPlatform(new Error("Failed to initiate Stripe checkout."));
          return;
        }

        const session = JSON.parse(data);
        window.location.href = session.sessionId; // Redirect to Stripe Checkout

      } catch (error) {
        console.error(
          "Error running Coaching Platform Checkout session:",
          error
        );
        setErrorWithStripeCheckoutCoachingPlatform(error);
      } finally {
        // setLoadingStripeCheckoutCoachingPlatform(false);
        if (
          loadingStripeCheckoutCoachingPlatformPersonal === "true" ||
          loadingStripeCheckoutCoachingPlatformPersonalAnnual === "true"
        ) {
          setLoadingStripeCheckoutCoachingPlatformPersonal(false);
        } else if (
          loadingStripeCheckoutCoachingPlatform1 === "true" ||
          loadingStripeCheckoutCoachingPlatform1Annual === "true"
        ) {
          setLoadingStripeCheckoutCoachingPlatform1(false);
        } else if (
          loadingStripeCheckoutCoachingPlatform2 === "true" ||
          loadingStripeCheckoutCoachingPlatform2Annual === "true"
        ) {
          setLoadingStripeCheckoutCoachingPlatform2(false);
        } else if (
          loadingStripeCheckoutCoachingPlatform3 === "true" ||
          loadingStripeCheckoutCoachingPlatform3Annual === "true"
        ) {
          setLoadingStripeCheckoutCoachingPlatform3(false);
        } else if (loadingStripeCheckoutCoachingPlatform4 === "true") {
          setLoadingStripeCheckoutCoachingPlatform4(false);
        }
      }
    },
    [productId]
  );

  return {
    handleStripeCheckoutCoachingPlatform,
    loadingStripeCheckoutCoachingPlatformPersonal,
    loadingStripeCheckoutCoachingPlatformPersonalAnnual,
    loadingStripeCheckoutCoachingPlatform1,
    loadingStripeCheckoutCoachingPlatform1Annual,
    loadingStripeCheckoutCoachingPlatform2,
    loadingStripeCheckoutCoachingPlatform2Annual,
    loadingStripeCheckoutCoachingPlatform3,
    loadingStripeCheckoutCoachingPlatform3Annual,
    loadingStripeCheckoutCoachingPlatform4,
    errorWithStripeCheckoutCoachingPlatform,
  };
};
