import React from 'react';

const MarkAsCompletedIcon = ({ color = "#B6B6B6", width = 25, height = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.62512 12.8218L10.9663 15.1605C12.0923 13.1915 13.6507 11.5039 15.5238 10.2248L15.6251 10.1556M21.2751 12.3094C21.2751 17.3628 17.1785 21.4594 12.1251 21.4594C7.07169 21.4594 2.9751 17.3628 2.9751 12.3094C2.9751 7.25602 7.07169 3.15942 12.1251 3.15942C17.1785 3.15942 21.2751 7.25602 21.2751 12.3094Z"
      stroke={color || "#B0B7BD"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MarkAsCompletedIcon;
