import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

export const _AssessmentsFilter = ({ value, handleChange, items }) => {
  const [opened, setOpened] = useState(false);

  // Check if all items are invalid (empty or "none")
  const allItemsInvalid =
    Array.isArray(items) &&
    items.every(
      (item) =>
        !item.key || item.key === "none" || !item.value || item.value === "none"
    );

  const disabled = allItemsInvalid; // Disable the dropdown if all items are invalid

  // Display "Most Recent" when value is cleared or set to "none"
  const selectedDate =
    items.find((item) => item.value === value)?.key || "Most Recent";

  return (
    <Box
      sx={{
        padding: "8px 6px",
        position: "relative",
        borderWidth: opened ? "1px 1px 0" : "1px",
        borderColor: "#D5D7DA",
        borderStyle: "solid",
        borderRadius: opened ? "8px 8px 0 0" : "8px",
        backgroundColor: disabled ? "#FAFCFE" : "white",
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        width: "auto", 
        minWidth: "100px", 
        maxWidth: "100%", 
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        height: "36px",
      }}
      aria-disabled={disabled} // Accessibility attribute
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!disabled) setOpened(!opened);
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: disabled ? "#B0B7BD" : "black",
              fontSize: "10px",
              fontWeight: 500,
              letterSpacing: "0.4px",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
          >
            {selectedDate}
          </Typography>

          {!disabled &&
          value !== "none" &&
          value !== "" &&
          value !== null ? (
            <CloseIcon
              sx={{
                color: "#8093A1",
                fontSize: "10px",
                cursor: "pointer",
                borderRadius: "50%",
                padding: "2px",
                width: "20px",
                height: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none"); // Clear the filter and trigger most recent selection
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              sx={{
                color: "#8093A1",
                transition: "all 0.3s ease",
                transform: `rotate(${opened ? "180deg" : "0deg"})`,
                width: "20px",
                height: "20px",
                cursor: disabled ? "not-allowed" : "pointer", // Always visible but non-functional when disabled
              }}
            />
          )}
        </Box>
      </Box>

      {!disabled && opened && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "30px",
            left: "-1px",
            zIndex: 10,
            width: "calc(100% + 2px)",
            borderWidth: "0 1px 1px",
            borderColor: "#CBD0C8",
            borderStyle: "solid",
            borderRadius: "0 0 8px 8px",
            maxHeight: "210px",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          {items.map(
            (item, index) =>
              item.key !== "none" &&
              item.value !== "none" && (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    color: "black",
                    backgroundColor: "white",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#EFF3F6",
                    },
                    margin: "4px 6px",
                    borderRadius: "8px",
                    border: "1px solid transparent",
                  }}
                  onClick={() => {
                    setOpened(false);
                    handleChange(item.value); // Select a specific assessment
                  }}
                >
                  {item.key}
                </Box>
              )
          )}
        </Box>
      )}

      {opened && !disabled && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
