import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { toast } from "react-toastify";

export const handleCompanySelect = async (company, user, userData, setSelectedCompany, navigate, loadingCompanySwitch, setLoadingCompanySwitch, currentPath) => {
  console.log("company to switch to: ", company);

  if (company.company_id !== userData?.companyInformation?.company_id) {
    setSelectedCompany(company);

    const loadingToastId = toast.loading("Switching company...");

    setLoadingCompanySwitch(true);

    try {
      // Call the REST API endpoint to switch company
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/user-switch-companies`, {
        user_id: user.sub,
        new_company_id: company.company_id,
      });

      if (response.status === 200) {
        // If the API call was successful, refetch Cognito session
        await refreshCognitoSession(user);

        // Remove loading toast
        toast.dismiss(loadingToastId);

        // Show success message
        toast.success(`Successfully switched to ${company.company_name}`);

        // Use the route returned by the backend to navigate
        const { redirect_route } = response.data;
        if (redirect_route) {
          if(currentPath === redirect_route){
            window.location.reload(); // Reload the page
          }else{
            navigate(redirect_route); // Navigate to the appropriate route
            window.location.reload();
          }
        } else {
          // Fallback if the route is not provided
            navigate("/authenticating"); // Navigate to the coach page
        }
      } else {
        // Handle failure
        toast.dismiss(loadingToastId);
        console.error("Failed to switch company");
        toast.error("Error switching companies");
      }
    } catch (error) {
      // Handle error
      toast.dismiss(loadingToastId);
      console.error("Error while switching company:", error);
      toast.error("Error while switching companies");
    } finally {
      setLoadingCompanySwitch(false);
    }
  }
};


const refreshCognitoSession = async (user) => {
  try {
    await fetchAuthSession({ forceRefresh: true });
    console.log("Session refreshed for user: ", user);
  } catch (error) {
    console.error("Failed to refresh Cognito session", error);
  }
};
