import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import * as Frigade from "@frigade/react";

const ClientCard = ({
  loading,
  zoomStatus,
  handleOpen,
  modal,
  client,
  assignedCallsSum,
  totalActionsSum,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "40px",
        padding: "30px",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        background:
          "linear-gradient(45deg, #FFFFFF 0%, #FFFFFF 70%, #98cbe9 100%)",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress sx={{ color: "black" }} />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 1,
            width: {
              xs: "100%",
              md: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: {
                    xs: "24px",
                  },
                  color: "black",
                  marginBottom: "8px",
                  lineHeight: "1.2",
                }}
                data-cy="client-center-title"
              >
                Leadership Center
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                "& > *": {
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid white",
                  marginRight: "-16px",
                },
              }}
            >
              {client?.map(
                (item, index) =>
                  index < 3 && (
                    <Avatar
                      key={index}
                      alt={item.full_name}
                      src={item.avatar || item.full_name}
                      sx={{ width: 42, height: 42 }}
                    />
                  )
              )}
              {client?.length > 3 && (
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 300,
                    lineHeight: "1.2",
                    background: "white",
                    color: "black",
                    zIndex: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  +{client?.length - 3}
                </Typography>
              )}
            </Box>
          </Box>
          <Typography
            sx={{
              color: "black",
              fontFamily: "Lexend",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "8px",
              lineHeight: "1.2",
              marginTop: "-3px",
            }}
          >
            {zoomStatus && "Check in with coachee activity"}
            {!zoomStatus &&
              "Finish setting up your account by logging into zoom using the button below"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              paddingY: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "56px",
                  fontWeight: 300,
                  lineHeight: "normal",
                  padding: "0px",
                }}
              >
                {assignedCallsSum ?? 0}
              </Typography>
              <Box display="flex">
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Successful Shares
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "56px",
                  fontWeight: 300,
                  lineHeight: "normal",
                  padding: "0px",
                }}
              >
                {totalActionsSum}
              </Typography>
              <Box display="flex">
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Actions Generated
                </Typography>
              </Box>
            </Box>
          </Box>
          {zoomStatus && (
            <Box
              sx={{
                display: "flex",
                marginTop: "auto",
              }}
            >
              <Frigade.Tour
                flowId="flow_HER5jgDw"
                defaultOpen={true}
                sequential={true}
                dismissible={true}
                css={{
                  ".fr-card": {
                    backgroundColor: "#EFF3F6",
                  },
                  ".fr-button-primary": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                    borderRadius: "50px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    transition: "background-color 0.3s, color 0.3s",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  },
                }}
              />
              <Button
                sx={{
                  display: " flex",
                  width: {
                    xs: "100%",
                    md: "220px",
                  },
                  padding: "10px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: " 10px",
                  borderRadius: "52px",
                  border: "1px solid #578FF2",
                  borderColor: "#578FF2",
                  background: "white",
                  marginTop: "15px",
                  opacity: 1,
                  transition: "all 0.3s",
                  marginBottom: "-15px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                }}
                onClick={() => {
                  navigate("/platform/coach/leadership-center");
                }}
                data-cy="client-center-button"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Visit Leadership Center
                </Typography>
              </Button>
            </Box>
          )}
          {!zoomStatus && (
            <Box
              sx={{
                display: "flex",
                marginTop: "auto",
              }}
            >
              <Button
                sx={{
                  display: " flex",
                  width: {
                    xs: "100%",
                    md: "220px",
                  },
                  padding: "10px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: " 10px",
                  borderRadius: "52px",
                  border: "1px solid #578FF2",
                  borderColor: "#578FF2",
                  background: "white",
                  marginTop: "15px",
                  opacity: 1,
                  transition: "all 0.3s",
                  marginBottom: "-15px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                }}
                onClick={handleOpen}
                data-cy="zoom-check"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Connect to Zoom
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {modal}
    </Box>
  );
};

export default ClientCard;
