import React from 'react';

function ContentLibrarySearchIcon({ color = '#111111' }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="search/search-big">
        <path 
          id="vector" 
          d="M21 21.5L17.5104 18.0104M17.5104 18.0104C19.0486 16.4722 20 14.3472 20 12C20 7.30558 16.1944 3.5 11.5 3.5C6.80558 3.5 3 7.30558 3 12C3 16.6944 6.80558 20.5 11.5 20.5C13.8472 20.5 15.9722 19.5486 17.5104 18.0104Z" 
          stroke={color} // Use the color prop for the stroke
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default ContentLibrarySearchIcon;
