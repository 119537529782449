import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { signIn } from "slices/UserSlice";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Header } from "components/GlobalHeader/GlobalHeader";
import googleImg from "assets/google.png";
import { Footer } from "scenes/login/Footer";
import { toast } from "react-toastify";
import LoginForm from "scenes/login/LoginForm";
import glowEffect from "assets/glowEffect.png";
import vector from "assets/Vector.png";
import Products from "components/Products";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: [
      "Lexend Peta",
      "Montseratt",
      "Source Sans Pro",
      "sans-serif",
    ].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const CoachingSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const username = user?.given_name
    ? user?.given_name
    : "Coach";

  return (
    <Box position="relative">
      <Box
        zIndex="30"
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        display="flex"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          pointerEvents: "none",
          zIndex: "10",
        }}
      >
        <Box
          width="100%"
          component="img"
          src={glowEffect}
          alt=""
          marginLeft="-1000px"
          marginTop="100px"
          sx={
            {
              // filter: "hue-rotate(281deg)",
            }
          }
        />
      </Box>
      <Box
        zIndex="30"
        position="absolute"
        bottom="0px"
        left="0px"
        right="0px"
        display="flex"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="500px" component="img" src={vector} alt="" />
      </Box>
      <Header
        typeOfNav="genericPageItems"
        type="platform"
        hideGlobalSearchbar={true}
      />
      <ThemeProvider theme={theme}>
        <Grid container marginTop={18} marginBottom={18}>
          <Grid item xs={0} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              height="70vh"
              gap="20px"
              marginTop={{
                xs: "100px",
                md: "0px",
              }}
            >
              <Box zIndex="30">
                <Typography
                  color="rgba(35, 39, 46, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                  textAlign="center"
                  letterSpacing="7px"
                >
                  ALTERCALL AI
                </Typography>
                <Typography
                  color="black"
                  fontSize="54px"
                  fontWeight="600"
                  textAlign="center"
                  letterSpacing="-4%"
                >
                  WELCOME {username}
                </Typography>
                <Typography
                  color="rgba(168, 168, 168, 1)"
                  fontSize="16px"
                  textAlign="center"
                  fontWeight="300"
                >
                  Please select your desired product
                </Typography>
              </Box>
              <Products />
              <Box
                zIndex="30"
                position="absolute"
                bottom="0px"
                left="700px"
                right="0px"
                display="flex"
                width="100%"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                top="800px"
              >
                <Box
                  width="500px"
                  component="img"
                  src={glowEffect}
                  alt=""
                  sx={{
                    transform: "rotate(180deg)",
                    // filter: "hue-rotate(281deg)",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={0} md={1} lg={1} />
        </Grid>
        {/* <Footer /> */}
      </ThemeProvider>
    </Box>
  );
};

export default CoachingSignUp;
