import React from 'react';

function SupportArrowIcon({ color = '#111111', width = '6', height = '11' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9.02148C2.45386 7.97015 3.7576 6.73953 4.87811 5.36157C5.04063 5.1617 5.04063 4.88127 4.87811 4.6814C3.7576 3.30344 2.45386 2.07282 1 1.02148"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SupportArrowIcon;
