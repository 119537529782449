const TheBigFiveChoices = {
    plus: [
      {
        text: 'Very Inaccurate',
        score: 1,
        color: 1
      },
      {
        text: 'Moderately Inaccurate',
        score: 2,
        color: 2
      },
      {
        text: 'Neither Accurate Nor Inaccurate',
        score: 3,
        color: 3
      },
      {
        text: 'Moderately Accurate',
        score: 4,
        color: 4
      },
      {
        text: 'Very Accurate',
        score: 5,
        color: 5
      }
    ],
    minus: [
      {
        text: 'Very Inaccurate',
        score: 5,
        color: 1
      },
      {
        text: 'Moderately Inaccurate',
        score: 4,
        color: 2
      },
      {
        text: 'Neither Accurate Nor Inaccurate',
        score: 3,
        color: 3
      },
      {
        text: 'Moderately Accurate',
        score: 2,
        color: 4
      },
      {
        text: 'Very Accurate',
        score: 1,
        color: 5
      }
    ]
  }
  
  export default TheBigFiveChoices;