import React from 'react';

function SupportIcon({ color = '#111111' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <path 
        stroke={color} 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        d="M2.5 9.101c0-2.128 0-3.192.414-4.005a3.8 3.8 0 0 1 1.66-1.66c.813-.415 1.877-.415 4.006-.415h6.839c2.128 0 3.192 0 4.005.415a3.8 3.8 0 0 1 1.66 1.66c.415.813.415 1.877.415 4.005v4.56c0 .706 0 1.059-.047 1.354a3.8 3.8 0 0 1-3.159 3.159c-.57.09-1.149.001-1.72.055a1.9 1.9 0 0 0-1.232.616c-.334.369-.611.801-.91 1.2-.825 1.1-1.237 1.65-1.743 1.847a1.9 1.9 0 0 1-1.378 0c-.505-.197-.918-.747-1.743-1.848-.298-.398-.575-.83-.91-1.2a1.9 1.9 0 0 0-1.232-.616c-.57-.053-1.15.036-1.72-.054a3.8 3.8 0 0 1-3.158-3.16c-.047-.294-.047-.647-.047-1.353V9.1Z"
      />
      <path 
        stroke={color} 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        d="M11.992 8.021a3 3 0 0 0 2.805 2.992c.043.003.094.005.195.008-.101.004-.152.006-.195.009A3 3 0 0 0 12 13.827a5.092 5.092 0 0 0-.008.194l-.009-.194a3 3 0 0 0-2.796-2.797 5.174 5.174 0 0 0-.195-.008l.195-.009a3 3 0 0 0 2.796-2.797c.003-.043.005-.094.009-.195Z"
      />
    </svg>
  );
}

export default SupportIcon;
