import { Button, Typography } from "@mui/material";

const DeclineButton = ({ onClick, label }) => {
  return (
    <Button
        sx={{
            paddingX: "80pt",
            paddingY: "10pt",
            borderRadius: "53px",
            background: "#FFFFFF",
            color: "#C10404",
            border: "1px solid #BE0808",
            fontWeight: "bold",
            "&:hover": {
              background: "rgba(190, 8, 8, 0.4)",
              border: "1px solid rgba(190, 8, 8, 0.4)",
              color: "white",
            },
        }}
        onClick={onClick}
    >
       <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "500",
            letterSpacing: "0.05em",
            lineHeight: "18px",
            textTransform: "Capitalize",
          }}
        >
          {label}
        </Typography>
    </Button>
  );
};

export default DeclineButton;
