export const verticalLineSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="340" height="1863" viewBox="0 0 340 1863" fill="none">
    <path d="M0 0H340V1863H0V0Z" fill="url(#paint0_linear_9403_19469)"/>
    <defs>
        <linearGradient id="paint0_linear_9403_19469" x1="294.376" y1="192.895" x2="-297.896" y2="273.282" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
    </defs>
</svg>
`;

export const backgroundSvg = `
<svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_1888)"/>
    <defs>
        <linearGradient id="paint0_linear_4460_1888" x1="1298.72" y1="204.388" x2="-718.232" y2="1344.22" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
    </defs>
</svg>
`;
export const logoSvg3 = `
<svg width="271" height="52" viewBox="0 0 271 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2724 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7168C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28892 3.33332 3.36529 7.05953 7.62939e-06C7.70601 0.673761 8.35337 1.34838 8.99985 2.02301C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.50201C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96382C40.1636 1.3214 40.8275 0.678984 41.4932 0.0356974C50.7036 8.38623 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
    <path d="M91.8662 28.2079H81.6535L79.4539 33.1328H77.4375L85.8172 14.6973H87.7288L96.1084 33.1328H94.0659L91.8662 28.2079ZM91.1592 26.6277L86.7599 16.7252L82.3606 26.6277H91.1592Z" fill="white"/>
    <path d="M104.376 14.6974H106.314V31.4474H116.579V33.1329H104.376V14.6974Z" fill="white"/>
    <path d="M126.898 16.3829H120.456V14.6974H135.278V16.3829H128.836V33.1329H126.898V16.3829Z" fill="white"/>
    <path d="M156.564 31.4473V33.1328H143.602V14.6973H156.171V16.3828H145.539V22.9406H155.019V24.5998H145.539V31.4473H156.564Z" fill="white"/>
    <path d="M178.887 33.1329L174.697 27.2072C174.226 27.2599 173.755 27.2862 173.231 27.2862H168.308V33.1329H166.37V14.6974H173.231C177.892 14.6974 180.72 17.0677 180.72 21.0181C180.72 23.9151 179.201 25.9431 176.53 26.8122L181.008 33.1329H178.887ZM178.782 21.0181C178.782 18.0685 176.845 16.3829 173.179 16.3829H168.308V25.627H173.179C176.845 25.627 178.782 23.9151 178.782 21.0181Z" fill="white"/>
    <path d="M188.905 23.9178C188.905 18.5188 192.99 14.542 198.516 14.542C201.187 14.542 203.596 15.4374 205.219 17.2283L203.989 18.4661C202.496 16.9386 200.663 16.2802 198.568 16.2802C194.143 16.2802 190.817 19.5459 190.817 23.9178C190.817 28.2896 194.143 31.5554 198.568 31.5554C200.663 31.5554 202.496 30.8969 203.989 29.3431L205.219 30.5809C203.596 32.3718 201.187 33.2936 198.49 33.2936C192.99 33.2936 188.905 29.3168 188.905 23.9178Z" fill="white"/>
    <path d="M225.622 28.208H215.409L213.21 33.1329H211.193L219.573 14.6974H221.485L229.864 33.1329H227.822L225.622 28.208ZM224.915 26.6278L220.516 16.7253L216.116 26.6278H224.915Z" fill="white"/>
    <path d="M238.132 14.6974H240.07V31.4474H250.335V33.1329H238.132V14.6974Z" fill="white"/>
    <path d="M258.797 14.6974H260.735V31.4474H271V33.1329H258.797V14.6974Z" fill="white"/>
</svg>
`;
export const gradientEllipseSvg = `
<svg width="1495" height="1474" viewBox="0 0 1495 1474" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4460_1889" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1495" height="1474">
        <rect width="1495" height="1474" rx="20" fill="url(#paint0_linear_4460_1889)"/>
    </mask>
    <g mask="url(#mask0_4460_1889)">
        <circle opacity="0.3" cx="317.881" cy="1989.57" r="1459.04" stroke="#6EA4FF"/>
        <path d="M-1081.79 2186.25C-1058.13 2364.38 -1001.27 2534.34 -912.797 2691.58L-861.788 2662.61C-946.495 2511.97 -1000.96 2349.16 -1023.63 2178.56C-1047.4 1999.53 -1034.99 1819.69 -986.696 1644.06C-938.4 1468.42 -857.133 1307.51 -745.143 1165.81C-636.975 1028.94 -504.837 915.624 -352.412 829.008C-199.988 742.392 -34.9421 686.894 138.096 663.998C317.236 640.308 497.198 652.801 672.964 701.133C848.73 749.465 1009.78 830.742 1151.62 942.695C1288.62 1050.85 1402.06 1182.94 1488.79 1335.3C1575.53 1487.66 1631.12 1652.62 1654.1 1825.55C1677.88 2004.59 1665.45 2184.42 1617.15 2360.06C1568.85 2535.69 1487.59 2696.6 1375.62 2838.31C1267.97 2974.55 1136.53 3087.43 985.006 3173.89L1012.9 3225.51C1171.51 3135.22 1309.04 3017.18 1421.69 2874.65C1538.55 2726.75 1623.36 2558.85 1673.75 2375.6C1724.14 2192.36 1737.1 2004.7 1712.29 1817.84C1688.32 1637.37 1630.29 1465.26 1539.81 1306.3C1449.34 1147.34 1330.96 1009.5 1187.99 896.652C1039.95 779.792 871.905 694.988 688.52 644.561C505.135 594.134 317.347 581.11 130.378 605.831C-50.1947 629.715 -222.399 687.643 -381.43 777.996C-540.46 868.35 -678.336 986.594 -791.206 1129.43C-908.085 1277.33 -992.879 1445.23 -1043.27 1628.48C-1093.66 1811.72 -1106.62 1999.38 -1081.81 2186.24L-1081.79 2186.25Z" fill="url(#paint1_linear_4460_1889)"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_4460_1889" x1="1530.81" y1="-193.08" x2="224.575" y2="153.393" gradientUnits="userSpaceOnUse">
            <stop stop-color="#03070E"/>
            <stop offset="1" stop-color="#478DFF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4460_1889" x1="-433.478" y1="621.878" x2="1518.83" y2="1401.08" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3480FF" stop-opacity="0"/>
            <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
            <stop offset="0.426471" stop-color="#3480FF"/>
            <stop offset="1"/>
        </linearGradient>
    </defs>
</svg>
`;
export const learnMoreSvg = `
<svg width="273" height="58" viewBox="0 0 273 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="white"/>
    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="#FF5E00"/>
    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" stroke="#FF5E00"/>
</svg>
`;
export const vectorFooterwhiteSvg = `
<svg width="49" height="52" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2723 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7167C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28891 3.33332 3.36528 7.05953 0C7.70601 0.673753 8.35337 1.34838 8.99985 2.023C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.502C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96381C40.1636 1.32139 40.8275 0.678976 41.4932 0.0356898C50.7036 8.38622 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
</svg>
`;
export const page2BackgroundSvg = `
<svg width="1500" height="1861" viewBox="0 0 1500 1861" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1500" height="1861" fill="url(#paint0_linear_9403_19580)"/>
    <defs>
        <linearGradient id="paint0_linear_9403_19580" x1="1603" y1="840.273" x2="-271.332" y2="-1886.93" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="0.59" stop-color="#3480FF" stop-opacity="0.86"/>
            <stop offset="1" stop-color="white" stop-opacity="0.1"/>
        </linearGradient>
    </defs>
</svg>
`;
export const Namesvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="192" height="40" viewBox="0 0 192 40" fill="none">
    <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5H172C182.77 0.5 191.5 9.23045 191.5 20C191.5 30.7696 182.77 39.5 172 39.5H20C9.23045 39.5 0.5 30.7696 0.5 20Z" fill="#F4F4F4" stroke="white"/>
</svg>
`;
export const vectorFooterBlackSvg = `
<svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
</svg>
`;
export const newSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="192" height="40" viewBox="0 0 192 40" fill="none">
    <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5H172C182.77 0.5 191.5 9.23045 191.5 20C191.5 30.7696 182.77 39.5 172 39.5H20C9.23045 39.5 0.5 30.7696 0.5 20Z" fill="#F4F4F4" stroke="white"/>
</svg>
`;
// pieCircleSvg.js
export function generatePieCircleSvg(colorPieHex, domainscore1) {
    return `
      <svg width="191" height="191" viewBox="0 0 191 191" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="95.1235" cy="95.1235" r="95.1235" fill="#${colorPieHex}" />
        <text x="50%" y="50%" text-anchor="middle" fill="#FFF" font-size="88px" font-weight="300" dy=".3em" style="text-align: center; line-height: 160%; font-family: 'Poppins', sans-serif;">${domainscore1}</text>
      </svg>`;
}
export const gradientSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="1500" height="1863" viewBox="0 0 1500 1863" fill="none">
    <path d="M0 0H1500V1863H0V0Z" fill="url(#paint0_linear_9403_19718)"/>
    <defs>
        <linearGradient id="paint0_linear_9403_19718" x1="631.5" y1="1753.99" x2="2299.47" y2="582.022" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
    </defs>
</svg>
`;

export const logoSvg = `
<svg width="276" height="156" viewBox="0 0 276 156" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M166.664 65.6049C171.849 74.6734 177.038 83.7497 182.275 92.9114C180.762 93.7765 179.295 94.6178 177.737 95.5076C172.618 86.5573 167.518 77.6407 162.454 68.7871C146.173 78.2908 130.011 78.2908 113.703 68.7882C108.651 77.6204 103.549 86.5415 98.4221 95.5053C96.8901 94.6291 95.4199 93.7877 93.8833 92.9103C99.1083 83.7699 104.296 74.6947 109.498 65.5993C98.0791 55.4612 92.592 42.8349 93.7135 27.5742C94.4874 17.0604 99.6797 6.18097 106.508 0C107.692 1.23732 108.879 2.47689 110.063 3.71533C93.2658 20.2538 96.0577 47.4141 112.216 60.8491C120.815 45.8123 129.405 30.7868 138.08 15.6161C146.746 30.771 155.337 45.7954 163.931 60.8255C179.511 48.0732 183.339 21.1975 165.96 3.60735C167.175 2.4274 168.392 1.24744 169.611 0.0663653C186.49 15.4035 188.643 48.053 166.663 65.6061H166.664V65.6049ZM116.396 64.0785C130.164 73.4574 148.411 72.1526 159.735 64.0279C152.545 51.4522 145.351 38.8721 138.08 26.1558C130.804 38.8811 123.611 51.4612 116.396 64.0774L116.396 64.0785Z" fill="white"/>
<path d="M20.5421 148.821L6.00242 148.821L2.87102 155.771L1.66699e-10 155.771L11.9304 129.751L14.6513 129.751L26.5817 155.771L23.6735 155.771L20.5421 148.821ZM19.5351 146.59L13.2723 132.614L7.00948 146.59L19.5351 146.59Z" fill="white"/>
<path d="M38.3623 129.751L41.1217 129.751L41.1217 153.392L55.7358 153.392L55.7358 155.771L38.3637 155.771L38.3637 129.751H38.3623Z" fill="white"/>
<path d="M70.423 132.13L61.252 132.13L61.252 129.751L82.3534 129.751L82.3534 132.13L73.1824 132.13L73.1824 155.771L70.423 155.771L70.423 132.13Z" fill="white"/>
<path d="M112.662 153.392L112.662 155.771L94.2085 155.771L94.2085 129.751L112.103 129.751L112.103 132.13L96.9665 132.13L96.9665 141.385L110.462 141.385L110.462 143.727L96.9665 143.727L96.9665 153.392L112.662 153.392Z" fill="white"/>
<path d="M144.424 155.771L138.459 147.407C137.788 147.482 137.117 147.519 136.372 147.519L129.363 147.519L129.363 155.771L126.604 155.771L126.604 129.751L136.372 129.751C143.008 129.751 147.033 133.096 147.033 138.673C147.033 142.762 144.87 145.623 141.068 146.85L147.442 155.771L144.423 155.771L144.424 155.771ZM144.275 138.673C144.275 134.509 141.516 132.13 136.297 132.13L129.363 132.13L129.363 145.177L136.297 145.177C141.517 145.177 144.275 142.76 144.275 138.671V138.673Z" fill="white"/>
<path d="M158.701 142.762C158.701 135.143 164.517 129.529 172.382 129.529C176.184 129.529 179.615 130.792 181.926 133.32L180.174 135.067C178.05 132.911 175.439 131.982 172.457 131.982C166.157 131.982 161.422 136.592 161.422 142.762C161.422 148.932 166.157 153.542 172.457 153.542C175.439 153.542 178.048 152.612 180.174 150.42L181.926 152.167C179.615 154.695 176.186 155.995 172.345 155.995C164.516 155.995 158.701 150.383 158.701 142.762Z" fill="white"/>
<path d="M210.969 148.821L196.43 148.821L193.298 155.771L190.427 155.771L202.358 129.751L205.079 129.751L217.009 155.771L214.101 155.771L210.969 148.821ZM209.962 146.59L203.7 132.614L197.437 146.59L209.962 146.59Z" fill="white"/>
<path d="M228.79 129.751L231.549 129.751L231.549 153.392L246.163 153.392L246.163 155.771L228.791 155.771L228.791 129.751H228.79Z" fill="white"/>
<path d="M258.203 129.751L260.963 129.751L260.963 153.392L275.577 153.392L275.577 155.771L258.205 155.771L258.205 129.751H258.203Z" fill="white"/>
</svg>
`;