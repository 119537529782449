import { Box, Button, Typography } from "@mui/material";
import Logo from "assets/altercall1.svg";
import { useNavigate } from "react-router-dom";

const CompanyLogo = () => {
  return (
    <Box
      sx={{ display: "block" }}
    >
      <a
        href="https://altercall.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Logo} style={{ width: "150px" }} alt="Company Logo" />
      </a>
    </Box>
  );
}

export const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box
      id="Footer"
      display="flex"
      justifyContent="center"
      border="1px solid red"
    >
      <Box
        display="grid"
        rowGap={{
          xs: "50px",
          lg: "20px",
        }}
        width="100%"
        sx={{
          marginBottom: "50px",
        }}
      >
        <Box
          gridColumn="span 12"
          gridRow="3"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="20px"
          height="50px"
          marginBottom={{ xs: "80px", lg: "auto" }}
        >
          <CompanyLogo />
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="40px"
        >
          <Box
            display="flex"
            backgroundColor="transparent"
            width={"100%"}
            justifyContent={"space-between"}
            flexDirection={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
            }}
            alignItems={"center"}
            sx={{
              width: {
                sm: "80%",
              },
              left: {
                sm: "50%",
              },
              transform: {
                sm: "translateX(-50%)",
              },
              position: {
                sm: "absolute",
              },
            }}
          >
            <Button
              onClick={() =>
              (window.location.href =
                "https://calendly.com/altercallai/aai-product-demo")
              }
              style={{
                textDecoration: "none",
                textTransform: "none",
                fontSize: "22px",
                marginRight: "0px",
                fontWeight: "500",
                border: "none",
                color: "#000",
                background: "none",
              }}
            >
              Contact Us
            </Button>
            <Button
              onClick={() => handleNavigate("/terms-login")}
              style={{
                textTransform: "none",
                fontSize: "22px",
                marginRight: "0px",
                fontWeight: "500",
                border: "none",
                color: "#000",
                background: "none",
              }}
            >
              Terms of Use
            </Button>
            <Button
              onClick={() => handleNavigate("/privacy-policy")}
              style={{
                textDecoration: "none",
                textTransform: "none",
                fontSize: "22px",
                marginRight: "0px",
                fontWeight: "500",
                border: "none",
                color: "#000",
                background: "none",
              }}
            >
              Privacy Policy
            </Button>

            <Button
              onClick={() => handleNavigate("/resources")}
              style={{
                textDecoration: "none",
                textTransform: "none",
                fontSize: "22px",
                marginRight: "0px",
                fontWeight: "500",
                border: "none",
                background: "none",
                color: "#000",
              }}
            >
              Resources
            </Button>
            <Button
              onClick={() => handleNavigate("/support")}
              style={{
                textDecoration: "none",
                textTransform: "none",
                fontSize: "22px",
                marginRight: "0px",
                fontWeight: "500",
                border: "none",
                background: "none",
                color: "#000",
              }}
            >
              Support
            </Button>
            <Typography
              display={{
                xs: "block",
                md: "none",
              }}
              marginTop={"40px"}
              marginBottom={"70px"}
              fontWeight={400}
              color={"#93989A"}
              fontSize={"14px"}
            >
              © AlterCall 2024
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
