import React, { useEffect, useRef, useState } from 'react';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Tooltip from '@mui/material/Tooltip';


const TextToSpeech = ({ text, id }) => {
    const [utterance, setUtterance] = useState(null);

    const [isPlaying, setIsPlaying] = useState(false);

    useEffect((() => {
        const textWithoutLinks = removeLinksFromMarkdown(text);
        const u = new SpeechSynthesisUtterance(textWithoutLinks);
        u.voice = window.speechSynthesis.getVoices()[1];
        u.pitch = 0.7;

        u.onend = () => {
            window.speechSynthesis.cancel();
            setIsPlaying(false);
        }

        setUtterance(u);

        return () => {
            window.speechSynthesis.cancel();
        }
    }), [text])

    function removeLinksFromMarkdown(markdownText) {
        // This regex matches markdown links of the form [text](url)
        const regex = /\[([^\]]+)\]\([^\)]+\)/g;
        return markdownText.replace(regex, '$1'); // Replace the whole link with just the link text
    }


    const handlePlayClick = () => {
        if (utterance) {
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(utterance);
            setIsPlaying(true);
        }
    };


    const handleStopClick = () => {
        window.speechSynthesis.cancel();
        setIsPlaying(false);
    };



    return (
        <>
            {
                isPlaying ?
                    <Tooltip title="Stop Voice">
                        <StopCircleIcon
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                fontSize: '18px',
                            }}
                            onClick={handleStopClick} />
                    </Tooltip>
                    :
                    <Tooltip title="Read Aloud">
                        <VolumeUpIcon sx={{
                            color: 'black',
                            cursor: 'pointer',
                            fontSize: '18px',
                        }}
                            onClick={handlePlayClick} />
                    </Tooltip>
            }
        </>

    );
};

export default TextToSpeech;