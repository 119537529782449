import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

const legendSpacingPlugin = {
  id: 'legendSpacing',
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 10;
    };
  }
};

const customShadowPlugin = {
  id: 'customShadow',
  beforeDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();
    const originalLineDraw = ctx.stroke;
    ctx.stroke = function () {
      if (chart.canvas.dataset.shadow === "true") {
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 4;
        ctx.shadowOffsetY = 4;
      }
      originalLineDraw.apply(this, arguments);
      if (chart.canvas.dataset.shadow === "true") {
        ctx.restore();
      }
    };
  },
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.restore();
  }
};

const CoachLaraLineChart = ({ laraData, loading, filterCoachLaraLine, filterClientLaraLine }) => {
  const [chartData, setChartData] = useState({
    months: [],
    listenScores: [],
    affirmScores: [],
    respondScores: [],
    askScores: [],
    overallScores: [],
  });

  useEffect(() => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    let validLaraData = laraData.filter(item => item.lara && (item.lara.client.length > 0 || item.lara.coach.length > 0) && new Date(item.zoom_date) >= oneYearAgo);

    if (filterClientLaraLine !== "none" && filterCoachLaraLine !== "none") {
      validLaraData = validLaraData.filter(item => item.lara.coach.length > 0);
    } else if (filterClientLaraLine !== "none") {
      validLaraData = validLaraData.filter(item => item.lara.client.length > 0);
    } else if (filterCoachLaraLine !== "none") {
      validLaraData = validLaraData.filter(item => item.lara.coach.length > 0);
    }

    const monthlyData = validLaraData.reduce((acc, item) => {
      const month = new Date(item.zoom_date).toLocaleString('default', { month: 'short', year: 'numeric' });
      let laraItem = null;
    
      if (filterClientLaraLine !== "none" && filterCoachLaraLine !== "none") {
        laraItem = item.lara.coach && item.lara.coach[0];
      } else if (filterClientLaraLine !== "none") {
        laraItem = item.lara.client && item.lara.client[0];
      } else {
        laraItem = item.lara.coach && item.lara.coach[0];
      }
    
      if (laraItem) {
        if (!acc[month]) {
          acc[month] = { listen: 0, affirm: 0, respond: 0, ask: 0, overall: 0, count: 0 };
        }
        acc[month].listen += (laraItem.listen_score || 0) * 20;
        acc[month].affirm += (laraItem.affirm_score || 0) * 20;
        acc[month].respond += (laraItem.respond_score || 0) * 20;
        acc[month].ask += (laraItem.ask_score || 0) * 20;
        acc[month].overall += ((laraItem.listen_score || 0) + (laraItem.affirm_score || 0) + (laraItem.respond_score || 0) + (laraItem.ask_score || 0)) * 5;
        acc[month].count += 1;
      }
      return acc;
    }, {});

    let months = Object.keys(monthlyData);
    let listenScores = months.map(month => (monthlyData[month].listen / monthlyData[month].count).toFixed(2));
    let affirmScores = months.map(month => (monthlyData[month].affirm / monthlyData[month].count).toFixed(2));
    let respondScores = months.map(month => (monthlyData[month].respond / monthlyData[month].count).toFixed(2));
    let askScores = months.map(month => (monthlyData[month].ask / monthlyData[month].count).toFixed(2));
    let overallScores = months.map(month => (monthlyData[month].overall / monthlyData[month].count).toFixed(2));

    if (months.length === 1) {
      const month = months[0];
      months = [month, month];
      listenScores = [listenScores[0], listenScores[0]];
      affirmScores = [affirmScores[0], affirmScores[0]];
      respondScores = [respondScores[0], respondScores[0]];
      askScores = [askScores[0], askScores[0]];
      overallScores = [overallScores[0], overallScores[0]];
    } else {
      months = months.reverse();
      listenScores = listenScores.reverse();
      affirmScores = affirmScores.reverse();
      respondScores = respondScores.reverse();
      askScores = askScores.reverse();
      overallScores = overallScores.reverse();
    }

    setChartData({
      months,
      listenScores,
      affirmScores,
      respondScores,
      askScores,
      overallScores,
    });
  }, [laraData, filterCoachLaraLine, filterClientLaraLine]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        },
        min: 0,
        beginAtZero: true,
        title: {
          display: true,
          text: "Month",
          font: {
            family: 'Lexend',
            size: "14px",
            color: '#040404',
            fontWeight: 600
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        },
      },
      y: {
        min: 0,
        max: 100,
        beginAtZero: true,
        grid: {
          display: true,
          color: '#F1F1F1',
          drawTicks: true,
          drawBorder: false,
        },
        ticks: {
          padding: 10,
          callback: function(value, index, values) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null;
          }
        },
        title: {
          display: true,
          text: "Score",
          font: {
            family: 'Lexend',
            size: "14px",
            color: '#040404',
            fontWeight: 600
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        }
      }
    },
    elements: {
      line: {
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 3,
          boxHeight: 3,
          font: {
            size: "14px",
            family: 'Lexend',
            style: 'normal',
            color: "#202226"
          },
        },
      },
      customShadow: customShadowPlugin,
    },
    fill: true,
    tension: 0.4,
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const data = {
    labels: chartData.months,
    datasets: [
      {
        label: 'Overall',
        data: chartData.overallScores,
        borderColor: hexToRgba('#FF5E00', 0.6),
        backgroundColor: hexToRgba('#FF5E00', 0.3),
        borderWidth: 6,
        pointRadius: 0,
      },
      {
        label: 'Listen',
        data: chartData.listenScores,
        borderColor: hexToRgba('#62C1EB', 0.6),
        backgroundColor: hexToRgba('#62C1EB', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Affirm',
        data: chartData.affirmScores,
        borderColor: hexToRgba('#568FF3', 0.6),
        backgroundColor: hexToRgba('#568FF3', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Respond',
        data: chartData.respondScores,
        borderColor: hexToRgba('#e383eb', 0.6),
        backgroundColor: hexToRgba('#e383eb', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Ask',
        data: chartData.askScores,
        borderColor: hexToRgba('#8e33b8', 0.6),
        backgroundColor: hexToRgba('#8e33b8', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
    ],
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1200px",
          height: "450px",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress 
              sx={{
                color: "black"
              }}
            />
          </Box>
        ) : (
          <Line
            options={options}
            data={data}
            plugins={[legendSpacingPlugin, customShadowPlugin]}
            style={{ width: '100%', height: '50%' }}
          />
        )}
      </Box>
    </>
  );
}

export default CoachLaraLineChart;
