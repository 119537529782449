import React, { useState } from "react";
import { Footer } from "scenes/login/Footer";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { useStripeCheckoutVirtualCoach } from "hooks/useStripeCheckoutVirtualCoach";
import { useStripeCheckoutVirtualCoachAnnual } from "hooks/useStripeCheckoutVirtualCoachAnnual";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { usePayment } from "../hooks/PaymentContext";

const PricingTable = () => {
  const navigate = useNavigate();

  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  // const { allowAccessToBot, allowAccessToPlatform, allowAccessToPlatformTier } = usePayment();
  const { allowAccessToBot, allowAccessToPlatform } = usePayment();

  // Hooks Virtual Coach
  const {
    handleStripeCheckoutVirtualCoach,
    setProductIdVirtualCoach,
    loadingStripeCheckoutVirtualCoach,
    errorWithStripeCheckoutVirtualCoach,
  } = useStripeCheckoutVirtualCoach();

  const {
    handleStripeCheckoutVirtualCoachAnnual,
    setProductIdVirtualCoachAnnual,
    loadingStripeCheckoutVirtualCoachAnnual,
    errorWithStripeCheckoutVirtualCoachAnnual,
  } = useStripeCheckoutVirtualCoachAnnual();

  const [
    loadingStripeCheckoutCoachingPlatformPersonal,
    setLoadingStripeCheckoutCoachingPlatformPersonal,
  ] = useState(false);

  const [
    loadingStripeCheckoutCoachingPlatform1,
    setLoadingStripeCheckoutCoachingPlatform1,
  ] = useState(false);

  const [
    loadingStripeCheckoutCoachingPlatform2,
    setLoadingStripeCheckoutCoachingPlatform2,
  ] = useState(false);

  const [
    loadingStripeCheckoutCoachingPlatform3,
    setLoadingStripeCheckoutCoachingPlatform3,
  ] = useState(false);

  const [
    loadingStripeCheckoutCoachingPlatform4,
    setLoadingStripeCheckoutCoachingPlatform4,
  ] = useState(false);

  // Hooks Coaching Platform
  const {
    handleStripeCheckoutCoachingPlatform,
    setProductIdCoachingPlatform,
    // loadingStripeCheckoutCoachingPlatform,
    errorWithStripeCheckoutCoachingPlatform,
  } = useStripeCheckoutCoachingPlatform();

  const handleCoachingPlatformClick = async (coachingPlatformProductId) => {
    await handleStripeCheckoutCoachingPlatform(coachingPlatformProductId);
  };

  return (
    <Grid container padding={{ xs: "0px 0px", lg: "0px 100px" }}>
      <Grid item xs={12} lg={12}>
        <Box
          textAlign={"center"}
          marginBottom={{
            xs: "30px",
            md: "70px",
          }}
          padding={{
            xs: "0px 20px",
            md: "auto",
          }}
          marginRight={{
            xs: "0px",
          }}
        >
          <Typography
            className="pricing"
            marginBottom={{
              xs: "10px",
              md: "20px",
            }}
            fontSize={{
              xs: "24px",
              md: "54px",
            }}
            fontWeight={500}
            color={{
              xs: "#070728",
              md: "black",
            }}
          >
            Our Pricing
          </Typography>
          <Typography
            fontSize={{
              xs: "16px",
              md: "22px",
            }}
            fontWeight={400}
            color={{
              xs: "#070728",
              md: "black",
            }}
          >
            Try our innovative products to revolutionize your leadership
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          justifyContent={{ xs: "space-between", md: "space-evenly" }}
          alignItems={{
            xs: "center",
            md: "auto",
          }}
          padding={"0px"}
          paddingRight={{
            xs: "0px",
          }}
          gap={{ xs: "25px", md: "auto" }}
          marginBottom={{
            xs: "50px",
            md: "50px",
          }}
          color={{
            xs: "#070728",
            md: "black",
          }}
        >
          {/* Pricing Boxes */}

          {/* Personal */}
          <Box
            display={"inline-block"}
            padding={"40px 25px"}
            width="287px"
            borderRadius={"15px"}
            border={"1px solid #DCDCDC"}
          >
            <Typography fontSize={"22px"} fontWeight={600}>
              Personal
            </Typography>
            <Typography
              height={"85px"}
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"10px"}
            >
              Leverage AI to optimize your own performance
            </Typography>
            <Typography
              component={"span"}
              fontSize={"36px"}
              fontWeight={800}
              display={"inline-block"}
            >
              $99
            </Typography>
            <Typography component={"span"} fontSize={"14px"} fontWeight={400}>
              /month
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              {allowAccessToPlatform === false &&
              authStatus === "authenticated" ? (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => {
                    setLoadingStripeCheckoutCoachingPlatformPersonal(true);
                    handleCoachingPlatformClick(
                      "price_1OyblKLKxpQPkYt5aHNWNzzc"
                    );
                  }}
                >
                  {loadingStripeCheckoutCoachingPlatformPersonal && (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{ marginRight: "5px", marginBottom: "-3px" }}
                    />
                  )}
                  {loadingStripeCheckoutCoachingPlatformPersonal
                    ? "Loading..."
                    : "Purchase"}
                </Button>
              ) : (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => navigate("/signup?p=cp-personal")}
                >
                  Purchase
                </Button>
              )}
            </Box>
          </Box>

          {/* Starter */}
          <Box
            display={"inline-block"}
            padding={"40px 25px"}
            width="287px"
            borderRadius={"15px"}
            border={"1px solid #DCDCDC"}
          >
            <Typography fontSize={"22px"} fontWeight={600}>
              Starter
            </Typography>
            <Typography
              height={"85px"}
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"10px"}
            >
              1-3 Clients: Become an AI powered coach
            </Typography>
            <Typography
              component={"span"}
              fontSize={"36px"}
              fontWeight={800}
              display={"inline-block"}
            >
              $149
            </Typography>
            <Typography component={"span"} fontSize={"14px"} fontWeight={400}>
              /month
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              {allowAccessToPlatform === false &&
              authStatus === "authenticated" ? (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => {
                    setLoadingStripeCheckoutCoachingPlatform1(true);
                    handleCoachingPlatformClick(
                      "price_1OgAnTLKxpQPkYt5fPl1R57a"
                    );
                  }}
                >
                  {loadingStripeCheckoutCoachingPlatform1 && (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{ marginRight: "5px", marginBottom: "-3px" }}
                    />
                  )}
                  {loadingStripeCheckoutCoachingPlatform1
                    ? "Loading..."
                    : "Purchase"}
                </Button>
              ) : (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => navigate("/signup?p=cp1")}
                >
                  Purchase
                </Button>
              )}
            </Box>
          </Box>

          {/* Standard */}
          <Box
            display={"inline-block"}
            padding={"40px 25px"}
            width="287px"
            borderRadius={"15px"}
            border={"1px solid #DCDCDC"}
          >
            <Typography fontSize={"22px"} fontWeight={600}>
              Standard
            </Typography>
            <Typography
              height={"85px"}
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"10px"}
            >
              4-10 Clients: Ideal for growing an AI powered coaching practice
            </Typography>
            <Typography
              component={"span"}
              fontSize={"36px"}
              fontWeight={800}
              display={"inline-block"}
            >
              $399
            </Typography>
            <Typography component={"span"} fontSize={"14px"} fontWeight={400}>
              /month
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              {allowAccessToPlatform === false &&
              authStatus === "authenticated" ? (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => {
                    setLoadingStripeCheckoutCoachingPlatform2(true);
                    handleCoachingPlatformClick(
                      "price_1OgAnJLKxpQPkYt5rFmZ857w"
                    );
                  }}
                >
                  {loadingStripeCheckoutCoachingPlatform2 && (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{ marginRight: "5px", marginBottom: "-3px" }}
                    />
                  )}
                  {loadingStripeCheckoutCoachingPlatform2
                    ? "Loading..."
                    : "Purchase"}
                </Button>
              ) : (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => navigate("/signup?p=cp2")}
                >
                  Purchase
                </Button>
              )}
            </Box>
          </Box>

          {/* Professional */}
          <Box
            display={"inline-block"}
            padding={"40px 25px"}
            width="287px"
            borderRadius={"15px"}
            border={"1px solid #DCDCDC"}
          >
            <Typography fontSize={"22px"} fontWeight={600}>
              Professional
            </Typography>
            <Typography
              height={"85px"}
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"10px"}
            >
              11-25 Clients: Ideal for scaling & retaining coaching clients
            </Typography>
            <Typography
              component={"span"}
              fontSize={"36px"}
              fontWeight={800}
              display={"inline-block"}
            >
              $799
            </Typography>
            <Typography component={"span"} fontSize={"14px"} fontWeight={400}>
              /month
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              {allowAccessToPlatform === false &&
              authStatus === "authenticated" ? (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => {
                    setLoadingStripeCheckoutCoachingPlatform3(true);
                    handleCoachingPlatformClick(
                      "price_1OgAn4LKxpQPkYt5NHVF9L6a"
                    );
                  }}
                >
                  {loadingStripeCheckoutCoachingPlatform3 && (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{ marginRight: "5px", marginBottom: "-3px" }}
                    />
                  )}
                  {loadingStripeCheckoutCoachingPlatform3
                    ? "Loading..."
                    : "Purchase"}
                </Button>
              ) : (
                <Button
                  sx={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 500,
                    marginTop: "40px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    textTransform: "none",
                    padding: "12px 54px",
                    color: "black",
                    width: "100%",
                    background: "white",
                    border: "1px solid #242F8C",
                    ":hover": {
                      color: "white",
                      border: "1px solid white",
                      background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    },
                  }}
                  onClick={() => navigate("/signup?p=cp3")}
                >
                  Purchase
                </Button>
              )}
            </Box>
          </Box>

          {/* Enterprise */}
          <Box
            display={"inline-block"}
            padding={"40px 25px"}
            width="287px"
            borderRadius={"15px"}
            border={"1px solid #DCDCDC"}
          >
            <Typography fontSize={"22px"} fontWeight={600}>
              Enterprise
            </Typography>
            <Typography
              height={"100px"}
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"5px"}
            >
              26+ Clients: An advanced platform for large organizations with
              multiple executives & coaches
            </Typography>
            <Typography
              display={"inline-block"}
              component={"span"}
              fontSize={"14px"}
              lineHeight={2.5}
              sx={{
                transform: "translate(0, 10px)",
              }}
              fontWeight={700}
            >
              Contact Sales
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                sx={{
                  display: "block",
                  fontSize: "15px",
                  fontWeight: 500,
                  marginTop: "52px",
                  borderRadius: "10px",
                  backdropFilter: "blur(25px)",
                  textTransform: "none",
                  padding: "12px 54px",
                  color: "black",
                  width: "100%",
                  background: "white",
                  transform: "translate(0, -4px)",
                  border: "1px solid #242F8C",
                  ":hover": {
                    color: "white",
                    border: "1px solid white",
                    background:
                      "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                  },
                }}
                onClick={() => {
                  setLoadingStripeCheckoutCoachingPlatform4(true);
                  window.location.href =
                    "https://calendly.com/altercallai/aai-enterprise-demo";
                }}
              >
                {loadingStripeCheckoutCoachingPlatform4 && (
                  <CircularProgress
                    size={20}
                    thickness={4}
                    sx={{ marginRight: "5px", marginBottom: "-3px" }}
                  />
                )}
                {loadingStripeCheckoutCoachingPlatform4
                  ? "Loading..."
                  : "Purchase"}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Call to Action */}
        <Box
          display={"flex"}
          flexDirection={{
            xs: "column",
            lg: "row",
          }}
          justifyContent={"center"}
          alignItems={"center"}
          marginBottom={"100px"}
        >
          <Typography
            fontSize={"22px"}
            fontWeight={400}
            letterSpacing={"0.22px"}
            marginRight={{
              lg: "36px",
            }}
            marginBottom={{ xs: "10px", lg: "0px" }}
            textAlign={{
              xs: "center",
              lg: "auto",
            }}
            color={{
              xs: "#070728",
              md: "black",
            }}
          >
            Experience the power of AI for yourself
          </Typography>
        </Box>
        {/* Footer */}
        <Footer />
      </Grid>
    </Grid>
  );
};

export default PricingTable;
