import React from "react";

function DeleteThreadIcon({ color = '#111111' }) {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none" viewBox="0 0 13 16">
        <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.417" d="M9.333 4.25 8.55 2.684a2.292 2.292 0 0 0-4.1 0L3.665 4.25m-2.833 0h11.333M5.083 7.792v3.541m2.833-3.541v3.541M2.25 4.25h8.5v6.375c0 1.32 0 1.98-.216 2.501a2.833 2.833 0 0 1-1.533 1.533c-.521.216-1.181.216-2.501.216-1.32 0-1.98 0-2.501-.216a2.833 2.833 0 0 1-1.534-1.533c-.215-.52-.215-1.18-.215-2.501V4.25Z"/>
        </svg>
    )
}

export default DeleteThreadIcon;
