import axios from 'axios';
import { fetchAuthSession } from "aws-amplify/auth";
import { toast } from "react-toastify";

// Define a unique toast ID
const toastId = "userGroupAlignmentToast";

export const checkUserGroupAlignment = async (userId, userGroup, companyId) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/check-user-group-alignment`, {
      user_id: userId,
      user_group: userGroup,
      company_id: companyId,
    });

    if (response && response.data.refreshSession) {
      console.log("Misalignment in user group detected, refreshing session...");

      // Check if the toast with the specified ID is already active
      if (!toast.isActive(toastId)) {
        // Display a toast notification with a unique ID
        toast.info("Refreshing your session to ensure everything is up to date...", {
          hideProgressBar: true,
          autoClose: 3000,
          toastId, // Pass the unique ID
        });
      }

      await fetchAuthSession({ forceRefresh: true });

      // Reload the page to ensure the updated session is applied
      window.location.reload();
    } else {
      console.log("No misalignment, can continue...");
    }
  } catch (error) {
    console.error("Error checking user group alignment: ", error);
    // Optionally, show a toast for errors (more technical users)
    // toast.error("An error occurred while checking your access. Please try again.", {
    //   hideProgressBar: true,
    //   autoClose: 3000,
    // });
    return { refreshSession: false }; // Default to no refresh on error
  }
};
