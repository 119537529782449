export function processAnswers(answers) {
    const result = {};

    answers.forEach(answer => {
        if (!result[answer.domain]) {
            result[answer.domain] = { score: 0, count: 0, result: 'neutral', facet: {} };
        }
        const domain = result[answer.domain];
        domain.score += answer.score;
        domain.count++;

        if (answer.facet === undefined) return;

        if (!domain.facet[answer.facet]) {
            domain.facet[answer.facet] = { score: 0, count: 0, result: 'neutral' };
        }
        const facet = domain.facet[answer.facet];
        facet.score += answer.score;
        facet.count++;
    });

    Object.values(result).forEach(domain => {
        domain.result = calculateResult(domain.score, domain.count);
        Object.values(domain.facet).forEach(facet => {
            facet.result = calculateResult(facet.score, facet.count);
        });
    });

    return result;
}

export function calculateResult(score, count) {
    const avgScore = score / count;
    if (avgScore > 3.5) {
        return 'high';
    } else if (avgScore < 2.5) {
        return 'low';
    }
    return 'neutral';
}
