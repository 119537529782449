import React from 'react';

function ContentLibraryCloseIcon({ color = '#111111' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 18.989C7.57703 19 8.23647 19 9 19H11C13.8003 19 15.2004 19 16.27 18.455C17.2108 17.9757 17.9757 17.2108 18.455 16.27C19 15.2004 19 13.8003 19 11V9C19 6.19974 19 4.79961 18.455 3.73005C17.9757 2.78924 17.2108 2.02433 16.27 1.54497C15.2004 1 13.8003 1 11 1H9C8.23647 1 7.57703 1 7 1.01105M7 18.989C5.46078 18.9595 4.50797 18.8514 3.73005 18.455C2.78924 17.9757 2.02433 17.2108 1.54497 16.27C1 15.2004 1 13.8003 1 11V9C1 6.19974 1 4.79961 1.54497 3.73005C2.02433 2.78924 2.78924 2.02433 3.73005 1.54497C4.50797 1.14859 5.46078 1.04052 7 1.01105M7 18.989L7 1.01105M11.5 7C12.5964 7.79655 13.5785 8.73031 14.4207 9.77659C14.5264 9.90803 14.5264 10.092 14.4207 10.2234C13.5785 11.2697 12.5964 12.2035 11.5 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContentLibraryCloseIcon;
