import React, { createContext, useContext } from 'react';

// Create context
export const PaymentContext = createContext();

export const usePayment = () => {
    const context = useContext(PaymentContext);
    if (context === undefined) {
        throw new Error('usePayment must be used within a PaymentProvider');
    }
    return context;
};