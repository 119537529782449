import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import CustomCheckbox from "./CustomCheckbox";
import {CustomPagination} from "./CustomPagination"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CustomTable = styled(DataGrid)(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  width: "100%",
  minHeight: "300px",
  height: "100%",

  "& .MuiDataGrid-columnHeaders": {
    fontSize: "13px",
    backgroundColor: "#EFF3F6",
    borderBottom: "1px solid #F3F3F3",
    color: "#667085",
    borderRadius: "24pt",
    paddingY: "12pt",
    paddingX: "14pt",
  },

  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
    backgroundColor: "transparent !important",
    borderRadius: "50px",
  },

  "& .MuiDataGrid-cellCheckbox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus-within": {
      outline: "none", // Remove outline for checkbox cells
    },
  },
  "& .MuiDataGrid-row:hover .MuiDataGrid-cell--with-file": {
    color: "#007bff",
    textDecoration: "underline",
  },
  // Existing styles remain unchanged
  " & .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // Base styling for all cells
  "& .MuiDataGrid-cell": {
    fontSize: "14px", 
    fontFamily: 'Lexend', 
    fontWeight: '400', 
    wordWrap: 'break-word',
    borderBottom: "none",
    color: "black",
    textTransform: "capitalize",
    whiteSpace: "pre-wrap", // Allows text to wrap to new lines
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "white",
    borderBottom: "1px solid #F3F3F3",
    "&.even": {
      backgroundColor: "transparent",
    },
    "&.odd": {
      backgroundColor: "#F2F7FB",
    },
    "&:hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 1,
      color: "#202226",
      cursor: "pointer",
    },
  },

    // Add selected row styling
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#D9E4EC", // Selected row background color
      "&:hover": {
        backgroundColor: "#D9E4EC", // Background color on hover when selected
      },
      outline: "none", // Remove outline for selected rows if any
    },

  "& .MuiDataGrid-footerContainer": {
    display: "flex", // Ensure proper flexbox layout
    justifyContent: "space-between", // Align content to both ends
    alignItems: "center", // Vertically center items
    padding: "0 16px", // Add some horizontal padding
    borderTop: "none",
    boxShadow: "none",
  },
  "& .MuiTablePagination-root": {
    border: "none",
    color: "black",
    "& .MuiTablePagination-caption": {
      color: "black",
    },
    "& .MuiSelect-select": {
      color: "black",
    },
    "& .MuiTablePagination-displayedRows": {
      color: "black",
    },
  },

  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MuiIconButton-root": {
    color: "black",
    "&:hover": {
      color: "#B6B6B6",
    },
  },
  "& .MuiDataGrid-overlay": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-noRowsOverlay": {
    backgroundColor: "transparent",
    color: "black",
  },
  "& .MuiDataGrid-loadingOverlay": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-checkboxInput": {
    "&:focus": {
      outline: "none",
      boxShadow: "none",
    },
  },
  "& .MuiCheckbox-root": {
    "&:focus": {
      outline: "none",
      boxShadow: "none",
    },
  },
"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
    outline: "none", // Remove the outline
  },

  "& .MuiDataGrid-virtualScroller": {
    overflow: "auto", // Ensure scrolling is enabled
    "&::-webkit-scrollbar": {
      width: "8px", // Adjust the width of the scrollbar
      height: "8px", // Adjust the height of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(207, 207, 207)", // Set the thumb color
      borderRadius: "4px", // Rounded corners for the thumb
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgb(150, 150, 150)", // Thumb color on hover
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Background for the track
    },
  },

  


  [theme.breakpoints.down("sm")]: {
    "& .MuiDataGrid-columnHeaders": {
      fontSize: "14px",
    },
    "& .MuiDataGrid-cell": {
      fontSize: "12px",
      padding: "4px",
    },
    height: "300px",
    minHeight: "200px",
    "& .MuiDataGrid-virtualScroller": {
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(207, 207, 207)",
        borderRadius: "3px",
      },
    },
  },
}));

const CustomDataGrid = (props) => (
  <CustomTable
    {...props}
    components={{
      BaseCheckbox: CustomCheckbox, // Apply the custom checkbox
      ColumnSortedDescendingIcon: KeyboardArrowUpIcon, // Custom descending sort icon
      ColumnSortedAscendingIcon: KeyboardArrowDownIcon, // Custom ascending sort icon
      Pagination: CustomPagination, // Use the custom pagination component
    }}
    componentsProps={{
      baseCheckbox: {
        sx: {
          "&.MuiCheckbox-indeterminate .MuiSvgIcon-root": {
            borderRadius: "6px",
          },
        },
      },
      columnSortedDescendingIcon: {
        sx: {
          fontSize: "50px", // Adjust the size of the descending sort icon
        },
      },
      columnSortedAscendingIcon: {
        sx: {
          fontSize: "50px", // Adjust the size of the ascending sort icon
        },
      },
    }}
    disableColumnMenu
    rowHeight={props.rowHeight || 52}
  />
);

export default CustomDataGrid;
