import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCallsByClientID } from "slices/ClientSlice";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import MetricCards from "components/ClientDashboardCards/MetricCards";
import VirtualCoachCard from "components/CoachDashboardCards/VirtualCoachCard";
import Team from "scenes/SessionsList";
import ClientHasInactiveCoachModal from "components/ClientHasInactiveCoach/ClientHasInactiveCoachModal";

const ClientDashboard = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [zoomStatus, setZoomStatus] = useState(false);
  const dispatch = useDispatch();
  const [lastFileName, setLastFileName] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await dispatch(getCallsByClientID({ clientId: user?.sub })).unwrap();
      console.log("response", response.data);
      if (response) {
        const sanitizedData = Array.isArray(response.data)
          ? response.data.map((row) => ({
              ...row,
              call_name: row.call_name.replace(/_/g, " "), // Replace underscores with spaces
              zoom_date: row.zoom_date
                ? new Date(row.zoom_date).toLocaleDateString("en", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                : "",
              file_name: row.file_name, // Include the "file_name" from the response
              id: row.id,
            }))
          : [];
        const sortedData = sanitizedData?.sort(
          (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
        );
        for (const row of sortedData) {
          if (row.status === "ReadyToPublish") {
            setLastFileName(row.file_name);
            break;
          }
        }

        if (!response) {
          setLoading(false);
          return;
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientHasInactiveCoachModal clientHasInactiveCoach={userData.clientHasInactiveCoach} isLocked={true} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          padding: {
            xs: "16px",
            md: "32px 40px",
          },
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        {/* <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ marginTop: { xs: "28px", md: 0 } }}>
          <Typography
            sx={{
              color: "black",
              fontWeight: 300,
              fontSize: {
                xs: "28px",
                md: "36px",
              },
              fontFamily: "Poppins",
              paddingBottom: "16px",
            }}
          >
            {zoomStatus && `Welcome Back ${user?.given_name}!`}
            {!zoomStatus && `Welcome ${user?.given_name}!`}
          </Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "calc(100% / 3) * 2" },
              gap: "8px",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: { xs: "column", md: "row" },
                height: "100%",
              }}
            >
              <MetricCards user={user} accessTo={userData.accessTo} />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "calc(100% / 3)" },
              flexGrow: 1,
            }}
          >
            <VirtualCoachCard user={user} />
          </Box>
        </Box>
        <Team user={user} fetchData={fetchUserData} accessTo={userData.accessTo} />
      </Box>
    </GlobalPageComponent>
  );
};

export default ClientDashboard;