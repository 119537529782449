import React from 'react';

function VideoIconForVC({ color = '#111111' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" viewBox="0 0 21 15">
            <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2.0" d="M14.876 9.192c.016.496.242.962.623 1.283l.875.735c1.139.956 2.876.146 2.876-1.34V5.13c0-1.486-1.737-2.296-2.876-1.34l-.875.735a1.75 1.75 0 0 0-.623 1.276m0 3.391-.001.933a3.5 3.5 0 0 1-3.5 3.5H5.25a3.5 3.5 0 0 1-3.5-3.5v-5.25a3.5 3.5 0 0 1 3.5-3.5h6.125a3.5 3.5 0 0 1 3.5 3.5l.001.926m0 3.391v-3.39" />
        </svg>
    )
}

export default VideoIconForVC;




