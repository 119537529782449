import { useState, useCallback } from "react";
import { generateClient } from 'aws-amplify/data';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({authMode: "userPool"});

export const useDecrementClientCount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const decrementClientCount = useCallback(async (userID) => {
    setLoading(true);

    // const variables = {
    //   userID: userID,
    // };

    try {
      // const response = await API.graphql(
      //   graphqlOperation(mutations.decrementClientCount, variables)
      // );
      
      const { data, errors } = await resourceClient.mutations.decrementCoacheeSeatCount({
        user_id: userID
      });

      console.log("Decrement Coachee Seat Count Response:", data);

      // Assuming your GraphQL response structure, adjust as necessary
      const { coachee_seat_tier_allowance, coachee_seat_count } = data;

      // Here you can return the response or handle it as needed
      return { clientTierAllowance: coachee_seat_tier_allowance, clientCount: coachee_seat_count };
    } catch (error) {
      console.error("Error decrementing client count:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    decrementClientCount,
    loading,
    error,
  };
};
