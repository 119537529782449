import React from "react";

const CheckboxNew = ({ checked, onClick }) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      style={{
        display: "inline-block",
        cursor: "pointer",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick} // Use external click handler
    >
      {checked ? (
        // Checked state SVG
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21C9.20435 21 7.80653 21 6.7039 20.5433C5.23373 19.9343 4.06569 18.7663 3.45672 17.2961C3 16.1935 3 14.7956 3 12Z"
            fill="#0076BA"
          />
          <path
            d="M8.5 12.5124L10.8412 14.851C11.9672 12.8821 13.5256 11.1944 15.3987 9.91536L15.5 9.84619M12 21C9.20435 21 7.80653 21 6.7039 20.5433C5.23373 19.9343 4.06569 18.7663 3.45672 17.2961C3 16.1935 3 14.7956 3 12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : hovered ? (
        // Hover state SVG
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <rect x="0.5" width="24" height="24" rx="8" fill="#FAFCFE" />
          <path
            d="M4.5 12C4.5 10.5885 4.50054 9.57994 4.55477 8.78514C4.60839 7.99931 4.71112 7.49574 4.8806 7.08658C5.38807 5.86144 6.36144 4.88807 7.58658 4.3806C7.99574 4.21112 8.49931 4.10839 9.28514 4.05477C10.0799 4.00054 11.0885 4 12.5 4C13.9115 4 14.9201 4.00054 15.7149 4.05477C16.5007 4.10839 17.0043 4.21112 17.4134 4.3806C18.6386 4.88807 19.6119 5.86144 20.1194 7.08658C20.2889 7.49574 20.3916 7.99931 20.4452 8.78514C20.4995 9.57994 20.5 10.5885 20.5 12C20.5 13.4115 20.4995 14.4201 20.4452 15.2149C20.3916 16.0007 20.2889 16.5043 20.1194 16.9134C19.6119 18.1386 18.6386 19.1119 17.4134 19.6194C17.0043 19.7889 16.5007 19.8916 15.7149 19.9452C14.9201 19.9995 13.9115 20 12.5 20C11.0885 20 10.0799 19.9995 9.28514 19.9452C8.49931 19.8916 7.99574 19.7889 7.58658 19.6194C6.36144 19.1119 5.38807 18.1386 4.8806 16.9134C4.71112 16.5043 4.60839 16.0007 4.55477 15.2149C4.50054 14.4201 4.5 13.4115 4.5 12Z"
            stroke="#DDE5ED"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        // Normal state SVG
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 12C4 10.5885 4.00054 9.57994 4.05477 8.78514C4.10839 7.99931 4.21112 7.49574 4.3806 7.08658C4.88807 5.86144 5.86144 4.88807 7.08658 4.3806C7.49574 4.21112 7.99931 4.10839 8.78514 4.05477C9.57994 4.00054 10.5885 4 12 4C13.4115 4 14.4201 4.00054 15.2149 4.05477C16.0007 4.10839 16.5043 4.21112 16.9134 4.3806C18.1386 4.88807 19.1119 5.86144 19.6194 7.08658C19.7889 7.49574 19.8916 7.99931 19.9452 8.78514C19.9995 9.57994 20 10.5885 20 12C20 13.4115 19.9995 14.4201 19.9452 15.2149C19.8916 16.0007 19.7889 16.5043 19.6194 16.9134C19.1119 18.1386 18.1386 19.1119 16.9134 19.6194C16.5043 19.7889 16.0007 19.8916 15.2149 19.9452C14.4201 19.9995 13.4115 20 12 20C10.5885 20 9.57994 19.9995 8.78514 19.9452C7.99931 19.8916 7.49574 19.7889 7.08658 19.6194C5.86144 19.1119 4.88807 18.1386 4.3806 16.9134C4.21112 16.5043 4.10839 16.0007 4.05477 15.2149C4.00054 14.4201 4 13.4115 4 12Z"
            stroke="#DDE5ED"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default CheckboxNew;
