import { Check } from "@mui/icons-material";
import { Box, Button, Modal, Typography, List, ListItem } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ReturnFromZoomModal = ({ open, setOpen }) => {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const returnFromZoom = queryParams.get('returnFromZoom');
        
        if (returnFromZoom === 'true') {
            setOpen(true);
            // Remove the returnFromZoom parameter from the URL
            queryParams.delete('returnFromZoom');
            navigate({ search: queryParams.toString() }, { replace: true });
        }
    }, [location, navigate, setOpen]); // Added setOpen to dependencies

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        window.open("/resources?search=Connecting-Zoom&pageId=3&sectionId=259", "_blank");
      };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            disableAutoFocus={true}
            sx={{
                outline: 'none'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "25px",
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: "center"}}>
                    <Typography id="modal-title" variant="h6" component="h2"
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: 500
                        }}
                    >
                        Your zoom account was successfully connected.
                    </Typography>
                    <Check sx={{ color: "#00C853", fontSize: "20px"}} />
                </Box>
                <Typography id="modal-description" 
                    sx={{ 
                        mt: 2,
                        color: "black"
                    }}
                >
                    We are gathering your calls. <br />
                    <br />
                    A notification will appear once they are ready.
                </Typography>
                <List sx={{ mt: 2, color: "black", listStyleType: 'disc', pl: 2 }}>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Only calls from the last 6 months that are 10 minutes or longer are pulled from your Zoom Cloud.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            The Zoom call must originate from your account, meaning you created the meeting and are the host.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Our platform works best with one-on-one calls.
                        </Typography>
                    </ListItem>
                </List>
                
                <Box sx={{ display: 'flex', width: "100%", justifyContent: 'space-between', mt: 3, alignItems: "center" }}>
                    <Typography
                        sx={{
                            fontSize: "12px",
                            cursor: 'pointer',
                            color: '#007bff',
                            '&:hover': {
                              color: '#0000FF', 
                            }
                        }}
                        onClick={handleClick}
                    >
                        Resources: Connecting Zoom
                    </Typography>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ReturnFromZoomModal;
