import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const domainColors = {
  A: '#F3AB54',
  C: '#49CA7A',
  E: '#337BE8',
  N: '#9865CF',
  O: '#F56565'
};

const domainTitles = {
  A: 'Agreeableness',
  C: 'Conscientiousness',
  E: 'Extraversion',
  N: 'Neuroticism',
  O: 'Openness'
}

const domainColorsHover = {
  A: '#F3AB54B3',  // 70% opacity
  C: '#49CA7AB3',  // 70% opacity
  E: '#337BE8B3',  // 70% opacity
  N: '#9865CFB3',  // 70% opacity
  O: '#F56565B3',  // 70% opacity
}


const GeneralBigFiveScore = ({ assessmentData }) => {
  const [results, setResults] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (assessmentData?.results) {
      let parsedResults;

      // Check if results are already parsed or not
      if (typeof assessmentData.results === 'string') {
        try {
          // Try to parse if it's a string
          parsedResults = JSON.parse(assessmentData.results);
        } catch (error) {
          console.error('Error parsing JSON results:', error);
          parsedResults = {}; // Default to an empty object if parsing fails
        }
      } else {
        // If it's already an object/array, just use it as is
        parsedResults = assessmentData.results;
      }

      setResults(parsedResults);
      console.log("results: ", parsedResults)
      console.log("assessment data in big 5: ", assessmentData.results)
    } else {
      // If no assessmentData, default to empty scores for each domain
      const defaultResults = {
        A: { score: 0 },
        C: { score: 0 },
        E: { score: 0 },
        N: { score: 0 },
        O: { score: 0 }
      };
      setResults(defaultResults);
    }
  }, [assessmentData]);

  const domainOrder = ['O', 'C', 'E', 'A', 'N'];

  return (
    <>
      {domainOrder.map((domainKey) => {
        const domainData = results[domainKey] || {}; // Default to empty object if no data
        const scoreValue = Number(domainData.score) || 0; // Ensure score is a valid number
        const scaledScore = Math.round(Math.min(Math.max((scoreValue / 120) * 100, 0), 100)); // Scale to 0-100 and round to nearest hundredth
  
        return (
          <Box
            key={domainKey}
            sx={{ marginBottom: '20px', display: 'flex', gap: '20px', justifyContent: 'center', textAlign: 'left' }}
          >
            <Typography
              sx={{
                color: domainColors[domainKey] || '#000',
                fontFamily: 'Lexend',
                fontSize: '20px',
                fontWeight: 400,
                marginBottom: '10px',
                minWidth: "200px",
                "&:hover": {
                  color: assessmentData?.id ? domainColorsHover[domainKey] : domainColors[domainKey],
                  fontWeight: assessmentData?.id ? 500 : 400, // Increase font weight only if id exists
                  cursor: assessmentData?.id ? "pointer" : "default", // Show pointer only if clickable
                },
              }}
              onClick={() => {
                if (assessmentData?.id) {
                  navigate(`/platform/coach/assessments/view/individual-breakdown/${assessmentData.id}/${assessmentData.user_id}/${domainTitles[domainKey]}`);
                }
              }}
            >
              {domainTitles[domainKey]} {/* Display the domain key */}
            </Typography>
  
            {/* Display the circles */}
            <Box sx={{ display: 'flex', gap: '5px' }}>
              {/* Render the filled circles */}
              {Array.from(Array(Math.floor(scaledScore / 10))).map((_, subIndex) => (
                <Box
                  key={subIndex}
                  sx={{
                    width: { xs: '18px', md: '27px' },
                    height: { xs: '18px', md: '27px' },
                    borderRadius: '50%',
                    backgroundColor: domainColors[domainKey], // Color based on the domain
                  }}
                />
              ))}
  
              {/* Render the empty circles */}
              {Array.from(Array(10 - Math.floor(scaledScore / 10))).map((_, subIndex) => (
                <Box
                  key={subIndex}
                  sx={{
                    width: { xs: '18px', md: '27px' },
                    height: { xs: '18px', md: '27px' },
                    borderRadius: '50%',
                    backgroundColor: '#EFF3F6', // Light gray for empty circles
                  }}
                />
              ))}
            </Box>
  
            {scaledScore !== null && assessmentData?.id && (
              <Typography
                sx={{
                  color: "#8F8F8F",
                  fontFamily: "Lexend",
                  fontSize: "16px"
                }}
              >
                {scaledScore} / 100 {/* Display percentage score */}
              </Typography>
            )}
          </Box>
        );
      })}
    </>
  );    
};

export default GeneralBigFiveScore;
