import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import "./VideoPlayerCard.css";
import thumbnailImage from "../../assets/sessionPageThumbnail.jpg";

const VideoPlayerCard = ({ videoUrl }) => {
  return (
    <Card
      sx={{
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        padding: 0,
        "& .MuiCardContent-root": {
          width: "100%",
          padding: 0,
          paddingBottom: "0 !important",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ReactPlayer
            light={thumbnailImage}
            className="video-react-player"
            url={videoUrl}
            controls={true}
            playing={false}
            width="100%"
            height="100%"
            style={{ maxHeight: "100%", margin: "auto" }} // Ensure it centers within the Box
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default VideoPlayerCard;
