import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const UserGuide = ({fullWidth}) => {
    const highlight = {
        cursor: "text",
        userSelect: "text",
    };

    const copytext = () => {
        const text = window.getSelection().toString();
        navigator.clipboard.writeText(text);
    };
    return (
        <Box
            marginLeft={{
                xs: "",
                lg: "10px",
            }}
            display={{
                xs: fullWidth ? "flex" : "none",
                lg: "flex",
            }}
            flexDirection={"column"}
            justifyContent={"space-between"}
            padding={{
                xs: "0 20px",
                lg: "0 30px",
                overflowY: "auto"
            }}
            sx={{
                width: {
                    xs: fullWidth ? "100vw" : "0%",
                    sm: fullWidth ? "100vw" : "0%",
                    md: fullWidth ? "calc(100vw - 25vw)" : "300px",
                    lg: fullWidth ? "calc(100vw - 20vw)" : "400px",
                    xl: fullWidth ? "calc(100vw - 20vw)" : "500px"
                }
            }}
            >
            <Box
                sx={{
                height: {
                    xs: "auto",
                    md: fullWidth ? "calc(100vh - 28vh)" : "0%",
                    lg: "calc(100vh - 28vh)",
                },
                overflowY: "scroll",
                }}
                paddingRight={"20px"}
                marginBottom={"10px"}
                color={"black"}
            >
                <Typography
                fontSize={"22px"}
                fontWeight={600}
                marginBottom={"10px"}
                >
                User Guide
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Leveraging Mentorship for Growth:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    How can I leverage AlterCall’s mentorship programs for
                    personal and professional growth?
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    7-Day Meditation Plan:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    Curate a 7-day meditation list for me.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Business Branding:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    My business is experiencing a branding crisis. What should
                    I do?
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Enhancing Self-Discipline:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    Teach me techniques to enhance self-discipline and focus.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Startup Strategy Assistance for Single Parents:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    Devise a plan for launching a new clothing brand that
                    aligns with my responsibilities as a single parent.
                    Incorporate specific time allocations and tasks during
                    school term hours from 8 AM to 3 PM, and also after my
                    son's bedtime at 9 PM.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Talent Acquisition Framework:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I need a framework for hiring great talent.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Engaging with Sound Healing:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I have never experienced sound healing or breathwork.
                    Please provide a session and guide me through it.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Parent-Entrepreneur Productivity:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    As a single parent running a startup, guide me through
                    balancing my schedule.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Overcoming Trust Challenges:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I am experiencing trust issues with my business partner
                    and need a comprehensive framework and resources to
                    overcome this challenge.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Personal Growth Pathway:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I want to experience a spiritual awakening. Please provide
                    resources that will help me.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Lead Generation:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I need help with acquiring more leads for my business.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Overcoming Grief:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I lost a loved one, help me.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Intensive Growth Plan:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    Provide a detailed 24-hour intensive plan for restriction
                    using PDF and video resources. Include tips, action items,
                    and activities.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Public Speaking Mastery Course:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    Construct a 3-day intensive public speaking mastery course
                    for me, drawing from AlterCall’s recommended resources.
                </Typography>
                </Typography>
                <Typography marginBottom={"10px"}>
                <Typography
                    component="span"
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "5px",
                    }}
                >
                    Stress Management:
                </Typography>
                <Typography
                    component="span"
                    sx={highlight}
                    onMouseUp={copytext}
                >
                    I'm feeling overwhelmed with my workload. What AlterCall
                    methods can help me manage stress?
                </Typography>
                </Typography>
            </Box>

            <Box
                display={"flex"}
                justifyContent={{
                xs: "center",
                lg: "flex-start",
                }}
                marginTop={{
                xs: "10px",
                lg: "30px",
                }}
            ></Box>

            <Box marginTop={"30px"}>
                <a
                href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide.pdf"
                download
                >
                <Button
                    sx={{
                    fontSize: "16px",
                    borderRadius: "10px",
                    backdropFilter: "blur(25px)",
                    color: "white",
                    textTransform: "none",
                    padding: "5px 30px",
                    width: "250px",
                    background:
                        "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                    }}
                >
                    Download Full Guide
                </Button>
                </a>
            </Box>
            </Box>
  )
}

export default UserGuide