import { Box, CircularProgress, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProfilePicture from "components/ProfilePicture";


const UserControls = ({ loading, clientName, picture, avatar, setPicture }) => {
    return(
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginRight: "40px"
            }}
          >
                <ProfilePicture
                    src={picture}
                    size={110} // Adjust size as needed
                    addBorder={true}
                    fallback={avatar}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontWeight: 400,
                        fontSize: "24px",
                        textAlign: "center"
                    }}
                >
                    {clientName || ""}
                </Typography>
          </Box>
    )
}

export default UserControls;