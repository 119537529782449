import React from 'react';

const LockIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 70 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.4472 20.5156C21.4472 13.8358 27.2941 8.0331 35.0005 8.0331C42.7069 8.0331 48.5538 13.8358 48.5538 20.5156V23.2411H21.4472V20.5156ZM14.2881 23.6813V20.5156C14.2881 9.45375 23.7825 0.874023 35.0005 0.874023C46.2185 0.874023 55.7129 9.45375 55.7129 20.5156V23.6816C63.8891 25.5091 69.9999 32.8092 69.9999 41.5365V74.9455C69.9999 85.0498 61.8087 93.241 51.7044 93.241H18.2954C8.19114 93.241 0 85.0498 0 74.9455V41.5365C0 32.8088 6.11132 25.5084 14.2881 23.6813ZM7.15908 41.5365C7.15908 35.3861 12.145 30.4002 18.2954 30.4002H51.7044C57.8549 30.4002 62.8408 35.3861 62.8408 41.5365V74.9455C62.8408 81.096 57.8549 86.0819 51.7044 86.0819H18.2954C12.145 86.0819 7.15908 81.096 7.15908 74.9455V41.5365ZM42.9542 51.3818C42.9542 54.4867 41.1753 57.1761 38.581 58.4864V69.6788C38.581 71.6557 36.9784 73.2583 35.0015 73.2583C33.0246 73.2583 31.422 71.6557 31.422 69.6788V58.4882C28.8257 57.1786 27.0451 54.4881 27.0451 51.3818C27.0451 46.9886 30.6065 43.4272 34.9997 43.4272C39.3928 43.4272 42.9542 46.9886 42.9542 51.3818Z" fill="black" />
        </svg>
    );
}
export default LockIcon;
