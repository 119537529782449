import React from 'react';

function AlterCallLogoIcon({ color = '#111111' }) {
  return (
<svg width="200" height="35" viewBox="0 0 200 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.0354 4.38965H37.4451V14.6964H43.0442V15.9873H36.0354V4.38965Z" fill="black"/>
<path d="M44.8005 12.194C44.8995 13.9338 46.0088 14.9441 47.8641 14.9441C48.9074 14.9441 49.8846 14.6139 50.8122 13.8843V15.2082C49.8846 15.8552 48.8744 16.1358 47.7981 16.1358C45.1802 16.1358 43.4899 14.4621 43.4899 11.9101C43.4899 9.60578 44.8995 7.56885 47.5175 7.56885C49.8053 7.56885 51.0301 9.12708 51.0301 11.6295V12.194H44.8005ZM49.67 11.1376C49.67 9.72793 48.8248 8.78375 47.3987 8.78375C45.956 8.78375 45.0778 9.72793 44.8632 11.1376H49.67Z" fill="black"/>
<path d="M57.174 15.987V14.6269C56.5269 15.6536 55.5332 16.1356 54.3909 16.1356C53.0308 16.1356 51.905 15.4555 51.905 13.9336C51.905 12.0782 53.6779 11.6292 54.7211 11.3816L55.8633 11.101C56.5764 10.936 57.1575 10.7379 57.1575 10.0248C57.1575 9.26218 56.4939 8.78349 55.3351 8.78349C54.2589 8.78349 53.2487 9.19615 52.4366 9.92575V8.58541C53.2487 7.88882 54.3249 7.5752 55.4342 7.5752C57.3886 7.5752 58.4978 8.58541 58.4978 10.2427V15.9903H57.174V15.987ZM54.6385 15.0098C55.8964 15.0098 57.1575 14.1317 57.1575 12.5734V11.6788C56.6755 11.9264 56.2133 12.0419 55.7973 12.1443L55.0182 12.3423C54.1731 12.5569 53.2619 12.8243 53.2619 13.8676C53.2652 14.6962 53.8627 15.0098 54.6385 15.0098Z" fill="black"/>
<path d="M67.9429 15.9871H66.5993V14.8943C65.8532 15.756 64.9915 16.1356 63.9153 16.1356C61.5284 16.1356 59.8546 14.2968 59.8546 11.8274C59.8546 9.34149 61.5449 7.56866 63.9153 7.56866C64.975 7.56866 65.8531 7.8988 66.5827 8.67791V4.20459H67.9429V15.9871ZM64.0341 14.9142C65.1764 14.9142 66.038 14.3826 66.5861 13.6398V10.0612C65.9885 9.24905 65.1269 8.80337 64.0506 8.80337C62.2448 8.80337 61.2181 10.0777 61.2181 11.834C61.2181 13.5903 62.2448 14.9142 64.0341 14.9142Z" fill="black"/>
<path d="M70.693 12.194C70.792 13.9338 71.9013 14.9441 73.7566 14.9441C74.7998 14.9441 75.777 14.6139 76.7047 13.8843V15.2082C75.777 15.8552 74.7668 16.1358 73.6906 16.1358C71.0726 16.1358 69.3823 14.4621 69.3823 11.9101C69.3823 9.60578 70.792 7.56885 73.41 7.56885C75.6978 7.56885 76.9226 9.12708 76.9226 11.6295V12.194H70.693ZM75.5657 11.1376C75.5657 9.72793 74.7206 8.78375 73.2944 8.78375C71.8517 8.78375 70.9736 9.72793 70.759 11.1376H75.5657Z" fill="black"/>
<path d="M79.7221 7.72091V8.96221C80.2536 8.08406 81.1813 7.67139 82.1419 7.67139C82.4721 7.67139 82.822 7.72091 83.1687 7.80344V9.04475C82.822 8.94571 82.4556 8.89619 82.1254 8.89619C81.1152 8.89619 80.2536 9.36168 79.7386 10.22V15.9842H78.3784V7.71761L79.7221 7.72091Z" fill="black"/>
<path d="M86.7969 14.9442C87.9392 14.9442 88.6688 14.5117 88.6688 13.7689C88.6688 13.0393 87.9557 12.7917 87.1601 12.6101L86.3644 12.412C85.0241 12.0984 83.7465 11.6659 83.7465 10.0252C83.7465 8.28535 85.2222 7.57227 86.8795 7.57227C87.7906 7.57227 88.6193 7.78685 89.3489 8.20282V9.51015C88.6028 9.02816 87.7576 8.76405 86.929 8.76405C85.9353 8.76405 85.0736 9.1437 85.0736 10.0054C85.0736 10.768 85.7702 10.9991 86.6484 11.2136L87.576 11.4282C88.4377 11.6428 90.0124 12.0093 90.0124 13.6996C90.0124 15.3073 88.5863 16.1359 86.8497 16.1359C85.7405 16.1359 84.5619 15.8058 83.7333 15.2083V13.8481C84.6907 14.5942 85.7702 14.9442 86.7969 14.9442Z" fill="black"/>
<path d="M92.8086 4.20801V8.82989C93.5217 7.98475 94.4494 7.57208 95.5422 7.57208C97.381 7.57208 98.8072 8.74735 98.8072 10.7051V15.9905H97.447V11.0682C97.447 9.54298 96.4533 8.81338 95.3276 8.81338C94.4329 8.81338 93.4392 9.27887 92.8086 10.1537V15.9872H91.4485V4.20801H92.8086Z" fill="black"/>
<path d="M100.398 5.15542C100.398 4.55787 100.811 4.17822 101.392 4.17822C102.006 4.17822 102.435 4.54137 102.435 5.15542C102.435 5.71995 101.99 6.0996 101.392 6.0996C100.811 6.0963 100.398 5.71335 100.398 5.15542ZM100.745 7.72056H102.105V15.9871H100.745V7.72056Z" fill="black"/>
<path d="M112.194 11.6956C112.194 13.8316 110.817 15.9874 108.1 15.9874C107.057 15.9874 106.179 15.6408 105.466 14.8781V19.1699H104.106V7.72083H105.446V8.76406C106.176 7.93542 107.103 7.57227 108.196 7.57227C110.867 7.56897 112.194 9.6257 112.194 11.6956ZM108.034 14.7626C109.956 14.7626 110.834 13.2539 110.834 11.7485C110.834 10.2728 109.956 8.80037 108.051 8.80037C106.876 8.80037 106.047 9.29887 105.466 10.0582V13.4718C106.08 14.2806 106.942 14.7626 108.034 14.7626Z" fill="black"/>
<path d="M119.451 15.9873H117.315V4.38965H119.451V15.9873Z" fill="black"/>
<path d="M123.491 7.46985V8.315C124.204 7.65143 125.083 7.32129 126.027 7.32129C127.75 7.32129 129.292 8.46356 129.292 10.5203V15.9873H127.222V10.966C127.222 9.7907 126.41 9.24267 125.466 9.24267C124.769 9.24267 123.99 9.57281 123.544 10.1869V15.984H121.474V7.46655H123.491V7.46985Z" fill="black"/>
<path d="M137.162 9.29214H133.914V12.6397C133.914 13.782 134.693 14.297 135.654 14.297C136.202 14.297 136.812 14.1319 137.36 13.815V15.6373C136.614 15.9675 135.868 16.1358 135.191 16.1358C133.303 16.1358 131.844 14.944 131.844 12.7387V9.29214H129.889V7.4698H131.844V4.98389H133.914V7.4698H137.162V9.29214Z" fill="black"/>
<path d="M139.645 12.4582C139.876 13.683 140.754 14.3961 142.279 14.3961C143.372 14.3961 144.415 14.0329 145.326 13.4189V15.2742C144.399 15.8388 143.306 16.1359 142.164 16.1359C139.381 16.1359 137.624 14.3796 137.624 11.7616C137.624 9.19315 139.298 7.32129 141.834 7.32129C144.171 7.32129 145.594 8.91254 145.594 11.5305V12.4582H139.645ZM143.557 10.9528C143.557 9.74448 142.86 9.11392 141.751 9.11392C140.576 9.11392 139.863 9.8105 139.648 10.9528H143.557Z" fill="black"/>
<path d="M146.769 15.9872V4.20801H148.823V15.9872H146.769Z" fill="black"/>
<path d="M150.579 15.9872V4.20801H152.632V15.9872H150.579Z" fill="black"/>
<path d="M153.992 4.97072C153.992 4.22461 154.524 3.63037 155.386 3.63037C156.231 3.63037 156.779 4.21141 156.779 4.97072C156.779 5.73333 156.231 6.31436 155.386 6.31436C154.524 6.31106 153.992 5.73333 153.992 4.97072ZM154.372 7.46983H156.426V15.9873H154.372V7.46983Z" fill="black"/>
<path d="M166.353 9.29218H164.25C164.798 9.70485 165.012 10.1538 165.012 10.8174C165.012 12.6067 163.57 13.5674 161.318 13.8315C160.671 13.9141 160.077 13.9801 160.077 14.4291C160.077 14.8913 160.691 14.9606 161.104 14.9771L162.328 15.0266C163.421 15.0761 165.725 15.2577 165.725 17.3145C165.725 19.2358 163.721 19.9819 161.384 19.9819C159.215 19.9819 157.409 19.3349 157.409 17.6776C157.409 16.6674 158.056 15.9873 159.364 15.7232C158.667 15.5416 158.221 15.0761 158.221 14.4819C158.221 13.9834 158.519 13.5047 159.248 13.3231C158.37 12.7586 157.871 11.8804 157.871 10.7547C157.871 8.86631 159.265 7.47314 161.731 7.47314H166.353V9.29218ZM161.397 18.4435C162.691 18.4435 163.768 18.2784 163.768 17.4828C163.768 16.8853 163.154 16.6212 161.463 16.6212C160.288 16.6212 159.327 16.7532 159.327 17.5489C159.327 18.2917 160.173 18.4435 161.397 18.4435ZM161.447 12.3129C162.441 12.3129 163.121 11.6989 163.121 10.7877C163.121 9.89303 162.441 9.24597 161.447 9.24597C160.453 9.24597 159.79 9.89303 159.79 10.7877C159.79 11.6824 160.453 12.3129 161.447 12.3129Z" fill="black"/>
<path d="M168.406 12.4582C168.637 13.683 169.515 14.3961 171.041 14.3961C172.133 14.3961 173.177 14.0329 174.088 13.4189V15.2742C173.16 15.8388 172.067 16.1359 170.925 16.1359C168.142 16.1359 166.386 14.3796 166.386 11.7616C166.386 9.19315 168.06 7.32129 170.595 7.32129C172.932 7.32129 174.355 8.91254 174.355 11.5305V12.4582H168.406ZM172.315 10.9528C172.315 9.74448 171.618 9.11392 170.509 9.11392C169.334 9.11392 168.621 9.8105 168.406 10.9528H172.315Z" fill="black"/>
<path d="M177.551 7.46985V8.315C178.264 7.65143 179.142 7.32129 180.086 7.32129C181.81 7.32129 183.351 8.46356 183.351 10.5203V15.9873H181.281V10.966C181.281 9.7907 180.469 9.24267 179.525 9.24267C178.828 9.24267 178.049 9.57281 177.604 10.1869V15.984H175.534V7.46655H177.551V7.46985Z" fill="black"/>
<path d="M186.497 11.7616C186.497 13.4189 187.623 14.2145 189.016 14.2145C189.746 14.2145 190.608 13.9834 191.436 13.4354V15.3733C190.657 15.9213 189.68 16.1359 188.835 16.1359C186.53 16.1359 184.444 14.5611 184.444 11.7451C184.444 8.92905 186.547 7.32129 188.851 7.32129C189.68 7.32129 190.657 7.51937 191.436 8.10041V10.0218C190.608 9.47377 189.746 9.24267 189.033 9.24267C187.607 9.24267 186.497 10.1076 186.497 11.7616Z" fill="black"/>
<path d="M194.051 12.4582C194.282 13.683 195.16 14.3961 196.685 14.3961C197.778 14.3961 198.821 14.0329 199.733 13.4189V15.2742C198.805 15.8388 197.712 16.1359 196.57 16.1359C193.787 16.1359 192.031 14.3796 192.031 11.7616C192.031 9.19315 193.704 7.32129 196.24 7.32129C198.577 7.32129 200 8.91254 200 11.5305V12.4582H194.051ZM197.96 10.9528C197.96 9.74448 197.263 9.11392 196.154 9.11392C194.979 9.11392 194.266 9.8105 194.051 10.9528H197.96Z" fill="black"/>
<rect x="169.986" y="20.8213" width="29.7515" height="13.8132" rx="3.21901" fill="#C5E8FF"/>
<path d="M177.759 30.2875L175.299 30.2875L175.299 24.2856L177.429 24.2856C177.979 24.2856 178.415 24.4292 178.731 24.7212C179.047 25.0131 179.205 25.3865 179.205 25.8412C179.205 26.3916 178.985 26.8128 178.544 27.0904C178.894 27.2196 179.162 27.4158 179.344 27.6838C179.525 27.9471 179.616 28.263 179.616 28.6219C179.616 29.1101 179.454 29.5122 179.123 29.8233C178.793 30.1344 178.338 30.2875 177.759 30.2875ZM177.3 25.2764L176.386 25.2764L176.386 26.7793L177.3 26.7793C177.534 26.7793 177.726 26.717 177.874 26.5878C178.023 26.4586 178.094 26.2671 178.094 26.0087C178.094 25.7646 178.018 25.5827 177.869 25.4583C177.721 25.3386 177.534 25.2764 177.3 25.2764ZM177.606 27.77L176.39 27.77L176.39 29.3016L177.606 29.3016C177.879 29.3016 178.094 29.2346 178.257 29.1053C178.42 28.9761 178.501 28.7847 178.501 28.531C178.501 28.2773 178.42 28.0859 178.257 27.9614C178.094 27.8322 177.879 27.77 177.606 27.77Z" fill="#00588B"/>
<path d="M184.522 30.2875L180.928 30.2875L180.928 24.2856L184.479 24.2856L184.479 25.2764L181.985 25.2764L181.985 26.7793L184.335 26.7793L184.335 27.77L181.985 27.77L181.985 29.3016L184.522 29.3016L184.522 30.2875Z" fill="#00588B"/>
<path d="M188.131 30.2875L187.04 30.2875L187.04 25.2764L185.408 25.2764L185.408 24.2856L189.763 24.2856L189.763 25.2764L188.131 25.2764L188.131 30.2875Z" fill="#00588B"/>
<path d="M190.878 30.2875L189.667 30.2875L192.036 24.2856L193.151 24.2856L195.521 30.2875L194.319 30.2875L193.865 29.1293L191.328 29.1293L190.878 30.2875ZM192.591 25.6832L191.687 28.1242L193.487 28.1242L192.591 25.6832Z" fill="#00588B"/>
<path d="M20.7617 18.534C22.2344 21.0959 23.7081 23.6601 25.1959 26.2482C24.7662 26.4925 24.3493 26.7301 23.907 26.9817C22.4529 24.4532 21.0042 21.9342 19.5659 19.4328C14.9414 22.1176 10.3506 22.1176 5.7188 19.4333C4.28373 21.9284 2.83458 24.4487 1.37816 26.9812C0.943093 26.7337 0.525286 26.4961 0.0888596 26.2482C1.57298 23.6659 3.04666 21.1022 4.52397 18.5327C1.28052 15.6681 -0.278083 12.1013 0.0407211 7.79003C0.260524 4.81982 1.73511 1.74617 3.67473 0C4.01125 0.349596 4.34822 0.699644 4.68473 1.04969C-0.0864355 5.72181 0.706487 13.3949 5.296 17.1903C7.73835 12.9423 10.1784 8.69745 12.6426 4.41151C15.104 8.69293 17.5441 12.9373 19.9851 17.1835C24.4107 13.581 25.4979 5.9883 20.5614 1.01898C20.9065 0.685642 21.2521 0.352306 21.5986 0.0185187C26.393 4.35143 27.0042 13.5751 20.7612 18.534L20.7617 18.534ZM6.48357 18.1027C10.3942 20.7522 15.5772 20.3836 18.7934 18.0882C16.7512 14.5353 14.708 10.9816 12.6426 7.38895C10.5758 10.9838 8.53264 14.5381 6.48357 18.1022L6.48357 18.1027Z" fill="black"/>
</svg>


  );
}

export default AlterCallLogoIcon;
