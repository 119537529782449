import { Modal, Box, Typography, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Import the icon
import ConfirmButton from "../../ConfirmButton";
import DeclineButton from "../../DeclineButton";
import { useState } from "react";

const DeleteMembersModal = ({
  open,
  handleClose,
  handleDelete,
  selectedCount,
  loading,
}) => {
  const [keepData, setKeepData] = useState(false); // State for checkbox

  const handleCheckboxChange = (event) => {
    setKeepData(event.target.checked);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-confirmation-modal"
      aria-describedby="modal-to-confirm-user-deletion"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxWidth: "calc(100% - 40px)",
          maxHeight: {
            xs: "calc(100% - 50px)",
            md: "80%",
          },
          overflowY: "hidden",
          boxShadow: 24,
          borderRadius: "30px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography sx={{ color: "black", fontSize: 36, fontFamily: "Lexend", fontWeight: "300" }}>
            Delete Members
          </Typography>
            <Typography sx={{ color: '#828282', fontSize: 16, fontFamily: 'Lexend', fontWeight: '500' }}>
                {`Deleting a private member will remove them entirely from the company and from other users.`}
            </Typography>
          <Typography sx={{ color: "#828282", fontSize: 16, fontFamily: "Lexend", fontWeight: "500" }}>
            {`Are you sure you want to delete ${selectedCount} user(s)?`}
          </Typography>
          {/* Checkbox with Tooltip */}
          <FormControlLabel
            control={
              <Checkbox
                checked={keepData}
                onChange={handleCheckboxChange}
                sx={{
                  color: "black", // Set default color to black
                  "&.Mui-checked": {
                    color: "black", // Ensure black when checked
                  },
                }}
              />
            }
            label={
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Keep user data
                <Tooltip title="If selected, the user's data will be retained in the system.">
                  <InfoOutlinedIcon sx={{ ml: 1, color: "black" }} /> {/* Add a tooltip icon */}
                </Tooltip>
              </Box>
            }
          />
          <Box sx={{ display: "flex", justifyContent: "center", gap: "15px" }}>
            <DeclineButton label={"Cancel"} onClick={handleClose} />
            <ConfirmButton
              label={"Delete"}
              loading={loading}
              onClick={() => handleDelete(keepData)} // Pass the checkbox value to handleDelete
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteMembersModal;
