import React from 'react';

function PdfIconForVC({ color = '#111111', width = '15', height = '18' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 21 15">
            <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2.0"
                d="M10.25 1.8v1c0 1.47 0 2.205.286 2.767.252.494.653.895 1.147 1.147C12.245 7 12.98 7 14.45 7h1m-5.2-5.2c-.344-.05-.756-.05-1.445-.05H7.1c-1.96 0-2.94 0-3.689.381a3.5 3.5 0 0 0-1.53 1.53C1.5 4.41 1.5 5.39 1.5 7.35v6.3c0 1.96 0 2.94.381 3.689a3.5 3.5 0 0 0 1.53 1.53c.749.381 1.729.381 3.689.381h2.8c1.96 0 2.94 0 3.689-.381a3.5 3.5 0 0 0 1.53-1.53c.381-.749.381-1.729.381-3.689V8.445c0-.689 0-1.1-.05-1.445m-5.2-5.2a3.5 3.5 0 0 1 1.254.465c.353.217.656.52 1.261 1.125l1.095 1.095c.605.605.908.908 1.124 1.261a3.5 3.5 0 0 1 .42 1.012c.018.078.033.158.046.242M5 11.375h7m-7 3.5h4.375"
            />
        </svg>
    )
}

export default PdfIconForVC;







