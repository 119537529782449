import React from "react";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Box from "@mui/material/Box";

const StyledFormControl = styled(FormControl)({
  "& .MuiInputBase-root": {
    color: "black",
    borderColor: "#E7E7E7",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    justifyContent: "center",
    padding: "0 20px",
  },
  "& .MuiSelect-select": {
    paddingRight: "0px",
  },
  "& .MuiSelect-icon": {
    top: 0,
  },
});

const StyledSelect = styled(Select)({
  fontSize: "12px",
  "&:focus": {
    backgroundColor: "transparent",
  },
  "& .MuiSelect-icon": {
    position: "relative",
    color: "black",
    fontSize: "14px",
  },
});

const StyledMenuProps = {
  PaperProps: {
    style: {
      borderRadius: 8,
      marginTop: 8,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  getContentAnchorEl: null,
};

const _Select = ({
  value,
  handleChange,
  items,
  defaultValue = items[0].value,
}) => {
  return (
    <StyledFormControl>
      <StyledSelect
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={StyledMenuProps}
      >
        {items.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            {item.key}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default _Select;
