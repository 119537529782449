import { useState, useEffect, useMemo } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import * as Frigade from "@frigade/react";
import UserControls from "./UserControls"
import PropTypes from "prop-types";

const Circle = ({ id, color, radius, fill, strokeDashoffset }) => {
  const circ = 2 * Math.PI * radius;

  return (
    <circle
      r={radius}
      cx="50%"
      cy="50%"
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth="3%"
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{
        transition: "stroke-dashoffset 1s ease-out",
      }}
    ></circle>
  );
};

const Text = ({ score, textSize = 50 }) => (
  <text
    x="50%"
    y="50%"
    dominantBaseline="central"
    textAnchor="middle"
    fontSize={textSize}
    fontFamily="Poppins"
    fontWeight={275}
  >
    {isNaN(score) ? "-" : Math.round(score)}
  </text>
);

const Pie = ({
  score,
  size,
  fillStopColor,
  isOverall,
  responsive,
}) => {
  const radius = size / 2 - size * 0.1;
  const [strokeDashoffset, setStrokeDashoffset] = useState(2 * Math.PI * radius);

  const gradientId = useMemo(() => `gradient-${score}`, [score]);
  const fillGradientId = useMemo(() => `fill-gradient-${score}`, [score]);

  const circ = useMemo(() => 2 * Math.PI * radius, [radius]);
  const strokescore = useMemo(() => ((100 - score) * circ) / 100, [score, circ]);

  useEffect(() => {
    setStrokeDashoffset(strokescore);
  }, [strokescore]);

  const color = useMemo(() => {
    if (score >= 1 && score <= 20) return "#DA0000";
    if (score >= 21 && score <= 40) return "#F90";
    if (score >= 41 && score <= 60) return "#FFD600";
    if (score >= 61 && score <= 70) return "#A2D831";
    if (score >= 71 && score <= 80) return "#70CD27";
    if (score >= 81 && score <= 95) return "#4BC500";
    if (score >= 95 && score <= 100) return "#37AB00";
    return "#D3D3D3";
  }, [score]);

  return (
    <svg
      viewBox="0 0 200 200"
      width={responsive ? "100%" : size}
      height={responsive ? "auto" : size}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#e383eb" />
          <stop offset="40%" stopColor="#8e33b8" />
          <stop offset="60%" stopColor="#568FF3" />
          <stop offset="100%" stopColor="#62C1EB" />
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop offset="0%" stopColor={fillStopColor ?? color} stopOpacity=".2" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g transform={`rotate(-90 ${100} ${100})`}>
        <Circle color="lightgrey" radius={radius} strokeDashoffset={0} fill={`url(#${fillGradientId})`} />
        <Circle id={gradientId} radius={radius} strokeDashoffset={strokeDashoffset} color={isOverall ? `url(#${gradientId})` : color} />
      </g>
      <Text score={score} textSize={`${radius * 0.7}px`} />
    </svg>
  );
};

Pie.defaultProps = {
  size: 170,
  responsive: true,
};

Pie.propTypes = {
  score: PropTypes.number.isRequired,
  size: PropTypes.number,
  fillStopColor: PropTypes.string,
  isOverall: PropTypes.bool,
  responsive: PropTypes.bool,
};


const LaraClientOverview = ({ clientName, client_id, picture, setPicture, avatar, laraData, loading }) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const pieSize = width < 600 ? 30 : width < 900 ? 55 : width < 1200 ? 60 : 80;

  console.log("final size: ", pieSize)
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
        }}
      >
        <Frigade.Tour
          flowId="flow_FnM7KnhQ"
          defaultOpen={true}
          sequential={true}
          dismissible={true}
          css={{
            ".fr-card": {
              backgroundColor: "#EFF3F6",
            },
            ".fr-button-primary": {
              backgroundColor: "white",
              color: "black",
              borderColor: "black",
              borderRadius: "50px",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            },
          }}
        />
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            width: "100%",
            borderRadius: "40px",
            background: "white",
            overflow: "auto",
            alignItems: "center",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Ensure the Box takes up the full width
                height: "100%", // Ensure the Box takes up the full height
              }}
            >
              <CircularProgress
                sx={{
                  color: "black",
                }}
              />
            </Box>
          ) : (
            <>
              <UserControls 
                loading={loading}
                clientName={clientName}
                picture={picture}
                avatar={avatar}
                setPicture={setPicture}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxWidth: "330px",
                    minWidth: "330px",
                    marginLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      color: "black",
                      fontWeight: 400,
                      fontSize: "24px",
                    }}
                  >
                    {/* {clientName + 'LARA Score'} */}
                    Communication Analytics
                  </Typography>
                  <Button
                    sx={{
                      paddingX: "4px",
                      paddingY: "8px",
                      background: "white",
                      border: "1px solid black",
                      color: "black",
                      borderRadius: "43px",
                      display: "flex",
                      width: "70%",
                      alignItems: "center",
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/platform/coach/lara-analytics?clientName=${clientName}`
                      )
                    }
                    data-cy="explore-lara-analytics"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 500,
                        letterSpacing: "0.05em",
                        textTransform: "capitalize",
                      }}
                    >
                      <span id="hint-anchor-1">Explore LARA analytics</span>
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.listen_score) ? "-" : laraData.listen_score
                  }
                  radius={pieSize}
                  color={"#62C1EB"}
                  textSize={"40px"}
                  fillStopColor={"#62C1EB"}
                  responsive
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Listen
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.affirm_score) ? "-" : laraData.affirm_score
                  }
                  radius={pieSize}
                  color={"#568FF3"}
                  textSize={"40px"}
                  fillStopColor={"#568FF3"}
                  responsive
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Affirm
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.respond_score)
                      ? "-"
                      : laraData.respond_score
                  }
                  radius={pieSize}
                  color={"#e383eb"}
                  textSize={"40px"}
                  fillStopColor={"#e383eb"}
                  responsive
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Respond
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={isNaN(laraData?.ask_score) ? "-" : laraData.ask_score}
                  radius={pieSize}
                  color={"#8e33b8"}
                  textSize={"40px"}
                  fillStopColor={"#8e33b8"}
                  responsive
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Ask
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default LaraClientOverview;

const useWindowSize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log("size: ", size)

  return size;
};
