import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Box, Button } from '@mui/material';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { getFilePlugin } from '@react-pdf-viewer/get-file';


import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';




const PdfViewer = ({ pdfUrl }) => {
    return (
        <Box sx={{
            height: '100%',
            overflow: 'auto'
        }}>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div>
                    <Viewer
                        fileUrl={pdfUrl}
                    // plugins={[getFilePluginInstance]}
                    />
                </div>
            </Worker>
        </Box>


    );
};

export default PdfViewer;

