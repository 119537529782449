export const boxStyle = {
  border: "1px solid #d3d3d3",
  borderRadius: "20px",
  padding: "40px",
  paddingTop: "40px",
  paddingRight: "80px",
  display: "flex",
  alignItems: "left",
  flexDirection: "column",
  gap: "10px",
  zIndex: 3,
  background: "white",
  height: {
    xs: "initial",
    sm: "initial",
    md: "250px",
    lg: "250px",
  },
  justifyContent: "center",
  marginRight: "4px",
  marginLeft: "8px",
  marginBottom: "10px",
  maxWidth: {
    md: "680px",
  },
};

export const buttonStyle = {
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "16px",
  },
  width: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "75%",
  },
  maxWidth: {
    md: "330px",
    lg: "330px",
  },
  height: {
    xs: "50px",
  },
  marginLeft: "0px",
  marginRight: "20px",
  borderRadius: "10px",
  backdropFilter: "blur(25px)",
  color: "white",
  textTransform: "none",
  padding: "5px 20px",
  background:
    "linear-gradient(330deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
  display: {
    // xs: "none",
    lg: "block",
  },
  transition: "0.5s all ease-in-out",
  "&:hover": {
    filter: "brightness(2)",
    transition: "0.5s all ease-in-out",
  },
};

export const buttonStyleLight = {
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
  },
  width: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "100%",
  },
  maxWidth: {
    md: "330px",
    lg: "330px",
  },
  height: {
    xs: "50px",
  },
  marginLeft: "0px",
  marginRight: "0px",
  borderRadius: "10px",
  backdropFilter: "blur(25px)",
  background:
    "linear-gradient(330deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  border: "1px solid #d3d3d3",
  whiteSpace: "nowrap",
  textTransform: "none",
  padding: "5px 20px",
  fontWeight: 400,
  display: {
    // xs: "none",
    lg: "block",
  },
  transition: "0.5s all ease-in-out",
  "&:hover": {
    filter: "brightness(2)",
    // background:
    //   "linear-gradient(45deg, rgba(255, 20, 147, 0.5) 13.07%, rgba(136, 136, 136, 0.5) 43.61%, rgba(255, 255, 255, 0.5) 89.22%)",

    transition: "0.5s all ease-in-out",
  },
};
