import React from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import Topbar from 'scenes/Layout/Topbar';
import Sidebar from 'scenes/Layout/Sidebar';
import ClientHasInactiveCoachModal from 'components/ClientHasInactiveCoach/ClientHasInactiveCoachModal';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const GlobalPageComponent = ({ children, user, userData }) => {
  const location = useLocation();
  // console.log('location', location.pathname);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // Check for desktop view
  const isTablet = useMediaQuery(theme.breakpoints.up('md')); // Check for tablet view
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile view
  // const isDesktop = useMediaQuery('(min-width:1210px)');


  // Check if the current route is "/coach/vc"
  const isVCRoute = location.pathname.includes('/virtualcoach')


  const defaultStyle = {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    width: '100%',
    height: '100%',
    background: '#EFF3F6',
    overflowY: "auto",
    // border: '3px solid yellow',
  };

  return (
    <Box sx={defaultStyle} id="GlobalPageComponent">
      <ClientHasInactiveCoachModal />

      {/* Prohibiting Topbar to render when the route is VC and the screen is Dekstop view */}
      {!(isVCRoute && isDesktop) && <Topbar user={user} userData={userData} />}

      <Sidebar user={user} userData={userData} />
      {children}

    </Box>
  );
};

GlobalPageComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default GlobalPageComponent;