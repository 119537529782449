import { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const getPieChartColor = (score) => {
  if (score >= 1 && score <= 20) return "#DA0000"; // Red
  if (score >= 21 && score <= 40) return "#F90"; // Orange
  if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
  if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
  if (score >= 71 && score <= 80) return "#70CD27"; // Green
  if (score >= 81 && score <= 95) return "#4BC500"; // Green
  if (score >= 95 && score <= 100) return "#37AB00"; // Green
  return "#D3D3D3"; // Grey for undefined or zero
};

const Circle = ({
  id,
  color,
  score,
  radius,
  fill,
  strokeDashoffset,
  strokeWidth,
}) => {
  const r = radius;
  const circ = 2 * Math.PI * r;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth={"6px"}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{
        transition: "stroke-dashoffset 1s ease-out",
        filter: "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.05))",
      }}
    ></circle>
  );
};

const Text = ({ score, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily="Poppins"
      fontWeight={275}
    >
      {isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
    </text>
  );
};

const Pie = ({ score, radius, textSize, fillStopColor, isOverall }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(
    2 * Math.PI * radius
  );
  const gradientId = `gradient-${Math.random()}`;
  const fillGradientId = `fill-gradient-${Math.random()}`;

  useEffect(() => {
    const circ = 2 * Math.PI * radius;
    const strokescore = ((100 - score) * circ) / 100;
    setStrokeDashoffset(strokescore);
  }, [score, radius]);

  const color = getPieChartColor(score);
  const strokeWidth = "6px";

  return (
    <svg width={200} height={200}>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#e383eb" />
          <stop offset="40%" stopColor="#8e33b8" />
          <stop offset="60%" stopColor="#568FF3" />
          <stop offset="100%" stopColor="#62C1EB" />
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop
            offset="0%"
            stopColor={fillStopColor ?? color}
            stopOpacity=".2"
          />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle
          color="lightgrey"
          radius={radius}
          strokeDashoffset={0}
          strokeWidth={strokeWidth}
          fill={`url(#${fillGradientId})`}
        />
        <Circle
          id={gradientId}
          radius={radius}
          strokeDashoffset={strokeDashoffset}
          strokeWidth={strokeWidth}
          color={isOverall ? `url(#${gradientId})` : color}
        />
      </g>
      <Text score={score} textSize={textSize} />
    </svg>
  );
};

const CoachLaraPieCharts = ({
  laraData = [], // default to empty array if not provided
  loading = false, // default loading to false
  filterCoachLaraPies,
  filterClientLaraPies,
}) => {

  console.log("lara data in pies: ", laraData )

  const [averageScores, setAverageScores] = useState({
    "Listen score": "-",
    "Affirm score": "-",
    "Respond score": "-",
    "Ask score": "-",
    "Overall score": "-",
  });

  useEffect(() => {
    if (laraData.length > 0) {
      // Actual data processing logic
      let validData = laraData.filter(
        (item) =>
          item.lara &&
          (item.lara.client.length > 0 || item.lara.coach.length > 0)
      );

      let filteredLaraData = validData;
      if (filterClientLaraPies !== "none" && filterCoachLaraPies !== "none") {
        filteredLaraData = validData.filter(
          (item) => item.lara.coach.length > 0
        );
      } else if (filterClientLaraPies !== "none") {
        filteredLaraData = validData.filter(
          (item) => item.lara.client.length > 0
        );
      } else if (filterCoachLaraPies !== "none") {
        filteredLaraData = validData.filter(
          (item) => item.lara.coach.length > 0
        );
      }

      const aggregatedScores = filteredLaraData.reduce(
        (acc, curr) => {
          const laraItem =
            filterClientLaraPies !== "none" && filterCoachLaraPies !== "none"
              ? curr.lara.coach[0]
              : filterClientLaraPies !== "none"
              ? curr.lara.client[0]
              : curr.lara.coach[0];

          if (laraItem) {
            acc.listen += parseFloat(laraItem.listen_score || 0);
            acc.affirm += parseFloat(laraItem.affirm_score || 0);
            acc.respond += parseFloat(laraItem.respond_score || 0);
            acc.ask += parseFloat(laraItem.ask_score || 0);
            acc.count += 1;
          }
          return acc;
        },
        { listen: 0, affirm: 0, respond: 0, ask: 0, count: 0 }
      );

      const averageScores = {
        "Listen score": Math.round(
          (aggregatedScores.listen / aggregatedScores.count) * 20
        ),
        "Affirm score": Math.round(
          (aggregatedScores.affirm / aggregatedScores.count) * 20
        ),
        "Respond score": Math.round(
          (aggregatedScores.respond / aggregatedScores.count) * 20
        ),
        "Ask score": Math.round(
          (aggregatedScores.ask / aggregatedScores.count) * 20
        ),
        "Overall score": Math.round(
          ((aggregatedScores.listen / aggregatedScores.count +
            aggregatedScores.affirm / aggregatedScores.count +
            aggregatedScores.respond / aggregatedScores.count +
            aggregatedScores.ask / aggregatedScores.count) /
            4) *
            20
        ),
      };

      setAverageScores(averageScores);
    } 
  }, [laraData, filterCoachLaraPies, filterClientLaraPies]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            borderRadius: "40px",
            background: "white",
            overflow: "hidden",
            minHeight: "220px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Pie
            score={averageScores["Overall score"]}
            radius={80}
            textSize={"50px"}
            isOverall={true} // Use the gradient for overall score
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie
              score={averageScores["Listen score"]}
              radius={60}
              color={"#62C1EB"}
              textSize={"35px"}
              fillStopColor={"#62C1EB"}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Listen
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie
              score={averageScores["Affirm score"]}
              radius={60}
              color={"#568FF3"}
              textSize={"35px"}
              fillStopColor={"#568FF3"}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Affirm
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie
              score={averageScores["Respond score"]}
              radius={60}
              color={"#e383eb"}
              textSize={"35px"}
              fillStopColor={"#e383eb"}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Respond
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie
              score={averageScores["Ask score"]}
              radius={60}
              color={"#8e33b8"}
              textSize={"35px"}
              fillStopColor={"#8e33b8"}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Ask
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CoachLaraPieCharts;
