import {
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import {
  _CoachLaraFilter,
  _CoachLaraFilterForClient,
} from "components/CoachLaraAnalytics/CoachLaraFilter";
import CoachLaraPieCharts from "components/CoachLaraAnalytics/CoachLaraPieCharts";
import CoachLaraLineChart from "components/CoachLaraAnalytics/CoachLaraLineChart";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import * as Frigade from "@frigade/react";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import { useNavigate } from "react-router-dom";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CoachLaraAnalytics = ({ user, userData }) => {
  const query = useQuery();
  const clientNameQuery = query.get("clientName");
  const [laraChartFileData, setLaraChartFileData] = useState([]);
  const [filteredLaraPiesFileData, setFilteredLaraPiesFileData] = useState([]);
  const [filteredLaraLineChartFileData, setFilteredLaraLineChartFileData] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);


  // Filters for LaraPies
  const [filterCoachLaraPiesItems, setFilterCoachLaraPiesItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterCoachLaraPies, setFilterCoachLaraPies] = useState("none");
  const [filterClientLaraPiesItems, setFilterClientLaraPiesItems] = useState([
    { key: "Client", value: "none" },
  ]);
  const [filterClientLaraPies, setFilterClientLaraPies] = useState("none");
  const [piesChartLaraCount, setPiesChartLaraCount] = useState("-");

  // Filters for LaraLineChart
  const [filterCoachLaraLineItems, setFilterCoachLaraLineItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterCoachLaraLine, setFilterCoachLaraLine] = useState("none");
  const [filterClientLaraLineItems, setFilterClientLaraLineItems] = useState([
    { key: "Client", value: "none" },
  ]);
  const [filterClientLaraLine, setFilterClientLaraLine] = useState("none");
  const [lineChartLaraCount, setLineChartLaraCount] = useState("-");
  const navigate = useNavigate();

  const fetchCallData = async () => {
    setLoading(true);
    try {
      let response = [];

      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          user?.sub,
          user?.role,
          userData?.companyInformation?.company_id
        );
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara-in-company`,
          {
            params: {
              user_id: user?.sub,
              company_id: userData?.companyInformation?.company_id,
            },
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara`,
          {
            params: {
              user_id: user?.sub,
              company_id: userData?.companyInformation?.company_id,
            },
          }
        );
      }

      response = response.data.data;

      console.log("Combined response lara", response);

      response.sort((a, b) => new Date(b.zoom_date) - new Date(a.zoom_date));

      let parsedChartFileData = await Promise.all(
        response.map(async (row) => {
          let filteredLara = {
            coach: [],
            client: [],
          };

          try {
            console.log("Fetching LARA data for call_id:", row.id);
            const { data: laraResult, errors: resultErrors } =
              await resourceClient.models.lara.get(
                { call_id: row.id },
                {
                  selectionSet: [
                    "call_id",
                    "coach_id",
                    "coach_name",
                    "coach_affirm_score",
                    "coach_ask_score",
                    "coach_listen_score",
                    "coach_respond_score",
                    "coach_overall_score",
                    "coachees.*",
                  ],
                }
              );

            if (resultErrors) {
              console.error("Error fetching LARA data:", resultErrors);
            }

            if (laraResult) {
              console.log("LARA data fetched successfully:", laraResult);

              console.log("row id: ", row.coach_id);
              console.log("lara result id: ", laraResult.coach_id);
              // Check for both coach and client
              if (laraResult.coach_id === row.coach_id) {
                filteredLara.coach.push({
                  listen_score: laraResult.coach_listen_score,
                  affirm_score: laraResult.coach_affirm_score,
                  respond_score: laraResult.coach_respond_score,
                  ask_score: laraResult.coach_ask_score,
                });
              }

              const clientLara = laraResult.coachees.find(
                (c) => c.coachee_name === row.client_name
              );

              if (clientLara) {
                filteredLara.client.push({
                  listen_score: clientLara.listen_score,
                  affirm_score: clientLara.affirm_score,
                  respond_score: clientLara.respond_score,
                  ask_score: clientLara.ask_score,
                });
              }

              // Set the data to the row
              row.coachLaraData = filteredLara.coach;
              row.clientLaraData = filteredLara.client;

              console.log("client lara: ", row.clientLaraData);
              console.log("coach lara: ", row.coachLaraData);

              // Calculate overall score for each row
              if (row.clientLaraData.length > 0) {
                row.clientOverallScore = Math.round(
                  ((row.clientLaraData[0].listen_score +
                    row.clientLaraData[0].affirm_score +
                    row.clientLaraData[0].respond_score +
                    row.clientLaraData[0].ask_score) /
                    4) *
                    20
                );
              }

              if (row.coachLaraData.length > 0) {
                row.coachOverallScore = Math.round(
                  ((row.coachLaraData[0].listen_score +
                    row.coachLaraData[0].affirm_score +
                    row.coachLaraData[0].respond_score +
                    row.coachLaraData[0].ask_score) /
                    4) *
                    20
                );
              }
            } else {
              console.warn("No data returned for call_id:", row.id);
            }
          } catch (error) {
            console.error(
              "Failed to process LARA data for row:",
              row.id,
              error
            );
          }

          return { ...row, lara: filteredLara };
        })
      );

      console.log("Parsed Chart Data", parsedChartFileData);
      setLaraChartFileData(parsedChartFileData);

      // Process and set unique coach names for filtering
      // Process and set unique coach names for filtering
      const coachItems = response
        .filter((row) => row.coach_name !== null)
        .map((row) => row.coach_name);

      const uniqueCoach = [...new Set(coachItems)].sort(); // Sort alphabetically

      const formattedCoach = uniqueCoach.map((row) => ({
        key: row,
        value: row,
      }));
      setFilterCoachLaraPiesItems([
        { key: "Coach", value: "none" },
        ...formattedCoach,
      ]);
      setFilterCoachLaraLineItems([
        { key: "Coach", value: "none" },
        ...formattedCoach,
      ]);

      // Process and set unique client names for filtering
      const clientItems = response
        .filter((row) => row.client !== null)
        .map((row) => row.client);

      // Convert to a Set for uniqueness, then sort alphabetically
      const uniqueClients = [...new Set(clientItems)].sort();

      const formattedClients = uniqueClients.map((row) => ({
        key: row,
        value: row,
      }));

      setFilterClientLaraPiesItems([
        { key: "Client", value: "none" },
        ...formattedClients,
      ]);
      setFilterClientLaraLineItems([
        { key: "Client", value: "none" },
        ...formattedClients,
      ]);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCallData();
  }, []);

  useEffect(() => {
    let filteredPiesData = laraChartFileData;
    if (filterCoachLaraPies !== "none") {
      filteredPiesData = filteredPiesData.filter(
        (item) => item.coach_name === filterCoachLaraPies
      );
    }
    if (filterClientLaraPies !== "none") {
      filteredPiesData = filteredPiesData.filter(
        (item) => item.client === filterClientLaraPies
      );
    }

    // Calculate the number of valid files where lara is not null
    const validFilesCount = filteredPiesData.reduce((count, item) => {
      return item.lara !== null ? count + 1 : count;
    }, 0);

    setFilteredLaraPiesFileData(filteredPiesData);
    setPiesChartLaraCount(validFilesCount); // Assuming setPiesChartLaraCount is the correct state setter
  }, [filterCoachLaraPies, filterClientLaraPies, laraChartFileData]);

  useEffect(() => {
    let filteredLineData = laraChartFileData;
    if (filterCoachLaraLine !== "none") {
      filteredLineData = filteredLineData.filter(
        (item) => item.coach_name === filterCoachLaraLine
      );
    }
    if (filterClientLaraLine !== "none") {
      filteredLineData = filteredLineData.filter(
        (item) => item.client === filterClientLaraLine
      );
    }

    // Calculate the number of valid files where lara is not null
    const validFilesCount = filteredLineData.reduce((count, item) => {
      return item.lara !== null ? count + 1 : count;
    }, 0);

    setFilteredLaraLineChartFileData(filteredLineData);
    setLineChartLaraCount(validFilesCount); // Assuming setLineChartLaraCount is the correct state setter
  }, [filterCoachLaraLine, filterClientLaraLine, laraChartFileData]);

  useEffect(() => {
    if (clientNameQuery) {
      setFilterClientLaraPies(clientNameQuery);
      setFilterClientLaraLine(clientNameQuery);
    }
  }, [clientNameQuery]);

  const handleResetLaraPiesFilters = () => {
    setFilterCoachLaraPies("none");
    setFilterClientLaraPies("none");
  };

  const handleResetLaraChartFilters = () => {
    setFilterCoachLaraLine("none");
    setFilterClientLaraLine("none");
  };

  const handleCoachLaraPiesFilterChange = (value) => {
    setFilterCoachLaraPies(value);
  };

  const handleClientLaraPiesFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraPies("by client");
    } else {
      setFilterClientLaraPies(value);
    }
  };

  const handleCoachLaraLineFilterChange = (value) => {
    setFilterCoachLaraLine(value);
  };

  const handleClientLaraLineFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraLine("by client");
    } else {
      setFilterClientLaraLine(value);
    }
  };

  const collapsed = useSelector((state) => state.layout.collapsed);

  const handleClick = () => {
    window.open(
      "/blog?title=Enhancing%20Communication%20and%20Coaching%20Through%20the%20Power%20of%20the%20LARA%20Framework&id=21#:~:text=The%20LARA%20score%20is%20calculated%20based",
      "_blank"
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setIsDataLoaded(true);
    }, 2500); 
  
    return () => clearTimeout(timer);
  }, []);
  

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Frigade.Tour
          flowId="flow_OwRgEcEK"
          defaultOpen={true}
          sequential={true}
          dismissible={true}
          css={{
            ".fr-card": {
              backgroundColor: "#EFF3F6",
            },
            ".fr-button-primary": {
              backgroundColor: "white",
              color: "black",
              borderColor: "black",
              borderRadius: "50px",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            },
          }}
        />
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontWeight: 300,
              marginBottom: "16px",
            }}
            data-cy="lara-analytics-title"
          >
            LARA Analytics
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // Changed to place at extreme right
              marginRight: {
                xs: "unset",
                md: "200px",
              },
              zIndex: 1100,
              position: "relative",
              paddingBottom: "24px",
              alignSelf: {
                xs: "center",
                md: "unset",
              },
            }}
          >
            <Button
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                  lg: "220px",
                },
                padding: "10px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "52px",
                border: "1px solid black",
                borderColor: "black",
                background: "white",
                opacity: 1,
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                  color: "white",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
              onClick={handleClick}
              data-cy="learn-more-button"
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  letterSpacing: "2px",
                  lineHeight: "1.5",
                  textTransform: "uppercase",
                }}
              >
                Learn More
              </Typography>
              {ArrowForwardBlack()}
            </Button>
          </Box>
        </Box> */}
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "50px",
            },
            paddingY: {
              xs: "30px",
              md: "30px",
            },
            height: "100%",
            overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                <span id="hint-anchor-0">Overall LARA Score</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  Sessions: {piesChartLaraCount}
                </Typography>
                <span id="hint-anchor-1">
                  <_CoachLaraFilter
                    value={filterCoachLaraPies}
                    items={filterCoachLaraPiesItems}
                    handleChange={(e) => handleCoachLaraPiesFilterChange(e)}
                  />
                </span>
                <_CoachLaraFilterForClient
                  value={filterClientLaraPies}
                  items={filterClientLaraPiesItems}
                  handleChange={(e) => handleClientLaraPiesFilterChange(e)}
                  type="client"
                />

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#FF5E00",
                      borderColor: "#FF5E00",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  data-cy="reset-filters-button"
                  onClick={handleResetLaraPiesFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <CoachLaraPieCharts
              laraData={filteredLaraPiesFileData}
              loading={loading}
              filterCoachLaraPies={filterCoachLaraPies}
              filterClientLaraPies={filterClientLaraPies}
            />
            <Divider color={"#DDE5ED"} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                <span id="hint-anchor-2">Score Comparison</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  <span id="hint-anchor-3">Sessions: {lineChartLaraCount}</span>
                </Typography>
                <_CoachLaraFilter
                  value={filterCoachLaraLine}
                  items={filterCoachLaraLineItems}
                  handleChange={(e) => handleCoachLaraLineFilterChange(e)}
                />
                <_CoachLaraFilterForClient
                  value={filterClientLaraLine}
                  items={filterClientLaraLineItems}
                  handleChange={(e) => handleClientLaraLineFilterChange(e)}
                  type="client"
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#FF5E00",
                      borderColor: "#FF5E00",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  onClick={handleResetLaraChartFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 420px)",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "black",
                    }}
                  />
                </Box>
              ) : isDataLoaded && filteredLaraLineChartFileData &&
                filteredLaraLineChartFileData.length > 0 ? (
                <CoachLaraLineChart
                  laraData={filteredLaraLineChartFileData}
                  loading={loading}
                  filterCoachLaraLine={filterCoachLaraLine}
                  filterClientLaraLine={filterClientLaraLine}
                />
              ) : isDataLoaded ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    textAlign: "center",
                    gap: "20px",
                    margin: "auto",
                    padding: "90px",
                    minHeight: "calc(100vh - 620px)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "21px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "32px", 
                    }}
                  >
                    No Data Available |{" "}
                    <span style={{ color: "var(--AlterCall-Blue, #0076BA)" }}>
                      Process a Session to Sync
                    </span>
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--Darkest-gray, #828282)",
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px", 
                      textAlign: "left",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    Process a call by{" "}
                    <span
                      style={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "18px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "var(--Darkest-gray, #828282)",
                      }}
                    >
                      Loading
                    </span>{" "}
                    or{" "}
                    <span
                      style={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "18px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "var(--Darkest-gray, #828282)",
                      }}
                    >
                      Generating Insights
                    </span>{" "}
                    from the session list on your dashboard
                  </Typography>

                  <Button
                    sx={{
                      display: "flex",
                      width: {
                        xs: "100%",
                        lg: "209px",
                      },
                      height: "34px",
                      padding: "10px 15px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "50px",
                      border: "1px solid #D9E4EC",
                      background: "white",
                      opacity: 1,
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() => {
                      navigate("/platform/coach");
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        letterSpacing: "0.05em",
                        lineHeight: "18px",
                        textTransform: "capitalize",
                      }}
                    >
                      return to Dashboard
                    </Typography>
                  </Button>
                </Box>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Changed to place at extreme right
                marginRight: {
                  xs: "unset",
                  md: "0px",
                },
                zIndex: 1100,
                position: "relative",
                paddingBottom: "24px",
                alignSelf: {
                  xs: "center",
                  md: "unset",
                },
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  width: {
                    xs: "100%",
                    lg: "220px",
                  },
                  padding: "10px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "52px",
                  border: "1px solid black",
                  borderColor: "black",
                  background: "white",
                  opacity: 1,
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                    color: "white",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
                onClick={handleClick}
                data-cy="learn-more-button"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Learn More
                </Typography>
                {/* {ArrowForwardBlack()} */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default CoachLaraAnalytics;
