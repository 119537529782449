import {
    Box,
  } from "@mui/material";

  import dayjs from "dayjs";

export const actionsColumns = [
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      renderCell: (params) => (
        <Box
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
          whiteSpace: "pre-wrap",
          textOverflow: "break-word",
        }}
      >
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            whiteSpace: "pre-wrap",
            textOverflow: "break-word",
            textTransform: "none"
          }}
        >
          {params.value}
        </Box>
      </Box>
      ),
    },
    {
      field: "call_date",
      headerName: "Start Date",
      flex: .5,
      renderCell: (params) =>
        <Box
            sx={{
                fontSize: "12px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
            }}
        >
        {params.value ? dayjs(params.value).utc().format('MM/DD/YY') : "No Date"}
        </Box>
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: .5,
      renderCell: (params) =>
        <Box
            sx={{
                fontSize: "12px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
            }}
        >
            {params.value ? dayjs(params.value).utc().format('MM/DD/YY') : "No Due Date"}
        </Box>
    },
];
  