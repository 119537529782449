import { Box, Typography, Button } from "@mui/material";
import {
    ActionListFilterSelect,
    ActionListFilterDateRange,
  } from "components/ActionListFilter";

const FilterComponents = ({ user, userData, filterClient, filterClientItems, setFilterClient, filterCoach, filterCoachItems, setFilterCoach, filterStartDate, setFilterStartDate, filterEndDate, setFilterEndDate, handleResetFilters }) => {
    return(
        <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "100%",
                      '& > :nth-of-type(n)': {
                        flexBasis: "50%",
                      },
                    }}
                  >
                    {(userData.accessTo === "platform") && (
                      <ActionListFilterSelect
                        value={filterCoach}
                        items={filterCoachItems}
                        handleChange={(e) => setFilterCoach(e)}
                      />
                    )}
                    {(userData.accessTo === "platform") && (
                      <ActionListFilterSelect
                        value={filterClient}
                        items={filterClientItems}
                        handleChange={(e) => setFilterClient(e)} // Ensure this updates the filterClient state
                      />
                    )}
                    <ActionListFilterDateRange
                      startDate={filterStartDate}
                      endDate={filterEndDate}
                      handleStartDateChange={(date) => {
                        setFilterStartDate(date);
                      }}
                      handleEndDateChange={(date) => {
                        setFilterEndDate(date);
                      }}
                    />
                  <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        maxWidth: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#0076BA', fontSize: "12px", fontFamily: 'Poppins', fontWeight: '400', letterSpacing: 0.40, wordWrap: 'break-word', textTransform: "capitalize"
                        }}
                        data-cy="session-list-reset"
                        onClick={handleResetFilters}
                      >
                        Reset
                      </Typography>
                    </Button>
                  </Box>
                </Box>
        </>
    )
}

export default FilterComponents;