import { Box, Button, CircularProgress, Typography } from '@mui/material';
//import { API } from 'aws-amplify';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import axios from 'axios';

const EditSection = ({ fetchedData, dataFetchLoading }) => {

    const [selectedResource, setSelectedResource] = useState();

    const [pagesForSelectedResource, setPagesForSelectedResource] = useState([])
    const [selectedPage, setSelectedPage] = useState();

    const [sectionsForSelectedPage, setSectionsForSelectedPage] = useState([]);
    const [selectedSection, setSelectedSection] = useState();

    const [editingSectionName, setEditingSectionName] = useState('')
    const [editingSectionContents, setEditingSectionContents] = useState('')


    const [loading, setLoading] = useState(false)



    const modules = {
        toolbar: [

            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            // [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"],
        ],
    };


    useEffect(() => {
        try {
            setSelectedResource(fetchedData[0]?.resource_id)
            setLoading(dataFetchLoading)

        } catch (error) {
            console.log(error);
        }
    }, [fetchedData, dataFetchLoading])


    // Change page for selected Resource
    useEffect(() => {
        const selectedResourceFromFormattedData = fetchedData?.find((data) => data.resource_id === selectedResource)
        const pageNames = selectedResourceFromFormattedData?.pages?.map((data) => {
            return {
                pageName: data.page_name,
                pageId: data.page_id,
                sections: data.sections
            }
        })
        if (pageNames && pageNames.length > 0) {
            setPagesForSelectedResource(pageNames)
            setSelectedPage(pageNames[0].pageId)
        } else {
            setPagesForSelectedResource([])
            setSelectedPage(undefined);
        }
    }, [selectedResource])

    // Change section for selected page
    useEffect(() => {
        if (selectedPage && selectedResource && fetchedData.length > 0) {
            const sections = fetchedData.find((data) => data.resource_id === selectedResource).pages.find((page) => page.page_id === selectedPage).sections;
            setSectionsForSelectedPage(sections);
            setSelectedSection(sections[0].section_id)
        }
    }, [selectedPage])

    useEffect(() => {
        try {
            if (selectedSection) {
                setLoading(true)
                const debouncedDataFetchHandler = debounce(async () => {
                    const section_id = parseInt(selectedSection)
                    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-section/${section_id}`);
                    setEditingSectionName(response.data.rows[0].name)
                    setEditingSectionContents(response.data.rows[0].contents)
                    setLoading(false)
                }, 300)

                debouncedDataFetchHandler()
            }
        } catch (error) {
            console.log(error);
        }
    }, [selectedSection])


    function getResourceNames() {
        const resourceNamesArray = [];
        fetchedData.forEach((resource) => {
            resourceNamesArray.push({
                resourceId: resource.resource_id,
                resourceName: resource.resource_name
            })
        })
        return resourceNamesArray;
    }


    async function handleUpdateSection() {

        try {
            if (!window.confirm('Sure to Save Changes ?')) {
                return;
            }
            setLoading(true)
            const data = {
                body: {
                    name: editingSectionName,
                    contents: editingSectionContents,
                    id: parseInt(selectedSection)
                }
            }
            const dbResponse = await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-section`, data)
            if (dbResponse) {
                setLoading(false)
                toast.success('Updated Successfully')

            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error('Error while updating')
        }
    }

    return (
        <Box color={'black'} pb={'100px'} >

            <Typography fontSize={'25px'} mt={'20px'} mb={'30px'} fontWeight={'500'}>Edit Section</Typography>

            <br />

            <Box display={'flex'} flexDirection={{ xs: "column", lg: 'row' }} gap={'25px'} pb={'20px'}>

                <Box>
                    <Typography fontSize={'10px'}>Select Resource Name</Typography>

                    <select onChange={(event) => setSelectedResource(parseInt(event.target.value))}>
                        {
                            getResourceNames()?.map((resource) => <option key={resource.resourceId} value={resource.resourceId}>{resource.resourceName}</option>)
                        }
                    </select>

                </Box>


                <Box>
                    <Typography fontSize={'10px'}>Select Page Name</Typography>

                    <select onChange={(event) => setSelectedPage(parseInt(event.target.value))}>
                        {
                            pagesForSelectedResource?.map((page) => <option key={page.pageId} value={page.pageId}>{page.pageName}</option>)
                        }
                    </select>

                </Box>


                <Box>
                    <Typography fontSize={'10px'}>Select Section Name. <small>The one you see in the sidebar</small></Typography>

                    <select
                        label='Name' onChange={(event) => setSelectedSection(parseInt(event.target.value))}>
                        {
                            sectionsForSelectedPage?.map((section) =>
                                <option
                                    key={section.section_id}
                                    value={section.section_id}
                                >
                                    {section.section_name}
                                </option>)
                        }
                    </select>

                </Box>

            </Box>

            <Box>
                <Typography fontSize={'10px'}>Write the Section Name here</Typography>
                <textarea value={editingSectionName} cols={30} onChange={(event) => setEditingSectionName((event.target.value).trim())} />
            </Box>

            <Box sx={{ width: "60%" }} position={'relative'}>
                <ReactQuill style={{ marginTop: "20px", height: "500px", }} modules={modules} theme="snow" value={editingSectionContents}
                    onChange={setEditingSectionContents} />

                {
                    loading && <Box sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: '100%',
                        height: '550px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(128, 128, 128, 0.2)',
                        backdropFilter: 'blur(5px)',
                        WebkitBackdropFilter: 'blur(5px)',

                    }} >
                        <CircularProgress color="inherit" />
                    </Box>
                }

                <Box sx={{ display: 'flex', justifyContent: 'end', mt: '80px' }}>
                    <Button
                        sx={{
                            paddingX: "20px",
                            paddingY: "12px",
                            borderRadius: "52px",
                            color: "white",
                            background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                                background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                                cursor: "pointer"
                            },
                            "&:disabled": {
                                background: "#d3d3d3",
                                color: "#B7B7B7",
                                border: "none",
                                cursor: "not-allowed",
                            },
                        }}
                        disabled={loading}
                        onClick={handleUpdateSection}>
                        <Typography
                            sx={{
                                fontFamily: "Lexend",
                                color: "white",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                letterSpacing: "2px",
                                textTransform: "uppercase",
                            }}
                        >
                            Update Section
                        </Typography>
                    </Button>
                </Box>

            </Box>




        </Box>
    );
};

export default EditSection