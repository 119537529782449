import React from "react";

function VcPdfModalCloseIcon({ color = '#000' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13">
      <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" d="M1.25 11.75 6.5 6.5m0 0 5.25-5.25M6.5 6.5 1.25 1.25M6.5 6.5l5.25 5.25" />
    </svg>
  );
}

export default VcPdfModalCloseIcon;






