import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

// Define the custom shadow plugin
const customShadowPlugin = {
  id: 'customShadow',
  beforeDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();

    const originalLineDraw = ctx.stroke;
    ctx.stroke = function () {
      if (ctx.canvas.dataset.shadow === "true") {
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 4;
        ctx.shadowOffsetY = 4;
      }
      originalLineDraw.apply(this, arguments);
      if (ctx.canvas.dataset.shadow === "true") {
        ctx.restore();
      }
    };
  },
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.restore();
  }
};

const ClientLaraLineChart = ({ laraData, loading }) => {

  // Filter the data to include only calls from the last year
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const validLaraData = laraData
    .filter(item => item.lara && item.lara.coach.length > 0 && item.lara.client.length > 0 && new Date(item.zoom_date) >= oneYearAgo);


  // Group data by month and calculate averages
  const monthlyData = validLaraData.reduce((acc, item) => {
    const month = new Date(item.zoom_date).toLocaleString('default', { month: 'short', year: 'numeric' });
    if (!acc[month]) {
      acc[month] = { listen: 0, affirm: 0, respond: 0, ask: 0, overall: 0, count: 0 };
    }
    acc[month].listen += item.lara.client[0].listen_score * 20;
    acc[month].affirm += item.lara.client[0].affirm_score  * 20;
    acc[month].respond += item.lara.client[0].respond_score * 20;
    acc[month].ask += item.lara.client[0].ask_score * 20;
    acc[month].overall += (item.lara.client[0].listen_score  + item.lara.client[0].affirm_score  + item.lara.client[0].respond_score  + item.lara.client[0].ask_score ) * 5;
    acc[month].count += 1;
    return acc;
  }, {});

  let months = Object.keys(monthlyData);
  let listenScores = months.map(month => (monthlyData[month].listen / monthlyData[month].count).toFixed(2));
  let affirmScores = months.map(month => (monthlyData[month].affirm / monthlyData[month].count).toFixed(2));
  let respondScores = months.map(month => (monthlyData[month].respond / monthlyData[month].count).toFixed(2));
  let askScores = months.map(month => (monthlyData[month].ask / monthlyData[month].count).toFixed(2));
  let overallScores = months.map(month => (monthlyData[month].overall / monthlyData[month].count).toFixed(2));

  // Reverse the order to ensure the latest month is displayed at the end
  months = months.reverse();
  listenScores = listenScores.reverse();
  affirmScores = affirmScores.reverse();
  respondScores = respondScores.reverse();
  askScores = askScores.reverse();
  overallScores = overallScores.reverse();

  // Handle case where there is only one data point
  if (validLaraData.length === 1) {
    const item = validLaraData[0];
    const month = new Date(item.zoom_date).toLocaleString('default', { month: 'short', year: 'numeric' });

    months = [month, month]; // Duplicate the month to create at least two points on the x-axis
    listenScores = [(item.lara.client[0].listen_score * 20).toFixed(2), (item.lara.client[0].listen_score * 20).toFixed(2)];
    affirmScores = [(item.lara.client[0].affirm_score  * 20).toFixed(2), (item.lara.client[0].affirm_score  * 20).toFixed(2)];
    respondScores = [(item.lara.client[0].respond_score  * 20).toFixed(2), (item.lara.client[0].respond_score  * 20).toFixed(2)];
    askScores = [(item.lara.client[0].ask_score  * 20).toFixed(2), (item.lara.client[0].ask_score  * 20).toFixed(2)];
    overallScores = [((item.lara.client[0].listen_score + item.lara.client[0].affirm_score  + item.lara.client[0].respond_score  + item.lara.client[0].ask_score ) * 5).toFixed(2), ((item.lara.client[0].listen_score + item.lara.client[0].affirm_score  + item.lara.client[0].respond_score  + item.lara.client[0].ask_score ) * 5).toFixed(2)];
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        },
        min: 0,
        beginAtZero: true, // Ensure y-axis starts at 0
        title: {
          display: true,
          text: "Month",
          font: {
            family: 'Lexend', // Set the font family
            size: "14px", // Set the font size
            color: '#040404', // Set the font color
            fontWeight: 600
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        },
      },
      y: {
        min: 0,
        max: 100,
        beginAtZero: true, // Ensure y-axis starts at 0
        grid: {
          display: true,
          color: '#F1F1F1',
          drawTicks: true, // Ensure grid lines start at the y-axis line
          drawBorder: false, // Ensure grid lines start at the y-axis line
        },
        ticks: {
          padding: 10, // Add padding between ticks and labels if necessary
          callback: function(value, index, values) {
            if (Number.isInteger(value)) {
              return value; // Only show whole numbers
            }
            return null; // Filter out non-whole numbers
          }
        },
        title: {
          display: true,
          text: "Score", // Add the label text here
          font: {
            family: 'Lexend', // Set the font family
            size: "14px", // Set the font size
            color: '#040404', // Set the font color
            fontWeight: 600
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        }
      }
    },
    elements: {
      line: {
        borderCapStyle: 'round', // Set the cap style of the line to round
        borderJoinStyle: 'round', // Optional: Set the join style of the line to round
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true, // This makes the legend use the point style (circle, rect, etc.)
          pointStyle: 'circle', // This specifically sets the style to circle, overriding dataset pointStyle
          padding: 20, // Adjusts the padding around each legend item for better spacing
          boxWidth: 3,  // Smaller value for a smaller circle
          boxHeight: 3,
          font: {
            size: "14px", // Sets the font size of the legend labels
            family: 'Lexend', // Sets the font family of the legend labels
            style: 'normal', // Sets the font style of the legend labels
            color: "#202226"
          },
        },
      },
      customShadow: customShadowPlugin, // Register the custom shadow plugin
    },
    fill: true,
    tension: 0.4,
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Overall',
        data: overallScores,
        borderColor: hexToRgba('#FF5E00', 0.6),
        backgroundColor: hexToRgba('#FF5E00', 0.3),
        borderWidth: 6,
        pointRadius: 0,
      },
      {
        label: 'Listen',
        data: listenScores,
        borderColor: hexToRgba('#62C1EB', 0.6),
        backgroundColor: hexToRgba('#62C1EB', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Affirm',
        data: affirmScores,
        borderColor: hexToRgba('#568FF3', 0.6),
        backgroundColor: hexToRgba('#568FF3', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Respond',
        data: respondScores,
        borderColor: hexToRgba('#e383eb', 0.6),
        backgroundColor: hexToRgba('#e383eb', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
      {
        label: 'Ask',
        data: askScores,
        borderColor: hexToRgba('#8e33b8', 0.6),
        backgroundColor: hexToRgba('#8e33b8', 0.3),
        borderWidth: 6,
        pointRadius: 0
      },
    ],
  };

  return (
    <>
      <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%", // Make the container take up the full width
        maxWidth: "1200px", // Set a max width if you want to limit the maximum width
        height: "450px", // Set a fixed height
        alignItems: "center",
        margin: "0 auto",
      }}
    >
      {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress 
              sx={{
                color: "black"
              }}
            />
          </Box>
      ) : (
        <Line
          options={options}
          data={data}
          style={{ width: '100%', height: '50%' }} // Make the chart take the full width and height of the parent container
          datasetKeyProvider={() => 'customShadow'}
        />
      )}
    </Box>
    </>

  );
}

export default ClientLaraLineChart;
