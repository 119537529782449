import React from 'react'

function AssessmentIcon({ color }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2.05654V3.19902C14 4.87918 14 5.71926 14.327 6.36099C14.6146 6.92548 15.0735 7.38442 15.638 7.67204C16.2798 7.99902 17.1198 7.99902 18.8 7.99902H19.9425M14 2.05654C13.6065 1.99902 13.136 1.99902 12.349 1.99902H10.4C8.15979 1.99902 7.03968 1.99902 6.18404 2.43499C5.43139 2.81849 4.81947 3.43041 4.43597 4.18306C4 5.03871 4 6.15881 4 8.39902L4 15.599C4 17.8392 4 18.9593 4.43597 19.815C4.81947 20.5676 5.43139 21.1795 6.18404 21.563C7.03968 21.999 8.15979 21.999 10.4 21.999H13.6C15.8402 21.999 16.9603 21.999 17.816 21.563C18.5686 21.1795 19.1805 20.5676 19.564 19.815C20 18.9593 20 17.8392 20 15.599V9.64999C20 8.86302 20 8.39256 19.9425 7.99902M14 2.05654C14.0957 2.07053 14.1869 2.08792 14.2769 2.10954C14.6851 2.20753 15.0753 2.36915 15.4331 2.58846C15.8368 2.83583 16.1827 3.18173 16.8745 3.87354L18.1255 5.1245C18.8173 5.81627 19.1632 6.16223 19.4106 6.56588C19.6299 6.92377 19.7915 7.31395 19.8895 7.72209C19.9111 7.81215 19.9285 7.90331 19.9425 7.99902M12 17.999L12 10.999M16 17.999V12.999M8 17.999V15.999" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
export default AssessmentIcon;
