import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Logo from "assets/altercall1.svg";
import { useNavigate } from 'react-router-dom';


const Footer = () => {

    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    };


    return (
        <Box sx={{ mx: "auto", mt: "70px", pb: "80px" }}>

            <Box sx={{ display: "flex", justifyContent: "center", }}>
                <a
                    href="https://altercall.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={Logo} style={{ width: "150px" }} alt="Company Logo" />
                </a>
            </Box>


            <Box
                mx={"auto"}
                mt={"30px"}
                display="flex"
                backgroundColor="transparent"
                justifyContent={"space-between"}
                flexDirection={{
                    xs: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                }}
                alignItems={"center"}
                width={"80%"}

            >
                <Button
                    onClick={() =>
                    (window.location.href =
                        "https://calendly.com/altercallai/aai-product-demo")
                    }
                    style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        color: "#000",
                        background: "none",
                    }}
                >
                    Contact Us
                </Button>
                <Button
                    onClick={() => handleNavigate("/terms-login")}
                    style={{
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        color: "#000",
                        background: "none",
                    }}
                >
                    Terms of Use
                </Button>
                <Button
                    onClick={() => handleNavigate("/privacy-policy")}
                    style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        color: "#000",
                        background: "none",
                    }}
                >
                    Privacy Policy
                </Button>

                <Button
                    onClick={() => handleNavigate("/resources")}
                    style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        background: "none",
                        color: "#000",
                    }}
                >
                    Resources
                </Button>

                <Button
                    onClick={() => handleNavigate("/blogs")}
                    style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        background: "none",
                        color: "#000",
                    }}
                >
                    Blog
                </Button>

                <Button
                    onClick={() => handleNavigate("/support")}
                    style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: "22px",
                        marginRight: "0px",
                        fontWeight: "500",
                        border: "none",
                        background: "none",
                        color: "#000",
                    }}
                >
                    Support
                </Button>

                <Typography
                    display={{
                        xs: "block",
                        md: "none",
                    }}
                    marginTop={"40px"}
                    fontWeight={400}
                    color={"#93989A"}
                    fontSize={"14px"}
                >
                    © AlterCall 2024
                </Typography>
            </Box>





        </Box>
    );
};

export default Footer;