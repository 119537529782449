import React, { useState } from "react";

const VirtualCoachFAQ = () => {
  const { Remove, Add } = require("@mui/icons-material");
  const {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
  } = require("@mui/material");
  const [expanded, setExpanded] = useState({});
  const handleFaqChange = (panel) => (event, isExpanded) => {
    setExpanded({
      [panel]: isExpanded,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "56px",
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: "300",
          color: "black",
          marginBottom: "-50px",
        }}
      >
        F.A.Q.
      </Typography>
      <Box>
        <Accordion
          expanded={expanded["panel1"] === true}
          onChange={handleFaqChange("panel1")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel1"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I access the settings or sign out?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            To access the Settings, click on your Profile Photo located at the
            top right corner of your screen. A dropdown menu will appear,
            offering you options to modify your Settings or Sign Out.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel2"] === true}
          onChange={handleFaqChange("panel2")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel2"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I get the most out of Virtual Coach?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The Virtual Coach Guide is a comprehensive manual designed to help
            you maximize the benefits of Virtual Coach. It includes detailed
            instructions and a variety of base prompts that you can customize to
            meet your specific needs. We recommend downloading the Virtual
            Coach user guide. To access the guide, please use the menu sidebar
            located on the left, navigate to the Virtual Coach section and click
            on DOWNLOAD USER GUIDE located at the top right corner.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel3"] === true}
          onChange={handleFaqChange("panel3")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel3"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Virtual Coach threads?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Virtual Coach Threads represent conversations. This is helpful when
            keeping interactions with Virtual Coach organized. We recommend
            starting a new thread when changing conversation topics and
            revisiting previous threads to continue conversations related to a
            thread's topic.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel4"] === true}
          onChange={handleFaqChange("panel4")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel4"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How do I start a new thread?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            To initiate a new topic, click the New Thread + button at the top
            right corner of the Virtual Coach interface. This action resets your
            session, prompting Virtual Coach to delve into introspective and
            probing questions aimed at uncovering the core of your inquiry.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel5"] === true}
          onChange={handleFaqChange("panel5")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel5"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            Can I collapse the threads list?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Yes, you can collapse your threads list for a more focused
            conversation view by clicking on the Arrow at the top right of the
            threads list. To reopen the list, click on the Hamburger Icon, which
            is represented by three horizontal lines.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel6"] === true}
          onChange={handleFaqChange("panel6")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel6"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the purpose of the thumbs up and down buttons?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            These buttons allow you to provide feedback on the effectiveness of
            responses from Virtual Coach. By voting, you help us tailor and
            enhance the performance of Virtual Coach based on your preferences
            and needs. Every vote is valuable and contributes to improving your
            experience!
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default VirtualCoachFAQ;
