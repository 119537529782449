import React from 'react';

function UserGuideIcon({ color = '#111111' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
            <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.833" d="M13.333 2.386v1.047c0 1.54 0 2.31.3 2.899a2.75 2.75 0 0 0 1.201 1.202c.588.3 1.358.3 2.899.3h1.047m-5.447-5.448c-.361-.053-.792-.053-1.514-.053h-1.786c-2.054 0-3.08 0-3.865.4-.69.351-1.25.912-1.602 1.602-.4.785-.4 1.811-.4 3.865v6.6c0 2.053 0 3.08.4 3.865.351.69.912 1.25 1.602 1.602.784.4 1.811.4 3.865.4h2.933c2.054 0 3.08 0 3.865-.4a3.666 3.666 0 0 0 1.602-1.602c.4-.785.4-1.812.4-3.865V9.347c0-.722 0-1.153-.053-1.514m-5.447-5.447a3.666 3.666 0 0 1 1.314.488c.37.226.687.543 1.32 1.178l1.147 1.146c.634.634.952.952 1.178 1.322.201.328.35.685.44 1.06.02.082.035.166.048.253m-7.28 2.292-1.652 2.202c-.343.457-.515.686-.503.857a.46.46 0 0 0 .218.359c.146.089.428.042.992-.052l1.889-.315c.564-.094.845-.141.991-.052a.458.458 0 0 1 .218.359c.012.17-.159.399-.502.856L11.5 16.542" />
        </svg>
    )
}

export default UserGuideIcon;