import React from 'react';

function VcProfilePicture({ color = '#111111' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 52 52">
            <circle cx="26" cy="26" r="25.5" fill="#fff" stroke="#EFF3F6" />
            <path fill="#000" d="m19.076 30.486-3.965 6.861 1.152.653 3.883-6.715c4.135 2.388 8.241 2.388 12.383 0L36.411 38l1.153-.653a88621.68 88621.68 0 0 0-3.966-6.862c2.9-2.548 4.294-5.721 4.01-9.556-.197-2.642-1.516-5.376-3.25-6.929l-.904.934c4.267 4.156 3.558 10.98-.547 14.357l-6.57-11.367-6.566 11.36c-3.958-3.204-4.93-9.957-.515-14.378l-.928-.89c-4.288 3.855-4.834 12.059.749 16.47Zm12.77-.384c-3.498 2.357-8.133 2.03-11.01-.013l5.501-9.517 5.508 9.53Z" />
        </svg>

    )
}

export default VcProfilePicture;




