import React from 'react';

function ContentLibraryOpenIcon({ color = '#111111' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 18.989C12.423 19 11.7635 19 11 19H9C6.19974 19 4.79961 19 3.73005 18.455C2.78924 17.9757 2.02433 17.2108 1.54497 16.27C1 15.2004 1 13.8003 1 11V9C1 6.19974 1 4.79961 1.54497 3.73005C2.02433 2.78924 2.78924 2.02433 3.73005 1.54497C4.79961 1 6.19974 1 9 1H11C11.7635 1 12.423 1 13 1.01105M13 18.989C14.5392 18.9595 15.492 18.8514 16.27 18.455C17.2108 17.9757 17.9757 17.2108 18.455 16.27C19 15.2004 19 13.8003 19 11V9C19 6.19974 19 4.79961 18.455 3.73005C17.9757 2.78924 17.2108 2.02433 16.27 1.54497C15.492 1.14859 14.5392 1.04052 13 1.01105M13 18.989L13 1.01105M8.5 7C7.40359 7.79655 6.42154 8.73031 5.57935 9.77659C5.47355 9.90803 5.47355 10.092 5.57935 10.2234C6.42154 11.2697 7.40359 12.2035 8.5 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContentLibraryOpenIcon;
