import React from 'react';
import { Box, Modal, Typography } from "@mui/material";
import PdfViewer from './PdfViewer';
import PdfDownloadIconForVC from 'components/Icons/PdfDownloadIconForVC';
import VcPdfModalCloseIcon from 'components/Icons/VcPdfModalCloseIcon.svg';



const PdfModal = ({ openPdfModal, handleClosePdfModal, selectedPdfUrl }) => {
    return (
        <div>
            {/* PDF Modal */}
            <Modal
                open={openPdfModal}
                onClose={handleClosePdfModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60vw',
                    height: '90vh',
                    bgcolor: 'white',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    overflow: 'hidden',
                    // border: '1px solid red'
                }}>
                    <Box sx={{
                        height: '100%',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}>
                        <Box
                            sx={{
                                // border: '1px solid #000',
                                height: '7%',
                                width: '98%',
                                display: 'flex',
                                justifyContent: 'end',
                                gap: '10px',
                                alignItems: 'center',
                                borderRadius: '2px 2px 0px 0px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box onClick={() => {
                                    window.open(selectedPdfUrl, '_blank');
                                }}
                                    sx={{
                                        cursor: 'pointer',
                                        border: '2px solid #000',
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px 25px',
                                        borderRadius: '50px',

                                    }}
                                >
                                    <PdfDownloadIconForVC width='27' height='27' />
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#000',
                                        font: 'Poppins',

                                    }} >Download</Typography>
                                </Box>

                                <Box
                                    onClick={handleClosePdfModal}
                                    sx={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        padding: '12px',
                                        borderRadius: '50%',
                                        border: '2px solid #000',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <VcPdfModalCloseIcon />
                                </Box>

                            </Box>
                        </Box>
                        <PdfViewer pdfUrl={selectedPdfUrl} />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default PdfModal;