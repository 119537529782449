import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { generateClient } from "aws-amplify/data";
import VideoPlayerCard from "scenes/SessionPageCards/VideoPlayerCard";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import avatar from "assets/avatar.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";
import { saveAs } from "file-saver";
import altercallLogo from "assets/altercall1.png"; // Ensure this is a valid PNG file
//import { create, defaultLanguage, logErrors, registerFormat, setDefaultLanguage } from 'fontkit';

// Queries
import ShareIcon from "components/Icons/Share.svg";
import { useSelector } from "react-redux";
import { Check, ErrorOutline, PersonAddAlt } from "@mui/icons-material";
import { toast } from "react-toastify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import { formatDistanceToNow } from "date-fns";
import dayjs from "dayjs";
import LaraSessionPage from "components/SessionPage/LaraSessionPage";
import SessionDetails from "components/SessionPage/SessionDetails";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const SessionPage = ({ user, isProfessionalTier, userData }) => {
  const { file_name } = useParams();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const encodedFileName = encodeURIComponent(file_name);
  const [fileData, setFileData] = useState({});
  const [laraData, setLaraData] = useState({});
  const [callActions, setCallActions] = useState({});
  const [videoUrl, setVideoUrl] = useState("");
  const [transcriptUrl, setTranscriptUrl] = useState("");
  const [vttText, setVttText] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingFile, setLoadingFile] = useState(true);
  const [showClientList, setShowClientList] = useState(false);
  const [clientShared, setClientShared] = useState("");
  const [clientData, setClient] = useState([]);
  const [assignee, setAssignee] = useState({});
  const [client, setAssignClient] = useState({});
  const [clientId, setClientId] = useState("");
  const [coachId, setCoachId] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [assignedModal, setAssignedModal] = useState(false);
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [quickRecap, setQuickRecap] = useState("");
  const [updatedQuickRecap, setUpdatedQuickRecap] = useState({});
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openEditQuickRecapModal, setOpenEditQuickRecapModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editText");
  const handleCloseEditAction = () => {
    setOpenEditActionModal(false);
    setInitialAction({});
    setActiveTab("editText");
  };
  const handleCloseEditQuickRecap = () => {
    setOpenEditQuickRecapModal(false);
  };
  const navigate = useNavigate();

  const openAssignModal = () => {
    setShowClientList(false);
    setAssignClient(assignee);
    setAssignModal(true);
  };

  const closeAssignModal = () => setAssignModal(false);
  const openAssignedModal = () => setAssignedModal(true);
  const closeAssignedModal = () => setAssignedModal(false);

  const assignCall = async (client_data) => {
    closeAssignModal();
    try {
      setShowClientList(false);
      const body = [];
      if (status === "ReadyToPublish") {
        body.push({
          id: fileData[0]?.id,
          coach_id: fileData[0]?.coach_id,
          call_name: fileData[0]?.call_name,
          client_name: client_data.name,
          client_id: client_data.id,
          zoom_date: fileData[0]?.zoom_date,
          coach_name: user?.given_name + " " + user?.family_name,
        });
      }

      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/assignCall`,
        body
      );

      // Show success toast and fetch updated file data
      toast(`Session has been shared with ${client_data.name}`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });

      fetchFileData();
    } catch (err) {
      // Show error toast on catch
      toast.error("Error while assigning calls");
      console.log("Error in assign call", err);
    }
  };

  const assignModalContent = () => {
    return (
      <Modal open={assignModal} onClose={closeAssignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to share the session with ${client.name}?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap="15px">
            <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "53px",
                background: "#FFFFFF",
                color: "#C10404",
                border: "1px solid #BE0808",
                fontWeight: "bold",
                "&:hover": {
                  background: "rgba(190, 8, 8, 0.4)",
                  border: "1px solid rgba(190, 8, 8, 0.4)",
                  color: "white",
                },
              }}
              onClick={closeAssignModal}
            >
              No
            </Button>
            <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "52px",
                color: "white",
                background:
                  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background:
                    "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                  cursor: "pointer",
                },
              }}
              onClick={() => assignCall(client)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const assignedModalContent = () => {
    return (
      <Modal open={assignedModal} onClose={closeAssignedModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Session has been shared with ${client.name}`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                background: "#160042",
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                "&:hover": {
                  background: "#10B02A",
                },
              }}
              onClick={closeAssignedModal}
            >
              <Check />
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  // const getLaraQuery = `query getLara($call_id: ID!) {
  //   getLara(call_id: $call_id) {
  //     call_id
  //     coach_id
  //     coachee_id
  //     coach_name
  //     coachee_name
  //     coach_overall_score
  //     coach_listen_score
  //     coach_listen_report
  //     coach_listen_area_of_improvement
  //     coach_affirm_score
  //     coach_affirm_report
  //     coach_affirm_area_of_improvement
  //     coach_respond_score
  //     coach_respond_report
  //     coach_respond_area_of_improvement
  //     coach_ask_score
  //     coach_ask_report
  //     coach_ask_area_of_improvement
  //     coachee_overall_score
  //     coachees {
  //       coachee_id
  //       coachee_name
  //       overall_score
  //       listen_score
  //       listen_report
  //       listen_area_of_improvement
  //       affirm_score
  //       affirm_report
  //       affirm_area_of_improvement
  //       respond_score
  //       respond_report
  //       respond_area_of_improvement
  //       ask_score
  //       ask_report
  //       ask_area_of_improvement
  //     }
  //   }
  // }`;

  const fetchFileData = useCallback(async () => {
    try {
      setLoadingFile(true);
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-call`,
        {
          params: { file_name: encodedFileName },
        }
      );

      if (!response?.data) {
        setLoadingFile(false);
        return;
      }

      const responseData = response.data[0];
      setFileData(response.data);
      setQuickRecap(responseData?.quick_recap);
      if (responseData.client && responseData.coach_id) {
        setClientShared(responseData.client);
        setClientId(responseData.client_id);
        setCoachId(responseData.coach_id);
      }
      const callId = responseData.id;
      // const result = await API.graphql(
      //   graphqlOperation(getLaraQuery, { call_id: callId })
      // );

      const { data: result, errors: resultErrors } =
        await resourceClient.models.lara.get(
          { call_id: callId },
          {
            selectionSet: [
              "call_id",
              "coach_id",
              "coach_name",
              "coach_communication",
              "coach_affirm_score",
              "coach_affirm_details",
              "coach_affirm_strengths",
              "coach_affirm_weaknesses",
              "coach_ask_score",
              "coach_ask_details",
              "coach_ask_strengths",
              "coach_ask_weaknesses",
              "coach_listen_score",
              "coach_listen_details",
              "coach_listen_strengths",
              "coach_listen_weaknesses",
              "coach_respond_score",
              "coach_respond_details",
              "coach_respond_strengths",
              "coach_respond_weaknesses",
              "coach_overall_score",
              "coach_affirm_report",
              "coach_ask_report",
              "coach_listen_report",
              "coach_respond_report",
              "coach_affirm_area_of_improvement",
              "coach_ask_area_of_improvement",
              "coach_listen_area_of_improvement",
              "coach_respond_area_of_improvement",
              "coachees.*",
            ],
          }
        );

      const laraData = result;
      // Extracting coachData
      const coachData = {
        coach_name: laraData?.coach_name ?? "",
        coach_overall_score: laraData?.coach_overall_score ?? "",
        coach_listen_score: laraData?.coach_listen_score ?? "",
        coach_listen_report: laraData?.coach_listen_report ?? "",
        coach_listen_area_of_improvement:
          laraData?.coach_listen_area_of_improvement ?? "",
        coach_listen_details: laraData?.coach_listen_details ?? "",
        coach_listen_strengths: laraData?.coach_listen_strengths ?? "",
        coach_listen_weaknesses: laraData?.coach_listen_weaknesses ?? "",
        coach_affirm_score: laraData?.coach_affirm_score ?? "",
        coach_affirm_report: laraData?.coach_affirm_report ?? "",
        coach_affirm_area_of_improvement:
          laraData?.coach_affirm_area_of_improvement ?? "",
        coach_affirm_details: laraData?.coach_affirm_details ?? "",
        coach_affirm_strengths: laraData?.coach_affirm_strengths ?? "",
        coach_affirm_weaknesses: laraData?.coach_affirm_weaknesses ?? "",
        coach_respond_score: laraData?.coach_respond_score ?? "",
        coach_respond_report: laraData?.coach_respond_report ?? "",
        coach_respond_area_of_improvement:
          laraData?.coach_respond_area_of_improvement ?? "",
        coach_respond_details: laraData?.coach_respond_details ?? "",
        coach_respond_strengths: laraData?.coach_respond_strengths ?? "",
        coach_respond_weaknesses: laraData?.coach_respond_weaknesses ?? "",
        coach_ask_score: laraData?.coach_ask_score ?? "",
        coach_ask_report: laraData?.coach_ask_report ?? "",
        coach_ask_area_of_improvement:
          laraData?.coach_ask_area_of_improvement ?? "",
        coach_ask_details: laraData?.coach_ask_details ?? "",
        coach_ask_strengths: laraData?.coach_ask_strengths ?? "",
        coach_ask_weaknesses: laraData?.coach_ask_weaknesses ?? "",
        coach_communication: laraData?.coach_communication ?? "",
      };

      // Find the specific coachee performance entry based on coachee_name
      const clientData = laraData?.coachees?.find(
        (coachee) => coachee.coachee_name === responseData.client_name
      ) || {
        coachee_name: "",
        overall_score: "",
        listen_score: "",
        listen_report: "",
        listen_area_of_improvement: "",
        listen_details: "",
        listen_strengths: "",
        listen_weaknesses: "",
        affirm_score: "",
        affirm_report: "",
        affirm_area_of_improvement: "",
        affirm_details: "",
        affirm_strengths: "",
        affirm_weaknesses: "",
        respond_score: "",
        respond_report: "",
        respond_area_of_improvement: "",
        respond_details: "",
        respond_strengths: "",
        respond_weaknesses: "",
        ask_score: "",
        ask_report: "",
        ask_area_of_improvement: "",
        ask_details: "",
        ask_strengths: "",
        ask_weaknesses: "",
        communication_report: "",
      };

      // Combine coachData and clientData into labeledData
      const labeledData = {
        coach: coachData,
        client: clientData,
      };

      setLaraData(labeledData);

      const vttUrl = responseData?.vtt_s3_link || "";
      const mp4Url = responseData?.mp4_s3_link || "";

      const fileDataObject = { vtt_s3_link: vttUrl, mp4_s3_link: mp4Url };
      const fileDataStringified = JSON.stringify(fileDataObject);

      // No need to stringify again
      const { data, errors } = await resourceClient.queries.fetchPresignedUrls({
        input: fileDataStringified, // Send the once-stringified object directly
      });

      const responseObject = JSON.parse(data);

      if (responseObject) {
        const vttPresignedUrl = responseObject.vtt_presigned;
        const mp4PresignedUrl = responseObject.mp4_presigned;

        if (vttPresignedUrl) {
          setTranscriptUrl(vttPresignedUrl);
          fetch(vttPresignedUrl)
            .then((response) => response.text())
            .then((data) => setVttText(data))
            .catch((error) => console.error("Error:", error));
        }

        if (mp4PresignedUrl) {
          setVideoUrl(mp4PresignedUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoadingFile(false);
      setLoading(false);
    }
  }, [encodedFileName]);

  const fetchClientData = useCallback(async () => {
    try {
      const userId = user?.sub;

      let response = null;

      if (userData?.companyInformation) {
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
              {
                params: { userId: userId },
              }
            );
            break;
          case "SUPERADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            break;
          case "ADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            console.log("response");
            break;
          case "USERS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            break;
          case "PRIVATE":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/private-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                  companyId: userData?.companyInformation?.company_id,
                },
              }
            );
            break;
          default:
            break;
        }
      } else {
        // Make the API call for clients if the user does not belong to a company
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-clients`,
          {
            params: { coach_id: userId },
          }
        );
      }

      response = response.data;

      if (!response.members) {
        setLoading(false);
        return;
      }

      // Filter out the row where member.user_id is equal to the current user's sub
      const filteredMembers = response.members.filter(
        (member) => member.user_id !== userId
      );

      // Map through the filtered client data

      // Fetch avatars asynchronously
      const updatedMembers = await Promise.all(
        filteredMembers.map(async (item) => {
          const avatarUrl = item.avatar
            ? await fetchAvatarUrl(item.avatar)
            : null;
          return {
            ...item,
            avatar: avatarUrl, // Update the avatar field with the fetched URL
          };
        })
      );

      // Sort the filtered data by zoom_date
      const sortedData = updatedMembers.sort(
        (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
      );

      // Update the state with the filtered and sorted client data
      setClient(sortedData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  }, [user, userData]);

  useEffect(() => {
    fetchFileData();
    fetchClientData();
  }, [fetchFileData, fetchClientData]);

  const clientName = fileData[0]?.client_name;
  const callName = fileData[0]?.call_name;
  const coachName = fileData[0]?.coach_name;
  const zoomDate = fileData[0]?.zoom_date;

  const fetchActions = useCallback(async () => {
    console.log("");
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetchActionsByCall`,
        {
          params: { call_id: fileData[0]?.id },
        }
      );
      console.log("call res: ", response);
      if (response) {
        setCallActions(response.actions);
      }
    } catch (error) {
      console.error("error fetching actions", error);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData[0]) {
      fetchActions();
    }
  }, [fileData, fetchActions]);

  //check if the user's id is either the coach or client id registered on the session
  useEffect(() => {
    if (fileData[0]) {
      const userId = user?.sub;
      const coachId = fileData[0]?.coach_id;
      const clientId = fileData[0]?.client_id;
      if (userId !== coachId && userId !== clientId) {
        navigate(-1); // Navigate to the last page the user was on
      }
    }
  }, [fileData, user?.sub, navigate]);

  const actionsArray = callActions;
  const summary = fileData[0]?.summary;
  const labels = summary
    ?.match(/"label": "(.*?)"/g)
    .map((label) => label.replace(/"label": "(.*?)"/, "$1"));
  const summaries = summary
    ?.match(/"summary": "(.*?)"/g)
    .map((summary) => summary.replace(/"summary": "(.*?)"/, "$1"));

  const status = fileData[0]?.status;
  const duration = fileData[0]?.duration_minutes;
  const formattedZoomDate = dayjs(zoomDate).utc().format("MM.DD.YY");

  const downloadTranscript = async () => {
    try {
      const response = await fetch(transcriptUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${callName}.vtt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  };
  //
  //new coach PDF report

  const downloadReport = async () => {
    try {
      if (!fileData) {
        console.log("file data is missing");
      } else {
        console.log("file data is present");
      }
      if (!fileData || !Array.isArray(fileData) || fileData.length === 0) {
        throw new Error("File data is missing or invalid.");
      }
      console.log("Generating PDF report...");
      // Ensure actions is an array

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Create the first page
      const page1 = pdfDoc.addPage([1500, 1974]); // Custom size

      // Embed the altercall.ai logo
      const logoImageBytes = await fetch(altercallLogo).then((res) =>
        res.arrayBuffer()
      );
      const logoImage = await pdfDoc.embedPng(logoImageBytes);

      // Embed the aaiLogoVerticalBlack logo

      const svgToPng = async (svg) => {
        const svgBlob = new Blob([svg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      //page 1 background color:
      const backgroundSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_1888)"/>
        <defs>
          <linearGradient id="paint0_linear_4460_1888" x1="1298.72" y1="204.388" x2="-718.232" y2="1344.22" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const backgroundPng = await svgToPng(backgroundSvg);
      const backgroundImage = await pdfDoc.embedPng(backgroundPng);

      page1.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: 1500,
        height: 1974,
      });

      const logoSvg3 = `
          <svg width="271" height="52" viewBox="0 0 271 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2724 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7168C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28892 3.33332 3.36529 7.05953 7.62939e-06C7.70601 0.673761 8.35337 1.34838 8.99985 2.02301C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.50201C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96382C40.1636 1.3214 40.8275 0.678984 41.4932 0.0356974C50.7036 8.38623 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
            <path d="M91.8662 28.2079H81.6535L79.4539 33.1328H77.4375L85.8172 14.6973H87.7288L96.1084 33.1328H94.0659L91.8662 28.2079ZM91.1592 26.6277L86.7599 16.7252L82.3606 26.6277H91.1592Z" fill="white"/>
            <path d="M104.376 14.6974H106.314V31.4474H116.579V33.1329H104.376V14.6974Z" fill="white"/>
            <path d="M126.898 16.3829H120.456V14.6974H135.278V16.3829H128.836V33.1329H126.898V16.3829Z" fill="white"/>
            <path d="M156.564 31.4473V33.1328H143.602V14.6973H156.171V16.3828H145.539V22.9406H155.019V24.5998H145.539V31.4473H156.564Z" fill="white"/>
            <path d="M178.887 33.1329L174.697 27.2072C174.226 27.2599 173.755 27.2862 173.231 27.2862H168.308V33.1329H166.37V14.6974H173.231C177.892 14.6974 180.72 17.0677 180.72 21.0181C180.72 23.9151 179.201 25.9431 176.53 26.8122L181.008 33.1329H178.887ZM178.782 21.0181C178.782 18.0685 176.845 16.3829 173.179 16.3829H168.308V25.627H173.179C176.845 25.627 178.782 23.9151 178.782 21.0181Z" fill="white"/>
            <path d="M188.905 23.9178C188.905 18.5188 192.99 14.542 198.516 14.542C201.187 14.542 203.596 15.4374 205.219 17.2283L203.989 18.4661C202.496 16.9386 200.663 16.2802 198.568 16.2802C194.143 16.2802 190.817 19.5459 190.817 23.9178C190.817 28.2896 194.143 31.5554 198.568 31.5554C200.663 31.5554 202.496 30.8969 203.989 29.3431L205.219 30.5809C203.596 32.3718 201.187 33.2936 198.49 33.2936C192.99 33.2936 188.905 29.3168 188.905 23.9178Z" fill="white"/>
            <path d="M225.622 28.208H215.409L213.21 33.1329H211.193L219.573 14.6974H221.485L229.864 33.1329H227.822L225.622 28.208ZM224.915 26.6278L220.516 16.7253L216.116 26.6278H224.915Z" fill="white"/>
            <path d="M238.132 14.6974H240.07V31.4474H250.335V33.1329H238.132V14.6974Z" fill="white"/>
            <path d="M258.797 14.6974H260.735V31.4474H271V33.1329H258.797V14.6974Z" fill="white"/>
          </svg>
        `;

      const logoPng = await svgToPng(logoSvg3);
      const logoImage3 = await pdfDoc.embedPng(logoPng);

      page1.drawImage(logoImage3, {
        x: 191,
        y: page1.getHeight() - 150 - 75, // Adjusted for top position
        width: 391,
        height: 75,
        opacity: 1,
      });

      const coacheeName = laraData?.client?.coachee_name || "Coachee Name";
      const coachCommunication =
        laraData?.coach?.coach_communication ||
        "No communication report available.";
      const listenScore = laraData?.coach?.coach_listen_score || 2.0;
      const respondScore = laraData?.coach?.coach_respond_score || 4.0;
      const affirmScore = laraData?.coach?.coach_affirm_score || 3.5;
      const askScore = laraData?.coach?.coach_ask_score || 2.7;

      // Extract data from fileData (session info)
      const clientName = fileData?.[0]?.client_name || "Client Name";
      const callName = fileData[0]?.call_name || "Call Name";
      const coachName = fileData[0]?.coach_name || "Coach Name";
      const zoomDate = fileData[0]?.zoom_date || new Date();
      const formattedZoomDate = dayjs(zoomDate).utc().format("MM.DD.YY");
      const duration = fileData[0]?.duration_minutes || 0;
      const dateCustom = dayjs(zoomDate).format("MMMM D, YYYY"); // Example: July 26, 2024

      // Embed a standard font (Helvetica)
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      const gradientEllipseSvg = `
      <svg width="1495" height="1474" viewBox="0 0 1495 1474" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4460_1889" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1495" height="1474">
        <rect width="1495" height="1474" rx="20" fill="url(#paint0_linear_4460_1889)"/>
      </mask>
      <g mask="url(#mask0_4460_1889)">
        <circle opacity="0.3" cx="317.881" cy="1989.57" r="1459.04" stroke="#6EA4FF"/>
        <path d="M-1081.79 2186.25C-1058.13 2364.38 -1001.27 2534.34 -912.797 2691.58L-861.788 2662.61C-946.495 2511.97 -1000.96 2349.16 -1023.63 2178.56C-1047.4 1999.53 -1034.99 1819.69 -986.696 1644.06C-938.4 1468.42 -857.133 1307.51 -745.143 1165.81C-636.975 1028.94 -504.837 915.624 -352.412 829.008C-199.988 742.392 -34.9421 686.894 138.096 663.998C317.236 640.308 497.198 652.801 672.964 701.133C848.73 749.465 1009.78 830.742 1151.62 942.695C1288.62 1050.85 1402.06 1182.94 1488.79 1335.3C1575.53 1487.66 1631.12 1652.62 1654.1 1825.55C1677.88 2004.59 1665.45 2184.42 1617.15 2360.06C1568.85 2535.69 1487.59 2696.6 1375.62 2838.31C1267.97 2974.55 1136.53 3087.43 985.006 3173.89L1012.9 3225.51C1171.51 3135.22 1309.04 3017.18 1421.69 2874.65C1538.55 2726.75 1623.36 2558.85 1673.75 2375.6C1724.14 2192.36 1737.1 2004.7 1712.29 1817.84C1688.32 1637.37 1630.29 1465.26 1539.81 1306.3C1449.34 1147.34 1330.96 1009.5 1187.99 896.652C1039.95 779.792 871.905 694.988 688.52 644.561C505.135 594.134 317.347 581.11 130.378 605.831C-50.1947 629.715 -222.399 687.643 -381.43 777.996C-540.46 868.35 -678.336 986.594 -791.206 1129.43C-908.085 1277.33 -992.879 1445.23 -1043.27 1628.48C-1093.66 1811.72 -1106.62 1999.38 -1081.81 2186.24L-1081.79 2186.25Z" fill="url(#paint1_linear_4460_1889)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4460_1889" x1="1530.81" y1="-193.08" x2="224.575" y2="153.393" gradientUnits="userSpaceOnUse">
        <stop stop-color="#03070E"/>
        <stop offset="1" stop-color="#478DFF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4460_1889" x1="-433.478" y1="621.878" x2="1518.83" y2="1401.08" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3480FF" stop-opacity="0"/>
        <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
        <stop offset="0.426471" stop-color="#3480FF"/>
        <stop offset="1"/>
        </linearGradient>
      </defs>
      </svg>
    `;

      const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
      const gradientEllipseImage = await pdfDoc.embedPng(gradientEllipsePng);

      page1.drawImage(gradientEllipseImage, {
        x: 5,
        y: page1.getHeight() - 404 - 1474, // Adjusted for top position
        width: 1495,
        height: 1474,
        opacity: 1,
      });
      // Draw the text "clientName Session Summary" in white color
      page1.drawText("L.A.R.A Analytics", {
        x: 191,
        y: page1.getHeight() - 446 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "from" in white color
      page1.drawText(`and Call Summary:`, {
        x: 191,
        y: page1.getHeight() - 546 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "callName" in orange color
      page1.drawText(`${callName || "Meeting Name"}`, {
        x: 191,
        y: page1.getHeight() - 646 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 94 / 255, 0), // Orange color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
      });

      // //need to made a line
      // const lineSvg = `
      //   <svg width="846" height="2" viewBox="0 0 846 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      //     <path d="M846 1L1.07288e-05 1" stroke="url(#paint0_linear_4460_1915)"/>
      //     <defs>
      //       <linearGradient id="paint0_linear_4460_1915" x1="846" y1="1.5" x2="0" y2="1.5" gradientUnits="userSpaceOnUse">
      //         <stop stop-color="white" stop-opacity="0"/>
      //         <stop offset="0.525" stop-color="white"/>
      //         <stop offset="1" stop-color="white" stop-opacity="0"/>
      //       </linearGradient>
      //     </defs>
      //   </svg>
      // `;

      // const linePng = await svgToPng(lineSvg);
      // const lineImage = await pdfDoc.embedPng(linePng);

      // page1.drawImage(lineImage, {
      //   x: 191,
      //   y: page1.getHeight() - 1200,
      //   width: 846,
      //   height: 2,
      //   opacity: 1,
      // });

      //profile picture
      const clientAvatarUrl = clientData[0]?.avatar || avatar; // Use imported default if no client avatar

      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page1.drawImage(avatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page1.drawImage(defaultAvatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Add text using the standard Helvetica font
      page1.drawText(`${clientName}`, {
        x: 248, // Adjusted to match the left position
        y: page1.getHeight() - 1403, // Adjusted for top position
        size: 24,
        //font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(1, 1, 1),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      const coachAvatarUrl = user?.avatar || avatar; // Use imported default if no coach avatar

      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page1.drawImage(avatarImage, {
          x: 466,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page1.drawImage(defaultAvatarImage, {
          x: 466,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      page1.drawText(`${coachName}`, {
        x: 524,
        y: page1.getHeight() - 1403,
        size: 24,
        //font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(1, 1, 1),
        width: 116,
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      //draw learn more text
      // Draw the "Learn More Here" rectangle

      const learnMoreSvg = `
      <svg width="273" height="58" viewBox="0 0 273 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="white"/>
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="#FF5E00"/>
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" stroke="#FF5E00"/>
      </svg>
    `;

      const learnMorePng = await svgToPng(learnMoreSvg);
      const learnMoreImage = await pdfDoc.embedPng(learnMorePng);

      page1.drawImage(learnMoreImage, {
        x: 190,
        y: page1.getHeight() - 1582,
        width: 273,
        height: 58,
        opacity: 1,
      });

      // Draw the "Learn More Here" text
      page1.drawText("Learn More Here", {
        x: 220,
        y: page1.getHeight() - 1582 + 15.5, // Adjusted to center the text vertically
        size: 24,
        color: rgb(1, 1, 1), // White color
        width: 273,
        height: 58,
        lineHeight: 35,
        fontWeight: 500,
        textAlign: "center",
        opacity: 1,
      });

      // Add a hyperlink to the "Learn More Here" text
      const linkAnnotation = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [
          225,
          page1.getHeight() - 1582,
          225 + 273,
          page1.getHeight() - 1582 + 58,
        ],
        Border: [0, 0, 0],
        A: {
          Type: "Action",
          S: "URI",
          URI: "https://altercall.ai/resources?search=LARA-Scores&pageId=33&sectionId=281",
        },
      });

      // Add the annotation to the page's annotations array
      page1.node.set(
        PDFName.of("Annots"),
        pdfDoc.context.obj([linkAnnotation])
      );

      page1.drawText(`${duration} Minutes`, {
        x: 193,
        y: page1.getHeight() - 1699,
        size: 24,
        color: rgb(1, 1, 1),
        width: 332,
        height: 70,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      page1.drawText(`${dateCustom}`, {
        x: 193,
        y: page1.getHeight() - 1740,
        size: 24,
        color: rgb(1, 1, 1),
        width: 247,
        height: 30,
        lineHeight: 35, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 0
      });
      const vectorFooterwhiteSvg = `
        <svg width="49" height="52" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2723 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7167C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28891 3.33332 3.36528 7.05953 0C7.70601 0.673753 8.35337 1.34838 8.99985 2.023C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.502C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96381C40.1636 1.32139 40.8275 0.678976 41.4932 0.0356898C50.7036 8.38622 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
        </svg>
      `;

      const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
      const vectorFooterwhiteImage = await pdfDoc.embedPng(
        vectorFooterwhitePng
      );

      page1.drawImage(vectorFooterwhiteImage, {
        x: 1378,
        y: page1.getHeight() - 1852,
        width: 48.64,
        height: 52,
        opacity: 1,
      });

      //page2
      const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
      page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: page2.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      const page2BackgroundSvg = `
      <svg width="1500" height="781" viewBox="0 0 1500 781" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4906_247)">
        <rect width="1500" height="1863" fill="url(#paint0_linear_4906_247)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4906_247" x1="1767" y1="793" x2="-274.986" y2="-1890.46" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="0.50204" stop-color="#3480FF" stop-opacity="0.86"/>
        <stop offset="1" stop-color="white" stop-opacity="0.1"/>
        </linearGradient>
        <clipPath id="clip0_4906_247">
        <rect width="1500" height="781" fill="white"/>
        </clipPath>
      </defs>
      </svg>
    `;

      const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
      const page2BackgroundImage = await pdfDoc.embedPng(page2BackgroundPng);

      // Draw the gradient background from y = 0 to y = 781
      page2.drawImage(page2BackgroundImage, {
        x: 0,
        y: page2.getHeight() - 781, // Start from the top
        width: page2.getWidth(), // Full width of the page
        height: 781, // Height of 781
        opacity: 1, // Set opacity to 1
      });
      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 780 - 16, // Positioned at the top of the white rectangle
        width: 1500, // Full width of the page
        height: 16, // Height of 16px
        color: rgb(1, 94 / 255, 0), // Orange color (rgba(255, 94, 0, 1))
        opacity: 1, // Set opacity to 1
      });

      // Draw a white rectangle for the rest of the page
      page2.drawRectangle({
        x: 0,
        y: 0, // Start from the bottom
        width: page2.getWidth(), // Full width of the page
        height: page2.getHeight() - 780, // Height from y = 0 to y = 1040
        color: rgb(1, 1, 1), // White color
      });

      const circleSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="331.881" cy="1289.57" r="1459.04" stroke="#6EA4FF"/>
      </svg>
    `;

      const circlePng = await svgToPng(circleSvg);
      const circleImage = await pdfDoc.embedPng(circlePng);

      page2.drawImage(circleImage, {
        x: -1127.66,
        y: page2.getHeight() + 169.97 - 2919.09,
        width: 2919.09,
        height: 2919.09,
        opacity: 0.3,
      });

      const newSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-1067.79 1486.25C-1044.13 1664.38 -987.271 1834.34 -898.797 1991.58L-847.788 1962.61C-932.495 1811.97 -986.962 1649.16 -1009.63 1478.56C-1033.4 1299.53 -1020.99 1119.69 -972.696 944.055C-924.4 768.423 -843.133 607.512 -731.143 465.812C-622.975 328.94 -490.837 215.624 -338.412 129.008C-185.988 42.3917 -20.9421 -13.1061 152.096 -36.0016C331.236 -59.6924 511.198 -47.199 686.964 1.13296C862.73 49.4649 1023.78 130.742 1165.62 242.695C1302.62 350.846 1416.06 482.942 1502.79 635.3C1589.53 787.659 1645.12 952.619 1668.1 1125.55C1691.88 1304.59 1679.45 1484.42 1631.15 1660.06C1582.85 1835.69 1501.59 1996.6 1389.62 2138.31C1281.97 2274.55 1150.53 2387.43 999.006 2473.89L1026.9 2525.51C1185.51 2435.22 1323.04 2317.18 1435.69 2174.65C1552.55 2026.75 1637.36 1858.85 1687.75 1675.6C1738.14 1492.36 1751.1 1304.7 1726.29 1117.84C1702.32 937.375 1644.29 765.26 1553.81 606.297C1463.34 447.335 1344.96 309.505 1201.99 196.652C1053.95 79.7918 885.905 -5.01182 702.52 -55.4388C519.135 -105.866 331.347 -118.89 144.378 -94.1694C-36.1947 -70.2849 -208.399 -12.3573 -367.43 77.9963C-526.46 168.35 -664.336 286.594 -777.206 429.43C-894.085 577.326 -978.879 745.233 -1029.27 928.479C-1079.66 1111.72 -1092.62 1299.38 -1067.81 1486.24L-1067.79 1486.25Z" fill="url(#paint0_linear_4906_250)"/>
        <defs>
          <linearGradient id="paint0_linear_4906_250" x1="-419.478" y1="-78.1218" x2="1532.83" y2="701.085" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3480FF" stop-opacity="0"/>
            <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
            <stop offset="0.426471" stop-color="#3480FF"/>
            <stop offset="1"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const newSvgPng = await svgToPng(newSvg);
      const newSvgImage = await pdfDoc.embedPng(newSvgPng);

      page2.drawImage(newSvgImage, {
        x: 1424.02,
        y: page2.getHeight() - 2634.7,
        width: 2817.87,
        height: 2402.57,
        opacity: 1,
        rotate: degrees(164.62),
      });

      page2.drawImage(logoImage3, {
        x: 80,
        y: page2.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });

      //make a container
      // Outer container
      const containerSvg = `
  <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
  </svg>
`;

      const containerPng = await svgToPng(containerSvg);
      const containerImage = await pdfDoc.embedPng(containerPng);

      page2.drawImage(containerImage, {
        x: 952,
        y: page2.getHeight() - 22 - 65, // Adjusted for top position
        width: 486,
        height: 65,
        opacity: 1,
      });

      // Inner container
      const innerContainerSvg = `
  <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
  </svg>
`;

      const innerContainerPng = await svgToPng(innerContainerSvg);
      const innerContainerImage = await pdfDoc.embedPng(innerContainerPng);

      page2.drawImage(innerContainerImage, {
        x: 956,
        y: page2.getHeight() - 26 - 57, // Adjusted for top position
        width: 478,
        height: 57,
        opacity: 1,
      });

      // Draw client avatar

      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch client avatar.");
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page2.drawImage(avatarImage, {
          x: 966,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page2.drawImage(defaultAvatarImage, {
          x: 966,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      }

      // Draw client name
      page2.drawText(`${clientName}`, {
        x: 1024, // Adjusted to match the left position
        y: page2.getHeight() - 36 - 30, // Adjusted for top position
        size: 24,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      // Draw coach avatar
      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch coach avatar.");
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page2.drawImage(avatarImage, {
          x: 1229,
          y: page2.getHeight() - 32 - 45,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page2.drawImage(defaultAvatarImage, {
          x: 1229,
          y: page2.getHeight() - 32 - 45,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Draw coach name
      page2.drawText(`${coachName}`, {
        x: 1287, // Adjusted to match the left position
        y: page2.getHeight() - 36 - 30, // Adjusted for top position
        size: 24,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0),
        width: 116, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      page2.drawText("L.A.R.A Score Overview", {
        x: 432, // Start of the text inside the box, centered
        y: page2.getHeight() - 217 - 35, // Adjust the Y position to center text in the box
        size: 67,
        // Ensure Helvetica font is used or provide an alternative
        color: rgb(1, 1, 1), // White color for the text
        width: 733, // Width of the text should match the box
        height: 75, // Height of the text area should match the box
        lineHeight: 75, // Line height to center text vertically
        fontWeight: 300,
        textAlign: "left", // Left align the text within the box
        opacity: 1,
      });

      let overallScore = laraData?.coach?.coach_overall_score || 4.5;
      const score1 = overallScore * 20;

      const generatePieChartSvg = (score1) => {
        const colors = [
          "#FF0000", // 0-10
          "#FF4500", // 10-20
          "#FF8C00", // 20-30
          "#FFD700", // 30-50
          "#ADFF2F", // 50-70
          "#7FFF00", // 70-80
          "#32CD32", // 80-90
          "#00FF00", // 90-95
          "#00FA9A", // 95-100
        ];

        const getColor = (score1) => {
          if (score1 <= 10) return colors[0];
          if (score1 <= 20) return colors[1];
          if (score1 <= 30) return colors[2];
          if (score1 <= 50) return colors[3];
          if (score1 <= 70) return colors[4];
          if (score1 <= 80) return colors[5];
          if (score1 <= 90) return colors[6];
          if (score1 <= 95) return colors[7];
          return colors[8];
        };

        // Multiply score by 20 to scale from 1-5 to 20-100
        let scaledScore = score1 * 20;
        scaledScore = Math.round(scaledScore); // Round off the score

        const color = getColor(scaledScore);
        const angle = (scaledScore / 100) * 360;

        // Set large-arc-flag to 1 if angle > 180
        const largeArcFlag = angle > 180 ? 1 : 0;

        const x = 84 + 76 * Math.cos((Math.PI * (angle - 90)) / 180);
        const y = 84 + 76 * Math.sin((Math.PI * (angle - 90)) / 180);

        return `
            <svg width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" style="border-radius: 39px 0px 0px 0px; box-shadow: 0px 3px 4px 0px #0000000D;">
              <circle 
                cx="84" 
                cy="84" 
                r="76" 
                fill="white" 
                stroke="#D3D3D3" 
                stroke-width="8"
              />
              <path 
                d="M84,8 
                   A 76 76 0 ${largeArcFlag} 1 ${x} ${y}"
                stroke="${color}" 
                stroke-width="8"
                fill="none" 
                stroke-linecap="round"
                transform="rotate(-90 84 84)"
              />
              <text 
                x="84" 
                y="84" 
                text-anchor="middle" 
                alignment-baseline="middle" 
                fill="black"
                font-size="78" 
                font-family="lexend"
                opacity="0.7"
                font-weight="300"
                line-height="26"
                text-align="center"
              >
                ${scaledScore} <!-- Rounded off score -->
              </text>
            </svg>
          `;
      };

      const pieChartSvg = generatePieChartSvg(overallScore);

      const pieChartPng = await svgToPng(pieChartSvg);
      const pieChartImage = await pdfDoc.embedPng(pieChartPng);

      page2.drawImage(pieChartImage, {
        x: 115,
        y: page2.getHeight() - 244 - 148,
        width: 168,
        height: 168,
        opacity: 1,
      });

      const wrapText = (text, maxWidth, fontSize, font) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = font.widthOfTextAtSize(
            currentLine + " " + word,
            fontSize
          );
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };
      const coachCommunicationText =
        coachCommunication || "No communication report available.";
      const coachFontSize = 20;
      const coachMaxWidth = 894; // Updated width according to layout
      const coachLines = wrapText(
        coachCommunicationText,
        coachMaxWidth,
        coachFontSize,
        helveticaFont
      );

      // Calculate the total height needed for the coach communication text
      const coachTotalHeight = coachLines.length * coachFontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let coachCurrentY = page2.getHeight() - 320; // Updated top position according to layout

      coachLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 432, // Updated left position according to layout
          y: coachCurrentY - index * coachFontSize * 1.2,
          size: coachFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: coachMaxWidth,
          height: 126, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1, // Updated opacity according to layout
          letterSpacing: -0.03,
        });
      });

      // Update the currentY position for the next section
      //currentY -= coachTotalHeight;

      // Update the currentY position for the next section
      //currentY -= coachTotalHeight;

      // Score Analysis
      const getPieChartColor = (score) => {
        if (score >= 1 && score <= 20) return "#DA0000"; // Red
        if (score >= 21 && score <= 40) return "#F90"; // Orange
        if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
        if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
        if (score >= 71 && score <= 80) return "#70CD27"; // Green
        if (score >= 81 && score <= 95) return "#4BC500"; // Green
        if (score >= 95 && score <= 100) return "#37AB00"; // Green
        return "#D3D3D3"; // Grey for undefined or zero
      };

      const scoreAnalysis = [
        { label: "Listen", score: listenScore * 20 },
        { label: "Respond", score: respondScore * 20 },
        { label: "Affirm", score: affirmScore * 20 },
        { label: "Ask", score: askScore * 20 },
      ];

      const drawPieChart = async (score) => {
        const angle = (score / 100) * 360;
        const color = getPieChartColor(score);
        // Calculate start and end points for the arc
        const startX = 66.5 + 59 * Math.cos((Math.PI * -90) / 180);
        const startY = 66.5 + 59 * Math.sin((Math.PI * -90) / 180);
        const endX = 66.5 + 59 * Math.cos((Math.PI * (angle - 90)) / 180);
        const endY = 66.5 + 59 * Math.sin((Math.PI * (angle - 90)) / 180);

        const largeArcFlag = angle > 180 ? 1 : 0;

        const pieSvg = `
            <svg width="133" height="133" viewBox="0 0 133 133" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="filter0_d" x="0" y="0" width="133" height="133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="3"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
              </defs>
              <g transform="rotate(-90 66.5 66.5)">
                <circle cx="66.5" cy="66.5" r="59" stroke="#D3D3D3" stroke-width="6" fill="white"/>
                <path d="M${startX},${startY} A 59,59 0 ${largeArcFlag} 1 ${endX},${endY}" stroke="${color}" stroke-width="6" fill="none"/>
              </g>
              <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="54px" font-family="Lexend" font-weight="275" line-height="26px" text-align="center" opacity="0.7" dy=".3em">${score}</text>
            </svg>
          `;

        const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      const baseX = 120; // Updated x position
      const baseY = page2.getHeight() - 504; // Updated y position
      const gap = 350; // Updated gap between each pie chart

      for (const [index, item] of scoreAnalysis.entries()) {
        const xPosition = baseX + index * gap;

        const pieImagePng = await drawPieChart(item.score);
        const pieImageEmbedded = await pdfDoc.embedPng(pieImagePng);

        page2.drawImage(pieImageEmbedded, {
          x: xPosition,
          y: baseY - 119,
          width: 133, // Updated width
          height: 133, // Updated height
          opacity: 1,
        });

        // Draw the label next to the pie chart
        page2.drawText(item.label, {
          x: xPosition + 150, // Increased x position
          y: baseY - 70, // Decreased y position
          size: 35, // Updated size
          color: rgb(1, 1, 1), // Changed color to white
          width: 115,
          height: 46,
          textAlign: "left",
          opacity: 1,
          lineHeight: 48,
          fontWeight: 300,
          letterSpacing: -0.02,
        });
      }
      page2.drawRectangle({
        x: 340,
        y: page2.getHeight() - 1853,
        width: 1067,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(90),
      });

      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 780 - 16,
        width: 1500,
        height: 16,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        opacity: 1,
      });

      //call summary heading
      page2.drawText("Call Summary", {
        x: 426,
        y: page2.getHeight() - 890,
        size: 48,
        // Assuming Poppins is not available in PDFKit, using helveticaFont
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        fontFamily: "Lexend",
        width: 283,
        height: 48,
        lineHeight: 48,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      const quickRecapText = quickRecap || "No quick recap available.";
      const fontSize = 20;
      const maxWidth = 901; // Updated width
      const lines = wrapText(quickRecapText, maxWidth, fontSize, helveticaFont);

      // Calculate the total height needed for the quick recap text
      const totalHeight = lines.length * fontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let currentY = page2.getHeight() - 957; // Updated top position

      lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 426, // Updated left position
          y: currentY - index * fontSize * 1.2,
          size: fontSize,
          fontFamily: "Lexend",
          color: rgb(0, 0, 0), // White color
          width: 901, // Updated width
          height: 126, // Updated height
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
        });
      });

      // Update the currentY position for the next section
      currentY -= totalHeight;

      page2.drawText("ACTIONS", {
        x: 421, // Adjusted left position
        y: page2.getHeight() - 1165, // Adjusted top position
        size: 41, // Adjusted font size
        color: rgb(86 / 255, 143 / 255, 243 / 255), // Adjusted color
        width: 395, // Adjusted width
        height: 57, // Adjusted height
        lineHeight: 48, // Adjusted line height
        textAlign: "left", // Left align the text
        fontFamily: "Poppins", // Adjusted font family
        fontWeight: 400, // Adjusted font weight
        opacity: 1, // Adjusted opacity
        letterSpacing: -0.02, // Adjusted letter spacing
      });

      currentY = page2.getHeight() - 1238;

      const maxY = page2.getHeight() - 1800;
      const minFontSize = 20;
      let minLineHeight = 9;
      if (actionsArray.length < 7) {
        minLineHeight = 18;
      }

      actionsArray.forEach((action, index) => {
        let fontSize = 20;
        let lineHeight = 28; // Updated line height to 28px
        let wrappedLines = wrapText(
          action.action,
          951,
          fontSize,
          helveticaFont
        );

        // Check if the currentY position will overlap with the next section
        while (
          currentY - wrappedLines.length * lineHeight < maxY &&
          fontSize > minFontSize
        ) {
          fontSize -= 1;
          lineHeight -= 1;
          wrappedLines = wrapText(action.action, 951, fontSize, helveticaFont);
        }

        if (currentY - wrappedLines.length * lineHeight < maxY) {
          return;
        }

        wrappedLines.forEach((line, lineIndex) => {
          page2.drawText(`${lineIndex === 0 ? "• " : "  "}${line}`, {
            x: 413,
            y: currentY - lineIndex * lineHeight,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            width: 951,
            height: 382,
            gap: 0,
            opacity: 1,
            fontFamily: "Lexend",
            fontWeight: 400,
            lineHeight: lineHeight,
            letterSpacing: -0.03, // Updated letter-spacing to -0.03em
            textAlign: "left",
          });
        });

        currentY -= wrappedLines.length * lineHeight + lineHeight; // Move to the next bullet point position
      });

      /////////////////////////////
      // Draw a container from y = 1042 to y = 1072 with full width and specified color

      // Draw the text "Score Overview:" over the box

      // Draw a fixed rectangle
      page2.drawRectangle({
        x: 27,
        y: page2.getHeight() - 868 - 44, // Adjusted Y position
        width: 8,
        height: 44,
        color: rgb(255 / 255, 94 / 255, 0 / 255), // rgba(255, 94, 0, 1)
        opacity: 1,
      });
      page2.drawText("Your Report", {
        x: 55,
        y: page2.getHeight() - 900,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(160 / 255, 160 / 255, 160 / 255),
        width: 225,
        height: 290,
        lineHeight: 24,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });
      const text3 =
        "Continue reading the report to learn about each of your scores and our suggested improvements to your communication.";
      const text1Lines = wrapText(text3, 225, 20, helveticaFont);

      text1Lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 55,
          y: page2.getHeight() - 955 - index * 24, // Adjusted y position to be 20 more y
          size: 20,
          font: helveticaFont,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });
      // Draw a rectangle with the specified properties
      page2.drawRectangle({
        x: -38,
        y: page2.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });

      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page2.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page2.drawText("www.altercall.com/aai", {
        x: 770,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 1),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page2.drawText("02", {
        x: 1437,
        y: page2.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        width: 18,
        height: 19,
        opacity: 1,
      });
      const vectorFooterBlackSvg = `
      <svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
      </svg>
    `;

      const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
      const vectorFooterBlackImage = await pdfDoc.embedPng(
        vectorFooterBlackPng
      );

      page2.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page2.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });
      //page 3 page 4 page 5 page 6
      const sections = [
        {
          title: "Ask",
          score: laraData?.coach?.coach_ask_score ?? 2.4,
          report: laraData?.coach?.coach_ask_report ?? "",
          areaOfImprovement:
            laraData?.coach?.coach_ask_area_of_improvement ?? "",
          details: laraData?.coach?.coach_ask_details ?? "",
          strengths: laraData?.coach?.coach_ask_strengths ?? "",
          weaknesses: laraData?.coach?.coach_ask_weaknesses ?? "",
          page: "03",
        },
        {
          title: "Respond",
          score: laraData?.coach?.coach_respond_score ?? 1.7,
          report: laraData?.coach?.coach_respond_report ?? "",
          areaOfImprovement:
            laraData?.coach?.coach_respond_area_of_improvement ?? "",
          details: laraData?.coach?.coach_respond_details ?? "",
          strengths: laraData?.coach?.coach_respond_strengths ?? "",
          weaknesses: laraData?.coach?.coach_respond_weaknesses ?? "",
          page: "04",
        },
        {
          title: "Affirm",
          score: laraData?.coach?.coach_affirm_score ?? 3.8,
          report: laraData?.coach?.coach_affirm_report ?? "",
          areaOfImprovement:
            laraData?.coach?.coach_affirm_area_of_improvement ?? "",
          details: laraData?.coach?.coach_affirm_details ?? "",
          strengths: laraData?.coach?.coach_affirm_strengths ?? "",
          weaknesses: laraData?.coach?.coach_affirm_weaknesses ?? "",
          page: "05",
        },
        {
          title: "Listen",
          score: laraData?.coach?.coach_listen_score ?? 3.0,
          report: laraData?.coach?.coach_listen_report ?? "",
          areaOfImprovement:
            laraData?.coach?.coach_listen_area_of_improvement ?? "",
          details: laraData?.coach?.coach_listen_details ?? "",
          strengths: laraData?.coach?.coach_listen_strengths ?? "",
          weaknesses: laraData?.coach?.coach_listen_weaknesses ?? "",
          page: "06",
        },
      ];

      for (const [index, section] of sections.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size

        //background color width full and height 106
        const svgGradient = `
        <svg width="1500" height="490" viewBox="0 0 1500 490" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H1500V490H0V0Z" fill="url(#paint0_linear_4460_2071)"/>
          <defs>
        <linearGradient id="paint0_linear_4460_2071" x1="1298.72" y1="50.7345" x2="868.339" y2="1030.56" gradientUnits="userSpaceOnUse">
          <stop/>
          <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
          </defs>
        </svg>
      `;

        const svgGradientPng = await svgToPng(svgGradient);
        const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

        page.drawImage(svgGradientImage, {
          x: 0,
          y: page.getHeight() - 490,
          width: page.getWidth(),
          height: 490,
          opacity: 1,
        });
        // Embed the altercall.ai logo
        page.drawImage(logoImage3, {
          x: 80,
          y: page.getHeight() - 77, // Adjusted for top position
          width: 229,
          height: 44,
        });
        const svgRectangle = `
        <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
        </svg>
      `;

        const svgRectanglePng = await svgToPng(svgRectangle);
        const svgRectangleImage = await pdfDoc.embedPng(svgRectanglePng);

        page.drawImage(svgRectangleImage, {
          x: 952,
          y: page2.getHeight() - 26 - 65, // Adjusted for top position
          width: 486,
          height: 65,
          opacity: 1,
        });
        // Inner container
        const svgInnerContainer = `
        <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
        </svg>
      `;

        const svgInnerContainerPng = await svgToPng(svgInnerContainer);
        const svgInnerContainerImage = await pdfDoc.embedPng(
          svgInnerContainerPng
        );

        page.drawImage(svgInnerContainerImage, {
          x: 956,
          y: page2.getHeight() - 26 - 62, // Adjusted for top position
          width: 478,
          height: 57,
          opacity: 1,
        });

        // Client avatar
        try {
          const response = await fetch(clientAvatarUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch avatar image: ${response.statusText}`
            );
          }
          const avatarImageBytes = await response.arrayBuffer();
          const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
          page.drawImage(avatarImage, {
            x: 966,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        } catch (error) {
          console.error("Error fetching or embedding avatar image:", error);
          const defaultAvatarBytes = await fetch(avatar).then((res) =>
            res.arrayBuffer()
          );
          const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
          page.drawImage(defaultAvatarImage, {
            x: 966,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        }

        // Client name
        page.drawText(`${clientName}`, {
          x: 1024,
          y: page.getHeight() - 65,
          size: 24,
          color: rgb(0, 0, 0),
          width: 170,
          fontFamily: "Lexend",
          height: 30,
          lineHeight: 30,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
        });

        // Coach avatar
        try {
          const response = await fetch(coachAvatarUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch avatar image: ${response.statusText}`
            );
          }
          const avatarImageBytes = await response.arrayBuffer();
          const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
          page.drawImage(avatarImage, {
            x: 1229,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        } catch (error) {
          console.error("Error fetching or embedding avatar image:", error);
          const defaultAvatarBytes = await fetch(avatar).then((res) =>
            res.arrayBuffer()
          );
          const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
          page.drawImage(defaultAvatarImage, {
            x: 1229,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        }

        // Coach name
        page.drawText(`${coachName}`, {
          x: 1287,
          y: page.getHeight() - 65,
          size: 24,
          color: rgb(0, 0, 0),
          fontFamily: "Lexend",
          width: 116,
          height: 30,
          lineHeight: 30,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
        });

        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1853,
          width: 1373,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 0,
          rotate: degrees(90),
        });

        // Draw pie chart for the section
        const drawSectionPieChart = async (score) => {
          score = score * 20;
          const radius = 82;
          const textSize = "78px";
          const strokeWidth = 8;

          // Color mapping logic - adjust based on your needs
          const color = getPieChartColor(score);

          const circ = 2 * Math.PI * radius;
          const filledPortion = (score / 100) * circ;
          const strokeDashoffset = circ - filledPortion;

          const pieSvg = `
          <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="shadow" x="-4" y="-4" width="192" height="192" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feDropShadow dx="0" dy="3" stdDeviation="2" flood-opacity="0.05" result="shadow"/>
                <feBlend in="SourceGraphic" in2="shadow" mode="normal"/>
              </filter>
            </defs>
            <g filter="url(#shadow)">
              <circle 
                cx="92" 
                cy="92" 
                r="${radius}" 
                fill="white" 
                stroke="#EBEBEB" 
                stroke-width="6" 
                stroke-linecap="round"
              />
              <circle 
                cx="92" 
                cy="92" 
                r="${radius}" 
                fill="white" 
                stroke="${color}" 
                stroke-width="${strokeWidth}" 
                stroke-linecap="round"
                stroke-dasharray="${circ}" 
                stroke-dashoffset="${strokeDashoffset}" 
                transform="rotate(-90 92 92)" 
              />
            </g>
            <text 
              x="50%" 
              y="50%" 
              text-anchor="middle"
              text-align="center" 
              font-size="${textSize}" 
              font-family="lexend" 
              opacity="0.7"
              font-weight="300" 
              fill="black" 
              dy=".3em"
            >
              ${isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
            </text>
          </svg>
        `;

          const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
          const url = URL.createObjectURL(svgBlob);
          const img = new Image();
          img.src = url;
          await new Promise((resolve) => (img.onload = resolve));
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(url);
          return canvas.toDataURL("image/png");
        };
        const sectionPieChartPng = await drawSectionPieChart(
          section.score || 0
        );
        const sectionPieChartImage = await pdfDoc.embedPng(sectionPieChartPng);

        page.drawImage(sectionPieChartImage, {
          x: 90,
          y: page.getHeight() - 202 - 148,
          width: 184,
          height: 184,
          opacity: 1,
        });
        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: 0,
          y: page.getHeight() - 490,
          width: 1500,
          height: 16,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 590,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 579,
          size: 20,
          font: helveticaFont,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // Changed to #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the criteria text
        const criteriaText =
          "Active listening, avoiding interruptions, recognizing themes, and understanding non-verbal cues. concerns, ideas, and emotions without interrupting or steering the conversation.";
        const criteriaFontSize = 20;
        const criteriaMaxWidth = 225;
        const criteriaLines = wrapText(
          criteriaText,
          criteriaMaxWidth,
          criteriaFontSize,
          helveticaFont
        );

        let criteriaCurrentY = page.getHeight() - 640; // Adjusted for top position

        criteriaLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55,
            y: criteriaCurrentY - index * criteriaFontSize * 1.2,
            size: criteriaFontSize,
            font: helveticaFont,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // Changed to #A0A0A0
            width: criteriaMaxWidth,
            height: criteriaFontSize * 1.2,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });
        });

        //report part

        page.drawText(section.title, {
          x: 456,
          y: page.getHeight() - 205,
          size: 67,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: 196,
          height: 75,
          opacity: 1,
          fontFamily: "Poppins",
          fontWeight: 300,
          lineHeight: 75,
          textAlign: "left",
        });

        // Coach session report
        const coachSessionReport =
          section.report ||
          `No coach ${section.title.toLowerCase()} report available.`;
        const coachReportFontSize = 20;
        const coachReportMaxWidth = 970;
        const coachReportLines = wrapText(
          coachSessionReport,
          coachReportMaxWidth,
          coachReportFontSize,
          helveticaFont
        );

        let coachReportY = page.getHeight() - 250; // Adjusted for top position

        coachReportLines.forEach((line, index) => {
          page.drawText(line, {
            x: 456,
            y: coachReportY - index * coachReportFontSize * 1.2,
            size: coachReportFontSize,
            color: rgb(1, 1, 1),
            width: coachReportMaxWidth,
            height: 84, // Adjusted height
            lineHeight: 42,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted opacity
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
        });

        //section partss
        // Strengths

        const arrowUpRightStrengthsSvg = `
        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.4226 27.1425L65.0902 27.142M65.0902 27.142L65.0902 59.345M65.0902 27.142L27.1422 65.0901" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;
        const arrowUpRightStrengthsPng = await svgToPng(
          arrowUpRightStrengthsSvg
        );
        const arrowUpRightStrengthsImage = await pdfDoc.embedPng(
          arrowUpRightStrengthsPng
        );

        page.drawImage(arrowUpRightStrengthsImage, {
          x: 392,
          y: page.getHeight() - 576 - 92,
          width: 92,
          height: 92,
          opacity: 1,
        });
        page.drawText("STRENGTHS", {
          x: 504,
          y: page.getHeight() - 594,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255),
          width: 228,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: 2,
          fontFamily: "Lexend",
        });
        const strengths =
          section.strengths ||
          `No coach ${section.title.toLowerCase()} strengths data available.`;
        const maxWidth1 = 990;
        let currentY = page.getHeight() - 632;

        let wrappedLines = wrapText(
          strengths,
          maxWidth1 - 30,
          18,
          helveticaFont
        );
        let totalHeight = wrappedLines.length * 24;
        console.log("currentY", currentY);
        console.log("total height", totalHeight);
        console.log("currentY - total ", currentY - totalHeight);
        if (currentY - totalHeight < 920) {
          // Decrease font size to fit within the space
          const adjustedFontSize = 16;
          wrappedLines = wrapText(
            strengths,
            maxWidth1 - 30,
            adjustedFontSize,
            helveticaFont
          );
          totalHeight = wrappedLines.length * (adjustedFontSize + 8); // Adjusted line height
          currentY = page.getHeight() - 632; // Reset currentY to original position
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 507,
            y: currentY,
            size: currentY - totalHeight < 920 ? 16 : 18, // Adjust font size if needed
            color: rgb(0, 0, 0), // Changed to black
            width: maxWidth1,
            height: 24,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
          currentY -= currentY - totalHeight < 920 ? 20 : 24; // Adjust line height if needed
        });

        // Weaknesses
        const arrowDownRightWeaknessesSvg = `
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M58.2816 63.4476L26.3242 63.448M26.3242 63.448L26.3242 31.9451M26.3242 63.448L63.4473 26.3249" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
        const arrowDownRightWeaknessesPng = await svgToPng(
          arrowDownRightWeaknessesSvg
        );
        const arrowDownRightWeaknessesImage = await pdfDoc.embedPng(
          arrowDownRightWeaknessesPng
        );

        page.drawImage(arrowDownRightWeaknessesImage, {
          x: 392,
          y: page.getHeight() - 896 - 100,
          width: 92,
          height: 92,
          opacity: 1,
          // rotate: degrees(-135),
        });
        page.drawText("WEAKNESSES", {
          x: 515,
          y: page.getHeight() - 936,
          size: 26,
          fontFamily: "Lexend",
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 343,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: 2,
        });

        const weaknesses =
          section.weaknesses ||
          `No coach ${section.title.toLowerCase()} weaknesses data available.`;
        currentY = page.getHeight() - 976;

        wrappedLines = wrapText(weaknesses, 990 - 20, 18, helveticaFont);
        totalHeight = wrappedLines.length * 24;

        if (currentY - totalHeight < 1200) {
          // Decrease font size to fit within the space
          const adjustedFontSize = 16;
          wrappedLines = wrapText(
            weaknesses,
            990 - 20,
            adjustedFontSize,
            helveticaFont
          );
          totalHeight = wrappedLines.length * (adjustedFontSize + 8); // Adjusted line height
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 506,
            y: currentY,
            size: currentY - totalHeight < 1200 ? 16 : 18, // Adjust font size if needed
            color: rgb(0, 0, 0), // Replaced with rgb equivalent of #000000
            width: 776,
            height: 24,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
          currentY -= currentY - totalHeight < 1200 ? 20 : 24; // Adjust line height if needed
        });
        // Areas for Improvement
        const improvementSvg = `
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.79883 6.80002V61.2H61.1988M20.3988 40.8004L32.2988 28.9004L40.7988 37.4004L59.4988 18.7004M46.7764 17H61.2014V31.425" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;

        const improvementPng = await svgToPng(improvementSvg);
        const improvementImage = await pdfDoc.embedPng(improvementPng);

        page.drawImage(improvementImage, {
          x: 407,
          y: page.getHeight() - 1211 - 68,
          width: 68,
          height: 68,
          opacity: 1,
        });
        page.drawText("IMPROVEMENTS", {
          x: 515,
          y: page.getHeight() - 1240,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 325,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: 2,
        });
        const areasForImprovement =
          section.areaOfImprovement || "No areas for improvement available.";
        currentY = page.getHeight() - 1280;

        wrappedLines = wrapText(
          areasForImprovement,
          990 - 30,
          18,
          helveticaFont
        );
        totalHeight = wrappedLines.length * 24;

        if (currentY - totalHeight < 1570) {
          // Decrease font size to fit within the space
          const adjustedFontSize = 16;
          wrappedLines = wrapText(
            areasForImprovement,
            990 - 30,
            adjustedFontSize,
            helveticaFont
          );
          totalHeight = wrappedLines.length * (adjustedFontSize + 8); // Adjusted line height
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 511,
            y: currentY,
            size: currentY - totalHeight < 1570 ? 16 : 18, // Adjust font size if needed
            color: rgb(0, 0, 0), // Changed to black
            width: 824,
            height: 24,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
          currentY -= currentY - totalHeight < 1570 ? 20 : 24; // Adjust line height if needed
        });

        // Details
        const listSvg = `
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.29923 9.45001L56.6992 9.45001M6.29923 23.1L43.3751 23.1M6.29923 36.75L56.6992 36.75M6.29923 50.4L33.2372 50.4" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;

        const listSvgPng = await svgToPng(listSvg);
        const listSvgImage = await pdfDoc.embedPng(listSvgPng);

        page.drawImage(listSvgImage, {
          x: 412,
          y: page.getHeight() - 1576 - 63,
          width: 63,
          height: 63,
          opacity: 1,
        });
        page.drawText("DETAILS", {
          x: 515,
          y: page.getHeight() - 1589,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 172,
          height: 39,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          fontFamily: "Lexend",
          opacity: 1,
          letterSpacing: 2,
        });
        const details = section.details || "No details available.";
        currentY = page.getHeight() - 1628;

        wrappedLines = wrapText(details, 990 - 30, 18, helveticaFont);
        totalHeight = wrappedLines.length * 24;

        if (currentY - totalHeight < 1840) {
          // Decrease font size to fit within the space
          const adjustedFontSize = 16;
          wrappedLines = wrapText(
            details,
            990 - 30,
            adjustedFontSize,
            helveticaFont
          );
          totalHeight = wrappedLines.length * (adjustedFontSize + 8); // Adjusted line height
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 511,
            y: currentY,
            size: currentY - totalHeight < 1840 ? 16 : 18, // Adjust font size if needed
            color: rgb(0, 0, 0), // Changed to black
            width: 784,
            height: 24,
            lineHeight: 24,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
            fontWeight: 400,
          });
          currentY -= currentY - totalHeight < 1840 ? 20 : 24; // Adjust line height if needed
        });
        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1853,
          width: 1606,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about L.A.R.A at", {
          x: 499,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          fontFamily: "Lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 770,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 1),
          fontFamily: "Lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });
        const pageno = section.page;
        page.drawText(pageno, {
          x: 1437,
          y: page2.getHeight() - 1933,
          size: 18,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          width: 18,
          height: 19,
          opacity: 1,
        });
        const vectorFooterBlackSvg = `
      <svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
      </svg>
    `;

        const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
        const vectorFooterBlackImage = await pdfDoc.embedPng(
          vectorFooterBlackPng
        );

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });
      }
      const page5 = pdfDoc.addPage([1500, 1974]);
      //background color width full and height 106
      const svgGradient = `
    <svg width="1500" height="106" viewBox="0 0 1500 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H1500V106H0V0Z" fill="url(#paint0_linear_4460_2071)"/>
      <defs>
        <linearGradient id="paint0_linear_4460_2071" x1="1298.72" y1="10.9752" x2="1274.91" y2="261.569" gradientUnits="userSpaceOnUse">
          <stop/>
          <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
      </defs>
    </svg>
  `;

      const svgGradientPng = await svgToPng(svgGradient);
      const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

      page5.drawImage(svgGradientImage, {
        x: 0,
        y: page5.getHeight() - 106,
        width: page5.getWidth(),
        height: 106,
        opacity: 1,
      });
      // Embed the altercall.ai logo
      page5.drawImage(logoImage3, {
        x: 80,
        y: page5.getHeight() - 77, // Adjusted for top position
        width: 229,
        height: 44,
      });
      const svgRectangle = `
    <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
    </svg>
  `;

      const svgRectanglePng = await svgToPng(svgRectangle);
      const svgRectangleImage = await pdfDoc.embedPng(svgRectanglePng);

      page5.drawImage(svgRectangleImage, {
        x: 952,
        y: page5.getHeight() - 26 - 65, // Adjusted for top position
        width: 486,
        height: 65,
        opacity: 1,
      });

      // Inner container
      const svgInnerContainer = `
    <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
    </svg>
  `;

      const svgInnerContainerPng = await svgToPng(svgInnerContainer);
      const svgInnerContainerImage = await pdfDoc.embedPng(
        svgInnerContainerPng
      );

      page5.drawImage(svgInnerContainerImage, {
        x: 956,
        y: page5.getHeight() - 26 - 62, // Adjusted for top position
        width: 478,
        height: 57,
        opacity: 1,
      });

      // Client avatar
      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page5.drawImage(avatarImage, {
          x: 966,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page5.drawImage(defaultAvatarImage, {
          x: 966,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Client name
      page5.drawText(`${clientName}`, {
        x: 1024,
        y: page5.getHeight() - 65,
        size: 24,
        color: rgb(0, 0, 0),
        width: 170,
        fontFamily: "Lexend",
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Coach avatar
      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page5.drawImage(avatarImage, {
          x: 1229,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page5.drawImage(defaultAvatarImage, {
          x: 1229,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Coach name
      page5.drawText(`${coachName}`, {
        x: 1287,
        y: page5.getHeight() - 65,
        size: 24,
        color: rgb(0, 0, 0),
        fontFamily: "Lexend",
        width: 116,
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });
      // Draw the text "how we" with specified styles
      page5.drawText("How We", {
        x: 121,
        y: page5.getHeight() - 215 - 95, // Adjusted for top position and line height
        size: 67,
        // font: await pdfDoc.embedFont(StandardFonts.Helvetica),
        color: rgb(0, 0, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });

      // Draw the text "Score" with specified styles
      page5.drawText("Score", {
        x: 121,
        y: page5.getHeight() - 215 - 170, // Adjusted for top position and height
        size: 67,
        // font: await pdfDoc.embedFont(StandardFonts.Helvetica),
        color: rgb(1, 94 / 255, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });
      // Draw the text "The LARA platform is designed to provide actionable insights into coaching conversations."
      //The LARA platform is designed to provide actionable insights into coaching conversations. This appendix explains the key components of the score recap, breaking down the four sub-scores: Listen, Affirm, Respond, and Ask
      // Draw the text "The LARA platform is designed to provide actionable insights"
      page5.drawText(
        "The LARA platform is designed to provide actionable insights",
        {
          x: 498,
          y: page5.getHeight() - 290,
          size: 32,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "into coaching conversations. This appendix explains the key"
      page5.drawText(
        "into coaching conversations. This appendix explains the key",
        {
          x: 498,
          y: page5.getHeight() - 335,
          size: 32,
          // font: helveticaFont,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "components of the score recap, breaking down the four sub-"
      page5.drawText(
        "components of the score recap, breaking down the four sub-",
        {
          x: 498,
          y: page5.getHeight() - 380,
          size: 32,
          //font: helveticaFont,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "scores:"
      page5.drawText("scores:", {
        x: 498,
        y: page5.getHeight() - 425,
        size: 32,
        //font: helveticaFont,
        color: rgb(0, 0, 0),
        width: 914,
        height: 42,
        lineHeight: 42,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: -0.03,
      });

      // Draw the text "Listen, Affirm, Respond, and Ask" with blue color
      page5.drawText("Listen, Affirm, Respond, and Ask", {
        x: 610,
        y: page5.getHeight() - 425,
        size: 32,
        //font: helveticaFont,
        color: rgb(0, 118 / 255, 186 / 255), // AlterCall-Blue color
        width: 914,
        height: 42,
        lineHeight: 42,
        fontWeight: 600,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: -0.03,
      });
      // Draw a horizontal line with the specified properties
      page5.drawRectangle({
        x: -38,
        y: page5.getHeight() - 499,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      // Draw a vertical line with the specified properties
      page5.drawRectangle({
        x: 354,
        y: page5.getHeight() - 1863,
        width: 1364,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(90),
      });
      page5.drawText("LISTEN", {
        x: 150,
        y: page5.getHeight() - 550,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 112,
        height: 39,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "This score evaluates the quality of attentiveness and understanding demonstrated during the conversation. It",
        {
          x: 402,
          y: page5.getHeight() - 555,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "measures how well the coach listens to the coachee's concerns, ideas, and emotions without interrupting or",
        {
          x: 402,
          y: page5.getHeight() - 583,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("steering the conversation.", {
        x: 402,
        y: page5.getHeight() - 611,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 650,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 1400,
        textAlign: "left",
        fontFamily: "Lexend-bold",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Active listening, avoiding interruptions, recognizing themes, and understanding non-verbal cues.",
        {
          x: 520,
          y: page5.getHeight() - 650,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 706,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Practice mindfulness, focus on the speaker without distractions, and summarize key points",
        {
          x: 570,
          y: page5.getHeight() - 706,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("back to the coachee.", {
        x: 402,
        y: page5.getHeight() - 734,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("AFFIRM", {
        x: 150,
        y: page5.getHeight() - 850,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 116,
        height: 39,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "The Affirm score assesses the coach's ability to validate and acknowledge the coachee's thoughts and feelings.",
        {
          x: 402,
          y: page5.getHeight() - 855,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "This includes offering positive reinforcement, showing empathy, and creating a supportive environment where ",
        {
          x: 402,
          y: page5.getHeight() - 883,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("the coachee feels heard and valued.", {
        x: 402,
        y: page5.getHeight() - 911,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 950,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Offering validation, providing encouragement, acknowledging the feelings and efforts.",
        {
          x: 520,
          y: page5.getHeight() - 950,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1006,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Use affirming language, focus on the coachee’s progress, and express appreciation for",
        {
          x: 570,
          y: page5.getHeight() - 1006,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("their insights and contributions.", {
        x: 402,
        y: page5.getHeight() - 1034,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("RESPOND", {
        x: 150,
        y: page5.getHeight() - 1200,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 143,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "The Respond score evaluates how effectively the coach answers questions or offers insights based on what the",
        {
          x: 402,
          y: page5.getHeight() - 1205,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "coachee has shared. This score also reflects the relevance and clarity of the coach's feedback, ensuring it",
        {
          x: 402,
          y: page5.getHeight() - 1233,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("aligns with the coachee's needs and goals.", {
        x: 402,
        y: page5.getHeight() - 1261,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 1300,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Timeliness, clarity, and relevance of responses; offering feedback that reflects the progress.",
        {
          x: 520,
          y: page5.getHeight() - 1300,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1356,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 2100,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Tailor responses to the coachee’s unique situation, be clear and concise, and offer",
        {
          x: 570,
          y: page5.getHeight() - 1356,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("solutions that the coachee can apply.", {
        x: 402,
        y: page5.getHeight() - 1384,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("ASK", {
        x: 180,
        y: page5.getHeight() - 1530,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 58,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "This score reflects the coach’s ability to ask thought-provoking, open-ended questions that encourage deeper",
        {
          x: 402,
          y: page5.getHeight() - 1535,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "reflection and exploration of the coachee’s ideas and challenges. Effective questioning helps the coachee",
        {
          x: 402,
          y: page5.getHeight() - 1563,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "discover new perspectives and develop actionable plans.",
        {
          x: 402,
          y: page5.getHeight() - 1591,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 1630,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255),
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend-bold",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Open-ended questions, depth of inquiry, fostering self-reflection and action planning.",
        {
          x: 520,
          y: page5.getHeight() - 1630,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255),
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1686,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Use probing questions to dig deeper into the coachee’s thoughts, avoid leading",
        {
          x: 570,
          y: page5.getHeight() - 1686,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255),
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "questions, and encourage self-directed problem-solving.",
        {
          x: 402,
          y: page5.getHeight() - 1714,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );
      page5.drawRectangle({
        x: -38,
        y: page5.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page5.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page5.getHeight() - 1933,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(0, 0, 0),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page5.drawText("www.altercall.com/aai", {
        x: 770,
        y: page5.getHeight() - 1933,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(0, 0, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page5.drawText("07", {
        x: 1437,
        y: page5.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        fontFamily: "Lexend",
        width: 18,
        height: 19,
        opacity: 1,
      });

      page5.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page5.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      //page 6
      const page6 = pdfDoc.addPage([1500, 1974]);

      // Set the background color for page 5
      page6.drawRectangle({
        x: 0,
        y: 0,
        width: page6.getWidth(),
        height: page6.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      // Draw the gradient overlay
      const gradientSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_2602)"/>
        <defs>
          <linearGradient id="paint0_linear_4460_2602" x1="631.5" y1="1858.5" x2="2361.99" y2="710.973" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const gradientPng = await svgToPng(gradientSvg);
      const gradientImage = await pdfDoc.embedPng(gradientPng);

      page6.drawImage(gradientImage, {
        x: 0,
        y: 0,
        width: page6.getWidth(),
        height: page6.getHeight(),
      });

      // const aaiLogoImageBytes = await fetch(aaiLogoVerticalBlack).then((res) => res.arrayBuffer());
      // const aaiLogoImage = await pdfDoc.embedPng(aaiLogoImageBytes);
      // Embed the altercall.ai logo on page 5
      const logoSvg = `
    <svg width="276" height="156" viewBox="0 0 276 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M166.664 65.6049C171.849 74.6734 177.038 83.7497 182.275 92.9114C180.762 93.7765 179.295 94.6178 177.737 95.5076C172.618 86.5573 167.518 77.6407 162.454 68.7871C146.173 78.2908 130.011 78.2908 113.703 68.7882C108.651 77.6204 103.549 86.5415 98.4221 95.5053C96.8901 94.6291 95.4199 93.7877 93.8833 92.9103C99.1083 83.7699 104.296 74.6947 109.498 65.5993C98.0791 55.4612 92.592 42.8349 93.7135 27.5742C94.4874 17.0604 99.6797 6.18097 106.508 0C107.692 1.23732 108.879 2.47689 110.063 3.71533C93.2658 20.2538 96.0577 47.4141 112.216 60.8491C120.815 45.8123 129.405 30.7868 138.08 15.6161C146.746 30.771 155.337 45.7954 163.931 60.8255C179.511 48.0732 183.339 21.1975 165.96 3.60735C167.175 2.4274 168.392 1.24744 169.611 0.0663653C186.49 15.4035 188.643 48.053 166.663 65.6061H166.664V65.6049ZM116.396 64.0785C130.164 73.4574 148.411 72.1526 159.735 64.0279C152.545 51.4522 145.351 38.8721 138.08 26.1558C130.804 38.8811 123.611 51.4612 116.396 64.0774L116.396 64.0785Z" fill="white"/>
    <path d="M20.5421 148.821L6.00242 148.821L2.87102 155.771L1.66699e-10 155.771L11.9304 129.751L14.6513 129.751L26.5817 155.771L23.6735 155.771L20.5421 148.821ZM19.5351 146.59L13.2723 132.614L7.00948 146.59L19.5351 146.59Z" fill="white"/>
    <path d="M38.3623 129.751L41.1217 129.751L41.1217 153.392L55.7358 153.392L55.7358 155.771L38.3637 155.771L38.3637 129.751H38.3623Z" fill="white"/>
    <path d="M70.423 132.13L61.252 132.13L61.252 129.751L82.3534 129.751L82.3534 132.13L73.1824 132.13L73.1824 155.771L70.423 155.771L70.423 132.13Z" fill="white"/>
    <path d="M112.662 153.392L112.662 155.771L94.2085 155.771L94.2085 129.751L112.103 129.751L112.103 132.13L96.9665 132.13L96.9665 141.385L110.462 141.385L110.462 143.727L96.9665 143.727L96.9665 153.392L112.662 153.392Z" fill="white"/>
    <path d="M144.424 155.771L138.459 147.407C137.788 147.482 137.117 147.519 136.372 147.519L129.363 147.519L129.363 155.771L126.604 155.771L126.604 129.751L136.372 129.751C143.008 129.751 147.033 133.096 147.033 138.673C147.033 142.762 144.87 145.623 141.068 146.85L147.442 155.771L144.423 155.771L144.424 155.771ZM144.275 138.673C144.275 134.509 141.516 132.13 136.297 132.13L129.363 132.13L129.363 145.177L136.297 145.177C141.517 145.177 144.275 142.76 144.275 138.671V138.673Z" fill="white"/>
    <path d="M158.701 142.762C158.701 135.143 164.517 129.529 172.382 129.529C176.184 129.529 179.615 130.792 181.926 133.32L180.174 135.067C178.05 132.911 175.439 131.982 172.457 131.982C166.157 131.982 161.422 136.592 161.422 142.762C161.422 148.932 166.157 153.542 172.457 153.542C175.439 153.542 178.048 152.612 180.174 150.42L181.926 152.167C179.615 154.695 176.186 155.995 172.345 155.995C164.516 155.995 158.701 150.383 158.701 142.762Z" fill="white"/>
    <path d="M210.969 148.821L196.43 148.821L193.298 155.771L190.427 155.771L202.358 129.751L205.079 129.751L217.009 155.771L214.101 155.771L210.969 148.821ZM209.962 146.59L203.7 132.614L197.437 146.59L209.962 146.59Z" fill="white"/>
    <path d="M228.79 129.751L231.549 129.751L231.549 153.392L246.163 153.392L246.163 155.771L228.791 155.771L228.791 129.751H228.79Z" fill="white"/>
    <path d="M258.203 129.751L260.963 129.751L260.963 153.392L275.577 153.392L275.577 155.771L258.205 155.771L258.205 129.751H258.203Z" fill="white"/>
    </svg>
    `;

      const logoImagePng = await svgToPng(logoSvg);
      const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

      page6.drawImage(logoImageEmbedded, {
        x: 612,
        y: page6.getHeight() - 112 - 156,
        width: 275.58,
        height: 156,
        opacity: 1,
      });
      // Draw the first text block

      const text1 = "AlterCall AI is a";
      const text2 = "revolutionary";
      const ltext3 = "Leadership Platform";
      const text4 = "we measure the";
      const text5 = "previously";
      const text6 = "unmeasurable.";

      const pageWidth = page6.getWidth();
      const pageHeight = page6.getHeight();

      // Text Configuration
      const lfontSize = 78;
      const lineHeight = 88;
      const left = 136;
      const top = pageHeight - 987;
      const colorWhite = rgb(1, 1, 1); // White color
      const colorOrange = rgb(1, 94 / 255, 0); // Orange color

      // Draw Text 1
      page6.drawText(text1, {
        x: left,
        y: top,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 2
      page6.drawText(text2, {
        x: left,
        y: top - lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 3
      page6.drawText(ltext3, {
        x: left,
        y: top - 2 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 4
      page6.drawText(text4, {
        x: left,
        y: top - 3 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 5
      page6.drawText(text5, {
        x: left,
        y: top - 4 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 6
      page6.drawText(text6, {
        x: left,
        y: top - 5 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });
      // Draw the new text block
      const newText1 =
        "We are pioneering the coaching industry with a specialized";
      const newText2 =
        "AI platform that doesn't just speculate about your potential;";
      const newText3 = "it knows it,";
      const newText4 = "and more importantly, it's here to unlock it.";

      const newFontSize = 24;
      const newLineHeight = 35;
      const newLeft = 136;
      const newTop = pageHeight - 1544;
      const newColor = rgb(1, 1, 1); // White color

      // Draw new Text 1
      page6.drawText(newText1, {
        x: newLeft,
        y: newTop,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 2
      page6.drawText(newText2, {
        x: newLeft,
        y: newTop - newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 3
      // Draw new Text 3
      page6.drawText(newText3, {
        x: newLeft,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 700,
      });

      // Draw new Text 4
      page6.drawText(newText4, {
        x: newLeft + 115,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 1400,
      });

      // Draw a horizontal line with the specified properties
      page6.drawRectangle({
        x: -38,
        y: page6.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page6.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page6.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(1, 1, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page6.drawText("www.altercall.com/aai", {
        x: 770,
        y: page6.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page6.drawText("08", {
        x: 1437,
        y: page6.getHeight() - 1933,
        size: 18,
        color: rgb(1, 1, 1),
        width: 18,
        height: 19,
        opacity: 1,
      });

      page6.drawImage(vectorFooterwhiteImage, {
        x: 35.72,
        y: page6.getHeight() - 1933,
        width: 38,
        height: 35.72,
        opacity: 1,
        //rotate: degrees(-90),
      });

      // Serialize the PDF to Uint8Array and start the download
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, `${callName}.pdf`);
    } catch (err) {
      console.error("Error generating the PDF report:", err);
    }
  };

  const downloadReportcoachee = async () => {
    try {
      if (!fileData) {
        console.log("file data is missing");
      } else {
        console.log("file data is present");
      }
      if (!fileData || !Array.isArray(fileData) || fileData.length === 0) {
        throw new Error("File data is missing or invalid.");
      }
      console.log("Generating PDF report...");
      // Ensure actions is an array

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Create the first page
      const page1 = pdfDoc.addPage([1500, 1974]); // Custom size

      // Embed the altercall.ai logo
      const logoImageBytes = await fetch(altercallLogo).then((res) =>
        res.arrayBuffer()
      );
      const logoImage = await pdfDoc.embedPng(logoImageBytes);

      // Embed the aaiLogoVerticalBlack logo

      const svgToPng = async (svg) => {
        const svgBlob = new Blob([svg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      //page 1 background color:
      const backgroundSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_1888)"/>
        <defs>
          <linearGradient id="paint0_linear_4460_1888" x1="1298.72" y1="204.388" x2="-718.232" y2="1344.22" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const backgroundPng = await svgToPng(backgroundSvg);
      const backgroundImage = await pdfDoc.embedPng(backgroundPng);

      page1.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: 1500,
        height: 1974,
      });

      const logoSvg3 = `
          <svg width="271" height="52" viewBox="0 0 271 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2724 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7168C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28892 3.33332 3.36529 7.05953 7.62939e-06C7.70601 0.673761 8.35337 1.34838 8.99985 2.02301C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.50201C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96382C40.1636 1.3214 40.8275 0.678984 41.4932 0.0356974C50.7036 8.38623 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
            <path d="M91.8662 28.2079H81.6535L79.4539 33.1328H77.4375L85.8172 14.6973H87.7288L96.1084 33.1328H94.0659L91.8662 28.2079ZM91.1592 26.6277L86.7599 16.7252L82.3606 26.6277H91.1592Z" fill="white"/>
            <path d="M104.376 14.6974H106.314V31.4474H116.579V33.1329H104.376V14.6974Z" fill="white"/>
            <path d="M126.898 16.3829H120.456V14.6974H135.278V16.3829H128.836V33.1329H126.898V16.3829Z" fill="white"/>
            <path d="M156.564 31.4473V33.1328H143.602V14.6973H156.171V16.3828H145.539V22.9406H155.019V24.5998H145.539V31.4473H156.564Z" fill="white"/>
            <path d="M178.887 33.1329L174.697 27.2072C174.226 27.2599 173.755 27.2862 173.231 27.2862H168.308V33.1329H166.37V14.6974H173.231C177.892 14.6974 180.72 17.0677 180.72 21.0181C180.72 23.9151 179.201 25.9431 176.53 26.8122L181.008 33.1329H178.887ZM178.782 21.0181C178.782 18.0685 176.845 16.3829 173.179 16.3829H168.308V25.627H173.179C176.845 25.627 178.782 23.9151 178.782 21.0181Z" fill="white"/>
            <path d="M188.905 23.9178C188.905 18.5188 192.99 14.542 198.516 14.542C201.187 14.542 203.596 15.4374 205.219 17.2283L203.989 18.4661C202.496 16.9386 200.663 16.2802 198.568 16.2802C194.143 16.2802 190.817 19.5459 190.817 23.9178C190.817 28.2896 194.143 31.5554 198.568 31.5554C200.663 31.5554 202.496 30.8969 203.989 29.3431L205.219 30.5809C203.596 32.3718 201.187 33.2936 198.49 33.2936C192.99 33.2936 188.905 29.3168 188.905 23.9178Z" fill="white"/>
            <path d="M225.622 28.208H215.409L213.21 33.1329H211.193L219.573 14.6974H221.485L229.864 33.1329H227.822L225.622 28.208ZM224.915 26.6278L220.516 16.7253L216.116 26.6278H224.915Z" fill="white"/>
            <path d="M238.132 14.6974H240.07V31.4474H250.335V33.1329H238.132V14.6974Z" fill="white"/>
            <path d="M258.797 14.6974H260.735V31.4474H271V33.1329H258.797V14.6974Z" fill="white"/>
          </svg>
        `;

      const logoPng = await svgToPng(logoSvg3);
      const logoImage3 = await pdfDoc.embedPng(logoPng);

      page1.drawImage(logoImage3, {
        x: 191,
        y: page1.getHeight() - 150 - 75, // Adjusted for top position
        width: 391,
        height: 75,
        opacity: 1,
      });

      const coacheeName = laraData?.client?.coachee_name || "Coachee Name";
      const coacheeCommunication =
        laraData?.client?.communication_report ||
        "No communication report available.";
      const listenScore = laraData?.client?.listen_score || 2.3;
      const respondScore = laraData?.client?.respond_score || 4.0;
      const affirmScore = laraData?.client?.affirm_score || 3.5;
      const askScore = laraData?.client?.ask_score || 1.7;

      // Extract data from fileData (session info)
      const clientName = fileData?.[0]?.client_name || "Client Name";
      const callName = fileData[0]?.call_name || "Call Name";
      const coachName = fileData[0]?.coach_name || "Coach Name";
      const zoomDate = fileData[0]?.zoom_date || new Date();
      const formattedZoomDate = dayjs(zoomDate).utc().format("MM.DD.YY");
      const duration = fileData[0]?.duration_minutes || 0;
      const dateCustom = dayjs(zoomDate).format("MMMM D, YYYY"); // Example: July 26, 2024

      // Embed a standard font (Helvetica)
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      const gradientEllipseSvg = `
      <svg width="1495" height="1474" viewBox="0 0 1495 1474" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4460_1889" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1495" height="1474">
        <rect width="1495" height="1474" rx="20" fill="url(#paint0_linear_4460_1889)"/>
      </mask>
      <g mask="url(#mask0_4460_1889)">
        <circle opacity="0.3" cx="317.881" cy="1989.57" r="1459.04" stroke="#6EA4FF"/>
        <path d="M-1081.79 2186.25C-1058.13 2364.38 -1001.27 2534.34 -912.797 2691.58L-861.788 2662.61C-946.495 2511.97 -1000.96 2349.16 -1023.63 2178.56C-1047.4 1999.53 -1034.99 1819.69 -986.696 1644.06C-938.4 1468.42 -857.133 1307.51 -745.143 1165.81C-636.975 1028.94 -504.837 915.624 -352.412 829.008C-199.988 742.392 -34.9421 686.894 138.096 663.998C317.236 640.308 497.198 652.801 672.964 701.133C848.73 749.465 1009.78 830.742 1151.62 942.695C1288.62 1050.85 1402.06 1182.94 1488.79 1335.3C1575.53 1487.66 1631.12 1652.62 1654.1 1825.55C1677.88 2004.59 1665.45 2184.42 1617.15 2360.06C1568.85 2535.69 1487.59 2696.6 1375.62 2838.31C1267.97 2974.55 1136.53 3087.43 985.006 3173.89L1012.9 3225.51C1171.51 3135.22 1309.04 3017.18 1421.69 2874.65C1538.55 2726.75 1623.36 2558.85 1673.75 2375.6C1724.14 2192.36 1737.1 2004.7 1712.29 1817.84C1688.32 1637.37 1630.29 1465.26 1539.81 1306.3C1449.34 1147.34 1330.96 1009.5 1187.99 896.652C1039.95 779.792 871.905 694.988 688.52 644.561C505.135 594.134 317.347 581.11 130.378 605.831C-50.1947 629.715 -222.399 687.643 -381.43 777.996C-540.46 868.35 -678.336 986.594 -791.206 1129.43C-908.085 1277.33 -992.879 1445.23 -1043.27 1628.48C-1093.66 1811.72 -1106.62 1999.38 -1081.81 2186.24L-1081.79 2186.25Z" fill="url(#paint1_linear_4460_1889)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4460_1889" x1="1530.81" y1="-193.08" x2="224.575" y2="153.393" gradientUnits="userSpaceOnUse">
        <stop stop-color="#03070E"/>
        <stop offset="1" stop-color="#478DFF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4460_1889" x1="-433.478" y1="621.878" x2="1518.83" y2="1401.08" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3480FF" stop-opacity="0"/>
        <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
        <stop offset="0.426471" stop-color="#3480FF"/>
        <stop offset="1"/>
        </linearGradient>
      </defs>
      </svg>
    `;

      const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
      const gradientEllipseImage = await pdfDoc.embedPng(gradientEllipsePng);

      page1.drawImage(gradientEllipseImage, {
        x: 5,
        y: page1.getHeight() - 404 - 1474, // Adjusted for top position
        width: 1495,
        height: 1474,
        opacity: 1,
      });
      // Draw the text "clientName Session Summary" in white color
      page1.drawText("L.A.R.A Analytics", {
        x: 191,
        y: page1.getHeight() - 446 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "from" in white color
      page1.drawText(`and Call Summary:`, {
        x: 191,
        y: page1.getHeight() - 546 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "callName" in orange color
      page1.drawText(`${callName || "Meeting Name"}`, {
        x: 191,
        y: page1.getHeight() - 646 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 94 / 255, 0), // Orange color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
      });

      // //need to made a line
      // const lineSvg = `
      //   <svg width="846" height="2" viewBox="0 0 846 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      //     <path d="M846 1L1.07288e-05 1" stroke="url(#paint0_linear_4460_1915)"/>
      //     <defs>
      //       <linearGradient id="paint0_linear_4460_1915" x1="846" y1="1.5" x2="0" y2="1.5" gradientUnits="userSpaceOnUse">
      //         <stop stop-color="white" stop-opacity="0"/>
      //         <stop offset="0.525" stop-color="white"/>
      //         <stop offset="1" stop-color="white" stop-opacity="0"/>
      //       </linearGradient>
      //     </defs>
      //   </svg>
      // `;

      // const linePng = await svgToPng(lineSvg);
      // const lineImage = await pdfDoc.embedPng(linePng);

      // page1.drawImage(lineImage, {
      //   x: 191,
      //   y: page1.getHeight() - 1200,
      //   width: 846,
      //   height: 2,
      //   opacity: 1,
      // });

      //profile picture
      const clientAvatarUrl = clientData[0]?.avatar || avatar; // Use imported default if no client avatar

      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page1.drawImage(avatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page1.drawImage(defaultAvatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Add text using the standard Helvetica font
      page1.drawText(`${clientName}`, {
        x: 248, // Adjusted to match the left position
        y: page1.getHeight() - 1403, // Adjusted for top position
        size: 24,
        //font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(1, 1, 1),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      console.log("user data", user);
      const coachAvatarUrl = user?.avatar || avatar; // Use imported default if no coach avatar

      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page1.drawImage(avatarImage, {
          x: 466,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page1.drawImage(defaultAvatarImage, {
          x: 466,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      page1.drawText(`${coachName}`, {
        x: 524,
        y: page1.getHeight() - 1403,
        size: 24,
        //font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(1, 1, 1),
        width: 116,
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      //draw learn more text
      // Draw the "Learn More Here" rectangle

      const learnMoreSvg = `
      <svg width="273" height="58" viewBox="0 0 273 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="white"/>
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="#FF5E00"/>
        <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" stroke="#FF5E00"/>
      </svg>
    `;

      const learnMorePng = await svgToPng(learnMoreSvg);
      const learnMoreImage = await pdfDoc.embedPng(learnMorePng);

      page1.drawImage(learnMoreImage, {
        x: 190,
        y: page1.getHeight() - 1582,
        width: 273,
        height: 58,
        opacity: 1,
      });

      // Draw the "Learn More Here" text
      page1.drawText("Learn More Here", {
        x: 220,
        y: page1.getHeight() - 1582 + 15.5, // Adjusted to center the text vertically
        size: 24,
        color: rgb(1, 1, 1), // White color
        width: 273,
        height: 58,
        lineHeight: 35,
        fontWeight: 500,
        textAlign: "center",
        opacity: 1,
      });

      // Add a hyperlink to the "Learn More Here" text
      const linkAnnotation = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [
          225,
          page1.getHeight() - 1582,
          225 + 273,
          page1.getHeight() - 1582 + 58,
        ],
        Border: [0, 0, 0],
        A: {
          Type: "Action",
          S: "URI",
          URI: "https://altercall.ai/resources?search=LARA-Scores&pageId=33&sectionId=281",
        },
      });

      // Add the annotation to the page's annotations array
      page1.node.set(
        PDFName.of("Annots"),
        pdfDoc.context.obj([linkAnnotation])
      );

      page1.drawText(`${duration} Minutes`, {
        x: 193,
        y: page1.getHeight() - 1699,
        size: 24,
        color: rgb(1, 1, 1),
        width: 332,
        height: 70,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      page1.drawText(`${dateCustom}`, {
        x: 193,
        y: page1.getHeight() - 1740,
        size: 24,
        color: rgb(1, 1, 1),
        width: 247,
        height: 30,
        lineHeight: 35, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 0
      });
      const vectorFooterwhiteSvg = `
        <svg width="49" height="52" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2723 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7167C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28891 3.33332 3.36528 7.05953 0C7.70601 0.673753 8.35337 1.34838 8.99985 2.023C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.502C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96381C40.1636 1.32139 40.8275 0.678976 41.4932 0.0356898C50.7036 8.38622 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
        </svg>
      `;

      const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
      const vectorFooterwhiteImage = await pdfDoc.embedPng(
        vectorFooterwhitePng
      );

      page1.drawImage(vectorFooterwhiteImage, {
        x: 1378,
        y: page1.getHeight() - 1852,
        width: 48.64,
        height: 52,
        opacity: 1,
      });

      //page2
      const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
      page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: page2.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      const page2BackgroundSvg = `
      <svg width="1500" height="781" viewBox="0 0 1500 781" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4906_247)">
        <rect width="1500" height="1863" fill="url(#paint0_linear_4906_247)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4906_247" x1="1767" y1="793" x2="-274.986" y2="-1890.46" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="0.50204" stop-color="#3480FF" stop-opacity="0.86"/>
        <stop offset="1" stop-color="white" stop-opacity="0.1"/>
        </linearGradient>
        <clipPath id="clip0_4906_247">
        <rect width="1500" height="781" fill="white"/>
        </clipPath>
      </defs>
      </svg>
    `;

      const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
      const page2BackgroundImage = await pdfDoc.embedPng(page2BackgroundPng);

      // Draw the gradient background from y = 0 to y = 781
      page2.drawImage(page2BackgroundImage, {
        x: 0,
        y: page2.getHeight() - 781, // Start from the top
        width: page2.getWidth(), // Full width of the page
        height: 781, // Height of 781
        opacity: 1, // Set opacity to 1
      });
      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 780 - 16, // Positioned at the top of the white rectangle
        width: 1500, // Full width of the page
        height: 16, // Height of 16px
        color: rgb(1, 94 / 255, 0), // Orange color (rgba(255, 94, 0, 1))
        opacity: 1, // Set opacity to 1
      });

      // Draw a white rectangle for the rest of the page
      page2.drawRectangle({
        x: 0,
        y: 0, // Start from the bottom
        width: page2.getWidth(), // Full width of the page
        height: page2.getHeight() - 780, // Height from y = 0 to y = 1040
        color: rgb(1, 1, 1), // White color
      });

      const circleSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="331.881" cy="1289.57" r="1459.04" stroke="#6EA4FF"/>
      </svg>
    `;

      const circlePng = await svgToPng(circleSvg);
      const circleImage = await pdfDoc.embedPng(circlePng);

      page2.drawImage(circleImage, {
        x: -1127.66,
        y: page2.getHeight() + 169.97 - 2919.09,
        width: 2919.09,
        height: 2919.09,
        opacity: 0.3,
      });

      const newSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-1067.79 1486.25C-1044.13 1664.38 -987.271 1834.34 -898.797 1991.58L-847.788 1962.61C-932.495 1811.97 -986.962 1649.16 -1009.63 1478.56C-1033.4 1299.53 -1020.99 1119.69 -972.696 944.055C-924.4 768.423 -843.133 607.512 -731.143 465.812C-622.975 328.94 -490.837 215.624 -338.412 129.008C-185.988 42.3917 -20.9421 -13.1061 152.096 -36.0016C331.236 -59.6924 511.198 -47.199 686.964 1.13296C862.73 49.4649 1023.78 130.742 1165.62 242.695C1302.62 350.846 1416.06 482.942 1502.79 635.3C1589.53 787.659 1645.12 952.619 1668.1 1125.55C1691.88 1304.59 1679.45 1484.42 1631.15 1660.06C1582.85 1835.69 1501.59 1996.6 1389.62 2138.31C1281.97 2274.55 1150.53 2387.43 999.006 2473.89L1026.9 2525.51C1185.51 2435.22 1323.04 2317.18 1435.69 2174.65C1552.55 2026.75 1637.36 1858.85 1687.75 1675.6C1738.14 1492.36 1751.1 1304.7 1726.29 1117.84C1702.32 937.375 1644.29 765.26 1553.81 606.297C1463.34 447.335 1344.96 309.505 1201.99 196.652C1053.95 79.7918 885.905 -5.01182 702.52 -55.4388C519.135 -105.866 331.347 -118.89 144.378 -94.1694C-36.1947 -70.2849 -208.399 -12.3573 -367.43 77.9963C-526.46 168.35 -664.336 286.594 -777.206 429.43C-894.085 577.326 -978.879 745.233 -1029.27 928.479C-1079.66 1111.72 -1092.62 1299.38 -1067.81 1486.24L-1067.79 1486.25Z" fill="url(#paint0_linear_4906_250)"/>
        <defs>
          <linearGradient id="paint0_linear_4906_250" x1="-419.478" y1="-78.1218" x2="1532.83" y2="701.085" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3480FF" stop-opacity="0"/>
            <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
            <stop offset="0.426471" stop-color="#3480FF"/>
            <stop offset="1"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const newSvgPng = await svgToPng(newSvg);
      const newSvgImage = await pdfDoc.embedPng(newSvgPng);

      page2.drawImage(newSvgImage, {
        x: 1424.02,
        y: page2.getHeight() - 2634.7,
        width: 2817.87,
        height: 2402.57,
        opacity: 1,
        rotate: degrees(164.62),
      });

      page2.drawImage(logoImage3, {
        x: 80,
        y: page2.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });

      //make a container
      // Outer container
      const containerSvg = `
  <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
  </svg>
`;

      const containerPng = await svgToPng(containerSvg);
      const containerImage = await pdfDoc.embedPng(containerPng);

      page2.drawImage(containerImage, {
        x: 952,
        y: page2.getHeight() - 22 - 65, // Adjusted for top position
        width: 486,
        height: 65,
        opacity: 1,
      });

      // Inner container
      const innerContainerSvg = `
  <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
  </svg>
`;

      const innerContainerPng = await svgToPng(innerContainerSvg);
      const innerContainerImage = await pdfDoc.embedPng(innerContainerPng);

      page2.drawImage(innerContainerImage, {
        x: 956,
        y: page2.getHeight() - 26 - 57, // Adjusted for top position
        width: 478,
        height: 57,
        opacity: 1,
      });

      // Draw client avatar

      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch client avatar.");
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page2.drawImage(avatarImage, {
          x: 966,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page2.drawImage(defaultAvatarImage, {
          x: 966,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      }

      // Draw client name
      page2.drawText(`${clientName}`, {
        x: 1024, // Adjusted to match the left position
        y: page2.getHeight() - 36 - 30, // Adjusted for top position
        size: 24,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      // Draw coach avatar
      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch coach avatar.");
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page2.drawImage(avatarImage, {
          x: 1229,
          y: page2.getHeight() - 32 - 45,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page2.drawImage(defaultAvatarImage, {
          x: 1229,
          y: page2.getHeight() - 32 - 45,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Draw coach name
      page2.drawText(`${coachName}`, {
        x: 1287, // Adjusted to match the left position
        y: page2.getHeight() - 36 - 30, // Adjusted for top position
        size: 24,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0),
        width: 116, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      page2.drawText("L.A.R.A Score Overview", {
        x: 432, // Start of the text inside the box, centered
        y: page2.getHeight() - 217 - 35, // Adjust the Y position to center text in the box
        size: 67,
        // Ensure Helvetica font is used or provide an alternative
        color: rgb(1, 1, 1), // White color for the text
        width: 733, // Width of the text should match the box
        height: 75, // Height of the text area should match the box
        lineHeight: 75, // Line height to center text vertically
        fontWeight: 300,
        textAlign: "left", // Left align the text within the box
        opacity: 1,
      });

      const overallScore = laraData?.client?.overall_score || 3.5;
      const score1 = overallScore * 20;

      const generatePieChartSvg = (score1) => {
        const colors = [
          "#FF0000", // 0-10
          "#FF4500", // 10-20
          "#FF8C00", // 20-30
          "#FFD700", // 30-50
          "#ADFF2F", // 50-70
          "#7FFF00", // 70-80
          "#32CD32", // 80-90
          "#00FF00", // 90-95
          "#00FA9A", // 95-100
        ];

        const getColor = (score1) => {
          if (score1 <= 10) return colors[0];
          if (score1 <= 20) return colors[1];
          if (score1 <= 30) return colors[2];
          if (score1 <= 50) return colors[3];
          if (score1 <= 70) return colors[4];
          if (score1 <= 80) return colors[5];
          if (score1 <= 90) return colors[6];
          if (score1 <= 95) return colors[7];
          return colors[8];
        };

        // Multiply score by 20 to scale from 1-5 to 20-100
        let scaledScore = score1 * 20;
        scaledScore = Math.round(scaledScore); // Round off the score

        const color = getColor(scaledScore);
        const angle = (scaledScore / 100) * 360;

        // Set large-arc-flag to 1 if angle > 180
        const largeArcFlag = angle > 180 ? 1 : 0;

        const x = 84 + 76 * Math.cos((Math.PI * (angle - 90)) / 180);
        const y = 84 + 76 * Math.sin((Math.PI * (angle - 90)) / 180);

        return `
            <svg width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" style="border-radius: 39px 0px 0px 0px; box-shadow: 0px 3px 4px 0px #0000000D;">
              <circle 
                cx="84" 
                cy="84" 
                r="76" 
                fill="white" 
                stroke="#D3D3D3" 
                stroke-width="8"
              />
              <path 
                d="M84,8 
                   A 76 76 0 ${largeArcFlag} 1 ${x} ${y}"
                stroke="${color}" 
                stroke-width="8"
                fill="none" 
                stroke-linecap="round"
                transform="rotate(-90 84 84)"
              />
              <text 
                x="84" 
                y="84" 
                text-anchor="middle" 
                alignment-baseline="middle" 
                fill="black"
                font-size="78" 
                font-family="lexend"
                opacity="0.7"
                font-weight="300"
                line-height="26"
                text-align="center"
              >
                ${scaledScore} <!-- Rounded off score -->
              </text>
            </svg>
          `;
      };

      const pieChartSvg = generatePieChartSvg(overallScore);

      const pieChartPng = await svgToPng(pieChartSvg);
      const pieChartImage = await pdfDoc.embedPng(pieChartPng);

      page2.drawImage(pieChartImage, {
        x: 115,
        y: page2.getHeight() - 244 - 148,
        width: 168,
        height: 168,
        opacity: 1,
      });

      const wrapText = (text, maxWidth, fontSize, font) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = font.widthOfTextAtSize(
            currentLine + " " + word,
            fontSize
          );
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };
      const coachCommunicationText =
        coacheeCommunication || "No communication report available.";
      const coachFontSize = 20;
      const coachMaxWidth = 894; // Updated width according to layout
      const coachLines = wrapText(
        coachCommunicationText,
        coachMaxWidth,
        coachFontSize,
        helveticaFont
      );

      // Calculate the total height needed for the coach communication text
      const coachTotalHeight = coachLines.length * coachFontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let coachCurrentY = page2.getHeight() - 320; // Updated top position according to layout

      coachLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 432, // Updated left position according to layout
          y: coachCurrentY - index * coachFontSize * 1.2,
          size: coachFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: coachMaxWidth,
          height: 126, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1, // Updated opacity according to layout
          letterSpacing: -0.03,
        });
      });

      // Update the currentY position for the next section
      //currentY -= coachTotalHeight;

      // Update the currentY position for the next section
      //currentY -= coachTotalHeight;

      // Score Analysis
      const getPieChartColor = (score) => {
        if (score >= 1 && score <= 20) return "#DA0000"; // Red
        if (score >= 21 && score <= 40) return "#F90"; // Orange
        if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
        if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
        if (score >= 71 && score <= 80) return "#70CD27"; // Green
        if (score >= 81 && score <= 95) return "#4BC500"; // Green
        if (score >= 95 && score <= 100) return "#37AB00"; // Green
        return "#D3D3D3"; // Grey for undefined or zero
      };

      const scoreAnalysis = [
        { label: "Listen", score: listenScore * 20 },
        { label: "Respond", score: respondScore * 20 },
        { label: "Affirm", score: affirmScore * 20 },
        { label: "Ask", score: askScore * 20 },
      ];

      const drawPieChart = async (score) => {
        const angle = (score / 100) * 360;
        const color = getPieChartColor(score);
        // Calculate start and end points for the arc
        const startX = 66.5 + 59 * Math.cos((Math.PI * -90) / 180);
        const startY = 66.5 + 59 * Math.sin((Math.PI * -90) / 180);
        const endX = 66.5 + 59 * Math.cos((Math.PI * (angle - 90)) / 180);
        const endY = 66.5 + 59 * Math.sin((Math.PI * (angle - 90)) / 180);

        const largeArcFlag = angle > 180 ? 1 : 0;

        const pieSvg = `
          <svg width="133" height="133" viewBox="0 0 133 133" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="filter0_d" x="0" y="0" width="133" height="133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
            </defs>
            <g transform="rotate(-90 66.5 66.5)">
              <circle cx="66.5" cy="66.5" r="59" stroke="#D3D3D3" stroke-width="6" fill="white"/>
              <path d="M${startX},${startY} A 59,59 0 ${largeArcFlag} 1 ${endX},${endY}" stroke="${color}" stroke-width="6" fill="none"/>
            </g>
            <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="54" font-family="lexend" opacity="0.7" dy=".3em">${score}</text>
          </svg>
        `;

        const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      const baseX = 120; // Updated x position
      const baseY = page2.getHeight() - 504; // Updated y position
      const gap = 350; // Updated gap between each pie chart

      for (const [index, item] of scoreAnalysis.entries()) {
        const xPosition = baseX + index * gap;

        const pieImagePng = await drawPieChart(item.score);
        const pieImageEmbedded = await pdfDoc.embedPng(pieImagePng);

        page2.drawImage(pieImageEmbedded, {
          x: xPosition,
          y: baseY - 119,
          width: 133, // Updated width
          height: 133, // Updated height
          opacity: 1,
        });

        // Draw the label next to the pie chart
        page2.drawText(item.label, {
          x: xPosition + 150, // Increased x position
          y: baseY - 70, // Decreased y position
          size: 35, // Updated size
          color: rgb(1, 1, 1), // Changed color to white
          width: 115,
          height: 46,
          textAlign: "left",
          opacity: 1,
          lineHeight: 48,
          fontWeight: 300,
          letterSpacing: -0.02,
        });
      }
      page2.drawRectangle({
        x: 340,
        y: page2.getHeight() - 1853,
        width: 1067,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(90),
      });

      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 780 - 16,
        width: 1500,
        height: 16,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        opacity: 1,
      });

      //call summary heading
      page2.drawText("Call Summary", {
        x: 426,
        y: page2.getHeight() - 890,
        size: 48,
        // Assuming Poppins is not available in PDFKit, using helveticaFont
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        fontFamily: "Lexend",
        width: 283,
        height: 48,
        lineHeight: 48,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });

      const quickRecapText = quickRecap || "No quick recap available.";
      const fontSize = 20;
      const maxWidth = 901; // Updated width
      const lines = wrapText(quickRecapText, maxWidth, fontSize, helveticaFont);

      // Calculate the total height needed for the quick recap text
      const totalHeight = lines.length * fontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let currentY = page2.getHeight() - 957; // Updated top position

      lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 426, // Updated left position
          y: currentY - index * fontSize * 1.2,
          size: fontSize,
          fontFamily: "Lexend",
          color: rgb(0, 0, 0), // White color
          width: 901, // Updated width
          height: 126, // Updated height
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
        });
      });

      // Update the currentY position for the next section
      currentY -= totalHeight;

      page2.drawText("ACTIONS", {
        x: 421, // Adjusted left position
        y: page2.getHeight() - 1165, // Adjusted top position
        size: 41, // Adjusted font size
        color: rgb(86 / 255, 143 / 255, 243 / 255), // Adjusted color
        width: 395, // Adjusted width
        height: 57, // Adjusted height
        lineHeight: 48, // Adjusted line height
        textAlign: "left", // Left align the text
        fontFamily: "Poppins", // Adjusted font family
        fontWeight: 400, // Adjusted font weight
        opacity: 1, // Adjusted opacity
        letterSpacing: -0.02, // Adjusted letter spacing
      });

      currentY = page2.getHeight() - 1238;

      const maxY = page2.getHeight() - 1800;
      const minFontSize = 20;
      let minLineHeight = 9;
      if (actionsArray.length < 7) {
        minLineHeight = 18;
      }

      actionsArray.forEach((action, index) => {
        let fontSize = 20;
        let lineHeight = 28; // Updated line height to 28px
        let wrappedLines = wrapText(
          action.action,
          951,
          fontSize,
          helveticaFont
        );

        // Check if the currentY position will overlap with the next section
        while (
          currentY - wrappedLines.length * lineHeight < maxY &&
          fontSize > minFontSize
        ) {
          fontSize -= 1;
          lineHeight -= 1;
          wrappedLines = wrapText(action.action, 951, fontSize, helveticaFont);
        }

        if (currentY - wrappedLines.length * lineHeight < maxY) {
          return;
        }

        wrappedLines.forEach((line, lineIndex) => {
          page2.drawText(`${lineIndex === 0 ? "• " : "  "}${line}`, {
            x: 413,
            y: currentY - lineIndex * lineHeight,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            width: 951,
            height: 382,
            gap: 0,
            opacity: 1,
            fontFamily: "Lexend",
            fontWeight: 400,
            lineHeight: lineHeight,
            letterSpacing: -0.03, // Updated letter-spacing to -0.03em
            textAlign: "left",
          });
        });

        currentY -= wrappedLines.length * lineHeight + lineHeight; // Move to the next bullet point position
      });

      /////////////////////////////
      // Draw a container from y = 1042 to y = 1072 with full width and specified color

      // Draw the text "Score Overview:" over the box

      // Draw a fixed rectangle
      page2.drawRectangle({
        x: 27,
        y: page2.getHeight() - 868 - 44, // Adjusted Y position
        width: 8,
        height: 44,
        color: rgb(255 / 255, 94 / 255, 0 / 255), // rgba(255, 94, 0, 1)
        opacity: 1,
      });
      page2.drawText("Your Report", {
        x: 55,
        y: page2.getHeight() - 900,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(160 / 255, 160 / 255, 160 / 255),
        width: 225,
        height: 290,
        lineHeight: 24,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });
      const text3 =
        "Continue reading the report to learn about each of your scores and our suggested improvements to your communication.";
      const text1Lines = wrapText(text3, 225, 20, helveticaFont);

      text1Lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 55,
          y: page2.getHeight() - 955 - index * 24, // Adjusted y position to be 20 more y
          size: 20,
          font: helveticaFont,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });
      // Draw a rectangle with the specified properties
      page2.drawRectangle({
        x: -38,
        y: page2.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });

      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page2.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page2.drawText("www.altercall.com/aai", {
        x: 770,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 1),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page2.drawText("02", {
        x: 1437,
        y: page2.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        width: 18,
        height: 19,
        opacity: 1,
      });
      const vectorFooterBlackSvg = `
      <svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
      </svg>
    `;

      const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
      const vectorFooterBlackImage = await pdfDoc.embedPng(
        vectorFooterBlackPng
      );

      page2.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page2.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });
      //page 3 page 4 page 5 page 6
      const sections = [
        {
          title: "Ask",
          score: laraData?.client?.ask_score ?? 2.4,
          report: laraData?.client?.ask_report ?? "",
          areaOfImprovement: laraData?.client?.ask_area_of_improvement ?? "",
          details: laraData?.client?.ask_details ?? "",
          strengths: laraData?.client?.ask_strengths ?? "",
          weaknesses: laraData?.client?.ask_weaknesses ?? "",
          page: "03",
        },
        {
          title: "Respond",
          score: laraData?.client?.respond_score ?? 1.7,
          report: laraData?.client?.respond_report ?? "",
          areaOfImprovement:
            laraData?.client?.respond_area_of_improvement ?? "",
          details: laraData?.client?.respond_details ?? "",
          strengths: laraData?.client?.respond_strengths ?? "",
          weaknesses: laraData?.client?.respond_weaknesses ?? "",
          page: "04",
        },
        {
          title: "Affirm",
          score: laraData?.client?.affirm_score ?? 3.8,
          report: laraData?.client?.affirm_report ?? "",
          areaOfImprovement: laraData?.client?.affirm_area_of_improvement ?? "",
          details: laraData?.client?.affirm_details ?? "",
          strengths: laraData?.client?.affirm_strengths ?? "",
          weaknesses: laraData?.client?.affirm_weaknesses ?? "",
          page: "05",
        },
        {
          title: "Listen",
          score: laraData?.client?.listen_score ?? 3.0,
          report: laraData?.client?.listen_report ?? "",
          areaOfImprovement: laraData?.client?.listen_area_of_improvement ?? "",
          details: laraData?.client?.listen_details ?? "",
          strengths: laraData?.client?.listen_strengths ?? "",
          weaknesses: laraData?.client?.listen_weaknesses ?? "",
          page: "06",
        },
      ];

      for (const [index, section] of sections.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size

        //background color width full and height 106
        const svgGradient = `
        <svg width="1500" height="490" viewBox="0 0 1500 490" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H1500V490H0V0Z" fill="url(#paint0_linear_4460_2071)"/>
          <defs>
        <linearGradient id="paint0_linear_4460_2071" x1="1298.72" y1="50.7345" x2="868.339" y2="1030.56" gradientUnits="userSpaceOnUse">
          <stop/>
          <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
          </defs>
        </svg>
      `;

        const svgGradientPng = await svgToPng(svgGradient);
        const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

        page.drawImage(svgGradientImage, {
          x: 0,
          y: page.getHeight() - 490,
          width: page.getWidth(),
          height: 490,
          opacity: 1,
        });
        // Embed the altercall.ai logo
        page.drawImage(logoImage3, {
          x: 80,
          y: page.getHeight() - 77, // Adjusted for top position
          width: 229,
          height: 44,
        });
        const svgRectangle = `
        <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
        </svg>
      `;

        const svgRectanglePng = await svgToPng(svgRectangle);
        const svgRectangleImage = await pdfDoc.embedPng(svgRectanglePng);

        page.drawImage(svgRectangleImage, {
          x: 952,
          y: page2.getHeight() - 26 - 65, // Adjusted for top position
          width: 486,
          height: 65,
          opacity: 1,
        });
        // Inner container
        const svgInnerContainer = `
        <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
        </svg>
      `;

        const svgInnerContainerPng = await svgToPng(svgInnerContainer);
        const svgInnerContainerImage = await pdfDoc.embedPng(
          svgInnerContainerPng
        );

        page.drawImage(svgInnerContainerImage, {
          x: 956,
          y: page2.getHeight() - 26 - 62, // Adjusted for top position
          width: 478,
          height: 57,
          opacity: 1,
        });

        // Client avatar
        try {
          const response = await fetch(clientAvatarUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch avatar image: ${response.statusText}`
            );
          }
          const avatarImageBytes = await response.arrayBuffer();
          const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
          page.drawImage(avatarImage, {
            x: 966,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        } catch (error) {
          console.error("Error fetching or embedding avatar image:", error);
          const defaultAvatarBytes = await fetch(avatar).then((res) =>
            res.arrayBuffer()
          );
          const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
          page.drawImage(defaultAvatarImage, {
            x: 966,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        }

        // Client name
        page.drawText(`${clientName}`, {
          x: 1024,
          y: page.getHeight() - 65,
          size: 24,
          color: rgb(0, 0, 0),
          width: 170,
          fontFamily: "Lexend",
          height: 30,
          lineHeight: 30,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
        });

        // Coach avatar
        try {
          const response = await fetch(coachAvatarUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch avatar image: ${response.statusText}`
            );
          }
          const avatarImageBytes = await response.arrayBuffer();
          const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
          page.drawImage(avatarImage, {
            x: 1229,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        } catch (error) {
          console.error("Error fetching or embedding avatar image:", error);
          const defaultAvatarBytes = await fetch(avatar).then((res) =>
            res.arrayBuffer()
          );
          const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
          page.drawImage(defaultAvatarImage, {
            x: 1229,
            y: page.getHeight() - 32 - 50,
            width: 45,
            height: 45,
            opacity: 1,
          });
        }

        // Coach name
        page.drawText(`${coachName}`, {
          x: 1287,
          y: page.getHeight() - 65,
          size: 24,
          color: rgb(0, 0, 0),
          fontFamily: "Lexend",
          width: 116,
          height: 30,
          lineHeight: 30,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
        });

        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1853,
          width: 1373,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 0,
          rotate: degrees(90),
        });

        // Draw pie chart for the section
        const drawSectionPieChart = async (score) => {
          score = score * 20;
          const radius = 82;
          const textSize = "78px";
          const strokeWidth = 8;

          // Color mapping logic - adjust based on your needs
          const color = getPieChartColor(score);

          const circ = 2 * Math.PI * radius;
          const filledPortion = (score / 100) * circ;
          const strokeDashoffset = circ - filledPortion;

          const pieSvg = `
          <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="shadow" x="-4" y="-4" width="192" height="192" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feDropShadow dx="0" dy="3" stdDeviation="2" flood-opacity="0.05" result="shadow"/>
                <feBlend in="SourceGraphic" in2="shadow" mode="normal"/>
              </filter>
            </defs>
            <g filter="url(#shadow)">
              <circle 
                cx="92" 
                cy="92" 
                r="${radius}" 
                fill="white" 
                stroke="#EBEBEB" 
                stroke-width="6" 
                stroke-linecap="round"
              />
              <circle 
                cx="92" 
                cy="92" 
                r="${radius}" 
                fill="white" 
                stroke="${color}" 
                stroke-width="${strokeWidth}" 
                stroke-linecap="round"
                stroke-dasharray="${circ}" 
                stroke-dashoffset="${strokeDashoffset}" 
                transform="rotate(-90 92 92)" 
              />
            </g>
            <text 
              x="50%" 
              y="50%" 
              text-anchor="middle" 
              font-size="${textSize}" 
              font-family="lexend"
              opacity="0.7"
              font-weight="300" 
              fill="black" 
              dy=".3em"
            >
              ${isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
            </text>
          </svg>
        `;

          const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
          const url = URL.createObjectURL(svgBlob);
          const img = new Image();
          img.src = url;
          await new Promise((resolve) => (img.onload = resolve));
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(url);
          return canvas.toDataURL("image/png");
        };
        const sectionPieChartPng = await drawSectionPieChart(
          section.score || 0
        );
        const sectionPieChartImage = await pdfDoc.embedPng(sectionPieChartPng);

        page.drawImage(sectionPieChartImage, {
          x: 90,
          y: page.getHeight() - 202 - 148,
          width: 184,
          height: 184,
          opacity: 1,
        });
        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: 0,
          y: page.getHeight() - 490,
          width: 1500,
          height: 16,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 590,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 579,
          size: 20,
          font: helveticaFont,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // Changed to #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the criteria text
        const criteriaText =
          "Active listening, avoiding interruptions, recognizing themes, and understanding non-verbal cues. concerns, ideas, and emotions without interrupting or steering the conversation.";
        const criteriaFontSize = 20;
        const criteriaMaxWidth = 225;
        const criteriaLines = wrapText(
          criteriaText,
          criteriaMaxWidth,
          criteriaFontSize,
          helveticaFont
        );

        let criteriaCurrentY = page.getHeight() - 640; // Adjusted for top position

        criteriaLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55,
            y: criteriaCurrentY - index * criteriaFontSize * 1.2,
            size: criteriaFontSize,
            font: helveticaFont,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // Changed to #A0A0A0
            width: criteriaMaxWidth,
            height: criteriaFontSize * 1.2,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });
        });

        //report part

        page.drawText(section.title, {
          x: 456,
          y: page.getHeight() - 205,
          size: 67,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: 196,
          height: 75,
          opacity: 1,
          fontFamily: "Poppins",
          fontWeight: 300,
          lineHeight: 75,
          textAlign: "left",
        });

        // Coach session report
        const coachSessionReport =
          section.report ||
          `No coachee ${section.title.toLowerCase()} report available.`;
        const coachReportFontSize = 20;
        const coachReportMaxWidth = 970;
        const coachReportLines = wrapText(
          coachSessionReport,
          coachReportMaxWidth,
          coachReportFontSize,
          helveticaFont
        );

        let coachReportY = page.getHeight() - 250; // Adjusted for top position

        coachReportLines.forEach((line, index) => {
          page.drawText(line, {
            x: 456,
            y: coachReportY - index * coachReportFontSize * 1.2,
            size: coachReportFontSize,
            color: rgb(1, 1, 1),
            width: coachReportMaxWidth,
            height: 84, // Adjusted height
            lineHeight: 42,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted opacity
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
        });

        //section partss
        // Strengths

        const arrowUpRightStrengthsSvg = `
        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.4226 27.1425L65.0902 27.142M65.0902 27.142L65.0902 59.345M65.0902 27.142L27.1422 65.0901" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;
        const arrowUpRightStrengthsPng = await svgToPng(
          arrowUpRightStrengthsSvg
        );
        const arrowUpRightStrengthsImage = await pdfDoc.embedPng(
          arrowUpRightStrengthsPng
        );

        page.drawImage(arrowUpRightStrengthsImage, {
          x: 392,
          y: page.getHeight() - 576 - 92,
          width: 92,
          height: 92,
          opacity: 1,
        });
        page.drawText("STRENGTHS", {
          x: 504,
          y: page.getHeight() - 594,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255),
          width: 228,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: 2,
          fontFamily: "Lexend",
        });
        const strengths =
          section.strengths ||
          `No coachee ${section.title.toLowerCase()} strengths data available.`;
        const maxWidth1 = 990;
        let currentY = page.getHeight() - 632;

        let wrappedLines = wrapText(
          strengths,
          maxWidth1 - 30,
          18,
          helveticaFont
        );
        let totalHeight = wrappedLines.length * 24;

        // Check if the total height exceeds the limit
        if (currentY - totalHeight < 920) {
          // Decrease font size to fit the text
          wrappedLines = wrapText(strengths, maxWidth1 - 30, 16, helveticaFont);
          totalHeight = wrappedLines.length * 20;
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 507,
            y: currentY - index * (totalHeight / wrappedLines.length),
            size: currentY - totalHeight < 920 ? 16 : 18,
            color: rgb(0, 0, 0), // Changed to black
            width: maxWidth1,
            height: totalHeight / wrappedLines.length,
            lineHeight: totalHeight / wrappedLines.length,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
        });
        currentY -= totalHeight;

        // Weaknesses
        const arrowDownRightWeaknessesSvg = `
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M58.2816 63.4476L26.3242 63.448M26.3242 63.448L26.3242 31.9451M26.3242 63.448L63.4473 26.3249" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
        const arrowDownRightWeaknessesPng = await svgToPng(
          arrowDownRightWeaknessesSvg
        );
        const arrowDownRightWeaknessesImage = await pdfDoc.embedPng(
          arrowDownRightWeaknessesPng
        );

        page.drawImage(arrowDownRightWeaknessesImage, {
          x: 392,
          y: page.getHeight() - 896 - 100,
          width: 92,
          height: 92,
          opacity: 1,
          // rotate: degrees(-135),
        });
        page.drawText("WEAKNESSES", {
          x: 515,
          y: page.getHeight() - 936,
          size: 26,
          fontFamily: "Lexend",
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 343,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: 2,
        });
        const weaknesses =
          section.weaknesses ||
          `No coachee ${section.title.toLowerCase()} weaknesses data available.`;
        currentY = page.getHeight() - 976;

        wrappedLines = wrapText(weaknesses, 990 - 20, 18, helveticaFont);
        totalHeight = wrappedLines.length * 24;

        // Check if the total height exceeds the limit
        if (currentY - totalHeight < 1200) {
          // Decrease font size to fit the text
          wrappedLines = wrapText(weaknesses, 990 - 20, 16, helveticaFont);
          totalHeight = wrappedLines.length * 20;
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 506,
            y: currentY - index * (totalHeight / wrappedLines.length),
            size: currentY - totalHeight < 1200 ? 16 : 18,
            color: rgb(0, 0, 0), // Replaced with rgb equivalent of #000000
            width: 776,
            height: totalHeight / wrappedLines.length,
            lineHeight: totalHeight / wrappedLines.length,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
        });
        currentY -= totalHeight;

        // Areas for Improvement
        const improvementSvg = `
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.79883 6.80002V61.2H61.1988M20.3988 40.8004L32.2988 28.9004L40.7988 37.4004L59.4988 18.7004M46.7764 17H61.2014V31.425" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;

        const improvementPng = await svgToPng(improvementSvg);
        const improvementImage = await pdfDoc.embedPng(improvementPng);

        page.drawImage(improvementImage, {
          x: 407,
          y: page.getHeight() - 1211 - 68,
          width: 68,
          height: 68,
          opacity: 1,
        });
        page.drawText("IMPROVEMENTS", {
          x: 515,
          y: page.getHeight() - 1240,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 325,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: 2,
        });
        const areasForImprovement =
          section.areaOfImprovement ||
          "No coachee areas for improvement available.";
        currentY = page.getHeight() - 1280;

        wrappedLines = wrapText(
          areasForImprovement,
          990 - 30,
          18,
          helveticaFont
        );
        totalHeight = wrappedLines.length * 24;

        // Check if the total height exceeds the limit
        if (currentY - totalHeight < 1570) {
          // Decrease font size to fit the text
          wrappedLines = wrapText(
            areasForImprovement,
            990 - 30,
            16,
            helveticaFont
          );
          totalHeight = wrappedLines.length * 20;
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 511,
            y: currentY - index * (totalHeight / wrappedLines.length),
            size: currentY - totalHeight < 1570 ? 16 : 18,
            color: rgb(0, 0, 0), // Changed to black
            width: 824,
            height: totalHeight / wrappedLines.length,
            lineHeight: totalHeight / wrappedLines.length,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
          });
        });
        currentY -= totalHeight;
        // Details
        const listSvg = `
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.29923 9.45001L56.6992 9.45001M6.29923 23.1L43.3751 23.1M6.29923 36.75L56.6992 36.75M6.29923 50.4L33.2372 50.4" stroke="#FF5E00" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;

        const listSvgPng = await svgToPng(listSvg);
        const listSvgImage = await pdfDoc.embedPng(listSvgPng);

        page.drawImage(listSvgImage, {
          x: 412,
          y: page.getHeight() - 1576 - 63,
          width: 63,
          height: 63,
          opacity: 1,
        });
        page.drawText("DETAILS", {
          x: 515,
          y: page.getHeight() - 1589,
          size: 26,
          color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
          width: 172,
          height: 39,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          fontFamily: "Lexend",
          opacity: 1,
          letterSpacing: 2,
        });
        const details = section.details || "No coachee details data available.";
        currentY = page.getHeight() - 1628;

        wrappedLines = wrapText(details, 990 - 30, 18, helveticaFont);
        totalHeight = wrappedLines.length * 24;

        // Check if the total height exceeds the limit
        if (currentY - totalHeight < 1840) {
          // Decrease font size to fit the text
          wrappedLines = wrapText(details, 990 - 30, 16, helveticaFont);
          totalHeight = wrappedLines.length * 20;
        }

        wrappedLines.forEach((line, index) => {
          page.drawText(line, {
            x: 511,
            y: currentY,
            size: currentY - totalHeight < 1840 ? 16 : 18,
            color: rgb(0, 0, 0), // Changed to black
            width: 784,
            height: totalHeight / wrappedLines.length,
            lineHeight: totalHeight / wrappedLines.length,
            textAlign: "left",
            opacity: 1,
            fontFamily: "Lexend",
            letterSpacing: -0.03,
            fontWeight: 400,
          });
          currentY -= totalHeight / wrappedLines.length;
        });

        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1853,
          width: 1606,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about L.A.R.A at", {
          x: 499,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          fontFamily: "Lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 770,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 1),
          fontFamily: "Lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });
        const pageno = section.page;
        page.drawText(pageno, {
          x: 1437,
          y: page2.getHeight() - 1933,
          size: 18,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          width: 18,
          height: 19,
          opacity: 1,
        });
        const vectorFooterBlackSvg = `
      <svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
      </svg>
    `;

        const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
        const vectorFooterBlackImage = await pdfDoc.embedPng(
          vectorFooterBlackPng
        );

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });
      }
      const page5 = pdfDoc.addPage([1500, 1974]);
      //background color width full and height 106
      const svgGradient = `
    <svg width="1500" height="106" viewBox="0 0 1500 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H1500V106H0V0Z" fill="url(#paint0_linear_4460_2071)"/>
      <defs>
        <linearGradient id="paint0_linear_4460_2071" x1="1298.72" y1="10.9752" x2="1274.91" y2="261.569" gradientUnits="userSpaceOnUse">
          <stop/>
          <stop offset="1" stop-color="#3480FF"/>
        </linearGradient>
      </defs>
    </svg>
  `;

      const svgGradientPng = await svgToPng(svgGradient);
      const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

      page5.drawImage(svgGradientImage, {
        x: 0,
        y: page5.getHeight() - 106,
        width: page5.getWidth(),
        height: 106,
        opacity: 1,
      });
      // Embed the altercall.ai logo
      page5.drawImage(logoImage3, {
        x: 80,
        y: page5.getHeight() - 77, // Adjusted for top position
        width: 229,
        height: 44,
      });
      const svgRectangle = `
    <svg width="486" height="65" viewBox="0 0 486 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="485" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
    </svg>
  `;

      const svgRectanglePng = await svgToPng(svgRectangle);
      const svgRectangleImage = await pdfDoc.embedPng(svgRectanglePng);

      page5.drawImage(svgRectangleImage, {
        x: 952,
        y: page5.getHeight() - 26 - 65, // Adjusted for top position
        width: 486,
        height: 65,
        opacity: 1,
      });

      // Inner container
      const svgInnerContainer = `
    <svg width="478" height="57" viewBox="0 0 478 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="477" height="56" rx="28" fill="white" stroke="white"/>
    </svg>
  `;

      const svgInnerContainerPng = await svgToPng(svgInnerContainer);
      const svgInnerContainerImage = await pdfDoc.embedPng(
        svgInnerContainerPng
      );

      page5.drawImage(svgInnerContainerImage, {
        x: 956,
        y: page5.getHeight() - 26 - 62, // Adjusted for top position
        width: 478,
        height: 57,
        opacity: 1,
      });

      // Client avatar
      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page5.drawImage(avatarImage, {
          x: 966,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page5.drawImage(defaultAvatarImage, {
          x: 966,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Client name
      page5.drawText(`${clientName}`, {
        x: 1024,
        y: page5.getHeight() - 65,
        size: 24,
        color: rgb(0, 0, 0),
        width: 170,
        fontFamily: "Lexend",
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Coach avatar
      try {
        const response = await fetch(coachAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page5.drawImage(avatarImage, {
          x: 1229,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page5.drawImage(defaultAvatarImage, {
          x: 1229,
          y: page5.getHeight() - 32 - 50,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Coach name
      page5.drawText(`${coachName}`, {
        x: 1287,
        y: page5.getHeight() - 65,
        size: 24,
        color: rgb(0, 0, 0),
        fontFamily: "Lexend",
        width: 116,
        height: 30,
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });
      // Draw the text "how we" with specified styles
      page5.drawText("How We", {
        x: 121,
        y: page5.getHeight() - 215 - 95, // Adjusted for top position and line height
        size: 67,
        // font: await pdfDoc.embedFont(StandardFonts.Helvetica),
        color: rgb(0, 0, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });

      // Draw the text "Score" with specified styles
      page5.drawText("Score", {
        x: 121,
        y: page5.getHeight() - 215 - 170, // Adjusted for top position and height
        size: 67,
        // font: await pdfDoc.embedFont(StandardFonts.Helvetica),
        color: rgb(1, 94 / 255, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });
      // Draw the text "The LARA platform is designed to provide actionable insights into coaching conversations."
      //The LARA platform is designed to provide actionable insights into coaching conversations. This appendix explains the key components of the score recap, breaking down the four sub-scores: Listen, Affirm, Respond, and Ask
      // Draw the text "The LARA platform is designed to provide actionable insights"
      page5.drawText(
        "The LARA platform is designed to provide actionable insights",
        {
          x: 498,
          y: page5.getHeight() - 290,
          size: 32,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "into coaching conversations. This appendix explains the key"
      page5.drawText(
        "into coaching conversations. This appendix explains the key",
        {
          x: 498,
          y: page5.getHeight() - 335,
          size: 32,
          // font: helveticaFont,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "components of the score recap, breaking down the four sub-"
      page5.drawText(
        "components of the score recap, breaking down the four sub-",
        {
          x: 498,
          y: page5.getHeight() - 380,
          size: 32,
          //font: helveticaFont,
          color: rgb(0, 0, 0),
          width: 914,
          height: 42,
          lineHeight: 42,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          fontFamily: "Lexend",
          letterSpacing: -0.03,
        }
      );

      // Draw the text "scores:"
      page5.drawText("scores:", {
        x: 498,
        y: page5.getHeight() - 425,
        size: 32,
        //font: helveticaFont,
        color: rgb(0, 0, 0),
        width: 914,
        height: 42,
        lineHeight: 42,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: -0.03,
      });

      // Draw the text "Listen, Affirm, Respond, and Ask" with blue color
      page5.drawText("Listen, Affirm, Respond, and Ask", {
        x: 610,
        y: page5.getHeight() - 425,
        size: 32,
        //font: helveticaFont,
        color: rgb(0, 118 / 255, 186 / 255), // AlterCall-Blue color
        width: 914,
        height: 42,
        lineHeight: 42,
        fontWeight: 600,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: -0.03,
      });
      // Draw a horizontal line with the specified properties
      page5.drawRectangle({
        x: -38,
        y: page5.getHeight() - 499,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      // Draw a vertical line with the specified properties
      page5.drawRectangle({
        x: 354,
        y: page5.getHeight() - 1863,
        width: 1364,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(90),
      });
      page5.drawText("LISTEN", {
        x: 150,
        y: page5.getHeight() - 550,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 112,
        height: 39,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "This score evaluates the quality of attentiveness and understanding demonstrated during the conversation. It",
        {
          x: 402,
          y: page5.getHeight() - 555,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "measures how well the coach listens to the coachee's concerns, ideas, and emotions without interrupting or",
        {
          x: 402,
          y: page5.getHeight() - 583,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("steering the conversation.", {
        x: 402,
        y: page5.getHeight() - 611,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 650,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 1400,
        textAlign: "left",
        fontFamily: "Lexend-bold",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Active listening, avoiding interruptions, recognizing themes, and understanding non-verbal cues.",
        {
          x: 520,
          y: page5.getHeight() - 650,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 706,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Practice mindfulness, focus on the speaker without distractions, and summarize key points",
        {
          x: 570,
          y: page5.getHeight() - 706,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1022,
          height: 290,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("back to the coachee.", {
        x: 402,
        y: page5.getHeight() - 734,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1022,
        height: 290,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("AFFIRM", {
        x: 150,
        y: page5.getHeight() - 850,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 116,
        height: 39,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "The Affirm score assesses the coach's ability to validate and acknowledge the coachee's thoughts and feelings.",
        {
          x: 402,
          y: page5.getHeight() - 855,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "This includes offering positive reinforcement, showing empathy, and creating a supportive environment where ",
        {
          x: 402,
          y: page5.getHeight() - 883,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("the coachee feels heard and valued.", {
        x: 402,
        y: page5.getHeight() - 911,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 950,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Offering validation, providing encouragement, acknowledging the feelings and efforts.",
        {
          x: 520,
          y: page5.getHeight() - 950,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1006,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Use affirming language, focus on the coachee’s progress, and express appreciation for",
        {
          x: 570,
          y: page5.getHeight() - 1006,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1014,
          height: 257,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("their insights and contributions.", {
        x: 402,
        y: page5.getHeight() - 1034,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1014,
        height: 257,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("RESPOND", {
        x: 150,
        y: page5.getHeight() - 1200,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 143,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "The Respond score evaluates how effectively the coach answers questions or offers insights based on what the",
        {
          x: 402,
          y: page5.getHeight() - 1205,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "coachee has shared. This score also reflects the relevance and clarity of the coach's feedback, ensuring it",
        {
          x: 402,
          y: page5.getHeight() - 1233,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("aligns with the coachee's needs and goals.", {
        x: 402,
        y: page5.getHeight() - 1261,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 1300,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Timeliness, clarity, and relevance of responses; offering feedback that reflects the progress.",
        {
          x: 520,
          y: page5.getHeight() - 1300,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1356,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 2100,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Tailor responses to the coachee’s unique situation, be clear and concise, and offer",
        {
          x: 570,
          y: page5.getHeight() - 1356,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("solutions that the coachee can apply.", {
        x: 402,
        y: page5.getHeight() - 1384,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 400,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText("ASK", {
        x: 180,
        y: page5.getHeight() - 1530,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 58,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      page5.drawText(
        "This score reflects the coach’s ability to ask thought-provoking, open-ended questions that encourage deeper",
        {
          x: 402,
          y: page5.getHeight() - 1535,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "reflection and exploration of the coachee’s ideas and challenges. Effective questioning helps the coachee",
        {
          x: 402,
          y: page5.getHeight() - 1563,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "discover new perspectives and develop actionable plans.",
        {
          x: 402,
          y: page5.getHeight() - 1591,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Key Criteria:", {
        x: 402,
        y: page5.getHeight() - 1630,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255),
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend-bold",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Open-ended questions, depth of inquiry, fostering self-reflection and action planning.",
        {
          x: 520,
          y: page5.getHeight() - 1630,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255),
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText("Improvement Tips:", {
        x: 402,
        y: page5.getHeight() - 1686,
        size: 20,
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C
        width: 1032,
        height: 270,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        fontFamily: "Lexend",
        letterSpacing: -0.03,
        opacity: 1,
      });

      page5.drawText(
        "Use probing questions to dig deeper into the coachee’s thoughts, avoid leading",
        {
          x: 570,
          y: page5.getHeight() - 1686,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255),
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );

      page5.drawText(
        "questions, and encourage self-directed problem-solving.",
        {
          x: 402,
          y: page5.getHeight() - 1714,
          size: 20,
          color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C
          width: 1032,
          height: 270,
          lineHeight: 28,
          fontWeight: 400,
          textAlign: "left",
          fontFamily: "Lexend",
          letterSpacing: -0.03,
          opacity: 1,
        }
      );
      page5.drawRectangle({
        x: -38,
        y: page5.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page5.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page5.getHeight() - 1933,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(0, 0, 0),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page5.drawText("www.altercall.com/aai", {
        x: 770,
        y: page5.getHeight() - 1933,
        size: 20,
        fontFamily: "Lexend",
        color: rgb(0, 0, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page5.drawText("07", {
        x: 1437,
        y: page5.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        fontFamily: "Lexend",
        width: 18,
        height: 19,
        opacity: 1,
      });

      page5.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page5.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      //page 6
      const page6 = pdfDoc.addPage([1500, 1974]);

      // Set the background color for page 5
      page6.drawRectangle({
        x: 0,
        y: 0,
        width: page6.getWidth(),
        height: page6.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      // Draw the gradient overlay
      const gradientSvg = `
      <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_2602)"/>
        <defs>
          <linearGradient id="paint0_linear_4460_2602" x1="631.5" y1="1858.5" x2="2361.99" y2="710.973" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-color="#3480FF"/>
          </linearGradient>
        </defs>
      </svg>
    `;

      const gradientPng = await svgToPng(gradientSvg);
      const gradientImage = await pdfDoc.embedPng(gradientPng);

      page6.drawImage(gradientImage, {
        x: 0,
        y: 0,
        width: page6.getWidth(),
        height: page6.getHeight(),
      });

      // const aaiLogoImageBytes = await fetch(aaiLogoVerticalBlack).then((res) => res.arrayBuffer());
      // const aaiLogoImage = await pdfDoc.embedPng(aaiLogoImageBytes);
      // Embed the altercall.ai logo on page 5
      const logoSvg = `
    <svg width="276" height="156" viewBox="0 0 276 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M166.664 65.6049C171.849 74.6734 177.038 83.7497 182.275 92.9114C180.762 93.7765 179.295 94.6178 177.737 95.5076C172.618 86.5573 167.518 77.6407 162.454 68.7871C146.173 78.2908 130.011 78.2908 113.703 68.7882C108.651 77.6204 103.549 86.5415 98.4221 95.5053C96.8901 94.6291 95.4199 93.7877 93.8833 92.9103C99.1083 83.7699 104.296 74.6947 109.498 65.5993C98.0791 55.4612 92.592 42.8349 93.7135 27.5742C94.4874 17.0604 99.6797 6.18097 106.508 0C107.692 1.23732 108.879 2.47689 110.063 3.71533C93.2658 20.2538 96.0577 47.4141 112.216 60.8491C120.815 45.8123 129.405 30.7868 138.08 15.6161C146.746 30.771 155.337 45.7954 163.931 60.8255C179.511 48.0732 183.339 21.1975 165.96 3.60735C167.175 2.4274 168.392 1.24744 169.611 0.0663653C186.49 15.4035 188.643 48.053 166.663 65.6061H166.664V65.6049ZM116.396 64.0785C130.164 73.4574 148.411 72.1526 159.735 64.0279C152.545 51.4522 145.351 38.8721 138.08 26.1558C130.804 38.8811 123.611 51.4612 116.396 64.0774L116.396 64.0785Z" fill="white"/>
    <path d="M20.5421 148.821L6.00242 148.821L2.87102 155.771L1.66699e-10 155.771L11.9304 129.751L14.6513 129.751L26.5817 155.771L23.6735 155.771L20.5421 148.821ZM19.5351 146.59L13.2723 132.614L7.00948 146.59L19.5351 146.59Z" fill="white"/>
    <path d="M38.3623 129.751L41.1217 129.751L41.1217 153.392L55.7358 153.392L55.7358 155.771L38.3637 155.771L38.3637 129.751H38.3623Z" fill="white"/>
    <path d="M70.423 132.13L61.252 132.13L61.252 129.751L82.3534 129.751L82.3534 132.13L73.1824 132.13L73.1824 155.771L70.423 155.771L70.423 132.13Z" fill="white"/>
    <path d="M112.662 153.392L112.662 155.771L94.2085 155.771L94.2085 129.751L112.103 129.751L112.103 132.13L96.9665 132.13L96.9665 141.385L110.462 141.385L110.462 143.727L96.9665 143.727L96.9665 153.392L112.662 153.392Z" fill="white"/>
    <path d="M144.424 155.771L138.459 147.407C137.788 147.482 137.117 147.519 136.372 147.519L129.363 147.519L129.363 155.771L126.604 155.771L126.604 129.751L136.372 129.751C143.008 129.751 147.033 133.096 147.033 138.673C147.033 142.762 144.87 145.623 141.068 146.85L147.442 155.771L144.423 155.771L144.424 155.771ZM144.275 138.673C144.275 134.509 141.516 132.13 136.297 132.13L129.363 132.13L129.363 145.177L136.297 145.177C141.517 145.177 144.275 142.76 144.275 138.671V138.673Z" fill="white"/>
    <path d="M158.701 142.762C158.701 135.143 164.517 129.529 172.382 129.529C176.184 129.529 179.615 130.792 181.926 133.32L180.174 135.067C178.05 132.911 175.439 131.982 172.457 131.982C166.157 131.982 161.422 136.592 161.422 142.762C161.422 148.932 166.157 153.542 172.457 153.542C175.439 153.542 178.048 152.612 180.174 150.42L181.926 152.167C179.615 154.695 176.186 155.995 172.345 155.995C164.516 155.995 158.701 150.383 158.701 142.762Z" fill="white"/>
    <path d="M210.969 148.821L196.43 148.821L193.298 155.771L190.427 155.771L202.358 129.751L205.079 129.751L217.009 155.771L214.101 155.771L210.969 148.821ZM209.962 146.59L203.7 132.614L197.437 146.59L209.962 146.59Z" fill="white"/>
    <path d="M228.79 129.751L231.549 129.751L231.549 153.392L246.163 153.392L246.163 155.771L228.791 155.771L228.791 129.751H228.79Z" fill="white"/>
    <path d="M258.203 129.751L260.963 129.751L260.963 153.392L275.577 153.392L275.577 155.771L258.205 155.771L258.205 129.751H258.203Z" fill="white"/>
    </svg>
    `;

      const logoImagePng = await svgToPng(logoSvg);
      const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

      page6.drawImage(logoImageEmbedded, {
        x: 612,
        y: page6.getHeight() - 112 - 156,
        width: 275.58,
        height: 156,
        opacity: 1,
      });
      // Draw the first text block

      const text1 = "AlterCall AI is a";
      const text2 = "revolutionary";
      const ltext3 = "Leadership Platform";
      const text4 = "we measure the";
      const text5 = "previously";
      const text6 = "unmeasurable.";

      const pageWidth = page6.getWidth();
      const pageHeight = page6.getHeight();

      // Text Configuration
      const lfontSize = 78;
      const lineHeight = 88;
      const left = 136;
      const top = pageHeight - 987;
      const colorWhite = rgb(1, 1, 1); // White color
      const colorOrange = rgb(1, 94 / 255, 0); // Orange color

      // Draw Text 1
      page6.drawText(text1, {
        x: left,
        y: top,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 2
      page6.drawText(text2, {
        x: left,
        y: top - lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 3
      page6.drawText(ltext3, {
        x: left,
        y: top - 2 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 4
      page6.drawText(text4, {
        x: left,
        y: top - 3 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 5
      page6.drawText(text5, {
        x: left,
        y: top - 4 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 6
      page6.drawText(text6, {
        x: left,
        y: top - 5 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });
      // Draw the new text block
      const newText1 =
        "We are pioneering the coaching industry with a specialized";
      const newText2 =
        "AI platform that doesn't just speculate about your potential;";
      const newText3 = "it knows it,";
      const newText4 = "and more importantly, it's here to unlock it.";

      const newFontSize = 24;
      const newLineHeight = 35;
      const newLeft = 136;
      const newTop = pageHeight - 1544;
      const newColor = rgb(1, 1, 1); // White color

      // Draw new Text 1
      page6.drawText(newText1, {
        x: newLeft,
        y: newTop,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 2
      page6.drawText(newText2, {
        x: newLeft,
        y: newTop - newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 3
      // Draw new Text 3
      page6.drawText(newText3, {
        x: newLeft,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 700,
      });

      // Draw new Text 4
      page6.drawText(newText4, {
        x: newLeft + 115,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 1400,
      });

      // Draw a horizontal line with the specified properties
      page6.drawRectangle({
        x: -38,
        y: page6.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page6.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page6.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(1, 1, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page6.drawText("www.altercall.com/aai", {
        x: 770,
        y: page6.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      // Add a clickable link annotation
      // page2.addLinkAnnotation({
      //   x: 515,
      //   y: page2.getHeight() - 1897,
      //   width: 894,
      //   height: 24,
      //   url: 'http://www.altercall.com/aai',
      // });
      page6.drawText("08", {
        x: 1437,
        y: page6.getHeight() - 1933,
        size: 18,
        color: rgb(1, 1, 1),
        width: 18,
        height: 19,
        opacity: 1,
      });

      page6.drawImage(vectorFooterwhiteImage, {
        x: 35.72,
        y: page6.getHeight() - 1933,
        width: 38,
        height: 35.72,
        opacity: 1,
        //rotate: degrees(-90),
      });

      // Serialize the PDF to Uint8Array and start the download
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, `${callName}.pdf`);
    } catch (err) {
      console.error("Error generating the PDF report:", err);
    }
  };

  // pdf Report for coachee

  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    let sortedEdits = [];
    if (initialAction && initialAction.edits && initialAction.edits[0]) {
      sortedEdits = initialAction.edits
        ? [...initialAction.edits].sort(
            (a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp)
          )
        : [];
      originalAction = sortedEdits[0].previous_value;
    }
    return (
      <Modal open={openEditActionModal} onClose={handleCloseEditAction}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editText" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editText")}
              >
                Edit Action
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editHistory" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editHistory")}
              >
                View History
              </Typography>
            </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto",
                }}
              >
                {sortedEdits &&
                  sortedEdits
                    .filter((edit) => edit && edit.edit_id !== null)
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px",
                          }}
                        >
                          edited by{" "}
                          {user?.sub === edit.edited_by_userid
                            ? "me"
                            : user?.sub === clientId
                            ? coachName ?? "Coach"
                            : (clientShared ?? clientName) || "Client"}{" "}
                          {formatDistanceToNow(new Date(edit.edit_timestamp))}{" "}
                          ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                {initialAction.edits && originalAction && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      {originalAction}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      orginal action
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction.action}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value,
                      }));
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const editQuickRecapModal = () => {
    return (
      <Modal
        open={openEditQuickRecapModal}
        onClose={() => setOpenEditQuickRecapModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "50%",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "36px",
              color: "black",
              fontWeight: 300,
            }}
          >
            Edit Recap
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  lineHeight: "150%",
                  letterSpacing: "2px",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Recap
              </Typography>
              <TextField
                required
                fullWidth
                autoFocus
                multiline
                rows={4}
                value={updatedQuickRecap}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "black",
                      height: "70px",
                    },
                  },
                  style: {
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    backgroundColor: "transparent",
                  },
                }}
                onChange={(event) => {
                  setUpdatedQuickRecap(event.target.value);
                }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="15px">
              <NegativeActionButton
                onClick={handleCloseEditQuickRecap}
                label={"Cancel"}
              />
              <PositiveActionButton
                onClick={saveEditQuickRecap}
                label={"Save Recap"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  const saveEditAction = async () => {
    console.log("here")
    try {
      setOpenEditActionModal(false);
      const { status, response } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`, {
          action_id: updatedAction.action_id,
          action: updatedAction.action,
          initial_action: initialAction.action,
          edited_by_userID: user?.sub
      });
      if (status === "success") {
        toast(`Updated action successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setCallActions((prev) =>
          prev.map((prevRow) =>
            prevRow.action_id === updatedAction.action_id
              ? { ...prevRow, action: updatedAction.action }
              : prevRow
          )
        );
        setUpdatedAction("");
      }
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const saveEditQuickRecap = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-file-recap`,
        {
          call_id: fileData[0]?.id,
          quick_recap: updatedQuickRecap,
        }
      );
      if (status === "success") {
        toast(`Updated recap successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setQuickRecap(updatedQuickRecap);
        setOpenEditQuickRecapModal(false);
      }
    } catch (error) {
      console.error("Error updating action:", error);
      setOpenEditQuickRecapModal(false);
    }
  };
  //change from here
  return (
    <Box
      sx={{
        backgroundColor: "#EFF3F6",
        width: "100%",
        height: "100%",
        padding: {
          xs: "16px",
          md: "32px 40px",
        },
        display: {
          xs: !collapsed ? "none" : "block",
        },
        overflowY: "auto",
      }}
    >
      {!loading && (
        <Box width={"80%"}>
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: { xs: "24px", md: "36px" },
              fontWeight: 300,
              marginBottom: "16px",
            }}
            data-cy="session-title"
          >
            {callName}
          </Typography>
        </Box>
      )}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "50%",
            left: { xs: "50%", lg: "60%", xl: "58%" },
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "30px",
            },
            paddingTop: {
              xs: "30px",
              md: "30px",
            },
            paddingBottom: {
              xs: "30px",
              md: "30px",
            },
            maxHeight: { md: "calc(100vh - 100px)" },
            height: "95%",
            // overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
            // "&::-webkit-scrollbar": {
            //   width: "8px",
            // },
            // "&::-webkit-scrollbar-thumb": {
            //   backgroundColor: "rgb(207, 207, 207)",
            //   borderRadius: "4px",
            // },
            // "&::-webkit-scrollbar-thumb:hover": {
            //   backgroundColor: "rgb(207, 207, 207)",
            // },
            // "&::-webkit-scrollbar-track": {
            //   backgroundColor: "transparent",
            // },
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgb(207, 207, 207)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgb(207, 207, 207)",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              paddingRight: "22px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "30px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "24px",
                      md: "32px",
                    },
                    lineHeight: "1.2",
                    letterSpacing: "-0.02em",
                    color: "#160042",
                    fontWeight: 300,
                  }}
                >
                  {"Your call had " +
                    (actionsArray.length ? actionsArray.length : "0") +
                    " actions."}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "14px",
                      md: "18px",
                    },
                    color: "#999999",
                    lineHeight: "1.4",
                    letterSpacing: "-0.03em",
                  }}
                >
                  {user?.sub === clientId ? (
                    <span>{"Coach: " + (coachName ? coachName : "")}</span>
                  ) : status === "assigned" && clientShared ? (
                    <Box
                      component="span"
                      sx={{
                        cursor: "pointer",
                        color: "#007bff",
                        "&:hover": {
                          color: "#0000FF",
                        },
                      }}
                      onClick={() =>
                        navigate(`/platform/coach/coachee-overview/${clientId}`)
                      }
                    >
                      {"Coachee: " + clientShared}
                    </Box>
                  ) : (
                    <span>
                      {"Participant: " + (clientName ? clientName : "")}
                    </span>
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "14px",
                      md: "18px",
                    },
                    color: "#999999",
                    lineHeight: "1.4",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Date: {formattedZoomDate}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "14px",
                      md: "18px",
                    },
                    color: "#999999",
                    lineHeight: "1.4",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Duration {duration} mins
                </Typography>

                {userData?.accessTo !== "platform" ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "30px",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: {
                          xs: "14px",
                          md: "18px",
                        },
                        color: "#999999",
                        lineHeight: "1.4",
                        letterSpacing: "-0.03em",
                      }}
                    >
                      Call Status:{" "}
                      {status === "assigned" ? "Shared" : "Ready to Share"}
                      {status === "assigned" && user?.sub === clientId
                        ? " to You"
                        : ""}
                    </Typography>
                    <Box>
                      {status !== "assigned" &&
                        userData.accessTo === "platform" && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              transition: "all 0.3s",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => {
                              if (status === "ReadyToPublish") {
                                setShowClientList(!showClientList);
                              }
                            }}
                          >
                            <ShareIcon />
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#999999",
                              }}
                            >
                              Share
                            </Typography>
                          </Box>
                        )}
                      {showClientList && (
                        <Box
                          position="absolute"
                          zIndex={2}
                          sx={{
                            right: {
                              xs: "-10px",
                              lg: "unset",
                            },
                            top: {
                              xs: "30px",
                              lg: "unset",
                            },
                            marginLeft: { xs: "0", md: "-330px" },
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            width: "430px",
                            background: "#F8F9FA",
                            padding: "16px 28px",
                            borderRadius: "8px",
                            boxShadow:
                              "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                          }}
                          onMouseLeave={() => {
                            setShowClientList(false);
                          }}
                        >
                          <Box display="flex" flexDirection="column" gap="4px">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                sx={{
                                  color: "#160042",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "1.5",
                                  letterSpacing: "-0.03",
                                }}
                              >
                                Who is this session being shared with?
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="4px">
                              <ErrorOutline
                                sx={{
                                  color: "#00B11C",
                                  fontSize: "11px",
                                  width: "11px",
                                  height: "11px",
                                }}
                              />
                              <Typography
                                sx={{
                                  color: "#6B7094",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.01",
                                }}
                              >
                                Once confirmed, the recipient will be notified
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              padding: "8px",
                              borderRadius: "8px",
                              background: "white",
                              maxHeight: "180px",
                              overflowY: "auto",
                            }}
                          >
                            {clientData && clientData.length > 0 ? (
                              clientData.map((client) =>
                                client.full_name ? (
                                  <Box
                                    key={client.user_id}
                                    display="flex"
                                    gap="12px"
                                    alignItems="center"
                                    padding="4px 12px"
                                    borderRadius="4px"
                                    sx={{
                                      background:
                                        client.full_name === assignee.name
                                          ? "#F5F5F5"
                                          : "initial",
                                      "&:hover": {
                                        background: "#F5F5F5",
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={() => {
                                      setAssignee({
                                        id: client.user_id,
                                        name: client.full_name,
                                      });
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        background:
                                          "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                        padding: "2px",
                                        borderRadius: "50%",
                                        width: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          background: "white",
                                          padding: "1px",
                                          borderRadius: "50%",
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      >
                                        <Avatar
                                          sx={{
                                            width: "22px",
                                            height: "22px",
                                          }}
                                          src={client.avatar || avatar}
                                        />
                                      </Box>
                                    </Box>
                                    <Typography
                                      color="#1B114A"
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "300",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {client.full_name}
                                    </Typography>
                                  </Box>
                                ) : null
                              )
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "12px",
                                }}
                              >
                                <Typography
                                  color="#1B114A"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "300",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Add Your First Team Member
                                </Typography>
                                <PersonAddAlt
                                  sx={{
                                    color: "black",
                                    fontSize: "24px",
                                    width: "24px",
                                    height: "24px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      "/platform/enterprise/admin-portal"
                                    )
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "15px",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              sx={{
                                paddingX: "20pt",
                                paddingY: "5pt",
                                borderRadius: "53px",
                                background: "#FFFFFF",
                                color: "#C10404",
                                border: "1px solid #BE0808",
                                fontWeight: "bold",
                                "&:hover": {
                                  background: "rgba(190, 8, 8, 0.4)",
                                  border: "1px solid rgba(190, 8, 8, 0.4)",
                                  color: "white",
                                },
                              }}
                              onClick={() => setShowClientList(false)}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  letterSpacing: "2px",
                                  textTransform: "uppercase",
                                }}
                              >
                                Cancel
                              </Typography>
                            </Button>
                            <Button
                              sx={{
                                paddingX: "20pt",
                                paddingY: "5pt",
                                borderRadius: "52px",
                                color: "white",
                                background:
                                  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                "&:disabled": {
                                  backgroundColor: "#DEDEDE",
                                  color: "#B7B7B7",
                                },
                                "&[disabled]": {
                                  backgroundColor: "#DEDEDE !important",
                                  color: "#B7B7B7 !important",
                                },
                              }}
                              disabled={!assignee.id}
                              onClick={openAssignModal}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  color: "white",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  letterSpacing: "2px",
                                  textTransform: "uppercase",
                                }}
                              >
                                Confirm
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: "20px",
                  maxHeight: { xs: "100%", md: "50%" },
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "100%", md: "50%" },
                  }}
                >
                  <VideoPlayerCard videoUrl={videoUrl} />
                  <Button
                    sx={{
                      fontSize: "14px",
                      color: "#B3B3B3",
                      textTransform: "none",
                      minWidth: "unset",
                      display: { xs: "flex", md: "none" },
                    }}
                    onClick={() => downloadTranscript()}
                  >
                    Download transcript
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", md: "50%" },
                    maxHeight: "100%",
                    overflow: "auto",
                    background: "#EFF3F6",
                    borderRadius: "12px",
                    padding: "10px",
                    minHeight: "300px",
                  }}
                >
                  <SessionDetails
                    user={user}
                    fileData={fileData}
                    quickRecap={quickRecap}
                    setQuickRecap={setQuickRecap}
                    setCallActions={setCallActions}
                    actionsArray={actionsArray}
                    labels={labels}
                    summaries={summaries}
                    clientName={clientName}
                    coachName={coachName}
                    clientShared={clientShared}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: { xs: "30px", lg: "0px" },
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loadingFile || !laraData ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: "black",
                      }}
                    />
                  </Box>
                ) : (
                  <LaraSessionPage
                    downloadTranscript={downloadTranscript}
                    laraData={laraData}
                    downloadReport={downloadReport}
                    downloadReportcoachee={downloadReportcoachee}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {editActionModal()}
      {editQuickRecapModal()}
      {assignModalContent()}
      {assignedModalContent()}
    </Box>
  );
};

export default SessionPage;
