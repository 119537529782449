import React from "react";

function Client({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.3946 20.9991H20.5C21.6046 20.9991 22.5 20.1037 22.5 18.9991C22.5 17.2322 21.3543 15.7328 19.7653 15.2034M16.404 3.48047C17.6524 4.15739 18.5 5.47927 18.5 6.99914C18.5 8.51901 17.6524 9.8409 16.404 10.5178M13.5 6.99902C13.5 9.20816 11.7091 10.999 9.5 10.999C7.29086 10.999 5.5 9.20816 5.5 6.99902C5.5 4.78988 7.29086 2.99902 9.5 2.99902C11.7091 2.99902 13.5 4.78988 13.5 6.99902ZM6 14.999H13C15.2091 14.999 17 16.7899 17 18.999C17 20.1036 16.1046 20.999 15 20.999H4C2.89543 20.999 2 20.1036 2 18.999C2 16.7899 3.79086 14.999 6 14.999Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Client;
