import { Pagination } from '@mui/material';
import React from 'react';

const PaginationForContentLibrary = ({ handlePaginationChange, paginationSelectedPage, paginationCount }) => {
    return (
        <Pagination
            sx={{
                // border: '1px solid red',
                display: 'flex',
                borderRadius: '10px',
                justifyContent: 'center',
                paddingX: '0px',
                backgroundColor: '#EFF3F6',
                '& .MuiPaginationItem-root': {
                    color: 'black',
                },
                '& .Mui-selected': {
                    backgroundColor: 'transparent !important',
                    fontWeight: 'bold',
                    color: 'black !important',
                },
                '& .MuiPaginationItem-page:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            }}
            count={Math.ceil(paginationCount / 10)}
            color='primary'
            page={paginationSelectedPage}
            onChange={handlePaginationChange}
        />
    );
};

export default PaginationForContentLibrary;








