import React, { useEffect, useState } from 'react';
import "./resourcePage.css"
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
//import { API } from 'aws-amplify';
import DefaultResourcesPage from './DefaultResourcesPage';
import 'react-quill/dist/quill.snow.css';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const ResourcePage = () => {
    const [searchParams] = useSearchParams();
    const [sectionIdFromParams, setSectionIdFromParams] = useState('')
    const [fetchedHtmlContent, setFetchedHtmlContent] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const pageId = searchParams.get('pageId');
        const sectionId = searchParams.get('sectionId');
        setSectionIdFromParams(parseInt(sectionId))

        async function fetchHtmlContentForAskedPage() {
            if (!pageId) {
                return
            }
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-all-sections-of-a-page/${pageId}`)
            const htmlContent = response.data;
            const serializedHtmlContent = htmlContent.sort((a, b) => a.section_id - b.section_id)
            setFetchedHtmlContent(serializedHtmlContent)
            setLoading(false)
        }

        if (pageId && sectionId) fetchHtmlContentForAskedPage()
        else return;

    }, [searchParams])

    useEffect(() => {
        if (sectionIdFromParams) {
            const section = document.getElementById(sectionIdFromParams)
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [fetchedHtmlContent, sectionIdFromParams])

    return (
        < Box sx={{ paddingTop: "5vh", width: '100%', marginBottom: "50px", }}  >
            <Box sx={{ display: 'flex', flexDirection: "column", width: { xs: "auto", lg: '50%' }, marginX: 'auto' }}>
                {
                    loading && <Box sx={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        width: "100vh"
                    }} >
                        <CircularProgress color="inherit" />
                    </Box>
                }

                {fetchedHtmlContent.length === 0 ? <DefaultResourcesPage /> :
                    fetchedHtmlContent.map((section, index) => {
                        return <div className="view ql-editor html-container" style={{ fontFamily: "Poppins", fontSize: "16px", lineHeight: '24px', }} id={section.section_id} key={index} dangerouslySetInnerHTML={{ __html: section.section_contents }}></div>
                    })
                }


            </Box>

        </ Box >

    );
};

export default ResourcePage;