import { useEffect, useState } from "react";

const LaraSessionCircle = ({ laraOverallScore }) => {
  const Circle = ({ id, color, score, radius, fill, strokeDashoffset }) => {
    const r = radius;
    const circ = 2 * Math.PI * r;
    const strokescore = ((100 - score) * circ) / 100;

    return (
      <circle
        r={r}
        cx={50} // Adjusted to fit within the smaller viewBox
        cy={50} // Adjusted to fit within the smaller viewBox
        fill={fill ?? "transparent"}
        stroke={color ? color : `url(#${id})`}
        strokeWidth={"0.4rem"}
        strokeDasharray={circ}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        style={{ transition: "stroke-dashoffset 1s ease-out" }}
      ></circle>
    );
  };

  const Text = ({ score, textSize }) => {
    return (
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle" // Changed to "middle" to center text vertically
        textAnchor="middle"
        fontSize={textSize}
        fontFamily="Poppins"
        fontWeight={300}
      >
        {isNaN(score) ? "-" : (score === 0 ? "0" : Math.round(score))}
      </text>
    );
  };

  const Pie = ({ score, radius, color, textSize, fillStopColor }) => {
    const [strokeDashoffset, setStrokeDashoffset] = useState(2 * Math.PI * radius);
    const gradientId = `gradient-${Math.random()}`;
    const fillGradientId = `fill-gradient-${Math.random()}`;

    useEffect(() => {
        const circ = 2 * Math.PI * radius;
        const strokescore = ((100 - score) * circ) / 100;
        setStrokeDashoffset(strokescore);
      }, [score, radius]);

    return (
      <svg viewBox="0 0 100 100" width={40} height={40}> {/* Adjusted viewBox and dimensions */}
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="0%" stopColor="#e383eb" />
            <stop offset="40%" stopColor="#8e33b8" />
            <stop offset="60%" stopColor="#568FF3" />
            <stop offset="100%" stopColor="#62C1EB" />
          </linearGradient>
          <linearGradient id={fillGradientId}>
            <stop offset="0%" stopColor={fillStopColor ?? "#62C1EB"} stopOpacity=".2" />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
          </linearGradient>
        </defs>
        <g transform={`rotate(90 50 50)`}> {/* Adjusted transform to fit within the smaller viewBox */}
          <Circle color="lightgrey" radius={radius} score={100} fill={`url(#${fillGradientId})`} strokeDashoffset={0} />
          <Circle id={gradientId} radius={radius} score={score} color={color} strokeDashoffset={strokeDashoffset} />
        </g>
        {isNaN(score) ? (
          <Text score="-" textSize={textSize} />
        ) : (
          <Text score={score} textSize={textSize} />
        )}
      </svg>
    );
  };

  return (
    <Pie score={laraOverallScore} radius={40} textSize={35} />
  );
};

export default LaraSessionCircle;
