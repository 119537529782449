// src/utils/urlHelper.js

export const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };
  
  export const handleStripeRedirect = async (updateUserStateAfterStripe) => {
    const isFromStripe = getQueryParams('from') === 'stripe'; // Check if 'from=stripe' exists
  
    if (isFromStripe) {
      console.log("Redirected from Stripe, refreshing session...");
      await updateUserStateAfterStripe(); // Call your session refresh function
    } else {
      console.log("Not redirected from Stripe.");
    }
  };
  
  // Include the session refresh function here
  export const updateUserStateAfterStripe = async (fetchUserData) => {
    // Your logic for refreshing the session token
    // After Stripe redirect, refresh the session and update the user data
    console.log("Refreshing session after Stripe redirect...");
    // Call session refresh and fetch user info again
    await fetchUserData();
  };
  