import { Box, CssBaseline, Typography } from "@mui/material";
import Logo from "scenes/Layout/logo";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ClientAcceptLoginForm from "components/ClientAcceptInviteLogin/ClientAcceptLoginForm";
import { useState } from "react";
import ClientAcceptForm from "components/ClientAcceptInviteLogin/ClientAcceptForm";

export const themeOptions = {
    palette: {
      mode: "dark",
      primary: {
        main: "#FFFFFF",
      },
      secondary: {
        main: "#FFFFFF",
      },
      background: {
        default: "#F7F7F7",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
    },
    headerImage: "",
  };
  
  export const theme = createTheme(themeOptions);

const InviteAcceptance = ({user, userData}) => {
    return(
        <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#EFF3F6",
          overflowY: "auto",
        }}
      >
        <Box display="flex" style={{ alignItems: "left", marginRight: "1200px" }}>
          <Logo />
        </Box>
        <ThemeProvider theme={theme}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            style={{
              position: "relative",
            }}
          >
            <CssBaseline />
            <Box
              sx={{
                marginTop: 0,
                margin: { xs: "0px 20px", lg: "0px 0px 0px 150px" },
                minHeight: "calc(100vh - 198px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
                borderRadius: "10px",
                width: "100%",
                zIndex: 1,
              }}
            >
                <ClientAcceptForm user={user} userData={userData}/>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    );
}

export default InviteAcceptance;