import { Modal, Box, Button, Typography, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { toast } from "react-toastify";
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { KeyboardArrowDown } from "@mui/icons-material";

const ActionOnVcUserModal = ({ user, userData, openActionOnVcUserModal, setOpenActionOnVcUserModal, companyMembers, companyInformation, teamsInformation, selectedUser, setSelectedUser, fetchCompanyData, loadingCompanyData }) => {
    const [userTeams, setUserTeams] = useState([]);
    const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);
    const [loadingResendInvite, setLoadingResendInvite] = useState(false);
    const [title, setTitle] = useState(selectedUser?.title);
    const [savingTitle, setSavingTitle] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [markingAsVcAdmin, setMarkingAsVcAdmin] = useState(null);
    const [removingVcAdminRole, setRemovingVcAdminRole] = useState(null);
    const [initialTitle, setInitialTitle] = useState('');

    console.log("selected user: ", selectedUser)

    const CustomIcon = (props) => (
        <KeyboardArrowDown {...props} style={{ color: 'black' }} />
      );
  

    const handleCloseActionOnVcUserModal = () => {
        setTitle('')
        setSelectedUser([]);
        setLoadingResendInvite(false);
        setOpenActionOnVcUserModal(false);
        setUserTeams([]);
    };

    const handleTitleSave = async () => {
        setSavingTitle(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-user-title`, {
                user_id: selectedUser.user_id,
                new_title: title,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            console.log("body: ",selectedUser.user_id, title, companyInformation?.company_id)
            toast.success("Title updated successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error updating title:", error);
            toast.error("Error while updating title");
        } finally {
            setSavingTitle(false);
        }
    };

    const handleMarkAsVcAdmin = async () => {
        try {
            setMarkingAsVcAdmin(true);
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/vc-mark-user-as-admin`, {
                user_id: selectedUser.user_id,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            toast.success("User marked as admin successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error marking user as admin:", error);
            toast.error("Error while marking user as admin");
        }finally{
            setMarkingAsVcAdmin(false)
        }
    };

    const handleMarkAsVcUser = async () => {
        try {
            setRemovingVcAdminRole(true)
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/vc-mark-user-as-user`, {
                user_id: selectedUser.user_id,
                company_id: companyInformation?.company_id,
                caller_id: user?.sub
            });
            toast.success("User removed from admin role successfully");
            fetchCompanyData();
        } catch (error) {
            console.error("Error marking removing admin role:", error);
            toast.error("Error while removing admin role");
        } finally{
            setRemovingVcAdminRole(false);
        }
    };

    const handleResendInvite = async () => {
        try {
            if (!companyInformation) {
                throw new Error("Company information is not available");
            }
            setLoadingResendInvite(true);
            await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/resend-invite`, {
                invited_user_id: selectedUser.user_id,
                user_id: user?.sub,
                company_id: companyInformation?.company_id,
                company_name: companyInformation?.company_name
            });
            toast.success("Invitation sent successfully");
        } catch (error) {
            console.error("Error resending invite:", error);
            alert(error.message || "Failed to resend invite. Please try again.");
        } finally {
            setLoadingResendInvite(false);
        }
    };

    return (
        <Modal
            open={openActionOnVcUserModal}
            onClose={handleCloseActionOnVcUserModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "white",
                    color: "black",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    maxWidth: "calc(100% - 40px)",
                    maxHeight: "80%",
                    overflowY: "hidden",
                    boxShadow: 24,
                    borderRadius: "30px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ color: 'black', fontSize: 36, fontFamily: 'Lexend', fontWeight: '300', wordWrap: 'break-word'}}>
                            Editing: {selectedUser.full_name}
                        </Typography>
                        {selectedUser.invite_status === 'pending' && (
                            <Button
                                sx={{
                                    color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, borderRadius: 50, border: '1px black solid', width: "200px", height: "30px"
                                }}
                                onClick={handleResendInvite}
                                disabled={loadingResendInvite || loadingCompanyData}
                            >
                                {loadingResendInvite ? <CircularProgress size={15} style={{ color: "black" }} /> : "Resend Invite"}
                            </Button>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <Typography sx={{color: 'black', fontSize: 12, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word'}}>
                            Title
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "16px"}}>
                            <TextField
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                            <Button
                                onClick={handleTitleSave}
                                disabled={savingTitle || loadingCompanyData || title === initialTitle}
                                sx={{ 
                                    color: 'black',
                                    fontFamily: "Lexend",
                                    fontWeight: '700',
                                    border: '1px solid #000',
                                    borderRadius: 8,
                                    padding: '6px 12px',
                                    '&.Mui-disabled': {
                                    color: 'black', // Ensure text remains black when disabled
                                    borderColor: '#000', // Ensure border remains visible
                                    opacity: 0.5, // Optional: adjust opacity to indicate disabled state
                                    },
                                 }}
                            >
                                {savingTitle ? <CircularProgress size={15} style={{ color: "black" }} /> : "Save"}
                            </Button>
                        </Box>
                    </Box>
                    {selectedUser?.role !== 'owner' && (
                        <Box
                            sx={{
                                display: "flex", 
                                gap: "20px"
                            }}
                        >
                            {selectedUser?.role === 'user' ? (
                                <Button
                                    onClick={() => handleMarkAsVcAdmin()}
                                    disabled={ markingAsVcAdmin || removingVcAdminRole || loadingCompanyData }
                                    sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", color: "black",
                                        '&.Mui-disabled': {
                                            backround: "#F0F0F0",
                                            color: 'gray', // Gray text
                                            borderColor: 'gray', // Optional: change border color when disabled
                                        }, 
                                    }}
                                >
                                    <Typography sx={{ fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2 }}>
                                        {markingAsVcAdmin ? <CircularProgress size={10} style={{ color: "black" }} /> : "Mark as Admin"}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => handleMarkAsVcUser()}
                                    disabled={ markingAsVcAdmin || removingVcAdminRole || loadingCompanyData }
                                    sx={{ border: "1px solid black", borderRadius: 50, display: "flex", alignItems: "center", gap: "8px", color: "black",
                                        '&.Mui-disabled': {
                                            backround: "#F0F0F0",
                                            color: 'gray', // Gray text
                                            borderColor: 'gray', // Optional: change border color when disabled
                                        }, 
                                    }}
                                >
                                    <Typography sx={{ fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2 }}>
                                        {removingVcAdminRole ? <CircularProgress size={10} style={{ color: "black" }} /> : "Remove Admin Role"}
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ActionOnVcUserModal;