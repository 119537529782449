import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Typography,
  } from "@mui/material";
import {
ErrorOutline,
PersonAddAlt,
} from "@mui/icons-material";

import AssignIcon from "components/Icons/Assign.svg";
import ShareIcon from "components/Icons/Share.svg";
import RefreshIcon from "components/Icons/Refresh.svg";
import DeleteIcon from "components/Icons/Delete.svg";

const SessionsToolBar = ({ 
    user, 
    userData, 
    accessTo, 
    isRefreshHovered, 
    setIsRefreshHovered,
    isShareHovered,
    setIsShareHovered,
    isDeleteHovered,
    setIsDeleteHovered,
    isAssignHovered,
    setIsAssignHovered,
    reFetchUserData, 
    selected, 
    isProfessionalTier, 
    customToast,
    showClientList,
    setShowClientList,
    setAssignee,
    memberData,
    navigate,
    assignee,
    openAssignModal,
    openDeleteModal,
    avatar,
    toast,
    isLoadingProcessing,
    openProcessModal
    }) => {
    return(
        <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
      >
        {accessTo !== "client_portal" ? (
          <Box
            display="flex"
            alignItems="center"
            position="relative"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s",
                cursor: "pointer",
                padding: "8px", // Add padding for better hover effect
                borderRadius: "8px", // Add border-radius for a rounded effect
                backgroundColor: isRefreshHovered
                  ? "#F5F7FF"
                  : "transparent",
                "&:hover": {
                  backgroundColor: "#F5F7FF", // Hover background color
                },
              }}
              onMouseEnter={() => setIsRefreshHovered(true)}
              onMouseLeave={() => setIsRefreshHovered(false)}
              onClick={() => {
                reFetchUserData();
              }}
            >
              {isRefreshHovered === false ? (
                <>
                  <RefreshIcon color="enabled" height={22} width={22} />
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#2D2D2D",
                    }}
                    data-cy="refresh-button"
                  >
                    <span id="hint-anchor-2">Refresh</span>
                  </Typography>
                </>
              ) : (
                <>
                  <RefreshIcon color="hovered" height={22} width={22} />

                  <Typography
                    sx={{
                      background:
                        "black",
                      backgroundOrigin: "border-box",
                      backgroundClip: "text",
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "transparent",
                    }}
                    data-cy="refresh-button"
                  >
                    Refresh
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s",
                padding: "8px", // Add padding for better hover effect
                borderRadius: "8px", // Add border-radius for a rounded effect
                "&:hover": {
                  backgroundColor: "#F5F7FF", // Hover background color
                },
                cursor:
                  selected.length > 0 &&
                  selected.filter((row) => row.status === "incomplete")
                    .length === selected.length
                    ? "pointer"
                    : "not-allowed",
              }}
              disabled={isLoadingProcessing}
              onMouseEnter={() => setIsAssignHovered(true)}
              onMouseLeave={() => setIsAssignHovered(false)}
              onClick={() => {
                if (
                  selected.length > 0 &&
                  selected.filter((row) => row.status === "incomplete")
                    .length === selected.length
                ) {
                  openProcessModal();
                }
              }}
            >
              {isLoadingProcessing ? (
                <CircularProgress size={15} />
              ) : (
                <>
                  {selected.length > 0 &&
                  selected.filter((row) => row.status === "incomplete")
                    .length === selected.length ? (
                    !isAssignHovered ? (
                      <>
                        <AssignIcon color="enabled" height={22} width={22} />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#2D2D2D",
                          }}
                          data-cy="generate-insights"
                        >
                          Generate Analytics
                        </Typography>
                      </>
                    ) : (
                      <>
                        <AssignIcon color="hovered" height={22} width={22} />
                        <Typography
                          sx={{
                            background:
                              "black",
                            backgroundOrigin: "border-box",
                            backgroundClip: "text",
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "transparent",
                          }}
                          data-cy="generate-insights"
                        >
                          Generate Analytics
                        </Typography>
                      </>
                    )
                  ) : (
                    <>
                      <AssignIcon color="disabled" height={22} width={22} />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "20px",
                          color: "#B6B6B6",
                        }}
                        data-cy="generate-insights"
                      >
                        <span id="hint-anchor-3">Generate </span>
                        <span id="hint-anchor-4">Analytics</span>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s",
                padding: "8px", // Add padding for better hover effect
                borderRadius: "8px", // Add border-radius for a rounded effect
                "&:hover": {
                  backgroundColor: "#F5F7FF", // Hover background color
                },
                cursor:
                  selected.length > 0 &&
                  selected.filter(
                    (row) => row.status === "ReadyToPublish"
                  ).length === selected.length
                    ? "pointer"
                    : "not-allowed",
              }}
              onMouseEnter={() => setIsShareHovered(true)}
              onMouseLeave={() => setIsShareHovered(false)}
              onClick={() => {
                if (isProfessionalTier) {
                  toast.error(customToast);
                } else {
                  if (
                    selected.length > 0 &&
                    selected.filter(
                      (row) => row.status === "ReadyToPublish"
                    ).length === selected.length
                  ) {
                    setShowClientList(!showClientList);
                  }
                }
              }}
            >
              {selected.length > 0 &&
              selected.filter((row) => row.status === "ReadyToPublish")
                .length === selected.length ? (
                isShareHovered === false ? (
                  <>
                    <ShareIcon color="enabled" height={22} width={22} />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#2D2D2D",
                      }}
                      data-cy="share-call-button"
                    >
                      Share
                    </Typography>
                  </>
                ) : (
                  <>
                    <ShareIcon color="hovered" height={22} width={22} />
                    <Typography
                      sx={{
                        background:
                          "black",
                        backgroundOrigin: "border-box",
                        backgroundClip: "text",
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "transparent",
                      }}
                      data-cy="share-call-button"
                    >
                      Share
                    </Typography>
                  </>
                )
              ) : (
                <>
                  <ShareIcon color="disabled" height={22} width={22} />
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#B6B6B6",
                    }}
                    data-cy="share-call-button"
                  >
                    Share
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s",
                padding: "8px", // Add padding for better hover effect
                borderRadius: "8px", // Add border-radius for a rounded effect
                "&:hover": {
                  backgroundColor: "#F5F7FF", // Hover background color
                },
                cursor: selected.length > 0 ? "pointer" : "not-allowed",
              }}
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onClick={() => {
                if (selected.length > 0) {
                  openDeleteModal();
                }
              }}
            >
              {selected.length > 0 ? (
                isDeleteHovered === false ? (
                  <>
                    <DeleteIcon color="enabled" height={22} width={22} />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#2D2D2D",
                      }}
                      data-cy="delete-button"
                    >
                      Delete
                    </Typography>
                  </>
                ) : (
                  <>
                    <DeleteIcon color="hovered" height={22} width={22} />
                    <Typography
                      sx={{
                        background:
                          "black",
                        backgroundOrigin: "border-box",
                        backgroundClip: "text",
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "transparent",
                      }}
                      data-cy="delete-button"
                    >
                      Delete
                    </Typography>
                  </>
                )
              ) : (
                <>
                  <DeleteIcon color="disabled" height={22} width={22} />
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#B6B6B6",
                    }}
                    data-cy="delete-button"
                  >
                    Delete
                  </Typography>
                </>
              )}
            </Box>
            {showClientList && (
              <Box
                position="absolute"
                zIndex={2}
                key={2}
                sx={{
                  right: {
                    xs: "0",
                    lg: "unset",
                  },
                  top: {
                    lg: "30px",
                  },
                  left: {
                    lg: "150px",
                  },
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "430px",
                  background: "#F8F9FA",
                  padding: "16px 28px",
                  borderRadius: "8px",
                  boxShadow:
                    "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                }}
                onMouseLeave={() => {
                  setShowClientList(false);
                }}
              >
                <Box display="flex" flexDirection="column" gap="4px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: "#160042",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        letterSpacing: "-0.03",
                      }}
                    >
                      Who is this session being shared with?
                    </Typography>
                    {/* <PersonAddAlt
                      sx={{
                        color: "black",
                        fontSize: "24px",
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/client-center')
                      }}
                    /> */}
                  </Box>
                  <Box display="flex" alignItems="center" gap="4px">
                    <ErrorOutline
                      sx={{
                        color: "#00B11C",
                        fontSize: "11px",
                        width: "11px",
                        height: "11px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#6B7094",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-0.01",
                      }}
                    >
                      Once confirmed, the recipient will be notified
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    background: "white",
                    maxHeight: "180px",
                    overflowY: "auto",
                  }}
                >
                  {memberData && memberData.length > 0 ? (
                    memberData.map((client, key) =>
                      client.full_name ? (
                        <Box
                          key={key}
                          display="flex"
                          gap="12px"
                          alignItems="center"
                          padding="4px 12px"
                          borderRadius="4px"
                          sx={{
                            background:
                              client.full_name === assignee.name
                                ? "#F5F5F5"
                                : "initial",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {
                            setAssignee({
                              id: client.user_id,
                              name: client.full_name,
                            });
                          }}
                        >
                          <Box
                            sx={{
                              background: "#0076BA",

                              padding: "2px",
                              borderRadius: "50%",
                              width: "28px",
                              height: "28px",
                            }}
                          >
                            <Box
                              sx={{
                                background: "white",
                                padding: "1px",
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                }}
                                src={client.avatar || avatar}
                              />
                            </Box>
                          </Box>
                          <Typography
                            color="#1B114A"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "300",
                              lineHeight: "24px",
                            }}
                          >
                            {client.full_name}
                          </Typography>
                        </Box>
                      ) : null
                    )
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography
                        color="#1B114A"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "300",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Add your first client
                      </Typography>
                      <PersonAddAlt
                        sx={{
                          color: "black",
                          fontSize: "24px",
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/platform/enterprise/admin-portal")
                        }
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      paddingX: "20pt",
                      paddingY: "5pt",
                      borderRadius: "53px",
                      background: "#FFFFFF",
                      color: "#C10404",
                      border: "1px solid #BE0808",
                      fontWeight: "bold",
                      "&:hover": {
                        background: "rgba(190, 8, 8, 0.4)",
                        border: "1px solid rgba(190, 8, 8, 0.4)",
                        color: "white",
                      },
                      textTransform: "capitalize",
                    }}
                    onClick={() => setShowClientList(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      paddingX: "20pt",
                      paddingY: "5pt",
                      borderRadius: "52px",
                      color: "white",
                      background:
                        "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:disabled": {
                        backgroundColor: "#DEDEDE",
                        color: "#B7B7B7",
                      },
                      "&[disabled]": {
                        // Change to "&[disabled='true']" if necessary
                        backgroundColor: "#DEDEDE !important",
                        color: "#B7B7B7 !important",
                      },
                      textTransform: "capitalize",
                    }}
                    disabled={!assignee.id}
                    onClick={openAssignModal}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
    )
}

export default SessionsToolBar;